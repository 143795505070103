import React from 'react';
import styles from './LiteEditorParagraph.module.scss';
import {
  convertStringToParagraph,
  isJsonString,
  serialize
} from 'components/atoms/MWEditor/Helpers';
function LiteEditorParagraph({ description, className }) {
  return (
    <div
      className={`${styles.location} ${className ?? ''}`}
      dangerouslySetInnerHTML={{
        __html: serialize(
          description
            ? isJsonString(description)
              ? JSON.parse(description)
              : convertStringToParagraph(description)
            : undefined
        )
      }}
    />
  );
}

export default LiteEditorParagraph;
