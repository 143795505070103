import { FileUploader, Textarea } from 'components/form';
import { Modal } from 'components/modals';
import { Button } from 'components/ui';
import React from 'react';
import { showErrorToast } from 'utils/toast';
function ResourceCreateModal({ isOpen, onClose, onSubmit, isLoading = false }) {
  const [resourceD, setResourceD] = React.useState({
    resourceName: '',
    resourceFile: ''
  });

  const handleSubmit = () => {
    const { resourceName, resourceFile } = resourceD;
    if (!resourceName) return showErrorToast('Please enter resource name');
    if (!resourceFile) return showErrorToast('Please upload resource file');
    setResourceD({
      resourceName: '',
      resourceFile: ''
    });
    onSubmit({ resourceName, resourceFile });
  };
  return (
    <Modal extraClass="awardsModal" isModalOpen={isOpen} onClose={onClose} hasHeader>
      <div
        className="modal-container"
        style={{
          padding: '2rem'
        }}
      >
        <h1>Upload Resources</h1>
        <Textarea
          onChange={({ target: { value } }) => {
            setResourceD((prev) => ({ ...prev, resourceName: value ?? '' }));
          }}
          placeholder="Name"
          value={resourceD.resourceName}
        />

        <FileUploader
          accept="image/*, video/*"
          onChange={(files) => {
            const file = files[0];
            setResourceD((prev) => ({ ...prev, resourceFile: file }));
          }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            gap: '.5rem'
          }}
        >
          <Button onClick={() => onClose?.()}>Close</Button>
          <Button
            disable={isLoading}
            onClick={() => handleSubmit?.()}
            style={{
              ...(isLoading && {
                cursor: 'not-allowed',
                backgroundColor: 'gray'
              })
            }}
          >
            {isLoading ? 'Uploading...' : 'Save'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ResourceCreateModal;
