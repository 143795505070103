import React from 'react';
import { Oval } from 'react-loader-spinner';
import styles from './Loader.module.scss';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import PropTypes from 'prop-types';

function Loader({ size = 4, atStart, fullpage, section, extraClass }) {
  return (
    <section
      className={styles['spinner']}
      style={{
        height: fullpage ? '80vh' : section ? '250px' : 'auto',
        position: 'relative',
        textAlign: atStart ? 'start' : 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Oval
        height={`${size * 12}px`}
        width={`${size * 12}px`}
        color="rgb(25, 180, 146)"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#08dcae89"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <div className={`${styles.load} ${styles[extraClass]}`}>
        <Logo />
      </div>
    </section>
  );
}

Loader.propTypes = {
  size: PropTypes.number,
  atStart: PropTypes.bool,
  fullpage: PropTypes.bool,
  section: PropTypes.bool,
  extraClass: PropTypes.string
};
export default Loader;
