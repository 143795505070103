export const FILTER_ITEMS_TYPES = {
  FILTER_ITEM_CHECK: 0,
  FILTER_ITEM_YEAR_PICKER: 1
};

export const FILTER_TYPES = [
  {
    title: 'Experience',
    filters: [
      {
        name: '0-2',
        value: '0-2'
      },
      {
        name: '2-4',
        value: '2-4'
      },
      {
        name: '4-6',
        value: '4-6'
      },
      {
        name: '6+',
        value: encodeURIComponent('6+')
      }
    ]
  },
  {
    title: 'Started',
    filters: [{ name: ' ', value: '0', type: FILTER_ITEMS_TYPES.FILTER_ITEM_YEAR_PICKER }]
  }
];
