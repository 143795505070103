import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PropTypes from 'prop-types';
import styles from './ImageOptimized.module.scss';

export const ImageOptimized = ({ src, alt }) => (
  <LazyLoadImage src={src} effect="blur" alt={alt} className={styles.image} />
);

ImageOptimized.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};
