import styles from './SidewaysEvent.module.scss';
export default function SidewaysEvent({ fullName, timeString,month,day }) {
    return (
        <div className={styles.eventRow}>
            <div className={styles.dateProfile}>
                <div className={styles.Month}>{month}</div>
                <div className={styles.Day}>{day}</div>
            </div>
              <div className={styles.nameRole}>
                  <h4>{fullName}</h4>
                  <h5>{timeString}</h5>
              </div>
        </div>
      );
}