import React, { forwardRef } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import styles from './EditInput.module.scss';
const EditInput = forwardRef(
  ({ label, extraClass, fontWeight, fontSize, maxLength = 1000, ...rest }, ref) => {
    return (
      <div className={`${styles.editinput} ${styles[extraClass]}`}>
        {label && <label>{label}</label>}
        <ReactTextareaAutosize
          ref={ref}
          style={{ fontWeight, fontSize }}
          maxLength={maxLength}
          minLength={5}
          {...rest}
        />
      </div>
    );
  }
);

export default EditInput;
