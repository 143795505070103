import React, { useState } from 'react';
import styles from './LinkCarousel.module.scss';
import Container from 'components/atoms/InfoHubContainerSection';
import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import { CloseI, EditI, AddI, SaveI, Button, Loader } from 'components/ui';
import { handleAsync } from 'helpers';
import { showSuccessToast } from 'utils/toast';
import Options from 'components/atoms/Options/Options';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';
import { Modal } from 'components/modals';
import { FileUploader, Textarea } from 'components/form';
import { useUploadBlob } from 'hooks';
import { getS3Url } from 'helpers';
import { showErrorToast } from 'utils/toast';
import InfoHubTitle from 'components/atoms/InfoHubTitle';
import { showWarnToast } from 'utils/toast';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

export default function LinkCarousel({
  data,
  title,
  subTitle,
  companyId,
  updateMutation,
  colorBgSwitcher,
  topRowEnabled,
  isLoading,
  ...rest
}) {
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [dataToBeUpdated, setDataToBeUpdated] = useState({});
  const [dataToBeAdded, setDataToBeAdded] = useState({});
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const { uploadBlob, isUploading } = useUploadBlob();
  const isAdmin = useSelector(getIsAdminSelector);
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;
  const handleAdd = async () => {
    let currentData = [];
    let saveData = { link: dataToBeAdded?.link, title: dataToBeAdded?.title };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : 'Error uploading thumbnail';
        return showErrorToast(error);
      }
      saveData.thumbnail = result;
    }
    if (data) {
      currentData = data ? [...data] : [];
    }
    currentData.push({
      link: saveData?.link,
      thumbnail: saveData?.thumbnail,
      title: saveData?.title
    });
    showSuccessToast('Saving...');
    currentData;
    handleAsync(
      updateMutation({
        companyId: companyId,
        dataToBeSaved: currentData
      }).unwrap()
    );
    setThumbnailFile(null);
    setDataToBeAdded({});
    showSuccessToast('Done!');
  };

  const handleUpdate = async () => {
    let currentData = [];
    let saveData = {
      link: dataToBeUpdated.link,
      thumbnail: dataToBeUpdated.thumbnail,
      title: dataToBeUpdated.title
    };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : 'Error uploading thumbnail';
        return showErrorToast(error);
      }
      saveData.thumbnail = result;
    }

    if (data) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      currentData = data ? [...data] : [];
    }

    currentData[dataToBeUpdated.index] = {
      link: saveData?.link,
      thumbnail: saveData?.thumbnail,
      title: saveData?.title
    };

    showSuccessToast('Updating...');
    handleAsync(
      updateMutation({
        companyId: companyId,
        dataToBeSaved: currentData
      }).unwrap()
    );
    setThumbnailFile(null);
    showSuccessToast('Done!');
  };

  const removeData = async (saveData) => {
    let currentData = [];

    if (data) {
      currentData = data ? [...data] : [];
    }

    for (let i = 0; i < currentData.length; i++) {
      if (currentData[i] == saveData) {
        currentData.splice(i, 1);
      }
    }

    showSuccessToast('Removing...');
    const result = await handleAsync(
      updateMutation({
        companyId: companyId,
        dataToBeSaved: currentData
      }).unwrap()
    );
    if (result[0]) {
      showSuccessToast('Done!');
    }
  };

  let topItems = [];
  let bottomItems = [];
  let tempData = data ? [...data] : [];
  if (tempData.length != 0) {
    if (topRowEnabled) {
      if (tempData.length >= 2) {
        topItems = [...tempData.splice(0, 2)];
        bottomItems = [...tempData];
      } else {
        topItems = [...tempData];
      }
    } else {
      bottomItems = [...tempData];
    }
  }
  if (isLoading || isUploading) {
    return (
      <Container>
        <div className={styles.headLiner}>
          <InfoHubTitle title={title} />
        </div>
        <Loader />
      </Container>
    );
  }
  return (
    <div
      className={`${styles.container} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}
    >
      <Container>
        <div className={styles.headLiner}>
          <InfoHubTitle title={title} />
          {edit ? (
            <div className={styles.save}>
              <SaveI onClick={() => setEdit(false)} />
            </div>
          ) : (
            <>
              {isAdmin && userTier === APP_TIERS.REGULAR && (
                <Options handleEdit={() => setEdit(!edit)} />
              )}
            </>
          )}
        </div>
        <p className={styles.desc}>{subTitle}</p>
        {topRowEnabled && (
          <div className={styles.topItems}>
            {topItems.map((item, i) => {
              return (
                <div className={styles.item} key={i}>
                  {edit && (
                    <CloseI
                      onClick={() => {
                        removeData(item);
                      }}
                    />
                  )}
                  {edit && (
                    <EditI
                      onClick={() => {
                        setOpenUpdateModal(true);
                        setDataToBeUpdated({ ...item, index: i });
                      }}
                    />
                  )}
                  <a
                    style={{
                      cursor: 'pointer'
                    }}
                    rel="noreferrer"
                    target="_blank"
                    href={item?.link}
                  >
                    <p className={styles.itemTitle}>{item.title}</p>
                    <img
                      className={styles.itemImage}
                      src={getS3Url(item.thumbnail)}
                      alt="thumbnail image"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        )}
        <SliderContainer
          extraClass="presenationsInfoSlider"
          breakpoint={1440}
          breakpointSlide={3}
          slides={3}
        >
          {bottomItems.map((item, i) => {
            return (
              <div className={styles.bottomData} key={i}>
                {edit && (
                  <CloseI
                    onClick={() => {
                      removeData(item);
                    }}
                  />
                )}
                {edit && (
                  <EditI
                    onClick={() => {
                      setOpenUpdateModal(true);
                      setDataToBeUpdated({ ...item, index: i });
                    }}
                  />
                )}
                <a
                  style={{
                    cursor: 'pointer'
                  }}
                  rel="noreferrer"
                  target="_blank"
                  href={item?.link}
                >
                  <p className={styles.itemTitle}>{item.title}</p>
                  <img
                    className={styles.itemImage}
                    src={getS3Url(item.thumbnail)}
                    alt="thumbnail image"
                  />
                </a>
              </div>
            );
          })}
        </SliderContainer>
        {edit && (
          <div className={styles.addButton}>
            <AddI
              onClick={() => {
                setAdd(true);
              }}
            />
          </div>
        )}
      </Container>
      <Modal
        isModalOpen={add}
        onClose={() => {
          setAdd(false);
        }}
        hasHeader
      >
        <div className={styles.FeaturedVideosModalContainer}>
          <Textarea
            onChange={({ target: { value } }) => {
              const val = value.substring(0, 30);
              if (value.length > 30) {
                showWarnToast('Title should be less than 30 characters');
              }
              setDataToBeAdded({ ...dataToBeAdded, title: val });
            }}
            placeholder="Add title"
            value={dataToBeAdded.title}
          />

          <Textarea
            onChange={({ target: { value } }) => {
              setDataToBeAdded({ ...dataToBeAdded, link: value });
            }}
            placeholder="Add link"
            value={dataToBeAdded.link}
          />
          <FileUploader
            onChange={(files) => {
              const file = files[0];
              setThumbnailFile(file);
            }}
            text="Upload Thumbnail"
          />

          <div className={styles.FeaturedVideosModalButtonContainer}>
            <Button
              onClick={() => {
                handleAdd();
                setAdd(false);
              }}
            >
              Save
            </Button>
            <Button onClick={() => setAdd(false)}>Close</Button>
          </div>
        </div>
      </Modal>
      <Modal
        isModalOpen={openUpdateModal}
        onClose={() => {
          setOpenUpdateModal(false);
        }}
        hasHeader
        colorBgSwitcher={colorBgSwitcher}
      >
        <div
          style={{
            padding: '1rem'
          }}
        >
          <Textarea
            onChange={({ target: { value } }) => {
              const val = value.substring(0, 30);
              if (value.length > 30) {
                showWarnToast('Title should be less than 30 characters');
              }
              setDataToBeUpdated({ ...dataToBeUpdated, title: val });
            }}
            placeholder="Add title"
            value={dataToBeUpdated.title}
          />
          <Textarea
            onChange={({ target: { value } }) => {
              setDataToBeUpdated({ ...dataToBeUpdated, link: value });
            }}
            placeholder="Add link"
            value={dataToBeUpdated.link}
          />
          <FileUploader
            onChange={(files) => {
              const file = files[0];
              setThumbnailFile(file);
            }}
            text="Edit Thumbnail"
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
              gap: '.5rem'
            }}
          >
            <Button
              onClick={() => {
                handleUpdate();
                setOpenUpdateModal(false);
              }}
              colorBgSwitcher={colorBgSwitcher}
            >
              Update
            </Button>
            <Button onClick={() => setOpenUpdateModal(false)} colorBgSwitcher={colorBgSwitcher}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
const initialLinks = [
  {
    link: 'http://google.com',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/freelancer_ix6yzb.webp',
    title: 'UI design'
  },
  {
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/freelancer_ix6yzb.webp',
    title: 'Fullstack development'
  },
  {
    link: '',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/freelancer_ix6yzb.webp',
    title: 'Game development'
  }
];
