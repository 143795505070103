import { apiSlice } from 'api/apiSlice';
import { store } from '../../store';
export const getIndividualProfileTags = (result) => {
  const { profile } = result;
  const invalidatesTags = [];
  if (profile) {
    invalidatesTags.push({ type: 'Profile', id: profile.id });
  }
  return invalidatesTags;
};

export const getUserRoleTags = (result) => {
  const { role } = result;
  const invalidatesTags = [];
  if (role) {
    invalidatesTags.push({ type: 'Role', id: role.id });
  }
  return invalidatesTags;
};

export const getAwardsAchievementsTags = (result) => {
  const { award } = result;
  const invalidatesTags = [];
  if (award) {
    invalidatesTags.push(...award.map((item) => ({ type: 'Award', id: item.id })));
  }
  return invalidatesTags;
};

export const getReviewsTags = (data) => {
  const { result: reviews } = data;
  const invalidatesTags = [];
  if (reviews) {
    invalidatesTags.push(...reviews.map((item) => ({ type: 'Review', id: item.id })));
  }
  return invalidatesTags;
};

// ====================== OPTIMISTIC UPDATES ======================
export const updateExperienceInListInQuery = ({ experience, experienceId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const item = draft.result.find((item) => item.id === experienceId);
      if (item) Object.assign(item, experience);
    })
  );
  return patchResult;
};

export const updateReviewInListInQuery = ({ review, reviewId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const item = draft.result.find((item) => item.id === reviewId);
      if (item) Object.assign(item, review);
    })
  );
  return patchResult;
};

export const deleteExperienceInListInQuery = ({ experienceId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const index = draft.result.findIndex((item) => item.id === experienceId);
      if (index !== -1) draft.result.splice(index, 1);
    })
  );
  return patchResult;
};

export const updateEducationInListInQuery = ({ education, educationId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const item = draft.result.find((item) => item.id === educationId);
      if (item) Object.assign(item, education);
    })
  );
  return patchResult;
};

export const deleteEducationInListInQuery = ({ educationId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const index = draft.result.findIndex((item) => item.id === educationId);

      if (index !== -1) draft.result.splice(index, 1);
    })
  );
  return patchResult;
};

export const updateStatsInListInQuery = ({ stats, statsId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const item = draft.result.find((item) => item.id === statsId);
      if (item) Object.assign(item, stats);
    })
  );
  return patchResult;
};

export const deleteStatsInListInQuery = ({ statsId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const index = draft.result.findIndex((item) => item.id === statsId);
      if (index !== -1) draft.result.splice(index, 1);
    })
  );
  return patchResult;
};

export const deleteQandAInListInQuery = ({ id, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const index = draft.result.findIndex((item) => item.id === id);
      if (index !== -1) draft.result.splice(index, 1);
    })
  );
  return patchResult;
};

export const updateQandAInListInQuery = ({ qAndAId, qAndA, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const item = draft.result.find((item) => item.id === qAndAId);
      if (item) Object.assign(item, qAndA);
    })
  );
  return patchResult;
};

export const updateProjectInListInQuery = ({ project, projectId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const item = draft.result.find((item) => item.id === projectId);
      if (item) Object.assign(item, project);
    })
  );
  return patchResult;
};

export const deleteProjectInListInQuery = ({ projectId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const index = draft.result.findIndex((item) => item.id === projectId);
      if (index !== -1) draft.result.splice(index, 1);
    })
  );
  return patchResult;
};

export const updatePublicationInListInQuery = ({
  publication,
  publicationId,
  queryName,
  params
}) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const item = draft.result.find((item) => item.id === publicationId);
      if (item) Object.assign(item, publication);
    })
  );
  return patchResult;
};

export const deletePublicationInListInQuery = ({ publicationId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const index = draft.result.findIndex((item) => item.id === publicationId);
      if (index !== -1) draft.result.splice(index, 1);
    })
  );
  return patchResult;
};

export const deleteAchievementInListInQuery = ({ achievementId, queryName, params }) => {
  const patchResult = store.dispatch(
    apiSlice.util.updateQueryData(queryName, { ...params }, (draft) => {
      const index = draft.result.findIndex((item) => item.id === achievementId);
      if (index !== -1) draft.result.splice(index, 1);
    })
  );
  return patchResult;
};
