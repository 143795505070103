import React from 'react';
import styles from './ServiceCard.module.scss';
import { EditableText } from 'components/form';
import { showWarnToast } from 'utils/toast';
import { isStringLimited } from 'helpers';
import { useSelector } from 'react-redux';
import { getCompanyThemeSelector } from 'store/login.slice';
import { getTheme } from 'helpers/general';

function ServiceCard({
  title,
  description,
  editMode = false,
  onFieldsChange,
  onToggleSelection,
  isSelected,
  companyTheme
}) {
  const [value, setValue] = React.useState({
    title: title,
    description: description
  });

  const theme = getTheme(companyTheme, 'companyPage');

  return (
    <div className="wrapper">
      <div className={styles['service-card']}>
        {editMode && (
          <>
            <input type="checkbox" checked={isSelected} onClick={onToggleSelection} />
          </>
        )}
        <div
          className={styles['service-img']}
          style={{
            backgroundImage: `url(${theme.assets?.defaultServiceImage})`
          }}
        />

        <div className={styles['service-title']}>
          <EditableText
            name="title"
            id="title"
            editMode={editMode}
            placeholder={title}
            style={{
              fontSize: '18px',
              lineHeight: '1.2rem'
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.substring(0, 30);
              onFieldsChange(trimmedValue, 'title');
              setValue({ ...value, title: trimmedValue });
              if (e.target.value.length > 30)
                showWarnToast('Service title should be less than 30 characters');
            }}
            value={editMode ? value.title : title}
          />
        </div>

        <div className={styles['service-description']}>
          <EditableText
            name="description"
            id="description"
            editMode={editMode}
            placeholder={description || theme.serviceDescription}
            onChange={(e) => {
              const inputValue = e.target.value;
              const [isWithinLimit, trimmedValue] = isStringLimited(inputValue, 200);

              if (!isWithinLimit) {
                showWarnToast('Service description should be less than 200 characters');
              }

              onFieldsChange(trimmedValue, 'description');
              setValue({ ...value, description: trimmedValue });
            }}
            value={editMode ? value.description : description || theme.serviceDescription}
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
