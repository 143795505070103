import { CompanyButton } from 'components/organisms/Company/components';
import React from 'react';
import styles from './IntroButtons.module.scss';
import {useTranslation} from "react-i18next";
function IntroButtons({ companyName }) {
    const { t } = useTranslation();
  return (
    <div className={styles['buttons']}>
      <CompanyButton link={'/profiles'} mr="1rem" bg="#2f2f2fb3" textColor="white">
          {t("Individual Profiles")}
      </CompanyButton>
      <CompanyButton link={'/teams'} bg="#2f2f2fb3" textColor="white">
          {t("Team Profiles")}{' '}
      </CompanyButton>
    </div>
  );
}

export default IntroButtons;
