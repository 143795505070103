import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UniversalMenu } from '@master-wizr/universal-menu';
import { deleteAuthData } from 'helpers/auth';
import { getHiddenUMIcons } from 'helpers/general';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
const initialValues = {
  CURRENT_ORG: 'engineering',
  mode: 'dark',
  displayValue: '-100',
  accounts: [
    'Intranet',
    'Sales',
    'HR',
    'Training',
    'Community',
    'Manuals',
    'Management',
    'Marketing',
    'Investors'
  ]
};

export default function UniversalMenuComponent({
  showMenu,
  handleShowMenu,
  currentUserId,
  currentUserProfile
}) {
  const [values, setProps] = useState(initialValues);
  const navigate = useNavigate();
  const WIZRX_URL = window.location.origin;
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const cookies = new Cookies();

  useEffect(() => {
    setProps((old) => ({
      ...old,
      displayValue: showMenu ? '0' : '-100'
    }));
  }, [showMenu]);

  const toggleMenu = () => {
    handleShowMenu();
  };
  const logout = () => {
    deleteAuthData();
  };

  useEffect(() => {
    if (values.displayValue === '-100') {
      document.body.classList.add('universalmenu-closed');
    } else if (values.displayValue === '0') {
      document.body.classList.remove('universalmenu-closed');
    }
  }, [values.displayValue]);
  const HUB_URL = `${import.meta.env.REACT_APP_LOGIN_URL}/hub`;
  //TODO: Add the correct accountRole role
  return (
    <UniversalMenu
      {...{
        ...values,
        logout,
        WIZRX_URL,
        ENV: process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
        toggleMenu,
        _redirect: navigate,
        accessedPortals: currentUserProfile?.accessedPortals || [],
        accountRole: '',
        CURRENT_ORG: `${companyId}-${cookies.get('activeProfile')?.split('-')[1]}`,
        CURRENT_PORTAL: `${cookies.get('activeProfile').split('-')[1]}` || '',
        activeProfile: cookies.get('activeProfile'),
        redirectURL: 'wizrx',
        profile: currentUserProfile
      }}
    />
  );
}
UniversalMenuComponent.propTypes = {
  showMenu: PropTypes.bool,
  handleShowMenu: PropTypes.func
};
