import { apiSlice } from 'api/apiSlice';
import { Input } from 'components/form';
import { Loader } from 'components/ui';
import { removeHeightSetWidthSvgIcon } from 'helpers';
import { useDebounce } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CreateModal from '../CreateModal/CreateModal';
import TechList from '../TechList/TechList';
import styles from './TechDropdown.module.scss';
import {useTranslation} from "react-i18next";

export default function TechDropdown({
  setKeyword,
  rtkQueryName,
  keyword,
  onToolsSelected,
  extraSelectedItems = [],
  title,
  padding,
  searchPlaceholder,
  rtkMethodQueries
}) {
  const [dropdown, setDropdown] = useState(false);
  const [name, setName] = useState('');
  const [selectedItems, setSelectedItems] = useState(extraSelectedItems ? extraSelectedItems : []);
  const { data, isLoading: isFetching } = useSelector(
    apiSlice.endpoints[rtkQueryName].select({ keyword, rtkMethodQueries })
  );
  const {t} = useTranslation();

  useDebounce(() => setQueryState(name), 1000, [name]);
  const setQueryState = async (name) => {
    setKeyword(name);
  };
  const handleSelectStateChange = ({ item, checked }) => {
    if (checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i.name !== item.name));
    }
  };

  // Components
  const SelectedItems = ({ selectedItems, onDeleteItem, removeBtn }) => {
    return (
      <>
        <div className={styles.selectedItems}>
          {selectedItems.map((item) => {
            return (
              <div onClick={() => onDeleteItem(item)} className={styles.techItem} key={item.name}>
                {item.icon ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: removeHeightSetWidthSvgIcon(item.icon) }}
                  />
                ) : (
                  <p className={styles['not-found-letter']}>
                    {item.name.substring(0, 1).toUpperCase()}
                  </p>
                )}
                {item.name}
                {removeBtn && (
                  <span
                    style={{ marginInlineStart: '5px', cursor: 'pointer', fontSize: '8px' }}
                    className={styles.add}
                  >
                    X
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const handleCloseAndSave = () => {
    setDropdown(false);
    setName('');
    setSelectedItems(extraSelectedItems ? [...extraSelectedItems] : []);
  };

  return (
    <div className={styles.techWrapper}>
      <div className={styles.techDropdown} style={{ padding: padding }}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setDropdown(true);
          }}
        >
          <p>{selectedItems.length > 0 ? t('Edit') : t('Add')}</p>
        </div>
        {dropdown && (
          <CreateModal
            handleClose={handleCloseAndSave}
            clickEvent={() => {
              handleCloseAndSave();
              onToolsSelected(selectedItems);
            }}
          >
            <>
              <h1>{t("Add")} {t(title)}</h1>
              <SelectedItems
                removeBtn
                selectedItems={selectedItems}
                onDeleteItem={(item) =>
                  setSelectedItems(selectedItems.filter((i) => i.name !== item.name))
                }
              />
              <Input
                extraClass="searchMore"
                autoFocus
                placeholder={ searchPlaceholder ? t(searchPlaceholder) : t('search')}
                onChange={(e) => setName(e.target.value.trim())}
              />
              <TechList
                searchedText={name}
                isFetching={isFetching}
                selectedItems={selectedItems}
                onSelectStateChange={handleSelectStateChange}
                list={data?.result || []}
              />
              {isFetching && <Loader />}
            </>
          </CreateModal>
        )}
      </div>
    </div>
  );
}
