import { Tooltip } from 'react-tooltip';
import styles from './Tooltip.module.scss';
const variants = {
  dark: 'dark',
  light: 'light',
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info'
};
export const BasicTooltip = ({ variant, id, ...props }) => (
  <Tooltip variant={variants[variant]} id={id} className={styles.basicTooltip} {...props} />
);
