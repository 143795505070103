import React from 'react';
import { CvTemplates } from '../../utils';
import { GeneralTools } from './general';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { Loader } from 'components/ui';
import { useParams } from 'react-router-dom';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import {useTranslation} from "react-i18next";

ToolsSection.creativeSelf = () => {
  return <div></div>;
};

ToolsSection.myFirstJob = () => {
  return <div></div>;
};

ToolsSection.summerJob = () => {
  return <div></div>;
};

ToolsSection.general = GeneralTools;

function ToolsSection({ template = CvTemplates.general.name, sectionTitle }) {
  const [sharedDate] = useGetSharedProfile();
  const currentProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || currentProfileId;
  const ToolSectionView = ToolsSection?.[template];
  const { t } = useTranslation();

  const placeHolder = [
    {
      name: t('jira-placeholder'),
      description: 'Jira'
    },
    {
      name: t('confluence-placeholder'),
      description: 'Confluence'
    },
    {
      name: t('git-placeholder'),
      description: 'Git'
    },
    {
      name: t('gitHub-placeholder'),
      description: 'GitHub'
    },
    {
      name: t('gitlab-placeholder'),
      description: 'GitLab'
    },
    {
      name: t('bitbucket-placeholder'),
      description: 'Bitbucket'
    },
    {
      name: t('docker-placeholder'),
      description: 'Docker'
    },
    {
      name: t('kubernetes-placeholder'),
      description: 'Kubernetes'
    }
  ];

  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );
  const { isLoading, isError, data, error } = !isSharedMode() ? result : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const tools = data?.bio?.tools;
  return (
    <ToolSectionView
      toolsList={tools && tools.length !== 0 ? tools : placeHolder}
      sectionTitle={t(sectionTitle)}
    />
  );
}

export default ToolsSection;
