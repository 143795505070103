import React, { useState, useRef, useEffect, Suspense } from 'react';
import styles from './InfoHubSection.module.scss';
import { useGetInfoHubQuery } from 'api/info-hub';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId, getCompanySelector } from 'store/login.slice';
import { useGetCompanyProfileQuery } from 'api/company-profile';
import { Loader } from 'components/ui';
import { InfoImages } from 'constants/homeData';
import { useOutSideClick } from 'hooks';
import { ECOMPANY_PROFILE_THEMES } from 'constants';
import {
  useUpdateSpecialitiesMutation,
  useUpdateMajorPresentationsMutation,
  useUpdateMinorPresentationsMutation
} from 'api/info-hub';
import { getTheme } from 'helpers/general';
import { getCompanyThemeSelector } from 'store/login.slice';
import { useTranslation } from 'react-i18next';
import DefaultT from './DefaultT';
import FreelancerT from './FreelancerT';
import NGOT from './NGOT';
import StartupT from './StartupT';
import CommunityT from './CommunityT';

function InfoHubSection() {
  const [videoPlayingComponent, setVideoPlayingComponent] = useState({
    Highlights: false,
    Product: false
  });
  const { t } = useTranslation();
  const profileId = useSelector(getCurrentUserProfileId);
  const company = useSelector(getCompanySelector);
  const companyId = company.id;
  const [uploadModal, setUploadModal] = useState(false);
  const uploadModalRef = useRef(null);
  const companyTheme = useSelector(getCompanyThemeSelector);
  const theme = getTheme(companyTheme, 'infoHub');

  useOutSideClick(
    uploadModalRef,
    () => {
      if (uploadModal) {
        setUploadModal(false);
      }
    },
    'background_excluded_section'
  );
  const themeKey = company.theme
    ? Object.keys(ECOMPANY_PROFILE_THEMES).find(
        (key) => ECOMPANY_PROFILE_THEMES[key] === company.theme
      )
    : ECOMPANY_PROFILE_THEMES.DEFAULT;
  const themeIndex = Object.keys(ECOMPANY_PROFILE_THEMES).indexOf(themeKey.toUpperCase());

  const headerTexts = [
    'Welcome to our intranet',
    'Sharing my work',
    'Sharing what we care for',
    'Welcome dear colleagues',
    'Welcome to our community'
  ];
  const { data, isLoading, isSuccess, error, isError } = useGetInfoHubQuery({
    companyId
  });
  const [lastSelectedImageIndex, setLastSelectedImageIndex] = useState(null);
  useEffect(() => {
    const savedBgImage = data?.info?.backgroundImage;
    const matchedIndex = InfoImages.findIndex((imageUrl) => imageUrl === savedBgImage);
    if (matchedIndex !== -1) {
      setLastSelectedImageIndex(matchedIndex + 1);
    }
  }, [data?.info?.backgroundImage]);

  const [updateMajorPresentations, { isLoading: isMajorPresentationsLoading }] =
    useUpdateMajorPresentationsMutation();
  const [updateMinorPresentations, { isLoading: isMinorPresentationsLoading }] =
    useUpdateMinorPresentationsMutation();
  const [updateSpecialties, { isLoading: isSpecialtiesLoading }] = useUpdateSpecialitiesMutation();

  if (isError)
    return (
      <p
        style={{
          color: 'red'
        }}
      >
        {error?.data?.message}
      </p>
    );
  // export const ECOMPANY_PROFILE_THEMES = {
  //   DEFAULT: 'default',
  //   FREELANCER: 'freelancer',
  //   NGO: 'ngo',
  //   STARTUP: 'startup',
  //   COMMUNITY: 'community'
  // };
  return (
    <Suspense fallback={<Loader fullpag={true} />}>
      <div
        className={`${styles.main} ${
          lastSelectedImageIndex ? styles[`bg-${lastSelectedImageIndex}`] : ''
        }`}
      >
        <div
          className={styles.infoBanner}
          style={{
            backgroundImage: `url(
      ${theme.assets?.mainBackground}
      )`
          }}
        >
          <h1 className={styles.infoTitle}>{t(headerTexts[themeIndex])}</h1>
        </div>
        <div className={styles.container}>
          {themeIndex === 0 && (
            <>
              <DefaultT
                companyId={companyId}
                data={data}
                lastSelectedImageIndex={lastSelectedImageIndex}
                setVideoPlayingComponent={setVideoPlayingComponent}
                videoPlayingComponent={videoPlayingComponent}
                isLoading={isLoading}
                profileId={profileId}
              />
            </>
          )}
          {themeIndex === 1 /*Freelancer*/ && (
            <>
              <FreelancerT
                companyId={companyId}
                data={data}
                isLoading={isLoading}
                isMajorPresentationsLoading={isMajorPresentationsLoading}
                isMinorPresentationsLoading={isMinorPresentationsLoading}
                isSpecialtiesLoading={isSpecialtiesLoading}
                lastSelectedImageIndex={lastSelectedImageIndex}
                profileId={profileId}
                updateMajorPresentations={updateMajorPresentations}
                updateMinorPresentations={updateMinorPresentations}
                updateSpecialties={updateSpecialties}
              />
            </>
          )}
          {themeIndex === 2 /*NGO*/ && (
            <>
              <NGOT
                companyId={companyId}
                data={data}
                isLoading={isLoading}
                isMinorPresentationsLoading={isMinorPresentationsLoading}
                lastSelectedImageIndex={lastSelectedImageIndex}
                profileId={profileId}
                setVideoPlayingComponent={setVideoPlayingComponent}
                updateMajorPresentations={updateMajorPresentations}
                updateMinorPresentations={updateMinorPresentations}
                videoPlayingComponent={videoPlayingComponent}
              />
            </>
          )}
          {themeIndex === 3 /*Startup*/ && (
            <>
              <StartupT
                companyId={companyId}
                data={data}
                isLoading={isLoading}
                isMinorPresentationsLoading={isMinorPresentationsLoading}
                lastSelectedImageIndex={lastSelectedImageIndex}
                profileId={profileId}
                setVideoPlayingComponent={setVideoPlayingComponent}
                updateMajorPresentations={updateMajorPresentations}
                updateMinorPresentations={updateMinorPresentations}
                videoPlayingComponent={videoPlayingComponent}
              />
            </>
          )}
          {themeIndex === 4 /*Community*/ && (
            <>
              <CommunityT
                companyId={companyId}
                data={data}
                isLoading={isLoading}
                isMinorPresentationsLoading={isMinorPresentationsLoading}
                lastSelectedImageIndex={lastSelectedImageIndex}
                profileId={profileId}
                setVideoPlayingComponent={setVideoPlayingComponent}
                updateMajorPresentations={updateMajorPresentations}
                updateMinorPresentations={updateMinorPresentations}
                videoPlayingComponent={videoPlayingComponent}
              />
            </>
          )}
        </div>
      </div>
    </Suspense>
  );
}

export default InfoHubSection;
