import { apiSlice } from 'api/apiSlice';
const secondaryUrl = `s3`;

export const s3ExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // this function is used outside a react component
    createPreSignedUrl: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/${secondaryUrl}`,
          method: 'POST',
          body
        };
      }
    }),
    // this function is used outside a react component
    deleteByKeys: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/${secondaryUrl}`,
          method: 'DELETE',
          body
        };
      }
    })
  })
});

export const { useCreatePreSignedUrlMutation, useDeleteByKeysMutation } = s3ExtendedSlice;
