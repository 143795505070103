export const DEFAULT_SELECTED_TIMES = {
  mon: {
    startTime: '9:00 AM',
    break: '12:00 PM',
    endTime: '6:00 PM',
    overtime: '0'
  },
  tue: { startTime: '9:00 AM', break: '12:00 PM', endTime: '6:00 PM', overtime: '0' },
  wed: { startTime: '9:00 AM', break: '12:00 PM', endTime: '6:00 PM', overtime: '0' },
  thu: { startTime: '9:00 AM', break: '12:00 PM', endTime: '6:00 PM', overtime: '0' },
  fri: { startTime: '9:00 AM', break: '12:00 PM', endTime: '6:00 PM', overtime: '0' }
};

export const TABLE_ROWS_DATA = [
  { value: 'startTime', label: 'Start Time' },
  { value: 'break', label: 'Break' },
  { value: 'endTime', label: 'End Time' },
  { value: 'overtime', label: 'Overtime' }
];

export const AVAILABILITY_OPTIONS = {
  all: {
    id: 0,
    item: 'All',
    value: undefined
  },
  online: {
    id: 1,
    item: 'Available now',
    value: 'online'
  },
  // meeting: {
  //   id: 2,
  //   item: 'Available for meeting',
  //   value: 'online'
  // },
  offline: {
    id: 2,
    item: 'Not available',
    value: 'offline'
  }
  // leave: {
  //   id: 4,
  //   item: 'On leave',
  //   value: 'offline'
  // }
};
