import React from 'react';
import styles from './Form.module.scss';
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks';
import { Button } from 'components/ui';

export default function Form({
  title,
  textarea,
  children,
  label,
  textAreaValue,
  setTextAreaValue,
  handleSubmit,
  onCancel,
  ...rest
}) {
  const [numOfClicks, setNumOfClicks] = React.useState(0);
  // TODO: use debounce
  useDebounce(() => handleSubmit?.(), 500, [numOfClicks]);

  return (
    <div className={styles.form}>
      {title && <h3>{title}</h3>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setNumOfClicks(numOfClicks + 1);
        }}
      >
        {children}
        {textarea && (
          <>
            <label>{label}</label>
            <textarea
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
              placeholder={rest?.placeholder}
              rows={rest?.cols}
              cols={rest?.cols}
            />
          </>
        )}
        <div className={styles.buttons}>
          <Button disabled={rest.isLoading} type="submit" bgColor={rest.isLoading ? '#414141' : ''}>
            {rest.isLoading ? 'loading...' : rest.button}
          </Button>
          {onCancel && (
            <Button
              type="button"
              onClick={(e) => {
                onCancel(e);
              }}
              bgColor="#414141"
              color="#fff"
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}

Form.propTypes = {
  textarea: PropTypes.bool,
  handleSubmit: PropTypes.func,
  children: PropTypes.any,
  title: PropTypes.string,
  label: PropTypes.string,
  textAreaValue: PropTypes.string,
  setTextAreaValue: PropTypes.func,
  isLoading: PropTypes.bool
};
