import defaultProfileImage from '../../../assets/images/avatar.webp';
import { getS3Url } from 'helpers/s3Handler';
import { useNavigate } from 'react-router-dom';
import styles from './ProfileBullet.module.scss';
export default function ProfileBullet({ image, onClick }) {
  const navigate = useNavigate();
  return (
    <div className={styles.bulletContainer}>
      <img
        className={styles.profileImage}
        src={image ? getS3Url(image) : defaultProfileImage}
        onClick={onClick}
        alt="profile image"
      />
    </div>
  );
}
