import React, { useState } from 'react';
import EventBullet from 'components/atoms/EventBullet/EventBullet';
import styles from './SideAgenda.module.scss';
function SideAgenda({ modal, events, date, onEventSelect, setShowSideAgendaProperties }) {
  return (
    <div className={modal ? `${styles.sideAgenda} ${styles.showMenu}` : styles.sideAgenda}>
      <div className={styles.container}>
        <h1>{date.toLocaleDateString('en-US')}</h1>
        {events.map((event, i) => {
          return (
            <EventBullet
              event={event}
              onEventSelect={onEventSelect}
              setShowSideAgendaProperties={setShowSideAgendaProperties}
            />
          );
        })}
      </div>
    </div>
  );
}
export default SideAgenda;
