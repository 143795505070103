import { convertStringToParagraph, isJsonString } from 'components/atoms/MWEditor/Helpers';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import { LiteEditorParagraph } from 'components/form';
import { useParagraphUtils } from 'components/organisms/Profile/hooks/templates';
import React from 'react';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import Options from 'components/atoms/Options/Options';

const defaultSectionTitle = 'Paragraph';

function ParagraphSectionView({ data, sectionTitle }) {
  const { isCurrentUserProfile, edit, setEdit } = useParagraphUtils();
  const [para, setPara] = React.useState(data);

  return (
    <SectionWrapper sectionTitle={sectionTitle ?? defaultSectionTitle}>
      {isCurrentUserProfile && (
        <Options
          extraClass="skillsOptions"
          handleDiscard={() => {
            setEdit(false);
          }}
          handleSave={() => {
            setEdit(false);
          }}
          handleEdit={() => setEdit(true)}
          edit={edit}
        />
      )}
      {edit && (
        <MWEditorLite
          data={
            data
              ? isJsonString(data)
                ? JSON.parse(data)
                : convertStringToParagraph(data)
              : undefined
          }
          placeholderText="Describe your experience"
          setData={(value) => setPara(JSON.stringify(value))}
        />
      )}

      {!edit && <LiteEditorParagraph description={para} />}
    </SectionWrapper>
  );
}

function ParagraphSection({ sectionTitle }) {
  return <ParagraphSectionView data={placeHolder} sectionTitle={sectionTitle} />;
}
const placeHolder = `
Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam voluptatem perspiciatis soluta est. Voluptate commodi quas aut soluta quos voluptatum libero animi minus labore vitae eius, a quaerat ipsum dolore!
`;
export default ParagraphSection;
