import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showMenu: false
  //   isFullscreen: false
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.showMenu = !state.showMenu;
    }
    // setFullscreen: (state, action) => {
    //   state.isFullscreen = action.payload;
    // }
  }
});

export const { toggleMenu, setFullscreen } = menuSlice.actions;

export default menuSlice.reducer;
