import ProfileTemplates from 'components/organisms/ProfileTemplates/ProfileTemplates';
import React from 'react';

export default function Templates() {
  document.body.style.overflow = '';

  return (
    <div>
      <ProfileTemplates />
    </div>
  );
}
