import { EVENT_RESPONSE_ENUM } from 'constants';
import { getMonthNameShort } from './dateHandler';
import { Button } from 'components/ui';
import {useTranslation} from "react-i18next";
export function getAttendanceComponent(attendanceType) {
  const { t } = useTranslation();
  switch (attendanceType) {
    case EVENT_RESPONSE_ENUM.YES:
      return <>{t("Attending")} ✅</>;
    case EVENT_RESPONSE_ENUM.NO:
      return <>{t("Not attending")} ❌</>;
    case EVENT_RESPONSE_ENUM.MAYBE:
      return <>{t("Maybe")} ❓</>;
    default:
      return <>{t("No response")}</>;
  }
}

export function getMessageComponent(message) {
  const { t } = useTranslation();
  if (message != '' && message != undefined) {
    return <span>{message}</span>;
  } else {
    return <span>{t("No message sent")}</span>;
  }
}

export function getProposalComponent(proposal, showProposals) {
  const { t } = useTranslation();
  if (!proposal || (!proposal[0]?.startDate && !proposal[0]?.endDate)) {
    return <span>{t("No reschedule requested")}</span>;
  }
  if (proposal[0]?.startDate && proposal[0]?.endDate) {
    return (
      <Button
        onClick={() => {
          showProposals(proposal);
        }}
      >
        {("Show proposals")}
      </Button>
    );
  }
}

export function getThisMonthsMeetings(data) {
  let thisMonthsMeetings = [];
  data?.result?.forEach((element) => {
    const start = new Date(element.startDate);
    const today = new Date();
    if (
      start.getMonth() == today.getMonth() &&
      start.getFullYear() == today.getFullYear() &&
      start.getDate() > today.getDate()
    ) {
      thisMonthsMeetings.push(element);
    }
  });
  return thisMonthsMeetings;
}

export function getComparedProfileIdsFromArray(profilesToBeCompared) {
  if (profilesToBeCompared?.length) {
    let profiles = [];
    profilesToBeCompared.map((profile, index) => {
      profiles.push(profile.id);
    });
    return profiles;
  } else {
    return profilesToBeCompared;
  }
}

export function hasCurrentUserResponded(event, profileId) {
  let responded = false;
  event?.responses.forEach((response) => {
    if (response.responder === profileId) {
      responded = true;
    }
  });
  return responded;
}

export function CurrentUserPartOfThisEvent(event, profileId) {
  let part = false;
  event?.participants.forEach((participant) => {
    if (participant.id === profileId) {
      part = true;
    }
  });
  return part;
}

export function getUserResponse(id, event) {
  let CurrentResponse = undefined;
  event?.responses.forEach((response) => {
    if (response.responder === id) {
      CurrentResponse = response.response;
    }
  });
  return CurrentResponse;
}

export function getWithinDisputePeriod(hours, event) {
  const startDate = new Date(event.startDate);
  const now = new Date().getTime();
  const disputePeriod = startDate.getTime() - 3600 * hours;
  if (disputePeriod > now) {
    return true;
  } else {
    return false;
  }
}
