// CREATOR
// SUPERADMINISTRATOR
// ADMINISTRATOR
// EDITOR
// PUBLISHER
// VIEWER

export const ADMINS_ROLES = ['creator', 'administrator', 'superadministrator'];
export const EDITOR_ROLE = 'editor';
export const PUBLISHER_ROLE = 'publisher';
