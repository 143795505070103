import { showErrorToast } from 'utils/toast';
import {
  useDeleteExperienceMutation,
  useGetIndividualProfileByProfileIdQuery,
  useSaveExperienceMutation,
  useUpdateExperienceMutation,
  useUpdateExperiencesDescMutation
} from 'api/individual-profile';
import { handleAsync, isSharedMode, removeObjectElements } from 'helpers';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useTranslation } from 'react-i18next';

function useExperienceUtils({ userId }) {
  const { t } = useTranslation();
  const staticDesc = t('i-am-pleased-to-share-my-experiences');
  const [updateExperience, { isLoading: isUpdating }] = useUpdateExperienceMutation();
  // const [saveExperience, { isLoading: isSaving }] = useSaveExperienceMutation();
  const [deleteExperience, { isLoading: isDeleting }] = useDeleteExperienceMutation();
  const [saveExperience, { isLoading: isSaving }] = useSaveExperienceMutation();
  const [updateExperienceDesc] = useUpdateExperiencesDescMutation();
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const globalEdit = useSelector(getGlobalEditSelector);
  const [editDescription, setDescription] = useState(false);
  const { data } = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );
  const experienceDesc = data?.bio?.experienceDesc ?? '';
  const [updatedDesc, setUpdatedDesc] = useState(experienceDesc ?? staticDesc);

  // const [updateExperienceDesc, { isLoading: isUpdatingDesc }] = useUpdateExperiencesDescMutation();
  const onUpdateSubmit = async (experience) => {
    const { id, ...rest } = experience;
    const experienceDto = removeObjectElements(rest, ['img', 'userId']);
    const { technologies } = experienceDto;
    if (technologies && technologies.length > 20)
      return showErrorToast(t('You can add up to 20 technologies'));
    await handleAsync(
      updateExperience({
        userId,
        experienceId: id,
        experience: experienceDto
      }).unwrap()
    );
  };

  const onDeleteSubmit = async (id) => {
    const status = await handleAsync(
      deleteExperience({
        experienceId: id,
        userId
      }).unwrap()
    );
    return status[0];
  };

  const onCreateSubmit = async (experience) => {
    const { technologies } = experience;
    if (technologies && technologies.length > 20) {
      showErrorToast(t('You can add up to 20 technologies'));
      return false;
    }
    const [status] = await handleAsync(
      saveExperience({
        experience,
        userId
      }).unwrap(),
      null,
      t('Error creating experience')
    );
    return status;
  };

  const onDescriptionSave = async () => {
    const experienceDescDto = {
      description: JSON.stringify(updatedDesc)
    };
    setDescription(false);
    const result = await handleAsync(updateExperienceDesc(experienceDescDto).unwrap());
    if (!result[0]) showErrorToast('Error updating description');
  };
  return {
    onDeleteSubmit,
    onUpdateSubmit,
    onCreateSubmit,
    isSaving,
    isUpdating,
    isDeleting,
    loggedInProfileId,
    globalEdit,
    isCurrentUserProfile,
    staticDesc,
    onDescriptionSave,
    editDescription,
    setDescription,
    setUpdatedDesc,
    experienceDesc
  };
}

export default useExperienceUtils;
