import React, { Suspense, useState } from 'react';
import styles from './CalendarSection.module.scss';
import { useNavigate } from 'react-router-dom';
import CalendarCard from '../../molecules/Calendar/Calendar';
import {
  getDayMonthYearFormat,
  getWeekDayLong,
  getMonthNameShort,
  convertTimeToUserTimezone
} from 'helpers/dateHandler';
import Timeline from 'components/molecules/Timeline/Timeline';
import CreateEventModal from 'components/molecules/CreateEventModal/CreateEventModal';
import { useGetEventsQuery, useDeleteEventMutation } from 'api/event';
import ProfilePickerModal from 'components/molecules/ProfilePickerModal/ProfilePickerModal';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import SidewaysProfile from 'components/atoms/SidewaysProfile/SidewaysProfile';
import SidewaysEvent from 'components/atoms/SidewaysEvent/SidewaysEvent';
import { ReactComponent as Edit } from 'assets/icons/editicon.svg';
import { ReactComponent as Add } from 'assets/icons/addicon.svg';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { handleAsync } from 'helpers';
import { getS3Url } from 'helpers/s3Handler';
import ProfileBullet from 'components/atoms/ProfileBullet/ProfileBullet';
import EventResponseModal from 'components/molecules/EventResponseModal/EventResponseModal';
import EventResponsesModal from 'components/molecules/EventResponsesModal/EventResponsesModal';
import { getAttendanceComponent as GetAttendanceComponent } from 'helpers/calendar';
import { isImageURL, isVideoURL } from 'helpers';
import EventAttachmentModal from 'components/molecules/eventAttachmentModal/eventAttachmentModal';
import {
  getThisMonthsMeetings,
  getComparedProfileIdsFromArray,
  hasCurrentUserResponded,
  CurrentUserPartOfThisEvent,
  getUserResponse,
  getWithinDisputePeriod
} from 'helpers/calendar';
import { copyCurrentPageURLToClipBoard } from 'helpers';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/ui';
function CalendarSection() {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [responsesModalOpen, setResponsesModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState();
  const [comparedProfiles, setComparedProfiles] = useState([]);
  const [create, setCreate] = useState(false);
  const [compareOpen, setCompareOpen] = useState(false);
  const userStatus = useSelector(getUserStatusSelector);
  const profileId = userStatus.profileStatus.id;
  const { t } = useTranslation();

  const { data } = useGetEventsQuery({
    profileId,
    start: new Date(selectedDate).setMonth(Math.max(0, selectedDate.getMonth() - 2), 0),
    end: new Date(selectedDate).setMonth(Math.min(11, selectedDate.getMonth() + 2), 31),
    comparisons: getComparedProfileIdsFromArray(comparedProfiles)
  });
  const [deleteEvent] = useDeleteEventMutation();
  // const [updateEvent] = useUpdateEventMutation();
  const [selectedAttachmentURL, setSelectedAttachmentURL] = useState('');
  async function deleteEventAndReturn(eventId) {
    const status = await handleAsync(deleteEvent({ eventId }).unwrap());
    if (status[0]) {
      setSelectedEvent(undefined);
      showSuccessToast(t('Event Deleted!'));
    } else {
      showErrorToast(t('Something went wrong!'));
    }
  }

  function onSlotSelected(slotInfo) {
    const date = new Date(slotInfo.start);
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    const nowMinusOneHour = now.getTime() - 3600;
    if (date >= nowMinusOneHour) {
      setSelectedSlot(new Date(slotInfo.start));
      setCreate(true);
    } else {
      showErrorToast(t('cannot-create-an-event-in-the-past'));
    }
  }

  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div
        className={styles.calendarSectionContainer}
        style={{ display: selectedEvent ? 'block' : 'none' }}
      >
        {deleteModalOpen && (
          <DeleteModal
            title="Are you sure you want to delete this event"
            handleDelete={() => {
              deleteEventAndReturn(selectedEvent.id);
              setDeleteModalOpen(false);
            }}
            handleClose={() => {
              setDeleteModalOpen(false);
            }}
          />
        )}
        <EventAttachmentModal
          url={selectedAttachmentURL}
          setAttachmentModalVisible={setSelectedAttachmentURL}
          onClose={() => {
            setSelectedAttachmentURL('');
          }}
          open={selectedAttachmentURL != ''}
        />
        <EventResponseModal
          open={responseModalOpen}
          onClose={() => {
            setResponseModalOpen(false);
          }}
          onRespond={() => {
            setResponseModalOpen(false);
            setSelectedEvent(undefined);
          }}
          dispute={selectedEvent?.mandatory}
          eventId={selectedEvent?.id}
        />
        <EventResponsesModal
          open={responsesModalOpen}
          onClose={() => {
            setResponsesModalOpen(false);
          }}
          translate={t}
          eventResponses={selectedEvent?.responses}
          participants={selectedEvent?.participants}
        />
        <div className={styles.headerBar}>
          <div className={styles.headerNavigation}>
            <div
              className={styles.calendarnavigator}
              onClick={() => {
                setSelectedEvent(undefined);
              }}
            >
              {t('Calendar')}
            </div>
            <div
              className={styles.calendarnavigator}
              onClick={() => {
                copyCurrentPageURLToClipBoard(
                  window.location.origin + '/calendar/event/' + selectedEvent.id
                );
                showSuccessToast(t('Link copied to clipboard!'));
              }}
            >
              {t('Share')}
            </div>
            <div className={styles.navigatorSelected}>{t('Event')}</div>
          </div>
        </div>
        {selectedEvent?.host.id === profileId && (
          <div className={styles.headerButtons}>
            <Add
              className={styles.deleteEvent}
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            />
            <Edit className={styles.editEvent} onClick={() => setCreate(true)} />
          </div>
        )}
        <div className={styles.calendarTimelineContainer}>
          <div className={styles.calendarContainer}>
            <CalendarCard
              value={selectedDate}
              onChange={setSelectedDate}
              events={data?.result}
              preventChange={true}
            />
            <div className={styles.upcomingMeetings}>
              <div className={styles.upcomingMeetingsButton}>{t('Upcoming Group Meetings')}</div>
              {data &&
                getThisMonthsMeetings(data).map((event) => {
                  return (
                    <SidewaysEvent
                      timeString={`${convertTimeToUserTimezone(
                        new Date(event.startDate)
                      )}-${convertTimeToUserTimezone(new Date(event.endDate))}`}
                      fullName={event.name}
                      day={new Date(event.startDate).getDate()}
                      month={getMonthNameShort(new Date(event.startDate))}
                      key={event.id}
                    />
                  );
                })}
            </div>
          </div>
          <div className={styles.eventPageContainer}>
            <div className={styles.eventHeaderContainer}>
              <h2>{`${selectedEvent?.name}`}</h2>
              <h6>{`${getDayMonthYearFormat(new Date(selectedEvent?.startDate))}`}</h6>
              <h6>{`${convertTimeToUserTimezone(
                new Date(selectedEvent?.startDate)
              )}-${convertTimeToUserTimezone(new Date(selectedEvent?.endDate))}`}</h6>
            </div>
            {CurrentUserPartOfThisEvent(selectedEvent, profileId) ? (
              !hasCurrentUserResponded(selectedEvent, profileId) ? (
                <div className={styles.responseSection}>
                  {selectedEvent?.mandatory && (
                    <div className={styles.disputeResponse}>
                      {getWithinDisputePeriod(selectedEvent?.disputeHours, selectedEvent) ? (
                        <>
                          <span>
                            {t('This Event is mandatory a dispute can be filled')}{' '}
                            {selectedEvent?.disputeHours} {t('hours before the event starts')}
                          </span>
                          <span
                            className={styles.responseButton}
                            onClick={() => {
                              setResponseModalOpen(true);
                            }}
                          >
                            {t('Dispute')}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>{t('This event is mandatory, the dispute period is over.')}</span>
                        </>
                      )}
                    </div>
                  )}
                  {!selectedEvent.mandatory && (
                    <div className={styles.eventResponse}>
                      <span>{t('Please respond with your attendance')}</span>
                      <span
                        className={styles.responseButton}
                        onClick={() => {
                          setResponseModalOpen(true);
                        }}
                      >
                        {t('Respond')}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.responseSection}>
                  <div className={styles.eventResponse}>
                    <span>
                      {t('You have responded to this event with')}:{' '}
                      {getUserResponse(profileId, selectedEvent)}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <div className={styles.responseSection}>
                <div className={styles.eventResponse}>
                  <span>{t('You are not a part of this event')}</span>
                </div>
              </div>
            )}
            <div className={styles.detailsAndParticipants}>
              <div className={styles.details}>
                <div className={styles.description}>
                  <h3>{t('Description')}</h3>
                  <p>{selectedEvent?.description}</p>
                </div>
                <div className={styles.attachments}>
                  <h3>{t('Attachments')}</h3>
                  <div className={styles.attachmentsListed}>
                    {selectedEvent?.attachments.map((attachment) => {
                      if (isVideoURL(getS3Url(attachment)) || isImageURL(getS3Url(attachment))) {
                        return (
                          <button
                            onClick={() => {
                              setSelectedAttachmentURL(attachment);
                            }}
                            key={attachment}
                          >
                            {attachment.split('/')[1]}
                          </button>
                        );
                      } else {
                        return (
                          <a href={getS3Url(attachment)} key={attachment} rel="noreferrer">
                            {attachment.split('/')[1]}
                          </a>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className={styles.links}>
                  <h3>{t('Links')}</h3>
                  {selectedEvent?.wizrConnectLink ? (
                    <a
                      href={selectedEvent?.wizrConnectLink}
                      style={{ color: '#0058ff' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('Meeting Link')}
                    </a>
                  ) : (
                    <span>{t('Link Not provided')}</span>
                  )}
                </div>
              </div>
              <div className={styles.participants}>
                <h3>{t('Participants')}</h3>
                <div className={styles.participantsListed}>
                  {selectedEvent?.participants.map((participant) => {
                    return (
                      <div className={styles.sideWaysProfileAttendance} key={participant.id}>
                        <SidewaysProfile
                          fullName={`${participant.contact.firstName} ${participant.contact.lastName}`}
                          image={participant.picture}
                          role={participant.jobPosition}
                          key={participant.id}
                        />
                        <GetAttendanceComponent
                          attendanceType={getUserResponse(participant.id, selectedEvent)}
                        ></GetAttendanceComponent>
                      </div>
                    );
                  })}
                  <div
                    className={styles.showResponsesButton}
                    onClick={() => {
                      setResponsesModalOpen(true);
                    }}
                  >
                    {t('Show responses')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateEventModal
          open={create}
          onClose={() => {
            setCreate(false);
          }}
          t={t}
          onSaveEvent={() => {
            setCreate(false);
            setSelectedEvent(undefined);
          }}
          eventToBeEditted={selectedEvent}
        />
      </div>
      <div
        className={styles.calendarSectionContainer}
        style={{ display: !selectedEvent ? 'block' : 'none' }}
      >
        <div className={styles.calendarBanner}>
          <div className={styles.headerBar}>
            <div className={styles.headerNavigation}>
              <div className={styles.navigatorSelected}>{t('Calendar')}</div>
              <div
                className={styles.navigatorSelected}
                onClick={() => {
                  navigate('/working-hours');
                }}
              >
                {t('Working Hours')}
              </div>
            </div>
          </div>
          <div className={styles.timelineHeader}>
            <h2>{getDayMonthYearFormat(selectedDate)}</h2>
            <h4>{getWeekDayLong(selectedDate)}</h4>
          </div>
        </div>
        <div className={styles.calendarTimelineContainer}>
          <div className={`${styles.calendarContainer} ${styles.mainCalendar}`}>
            <CalendarCard value={selectedDate} onChange={setSelectedDate} events={data?.result} />
            <div className={styles.upcomingMeetings}>
              <div className={styles.upcomingMeetingsButton}>{t('Upcoming Group Meetings')}</div>
              {data &&
                getThisMonthsMeetings(data).map((event) => {
                  return (
                    <SidewaysEvent
                      timeString={`${convertTimeToUserTimezone(
                        new Date(event.startDate)
                      )}-${convertTimeToUserTimezone(new Date(event.endDate))}`}
                      fullName={event.name}
                      day={new Date(event.startDate).getDate()}
                      month={getMonthNameShort(new Date(event.startDate))}
                      key={event.id}
                    />
                  );
                })}
            </div>
            {
              <div className={styles.comparisonContainer}>
                <h3>{t('Compare calendars')}</h3>
                <div className={styles.comparisonRow}>
                  <div className={styles.comparisonButton} onClick={() => setCompareOpen(true)}>
                    <Add />
                  </div>
                  {comparedProfiles.map((profile) => {
                    return (
                      <ProfileBullet
                        image={profile.picture}
                        link={`/profile/${profile.id}`}
                        key={profile.id}
                      />
                    );
                  })}
                </div>
              </div>
            }
          </div>
          <div className={styles.timelineContainer}>
            <div className={styles.timeline}>
              <Timeline
                date={selectedDate}
                setDate={setSelectedDate}
                onEventSelect={(event) => {
                  setSelectedEvent(event.resource);
                }}
                onSelectSlot={(slotInfo) => onSlotSelected(slotInfo)}
                events={data?.result}
              />
            </div>
          </div>
        </div>
        <CreateEventModal
          open={create}
          onClose={() => setCreate(false)}
          selectedSlot={selectedSlot}
          onSaveEvent={() => setCreate(false)}
          t={t}
        />
        <ProfilePickerModal
          open={compareOpen}
          setOpen={setCompareOpen}
          selectedProfiles={comparedProfiles}
          setSelectedProfiles={setComparedProfiles}
          pickProfileObject={true}
        />
      </div>
    </Suspense>
  );
}
export default CalendarSection;
