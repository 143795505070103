import React, { useState } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as Arrow } from 'assets/icons/arrowSlider.svg';

function Slider({ children, itemsPerSlide = 5 }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const totalChildren = children.length;

  const handlePrev = slideIndex > 0;
  const handleNext = slideIndex < totalChildren - itemsPerSlide;

  const handleSlide = (direction) => {
    setSlideIndex(slideIndex + direction);
  };

  const displayedChildren = children.slice(slideIndex, slideIndex + itemsPerSlide);

  return (
    <div className={styles.container}>
      {handlePrev && (
        <button className={styles.prev} onClick={() => handleSlide(-1)} name="previous">
          <Arrow />
        </button>
      )}
      <div className={styles.sliderContent}>{displayedChildren}</div>
      {handleNext && (
        <button className={styles.next} onClick={() => handleSlide(1)} name="next">
          <Arrow />
        </button>
      )}
    </div>
  );
}

export default Slider;
