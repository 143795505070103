import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const parseDate = (date) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  // return `${year}-${month}-${day}`;
  return `${day}-${month}-${year}`;
};

export const parseDateWithHour = (date) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const day = dateObj.getDate();
  // return `${year}-${month}-${day}`;
  return `${hour}:${minutes} ${day}-${month}-${year}`;
};

export const parseDateToString = (date) => {
  return new Date(date).toDateString();
};

export const getDurationBetweenDatesInMills = (date1, date2) => {
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);
  return Math.abs(date2Obj - date1Obj);
};

export const getStringDurationByMills = (diffTime) => {
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffYears = Math.ceil(diffDays / 365);
  const diffMonths = Math.ceil(diffDays / 30);
  if (diffYears > 1) return `${diffYears} years`;
  if (diffYears === 1) return `${diffYears} year`;
  if (diffMonths > 1) return `${diffMonths} months`;
  if (diffMonths === 1) return `${diffMonths} month`;
  if (diffDays > 1) return `${diffDays} days`;
  if (diffDays === 1) return `${diffDays} day`;
  return 'less than a day';
};

export const getMonthAndYearName = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  return monthNames[month] + ' ' + year;
};

export const getMonthNameShort = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  return monthNames[month];
};

export const compareDates = (date1, date2) => {
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);
  if (date1Obj.getTime() === date2Obj.getTime()) return 0;
  if (date1Obj.getTime() > date2Obj.getTime()) return 1;
  return -1;
};
export const getCurrentMonthAndYear = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return { month, year };
};

export const getCurrentTimeByTimeZone = (timezone) => {
  const { t } = useTranslation();
  let result = t('Time Zone not set');
  if (!timezone) return result;
  const date = new Date();
  const options = {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric'
  };

  try {
    result = new Intl.DateTimeFormat('en-US', options).format(date);
  } catch (error) {
    error;
  }

  return result;
};

export const getDefaultTimezone = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!timezone) return null;
  return `${timezone} (GMT${timezoneOffset > 0 ? '-' : '+'}${Math.abs(timezoneOffset / 60)})`;
};

export const parseUserExp = (exp) => {
  const { years, months, days } = exp;
  const { t } = useTranslation();
  if (!years && !months && !days) return t('To be calculated when you add experiences');
  let result = '';
  if (years > 0) {
    result += years > 1 ? `${years} years` : `${years} year`;
  }
  if (months > 0) {
    result += months > 1 ? ` ${months} months` : ` ${months} month`;
  }
  return result;
};

export const convertTimeToUserTimezone = (date, timezone) => {
  const userTimeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateObj = new Date(date);
  if (isNaN(dateObj) || isNaN(date)) {
    return 'Invalid Date';
  }
  const options = {
    timeZone: userTimeZone,
    hour: 'numeric',
    minute: 'numeric'
  };

  let result = 'Time Zone not correct';
  try {
    result = new Intl.DateTimeFormat('en-US', options).format(dateObj);
  } catch (error) {
    error;
  }

  return result;
};

export const getWeekDay = () => {
  const dateObj = new Date();
  const day = dateObj.getDay();
  const dayNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  return dayNames[day];
};

export const getDayMonthYearFormat = (date) => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getUTCFullYear();
  const { t } = useTranslation();
  const monthNames = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December')
  ];

  return `${day} ${monthNames[month]} ${year}`;
};

export const getWeekDayLong = (date) => {
  const day = date.getDay();
  const { t } = useTranslation();
  const dayNames = [
    t('Sunday'),
    t('Monday'),
    t('Tuesday'),
    t('Wednesday'),
    t('Thursday'),
    t('Friday'),
    t('Saturday')
  ];
  return dayNames[day];
};

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function getDifferenceInMilliseconds(futureDate) {
  var currentDate = new Date();
  var futureTime = new Date(futureDate);
  var difference = futureTime.getTime() - currentDate.getTime();

  return difference;
}

export function combineDateWithTime(date, time) {
  time = new Date(time);
  const combined = moment(date);
  combined.set('hour', time.getHours());
  combined.set('minute', time.getMinutes());
  combined.set('second', time.getSeconds());
  return combined.toDate();
}
