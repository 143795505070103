import React from 'react';
import styles from './RatingStars.module.scss';
function RatingStars({ size, value }) {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < value) {
      stars.push(
        <span
          key={i}
          style={{
            fontSize: size * 10 + 'px'
          }}
          className={`${styles.star} ${styles.active}`}
        >
          &#9733;
        </span>
      );
    } else {
      stars.push(
        <span
          style={{
            fontSize: size * 10 + 'px'
          }}
          key={i}
          className={styles.star}
        >
          &#9734;
        </span>
      );
    }
  }
  return <div className={styles.stars}>{stars}</div>;
}

export default RatingStars;
