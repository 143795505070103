import { useSaveStatsMutation } from 'api/individual-profile/statsSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserIdSelector, getCurrentUserProfileId } from 'store/login.slice';

function useStatsUtils() {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const [saveStats, { isLoading: isSaving }] = useSaveStatsMutation();
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const currentUserId = useSelector(getCurrentUserIdSelector);
  return { isCurrentUserProfile, saveStats, isSaving, currentUserId };
}

export default useStatsUtils;
