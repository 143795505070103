import React, { useState } from 'react';
import LandingPage from 'components/molecules/LandingPage/LandingPage';
import Boarding from 'components/molecules/BoardingContent';

export default function OnBoarding({ click, loading }) {
  const [started, setStarted] = useState(false);

  const onclick = () => setStarted(true);
  return (
    <>{!started ? <LandingPage click={onclick} /> : <Boarding click={click} loading={loading} />}</>
  );
}
