/**
 * A hook to get the query param from the url
 * @param {string} key - key of the query param
 * @returns {string} - query param value
 */

function useGetUrlQuery(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get(key);
  return query;
}

export default useGetUrlQuery;
