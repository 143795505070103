import React from 'react';
import DatePicker from 'react-datepicker';
import classes from './CustomDatePicker.module.scss';
function CustomDatePicker({ onChange, maxDate, selectedData, ...rest }) {
  return (
    <div className={classes['wrapper']}>
      <DatePicker
        {...rest}
        maxDate={maxDate || new Date()}
        selected={selectedData || new Date()}
        onChange={onChange}
        className={classes['date-picker-input']}
      />
    </div>
  );
}

export default CustomDatePicker;
