import React from 'react';
import { CompanyProfileImage } from './sections';
import styles from './CompanyIntro.module.scss';
import { getTheme } from 'helpers/general';
function CompanyIntro({ companyName, companyLogo, companyTheme }) {
  // TODO: we do want the logo here
  const companyName30Chars = companyName?.substring(0, 30) || '';
  const theme = getTheme(companyTheme, 'companyPage');
  return (
    <>
      <div
        className={styles['wrapper']}
        style={{
          backgroundImage: `url(
      ${theme.assets?.bannerImage}
      )`
        }}
      >
        <CompanyProfileImage companyLogo={companyLogo} name={companyName30Chars} />
      </div>
    </>
  );
}

export default CompanyIntro;
