import React from 'react';
import InfoHubSection from 'components/organisms/InfoHubSection/InfoHubSection';
import { Helmet } from 'react-helmet';
function InfoHub() {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>InfoHub</title>
        <meta name="description" content="Everything and anything you need to know" />
      </Helmet>
      <InfoHubSection />
    </>
  );
}
export default InfoHub;
