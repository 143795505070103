import { handleAsync, isSharedMode } from 'helpers';
import {
  useGetIndividualProfileByProfileIdQuery,
  useUpdateIndividualProfileMutation
} from 'api/individual-profile';
import { showErrorToast } from 'utils/toast';
import { useIsCurrentUserProfile, useUploadBlobs } from 'hooks';
import useGetProfileActiveTemplate from './useGetProfileActiveTemplate';
import React from 'react';
import { useDeleteByKeysMutation } from 'api/s3';
function useResourcesUtils({ profileId }) {
  const [openUploadModal, setOpenUploadModal] = React.useState(false);
  const [removeResourcesByKeys] = useDeleteByKeysMutation();
  const { templateType } = useGetProfileActiveTemplate(profileId);
  const isCurrentUserProfile = useIsCurrentUserProfile();
  const [updateIndividualProfile, { isLoading }] = useUpdateIndividualProfileMutation();
  const { uploadBlobs, isUploading, totalUploadProgress } = useUploadBlobs();
  const profileData = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );

  const save = async ({ resourceName, resourceFile }) => {
    if (!resourceFile.type.includes('image') && !resourceFile.type.includes('video'))
      return showErrorToast('Only images and videos are allowed');

    setOpenUploadModal(false);
    const [status, response] = await handleAsync(uploadBlobs([resourceFile], 'resources_media'));
    const keys = response?.keys ?? [];
    if (!status) {
      const error = typeof response === 'string' ? response : 'Error uploading image';
      return showErrorToast(error);
    }

    handleAsync(
      updateIndividualProfile({
        profile: {
          resources: {
            [templateType]: [
              ...(profileData.data?.bio?.resources?.[templateType] ?? []),
              {
                links: keys[0],
                title: resourceName?.trim(),
                type: resourceFile.type
              }
            ]
          }
        },
        key: 'resources'
      }).unwrap(),
      'Updated Successfully',
      'Something went wrong'
    );
  };
  const removeResources = async (resources) => {
    const resourcesKeys = resources.map((resource) => resource.links);
    const [status] = await handleAsync(
      updateIndividualProfile({
        profile: {
          resources: {
            [templateType]: profileData.data?.bio?.resources?.[templateType].filter(
              (resource) => !resourcesKeys.includes(resource.links)
            )
          }
        },
        key: 'resources'
      }).unwrap(),
      'Deleted Successfully',
      'Something went wrong'
    );
    if (status) {
      handleAsync(
        removeResourcesByKeys({
          body: {
            keys: resourcesKeys
          }
        }).unwrap()
      );
    }
    return status;
  };

  return {
    isLoading,
    isCurrentUserProfile,
    isUploading,
    totalUploadProgress,
    removeResources,
    save,
    profileData,
    openUploadModal,
    setOpenUploadModal
  };
}

export default useResourcesUtils;
