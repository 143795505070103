import { showErrorToast } from 'utils/toast';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';

const withProfileAccess = (WrappedComponent) => {
  const ProfileAccessCheck = ({ ...props }) => {
    const navigate = useNavigate();
    const urlProfileId = useParams()?.id;
    const profileId = useSelector(getCurrentUserProfileId);
    const isOwner = profileId === urlProfileId;
    // const isAdmin = profileData.isAdmin;

    useEffect(() => {
      if (!isOwner) {
        showErrorToast("You don't have access to this page");
        navigate('/');
      }
    }, [urlProfileId]);
    if (isOwner) return <WrappedComponent {...props} />;
    else null;
  };

  return ProfileAccessCheck;
};

export default withProfileAccess;
