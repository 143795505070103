import React from 'react';

const defaultSectionTitle = 'Involvements';
function InvolvementsSectionView({ sectionTitle }) {
  return (
    <div>
      <p
        style={{
          color: 'white'
        }}
      >
        {sectionTitle ?? defaultSectionTitle}
      </p>
    </div>
  );
}
function InvolvementsSection({ sectionTitle }) {
  return <InvolvementsSectionView sectionTitle={sectionTitle} />;
}

export default InvolvementsSection;
