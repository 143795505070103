import React, { useEffect } from 'react';
import { getUserStatusSelector } from 'store/login.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setGlobalEdit } from 'store/individualProfile.slice';
import { useParams } from 'react-router-dom';
import ProfileView from 'components/organisms/Profile/ProfileView';
const ProfilePage = () => {
  document.body.style.overflow = '';

  const { profileStatus } = useSelector(getUserStatusSelector);
  const urlProfileId = useParams()?.id;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setGlobalEdit(false));
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Profile</title>
        <meta name="description" content="Everything you need to know about a person" />
      </Helmet>
      <ProfileView key={urlProfileId} currentProfile={profileStatus} />
    </>
  );
};

export default ProfilePage;
