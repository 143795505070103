import { isSharedMode } from 'helpers';
import { useGetUrlQuery } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useGetSharedPostBySlugQuery } from 'api/social-board';
import { getSharedPostKeySelector, setPostKey } from 'store/socialBoard.slice';
import { useDispatch, useSelector } from 'react-redux';
function useGetSharedPost() {
  const isShared = isSharedMode();
  const slug = useGetUrlQuery('slug');
  const passKey = useSelector(getSharedPostKeySelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (slug?.includes('private') && !passKey) {
    const key = prompt('Please enter the pass key');
    dispatch(setPostKey(key?.trim()));
    navigate(0);
  }
  const goBack = () => {
    navigate(-1);
  };
  const result = useGetSharedPostBySlugQuery(
    { slug, passKey },
    {
      skip: !isShared || !slug || (slug?.includes('private') && !passKey)
    }
  );

  return [result, goBack];
}

export default useGetSharedPost;
