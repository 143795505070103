import React from 'react';
import styles from './TechsSection.module.scss';
import { ReactComponent as SketchI } from 'assets/icons/sketch.svg';

const TechUi = ({ name = 'html', icon = SketchI, rate = 4 }) => {
  return (
    <div className={styles.tech}>
      <div className={styles.left}>
        <SketchI
          style={{
            width: '30px',
            height: '30px'
          }}
        />
      </div>
      <div className={styles.right}>
        <p className={styles.title} style={{ color: 'white' }}>
          {name?.toUpperCase()}
        </p>
        <TechUi.rate rate={rate} />
      </div>
    </div>
  );
};
TechUi.rate = ({ rate }) => {
  const starsCount = Array.from({ length: 5 }, (_, i) => i);
  return (
    <div className={styles.rate}>
      {starsCount.map((_, index) => (
        <span
          key={index}
          className={styles.star}
          style={{
            ...(index < rate ? { backgroundColor: '#EC7600' } : { border: '1px solid #EC7600' })
          }}
        />
      ))}
    </div>
  );
};
export default TechUi;
