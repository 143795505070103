import React, { useEffect, useState } from 'react';
import styles from './QueryableTools.module.scss';
import Icontext from '../IconText/IconText';
import TechDropdown from '../TechDropdown/TechDropdown';
import { apiSlice } from 'api/apiSlice';
import { useDispatch } from 'react-redux';
import { isSharedMode, removeHeightSetWidthSvgIcon } from 'helpers';

export default function QueryableTools({
  list = [],
  add,
  onToolsSelected,
  rtkQueryName,
  extraClass,
  title,
  padding,
  searchPlaceholder,
  bgColor,
  rtkMethodQueries = ''
}) {
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (!rtkQueryName || isSharedMode()) {
      return;
    }
    const result = dispatch(
      apiSlice.endpoints[rtkQueryName].initiate({ keyword, rtkMethodQueries })
    );
    return () => result.unsubscribe();
  }, [keyword, rtkQueryName, dispatch, rtkMethodQueries]);

  return (
    <div className={`${styles.technologies} ${styles[extraClass]}`}>
      <div className={styles.tech}>
        {(list || []).map((item) => {
          return (
            <div
              key={item.id}
              className={add ? `${styles.techItem} ${styles.addItem}` : `${styles.techItem}`}
              style={{ backgroundColor: bgColor }}
            >
              <Icontext
                text={item.name}
                icon={
                  item.icon ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: removeHeightSetWidthSvgIcon(item.icon) }}
                    />
                  ) : (
                    <p className={styles['not-found-letter']}>
                      {item.name.substring(0, 1).toUpperCase()}
                    </p>
                  )
                }
                opacity={1}
              />
            </div>
          );
        })}
        {/* TODO change the name of the component */}
        {add && (
          <TechDropdown
            key={list}
            rtkMethodQueries={rtkMethodQueries}
            extraSelectedItems={[...list]}
            keyword={keyword}
            rtkQueryName={rtkQueryName}
            setKeyword={setKeyword}
            onToolsSelected={onToolsSelected}
            title={title}
            padding={padding}
            searchPlaceholder={searchPlaceholder}
          />
        )}
      </div>
    </div>
  );
}
