import React, { useState } from 'react';
import styles from './FilterSection.module.scss';
import PropTypes from 'prop-types';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrowdown.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as Grid2 } from 'assets/icons/grid2.svg';
import { ReactComponent as Grid4 } from 'assets/icons/grid4.svg';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import { FILTER_TYPES } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import { getProfilesQueryFilter, setProfilesQueryFilter } from 'store/profile.slice';
import { useTranslation } from 'react-i18next';

const FilterSection = ({
  index,
  setIndex,
  extraClass,
  icons,
  searchEmployees,
  extraFilters = [],
  hideFilter,
  colorBgSwitcher
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { t } = useTranslation();

  const profilesFilter = useSelector(getProfilesQueryFilter);
  const dispatch = useDispatch();

  const handleCheckboxChange = (e, title, filter) => {
    const { checked } = e.target;
    
    if (title === 'Started') {
      if (checked) {
        dispatch(setProfilesQueryFilter({ ...profilesFilter, [title]: filter.value }));

        setSelectedItems((prevItems) => {
          const updatedItems = prevItems.filter((item) => item.title !== 'Started');
          return [...updatedItems, { ...filter, title }];
        });
      } else {
        let temp = { ...profilesFilter };
        delete temp[title];
        dispatch(setProfilesQueryFilter({ ...temp }));

        setSelectedItems((prevItems) => prevItems.filter((item) => item.title !== 'Started'));
      }
    } else {
      if (checked) {
        const prev = profilesFilter[title] || null;
        prev
          ? dispatch(
              setProfilesQueryFilter({ ...profilesFilter, [title]: `${filter.value},${prev}` })
            )
          : dispatch(setProfilesQueryFilter({ ...profilesFilter, [title]: filter.value }));
        setSelectedItems((prevItems) => [...prevItems, { ...filter, title }]);
      } else {
        const v = profilesFilter[title].split(',');
        const index = v.indexOf(filter.value);
        v.splice(index, 1);
        if (v.join(',').length === 0) {
          let temp = { ...profilesFilter };
          delete temp[title];
          dispatch(setProfilesQueryFilter({ ...temp }));
        } else {
          dispatch(setProfilesQueryFilter({ ...profilesFilter, [title]: v.join(',') }));
        }
        setSelectedItems((prevItems) => prevItems.filter((item) => item.name !== filter.name));
      }
    }
  };

  return (
    <>
      <div
        className={`${styles.filterContainer} ${styles[extraClass]}  ${
          colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''
        } JoyridefilterContainer `}
      >
        <div className={styles.filterSection}>
          <div className={styles.searchGroup}>
            <div className={styles.searchInput}>
              <input
                type="text"
                placeholder={t('Search all')}
                style={{ color: '#dfdfdf', outline: 'none' }}
                onChange={(e) => searchEmployees(e.target.value)}
              />
              <div className={styles.iconDropdown}>
                <Search />
                {!hideFilter && (
                  <div
                    className={
                      openDropdown
                        ? `${styles.filterGroup} ${styles.activeFilter}`
                        : styles.filterGroup
                    }
                    onClick={() => setOpenDropdown(!openDropdown)}
                  >
                    <Filter />
                    <p>{t('Filter')}</p>
                  </div>
                )}
              </div>
              <DropDownMenu
                filtersData={[...extraFilters, ...FILTER_TYPES]}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                handleCheckboxChange={handleCheckboxChange}
                setSelectedItems={setSelectedItems}
              />
            </div>
          </div>
          {icons && (
            <div className={styles.filterOptions}>
              <div className={styles.filterOption}>
                <Grid4 onClick={() => setIndex(2)} className={index === 2 ? styles.active : ``} />
                <span className={styles.tooltip}>{t('Wide Grid view')}</span>
              </div>
              <div className={styles.filterOption}>
                <Grid2 onClick={() => setIndex(1)} className={index === 1 ? styles.active : ``} />
                <span className={styles.tooltip}>{t('Tight Grid View')}</span>
              </div>
              <div className={styles.filterOption}>
                <List
                  onClick={() => setIndex(0)}
                  className={index === 0 ? `${styles.active} ${styles.activelist}` : ``}
                />
                <span className={styles.tooltip}>{'List View'}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={selectedItems.length > 0 ? styles.selectedFilters : styles.noItemsSelected}>
        <div className={styles.items}>
          {selectedItems.map((item, index) => (
            <div className={styles.item} key={index}>
              {(item.label ? item.label.replace(/_/g, ' ') : item.name) || item.name}
              <button
                onClick={() =>
                  handleCheckboxChange({ target: { checked: false } }, item.title, item, item.label)
                }
              >
                x
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FilterSection;
