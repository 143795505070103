import React, { useState, useEffect, useRef } from 'react';
import styles from './ProjectsSection.module.scss';
import { EditInput } from 'components/form';
import { onFieldChangeProfileModules, objectsEqual_ByKeys, getAuthData, getS3Url } from 'helpers';
import { useSelector } from 'react-redux';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import Options from 'components/atoms/Options/Options';
import { showInfoToast, showSuccessToast } from 'utils/toast';

export default function ProjectCard({
  id,
  description,
  name,
  isCurrentUserProfile,
  isPlaceholdersActive,
  hyperlinkClick,
  link,
  onSave,
  handleDelete,
  cover,
  handelUpload,
  ...rest
}) {
  const originValues = {
    id,
    description,
    name,
    link,
    cover
  };
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState({ ...originValues });
  const [coverImage, setCoverImage] = useState(cover);
  const fileInputRef = useRef(null);

  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };
  const globalEdit = useSelector(getGlobalEditSelector);

  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      showInfoToast('Saving Cover');
      const coverResult = await handelUpload(file);
      const companyId = getAuthData('activeProfile') || '';
      setCoverImage(coverResult);
      rest.onUpdateSubmit({
        ...originValues,
        cover: coverResult,
        company: companyId.split('-')[0]
      });
      showSuccessToast('Cover Saved');
    }
  };

  const handleCardClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  // const handleSave = () => {
  //   setEdit(false);
  //   if (onSave) {
  //     onSave(fieldsChange);
  //   }
  // };

  const convertDescriptionToPlainText = (desc) => {
    if (!desc) return '';
    try {
      const parsedDesc = JSON.parse(desc);
      return parsedDesc
        .map((block) => block.children.map((child) => child.text).join(''))
        .join('\n');
    } catch (error) {
      return desc;
    }
  };

  return (
    <>
      <div className={styles.projCard}>
        {isCurrentUserProfile && !isPlaceholdersActive && (
          <Options
            extraClass="projectOptions"
            handleHyperlink={hyperlinkClick}
            handleUpload={() => fileInputRef.current.click()}
            handlePDF={() => setDeleteModal(true)}
            handleEdit={() => setEdit(true)}
            handleSave={() => {
              const objectsEqual = objectsEqual_ByKeys(fieldsChange, originValues);
              if (objectsEqual) return setEdit(false);
              rest.onUpdateSubmit(fieldsChange);
              setEdit(false);
            }}
            handleDiscard={() => setEdit(false)}
            edit={edit}
            handleDelete={handleDelete}
          />
        )}
        <div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {edit ? (
            <>
              <div className={styles.card}>
                <div className={styles.coverImage}>
                  <img
                    src={
                      cover?.length > 0
                        ? getS3Url(coverImage)
                        : 'https://cdn.wizrx.wizrapps.com/Rectangle_42159_2_1_jamqb8.webp'
                    }
                    alt="Project"
                  />
                </div>
                <div className={styles.profile}>
                  <p className={`${styles.location} ${styles.editField}`}>
                    <EditInput
                      maxLength={100}
                      value={fieldsChange.name}
                      onChange={({ target }) => onFieldsChange('name', target.value)}
                    />
                  </p>
                </div>
                <div className={styles.content}>
                  <p className={`${styles.location} ${styles.editField} ${styles.descField}`}>
                    <EditInput
                      value={convertDescriptionToPlainText(fieldsChange.description)}
                      onChange={({ target }) =>
                        onFieldsChange(
                          'description',
                          JSON.stringify([
                            { type: 'paragraph', children: [{ text: target.value }] }
                          ])
                        )
                      }
                      placeholder="Describe your project"
                      extraClass="projectDescription"
                    />
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div onClick={edit ? undefined : handleCardClick}>
              <div className={styles.card}>
                <div className={styles.coverImage}>
                  <img
                    src={
                      cover?.length > 0
                        ? getS3Url(coverImage)
                        : 'https://cdn.wizrx.wizrapps.com/Rectangle_42159_2_1_jamqb8.webp'
                    }
                    alt="Project"
                  />
                </div>
                <div className={styles.profile}>
                  <h2>{fieldsChange.name}</h2>
                </div>
                <div className={styles.content}>
                  <div
                    className={styles.descriptionHolder}
                    dangerouslySetInnerHTML={{
                      __html: convertDescriptionToPlainText(fieldsChange.description)
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
          {deleteModal && (
            <DeleteModal
              title="Are you sure you want to delete this project?"
              handleClose={() => setDeleteModal(false)}
              handleDelete={() => rest.onDeleteSubmit(id)}
            />
          )}
        </div>
      </div>
    </>
  );
}
