import { apiSlice } from '../apiSlice';

// represent the controller
const secondaryUrl = `event`;

export const eventExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: ({ profileId, start, end, comparisons }) => ({
        url: `/v1/${secondaryUrl}/my/${profileId}?start=${start}&end=${end}`,
        method: 'POST',
        body: comparisons
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'Event', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = result.result.map((item) => ({ type: 'Event', id: item.id }));
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      }
    }),
    getTeamEvents: builder.query({
      query: ({ teamId, start, end }) => ({
        url: `/v1/${secondaryUrl}/team/${teamId}?start=${start}&end=${end}`
      }),
      providesTags: (res, err, { teamId }) => {
        const intiTags = [
          { type: 'Event', id: 'LIST' },
          { type: 'Event', id: teamId }
        ];
        if (err) return [...intiTags];
        return [...intiTags, ...res.result.map((item) => ({ type: 'Event', id: item.id }))];
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { teamId, start: s, end: e } = queryArgs;
        const start = new Date(s);
        const end = new Date(e);
        // We are doing that to avoid fetching every time we change the date, so we are going to fetch only when the day, month or year changes AND not when the time(ms) changes
        const startD = `${start.getFullYear()}-${start.getMonth()}-${start.getDate()}`;
        const endD = `${end.getFullYear()}-${end.getMonth()}-${end.getDate()}`;
        return `${endpointName}({"teamId":"${teamId}","start":"${startD}","end":"${endD}"})`;
      }
    }),
    getEventById: builder.query({
      query: ({ eventId }) => ({
        url: `/v1/${secondaryUrl}/${eventId}`
      }),
      providesTags: (res, err, { eventId }) => [{ type: 'Event', id: eventId }]
    }),
    saveEvent: builder.mutation({
      query: ({ event }) => ({
        url: `/v1/${secondaryUrl}/`,
        method: 'POST',
        body: event
      }),
      invalidatesTags: () => [{ type: 'Event', id: 'LIST' }]
    }),
    saveTeamEvent: builder.mutation({
      query: ({ event, teamId }) => ({
        url: `/v1/${secondaryUrl}/team/${teamId}`,
        method: 'POST',
        body: event
      }),
      invalidatesTags: (result, error, { teamId }) => [{ type: 'Event', id: teamId }]
    }),
    deleteEvent: builder.mutation({
      query: ({ eventId }) => ({
        url: `/v1/${secondaryUrl}/${eventId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { eventId }) => [{ type: 'Event', id: eventId }]
    }),
    updateEvent: builder.mutation({
      query: ({ event, eventId }) => ({
        url: `/v1/${secondaryUrl}/${eventId}`,
        method: 'PATCH',
        body: event
      }),
      invalidatesTags: (result, error, { eventId }) => {
        if (error) return [{ type: 'Event', id: 'LIST' }];
        return [{ type: 'Event', id: eventId }];
      }
    }),
    respondToEvent: builder.mutation({
      query: ({ eventResponse, eventId }) => ({
        url: `/v1/${secondaryUrl}/${eventId}/respond`,
        method: 'PATCH',
        body: eventResponse
      }),
      invalidatesTags: (result, error, { eventId }) => {
        if (error) return [{ type: 'Event', id: 'LIST' }];
        return [{ type: 'Event', id: eventId }];
      }
    })
  })
});

export const {
  useGetEventsQuery,
  useGetTeamEventsQuery,
  useGetEventByIdQuery,
  useSaveEventMutation,
  useSaveTeamEventMutation,
  useDeleteEventMutation,
  useUpdateEventMutation,
  useRespondToEventMutation
} = eventExtendedSlice;
