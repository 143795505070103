import {
  useDeleteTestimonialMutation,
  useSaveTestimonialMutation,
  useUpdateTestimonialMutation
} from 'api/individual-profile';
import { handleAsync } from 'helpers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';

function useTestimonialsUtils({ userId }) {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [updateTestimonial, { isLoading: isUpdating }] = useUpdateTestimonialMutation();
  const [saveTestimonial, { isLoading: isSaving }] = useSaveTestimonialMutation();
  const [deleteTestimonial, { isLoading: isDeleting }] = useDeleteTestimonialMutation();
  const onUpdateSubmit = async (testimonial) => {
    await handleAsync(
      updateTestimonial({
        testimonialId: testimonial.id,
        testimonial,
        userId
      }).unwrap()
    );
  };
  const onCreateSubmit = async (testimonial) => {
    const status = await handleAsync(
      saveTestimonial({
        testimonial,
        userId
      }).unwrap()
    );
    return status[0];
  };
  const onDeleteSubmit = async (testimonialId) => {
    const status = await handleAsync(
      deleteTestimonial({
        testimonialId,
        userId
      }).unwrap()
    );

    return status[0];
  };
  return {
    onDeleteSubmit,
    onUpdateSubmit,
    onCreateSubmit,
    isSaving,
    isUpdating,
    isDeleting,
    isCurrentUserProfile
  };
}

export default useTestimonialsUtils;
