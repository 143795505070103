import CompanySettingView from 'components/organisms/CompanySetting/CompanySettingView';
import React from 'react';

function CompanySetting() {
  document.body.style.overflow = '';

  return <CompanySettingView />;
}

export default CompanySetting;
