import React, { Suspense } from 'react';
import InfoHubProfileBasedTextBlock from 'components/molecules/InfoHubProfileBasedTextBlock';
import FAQ from 'components/molecules/Faq';
import ClientCases from 'components/molecules/ClientCases/ClientCases';
import LinkCarousel from 'components/molecules/LinkCarousel/LinkCarousel';
import SingleItemLinkCarousel from 'components/molecules/SingleItemLinkCarousel/SingleItemLinkCarousel';
import { Loader } from 'components/ui';
function FreelancerT({
  companyId,
  data,
  isLoading,
  lastSelectedImageIndex,
  profileId,
  updateMajorPresentations,
  updateMinorPresentations,
  updateSpecialties,
  isMajorPresentationsLoading,
  isMinorPresentationsLoading,
  isSpecialtiesLoading
}) {
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
        <SingleItemLinkCarousel
          companyId={companyId}
          title={'Presentations'}
          subTitle={'Here are the major presentations that we would like to share'}
          data={data?.info?.majorPresentations}
          updateMutation={updateMajorPresentations}
          colorBgSwitcher={lastSelectedImageIndex}
          isLoading={isLoading || isMajorPresentationsLoading}
        />
        <ClientCases title={'References'} />
        <LinkCarousel
          companyId={companyId}
          title={'Presentations'}
          subTitle={'Recommended presentations'}
          data={data?.info?.minorPresentations}
          updateMutation={updateMinorPresentations}
          colorBgSwitcher={lastSelectedImageIndex}
          isLoading={isLoading || isMinorPresentationsLoading}
          topRowEnabled
        />
        <LinkCarousel
          companyId={companyId}
          title={'Other specialities'}
          data={data?.info?.specialties}
          isLoading={isLoading || isSpecialtiesLoading}
          updateMutation={updateSpecialties}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <InfoHubProfileBasedTextBlock
          data={data}
          isLoading={isLoading}
          profileId={profileId}
          companyId={companyId}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <FAQ colorBgSwitcher={lastSelectedImageIndex} />
    </Suspense>
  );
}

export default FreelancerT;
