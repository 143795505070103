import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getAuthData } from 'helpers/auth';

const cookieAuthToken = getAuthData();
export const client = new ApolloClient({
  uri: `${import.meta.env.REACT_APP_MASTERHUB_API}`,
  cache: new InMemoryCache(),
  method: 'POST',
  headers: {
    Authorization: `Bearer ${cookieAuthToken || ''}`,
    'Content-Type': 'application/json'
  }
});
