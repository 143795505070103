import React, { useState } from 'react';
import styles from './NewsFilters.module.scss';
import { POST_CATEGORIES } from 'constants/socialBoards';
import { Button } from 'components/ui';
import { useTranslation } from 'react-i18next';
export default function NewsFilters({ setFilters, filter }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {POST_CATEGORIES.map((item, i) => {
        return (
          <Button
            bgColor={filter === item.text ? '#069677' : '#232323'}
            color={filter === item.text ? 'white' : 'gray'}
            key={item.text}
            buttonBorder={filter === item.text ? '1px solid red' : ''}
            onClick={() => {
              setFilters(item.text);
            }}
          >
            {t(item.label)}
          </Button>
        );
      })}
    </div>
  );
}
