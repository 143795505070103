import React, { useState } from 'react';
import styles from './WhyThisOpportunitySection.module.scss';
import { useWhyThisOpportunityUtils } from 'components/organisms/Profile/hooks/templates';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import Options from 'components/atoms/Options/Options';
import { showErrorToast } from 'utils/toast';
import { Textarea } from 'components/form';
import { Loader } from 'components/ui';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import { SectionWrapper } from '..';
import { maxWords } from 'helpers/general';

const defaultSectionName = 'Why This Opportunity';
function WhyThisOpportunitySectionView({ data, sectionTitle, isFetching }) {
  const userId = useSelector(getCurrentUserIdSelector);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(data);
  const { onUpdateSubmit, isCurrentUserProfile } = useWhyThisOpportunityUtils({ userId });
  const onSubmit = () => {
    const textData = text.trim();
    onUpdateSubmit(textData);
    setEdit(false);
  };
  return (
    <SectionWrapper sectionTitle={sectionTitle}>
      <div className={styles.container}>
        {isCurrentUserProfile && (
          <Options
            extraClass="whyThisOportunityOptions"
            handleDiscard={() => setEdit(false)}
            handleEdit={() => setEdit(true)}
            edit={edit}
            handleSave={() => {
              const exceedsLimit = maxWords(50, text);
              if (exceedsLimit) {
                showErrorToast('You cannot add more than 50 words');
              } else {
                if (!text || text.length === 0) {
                  return showErrorToast('The field is not allowed to be empty!');
                }
                if (text === data) return setEdit(false);
                onSubmit();
              }
            }}
          />
        )}
        <div className={styles.textContainer}>
          {edit && (
            <div className={styles.textInput}>
              <Textarea
                placeHolder="Describe in 50 words or less why this opportunity suits you the most"
                value={text}
                onChange={({ target: { value } }) => {
                  setText(value);
                }}
              />
            </div>
          )}
          {!edit && <p className={styles.text}>{text}</p>}
        </div>
      </div>
    </SectionWrapper>
  );
}

function WhyThisOpportunitySection({ ...args }) {
  const [sharedDate] = useGetSharedProfile();

  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId: args?.profileId
    },
    {
      skip: isSharedMode() || !args?.profileId
    }
  );
  const { data, isLoading, isError, isFetching } = !isSharedMode() ? result : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {isError?.data?.message}
      </div>
    );
  }
  const text = data?.bio?.whyThisOpportunity;
  const placeHolder = `I've always been fascinated by the endless possibilities in the tech world. From coding to creating innovative solutions, it's a field where I can unleash my creativity and problem-solving skills. I crave the excitement of being at the forefront of technological advancements, making a tangible difference in people's lives.`;

  return (
    <WhyThisOpportunitySectionView
      sectionTitle={defaultSectionName}
      data={text && text?.length !== 0 ? text : placeHolder}
      isFetching={isFetching}
    />
  );
}

export default WhyThisOpportunitySection;
