import React, { useState, useRef, useEffect } from 'react';
import styles from './Options.module.scss';
import { ReactComponent as Select } from 'assets/icons/select.svg';
import { SaveI, CloseI } from 'components/ui';
import { useTour } from '@reactour/tour';
import {useTranslation} from "react-i18next";

export default function Options({
  handleAdd,
  handleEdit,
  handleSave,
  handleDelete,
  handleDiscard,
  edit,
  extraClass,
  colorBgSwitcher,
  hideSave,
  hideDiscard,
  handleUpload,
  handleHyperlink
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const optionsRef = useRef(null);
  const { currentStep, setCurrentStep, isOpen } = useTour();
  const { t } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setSelectedOption(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const optionsList = {
      Add: handleAdd,
      Edit: handleEdit,
      Delete: handleDelete,
      'Add Cover': handleUpload,
      Hyperlink: handleHyperlink,
    };
    const optionSelected = optionsList[option];
    if (optionSelected) {
      optionSelected();
      if (isOpen) setCurrentStep(currentStep + 1);
    }
  };

  let availableOptions = [];

  if (handleAdd) {
    availableOptions.push('Add');
  }
  if (handleEdit) {
    availableOptions.push('Edit');
  }
  if (handleDelete) {
    availableOptions.push('Delete');
  }
  if (handleUpload) {
    availableOptions.push('Add Cover');
  }
  if (handleHyperlink) {
    availableOptions.push('Hyperlink');
  }

  if (edit) {
    return (
      <div className={`${styles.saveDiscard} ${styles[extraClass]}`}>
        {!hideSave && (
          <SaveI
            onClick={() => {
              handleSave();
              if (isOpen) setCurrentStep(currentStep + 1);
            }}
          />
        )}
        {!hideDiscard && (
          <CloseI
            onClick={() => {
              handleDiscard();
              if (isOpen) setCurrentStep(currentStep + 1);
            }}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`${styles.options} ${styles[extraClass]} ${
          colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''
        }`}
        ref={optionsRef}
      >
        <Select
          style={{ padding: '10px' }}
          onClick={(event) => {
            event.stopPropagation();
            setSelectedOption(selectedOption === null ? availableOptions[0] : null);
            if (isOpen) setCurrentStep(currentStep + 1);
          }}
        />
        {selectedOption && (
          <ul>
            {availableOptions.map((option) => (
              <li key={option} onClick={() => handleOptionClick(option)}>
                {t(option)}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
