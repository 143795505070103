import React from 'react';
import styles from './TemplateSection.module.scss';
import TemplateCard from 'components/atoms/TemplateCard/TemplateCard';
import {useTranslation} from "react-i18next";

export default function TemplateSection({ title, templates = [] }) {
    const { t } = useTranslation();
    return (
    <div className={styles.section}>
      <h1 className={styles.templateTitle}>{t(title)}</h1>
      <div className={styles.templates}>
        {templates.map((item, i) => (
          <TemplateCard key={i} {...item} />
        ))}
      </div>
    </div>
  );
}
