import { apiSlice } from '../apiSlice';

// represent the controller
const secondaryUrl = `clients`;

export const clientSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteClientById: builder.mutation({
      query: ({ clientId }) => ({
        url: `v1/${secondaryUrl}/${clientId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['clients']
    }),

    updateClientById: builder.mutation({
      query: ({ clientId, client }) => ({
        url: `v1/${secondaryUrl}/${clientId}`,
        method: 'PATCH',
        body: client
      }),
      invalidatesTags: ['clients']
    }),

    createClient: builder.mutation({
      query: ({ client }) => ({
        url: `v1/${secondaryUrl}`,
        method: 'POST',
        body: client
      }),

      invalidatesTags: ['clients']
    }),
    getClientsByCompanyId: builder.query({
      query: ({ companyId, page = 1 }) => ({
        url: `v1/${secondaryUrl}/${companyId}?page=${page}`
      }),
      providesTags: ['clients']
    }),
    getAllClientsByCompanyId: builder.query({
      query: ({ companyId }) => ({
        url: `v1/${secondaryUrl}/${companyId}/All`
      }),
      providesTags: ['clients']
    })
  })
});

export const {
  useDeleteClientByIdMutation,
  useUpdateClientByIdMutation,
  useCreateClientMutation,
  useGetClientsByCompanyIdQuery,
  useGetAllClientsByCompanyIdQuery
} = clientSlice;
