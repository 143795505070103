import { React, useState } from 'react';
import CreateModal from '../CreateModal/CreateModal';
import SidewaysProfile from 'components/atoms/SidewaysProfile/SidewaysProfile';
import styles from './EventResponsesModal.module.scss';
import { convertTimeToUserTimezone, getMonthNameShort } from 'helpers';
import {
  getAttendanceComponent,
  getMessageComponent,
  getProposalComponent
} from 'helpers/calendar';
function EventResponsesModal({ open, onClose, eventResponses, participants, translate }) {
  if (!open) return null;
  const [showProposal, setShowProposals] = useState(undefined);
  function getUserResponse(id) {
    let CurrentResponse = undefined;
    eventResponses.forEach((response) => {
      if (response.responder === id) {
        CurrentResponse = response;
      }
    });
    return CurrentResponse;
  }

  if (showProposal) {
    return (
      <>
        <>
          <CreateModal
            extraClass={'eventResponsesModal'}
            clickEvent={() => {}}
            handleClose={() => {
              setShowProposals(undefined);
            }}
            ignoreOutsideClick={true}
            showSave={false}
          >
            <h1>{'Proposals...'}</h1>
            <div className={styles.responses}>
              <table>
                {showProposal.map((proposal, index) => {
                  return (
                    <div key={index}>
                      {` ${convertTimeToUserTimezone(
                        new Date(proposal.startDate)
                      )}-${convertTimeToUserTimezone(new Date(proposal.endDate))}`}{' '}
                      on
                      {` ${new Date(proposal.startDate).getDate()} ${getMonthNameShort(
                        new Date(proposal.startDate)
                      )}`}{' '}
                    </div>
                  );
                })}
              </table>
            </div>
          </CreateModal>
        </>
      </>
    );
  } else {
    return (
      <>
        <>
          <CreateModal
            extraClass={'eventResponsesModal'}
            clickEvent={() => {}}
            handleClose={() => {
              onClose();
            }}
            ignoreOutsideClick={true}
            showSave={false}
          >
            <h1>{translate('Event responses')}</h1>
            <div className={styles.responses}>
              <table>
                {participants.map((participant, index) => {
                  const repsonse = getUserResponse(participant.id);
                  return (
                    <tr key={index}>
                      <th>
                        <span>{`${participant.contact.firstName} ${participant.contact.lastName}`}</span>
                      </th>
                      <th>{getAttendanceComponent(repsonse?.response)}</th>
                      <th>{getMessageComponent(repsonse?.message)}</th>
                      <th>
                        {getProposalComponent(repsonse?.rescheduleProposal, setShowProposals)}
                      </th>
                    </tr>
                  );
                })}
              </table>
            </div>
          </CreateModal>
        </>
      </>
    );
  }
}

export default EventResponsesModal;
