import React, { useState } from 'react';
import styles from './CalendarTopMenu.module.scss';
import { useNavigate } from 'react-router-dom';
import EmployeeIcons from '../EmployeeIcons/EmployeeIcons';
import PropTypes from 'prop-types';
import { Button } from 'components/ui';
import {useTranslation} from "react-i18next";

function CalendarTopMenu({ employees }) {
  const [state, setState] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.topmenu}>
      <div className={styles.icons}>
        <EmployeeIcons employees={employees} />
      </div>
      <div className={styles.navigation}>
        <Button
          bgColor={state === 0 ? '#43434375' : '#2b2b2b52'}
          color={state === 0 ? 'white' : 'gray'}
          onClick={() => {
            navigate('/calendar'), setState(0);
          }}
        >
            {t("Calendar")}
        </Button>
        <Button
          bgColor={state === 1 ? '#43434375' : '#2b2b2b52'}
          color={state === 1 ? 'white' : 'gray'}
          onClick={() => {
            navigate('/working-hours'), setState(1);
          }}
        >
            {t("Working Hours")}
        </Button>
      </div>
    </div>
  );
}
export default CalendarTopMenu;

CalendarTopMenu.propTypes = {
  employees: PropTypes.array
};
