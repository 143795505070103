import React from 'react';
import styles from './CompanyButton.module.scss';
import { Link } from 'react-router-dom';

function CompanyButton({
  bg,
  mr = '5px',
  ml = '5px',
  mt = '5px',
  mb = '5px',
  textColor = 'white',
  link,
  onClick,
  children,
  ...rest
}) {
  if (link) {
    return (
      <Link to={link}>
        <button
          className={styles['company-button']}
          style={{
            marginRight: mr,
            marginLeft: ml,
            marginTop: mt,
            marginBottom: mb,
            background: bg,
            color: textColor
          }}
        >
          {children}
        </button>
      </Link>
    );
  }
  return (
    <button
      {...rest}
      className={styles['company-button']}
      style={{
        marginRight: mr,
        marginLeft: ml,
        marginTop: mt,
        marginBottom: mb,
        background: bg,
        color: textColor
      }}
      onClick={() => onClick?.()}
    >
      {children}
    </button>
  );
}

export default CompanyButton;
