import React from "react";
import styles from "./styles.module.scss";

const ChatChannelList = () => {
    return (
        <div className={styles.list}>
            <div className={styles.listItem}>Uganda_team</div>
            <div className={styles.listItem}>Sales</div>
            <div className={styles.listItem}>Marketing</div>
        </div>
    )
}

export default ChatChannelList