import React, { useState } from 'react';
import CalendarCard from '../Calendar/Calendar';
import Timeline from '../Timeline/Timeline';
import CreateEventModal from '../CreateEventModal/CreateEventModal';
import ProfileBullet from 'components/atoms/ProfileBullet/ProfileBullet';
import { showErrorToast } from 'utils/toast';
import { useGetTeamEventsQuery } from 'api/event';

import { Loader } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import styles from './TeamSchedule.module.scss';
import {useTranslation} from "react-i18next";
function TeamSchedule({ team }) {
  const navigate = useNavigate();
  const [create, setCreate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(new Date());
  const { t } = useTranslation();
  const teamId = team.id;
  const { data } = useGetTeamEventsQuery({
    teamId,
    start: new Date(new Date(selectedDate).setMonth(selectedDate.getMonth() - 2)).getTime(),
    end: new Date(new Date(selectedDate).setMonth(selectedDate.getMonth() + 2)).getTime()
  });
  if (!data) {
    return <Loader fullpage />;
  }
  return (
    <div className={styles.calendarSectionContainer}>
      <div className={styles.headerBar}>
        <h2>{team.name}</h2>
        <div className={styles.teamProfileBullets}>
          {team?.profiles?.map((item) => {
            return (
              <ProfileBullet
                key={item.id}
                image={item.picture}
                onClick={() => {
                  navigate(`/profile/${item.id}`);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.calendarTimelineContainer}>
        <div className={styles.calendarContainer}>
          <CalendarCard value={selectedDate} onChange={setSelectedDate} events={data?.result} />
        </div>
        <div className={styles.timelineContainer}>
          {/* <div className={styles.timelineHeader}>
            <h4>{getWeekDayLong(selectedDate)}</h4>
          </div> */}
          <div className={styles.timeline}>
            <Timeline
              date={selectedDate}
              setDate={setSelectedDate}
              onEventSelect={(event) => {
                navigate(`/calendar/event/${event.resource.id}`);
              }}
              onSelectSlot={(slotInfo) => {
                const date = new Date(slotInfo.start);
                const now = new Date();
                now.setHours(0);
                now.setMinutes(0);
                now.setSeconds(0);
                const nowMinusOneHour = now.getTime() - 3600;
                if (date >= nowMinusOneHour) {
                  setSelectedSlot(new Date(slotInfo.start));
                  setCreate(true);
                } else {
                  showErrorToast(t("Can't create an event in the past"));
                }
              }}
              events={data?.result}
            />
          </div>
        </div>
      </div>
      <CreateEventModal
        open={create}
        onClose={() => setCreate(false)}
        selectedSlot={selectedSlot}
        onSaveEvent={() => setCreate(false)}
        teamId={teamId}
        t={t}
      />
    </div>
  );
}
export default TeamSchedule;
