import React from 'react';
import { showWarnToast } from 'utils/toast';
export default function LinkChecker({ link, children, isWhatsApp = false }) {
  if (link) {
    return (
      <a target="_blank" href={!isWhatsApp ? link : `https://wa.me/${link}`} rel="noreferrer">
        {children}
      </a>
    );
  }
  return (
    <span
      onClick={() => {
        showWarnToast('Link not set');
      }}
    >
      {' '}
      {children}{' '}
    </span>
  );
}
