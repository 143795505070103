import React, { useState, useCallback } from 'react';
import styles from './addUserModal.module.scss';
import EmployeeCard from '../EmployeeCard/EmployeeCard';
import searchIcon from 'assets/icons/search.svg';
import { handleAsync } from '../../../helpers';
import { useInviteProfilesMutation } from 'api/team';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import CreateModal from '../CreateModal/CreateModal';
import { Loader } from 'components/ui';
import { useEnterClicked, useProfilesServerSearch } from 'hooks';
// TO DOO: TO ADD PAGINATION
const AddUserModal = ({ closeModal, teamProfiles, teamId }) => {
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [inviteProfiles, { isLoading: isUpdating }] = useInviteProfilesMutation();
  const { setProfileName, profiles, isFetching } = useProfilesServerSearch(
    10 + (teamProfiles?.length || 0)
  );
  const selectedProfilesIds = selectedProfiles.map((item) => item.id);
  const filterProfiles = React.useMemo(() => {
    if (profiles) {
      const filteredProfiles = profiles?.filter(
        (profile) => !teamProfiles.map((item) => item.id).includes(profile.id)
      );
      const filteredProfilesNoSelected = filteredProfiles?.filter(
        (profile) => !selectedProfilesIds.includes(profile.id)
      );

      return [...selectedProfiles, ...filteredProfilesNoSelected];
    }
  }, [profiles]);

  const handleEmployeeCardClick = useCallback((profile) => {
    if (selectedProfilesIds.includes(profile.id)) {
      const newSelectedPros = selectedProfiles.filter((item) => item.id !== profile.id);
      setSelectedProfiles(newSelectedPros);
    } else {
      setSelectedProfiles([...selectedProfiles, profile]);
    }
  });

  const handleSave = async () => {
    const ids = selectedProfilesIds;
    if (ids.length === 0) return closeModal();
    const status = await handleAsync(inviteProfiles({ teamId: teamId, profiles: ids }).unwrap());
    if (status[0]) {
      showSuccessToast('User added successfully.');
      closeModal();
    } else {
      showErrorToast('Something went wrong!');
    }
  };
  useEnterClicked(() => handleSave(), null, [selectedProfiles]);
  return (
    <CreateModal
      isSubmitting={isUpdating}
      extraClass="modalAddNewUser"
      clickEvent={() => handleSave()}
      handleClose={closeModal}
    >
      <h1>{'Add Members'}</h1>
      <div className={styles.searchFormM}>
        <img src={searchIcon} alt='search icon'/>
        <input
          className={styles.searchForm}
          type="text"
          placeholder="Search member..."
          onChange={(e) => setProfileName(e.target.value)}
        />
      </div>
      {isFetching && <Loader />}
      <div className={styles.contentHolder}>
        {!isFetching &&
          filterProfiles?.map((profile) => (
            <EmployeeCard
              showContactButton
              showProfileButton
              key={profile.id}
              clickEvent={() => handleEmployeeCardClick(profile)}
              selectedEmployeesIds={selectedProfilesIds}
              {...profile}
              width="110px"
              height="190px"
              extraClass={`singleCard`}
            />
          ))}
        {filterProfiles?.length === 0 && !isFetching && <p>Not found any user.</p>}
      </div>
    </CreateModal>
  );
};

export default AddUserModal;
