import { TabsList } from 'components/organisms/Profile/templates/utils';

// };
export const COMPANY_INITIAL_SETTINGS = {
  visibleProfileSegments: {
    [TabsList.home]: true,
    [TabsList.role]: true,
    [TabsList.awards]: true,
    [TabsList.reviews]: true,
    [TabsList.workingHours]: true,
    [TabsList.itEssentials]: true
  }
};

export const ECOMPANY_PROFILE_THEMES = {
  DEFAULT: 'default',
  FREELANCER: 'freelancer',
  NGO: 'ngo',
  STARTUP: 'startup',
  COMMUNITY: 'community'
};

export const thumbnailsData = [
  {
    tab: 'company-profile',
    content: [
      {
        title: 'Freelancer',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15020_3_sasemc.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/design_dy92mi.webp'
      },
      {
        title: 'NGO',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15021_1_oeyafk.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/ngo_wjmt9h.webp'
      },
      {
        title: 'Startup',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15029_1_hxacd3.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/start_up_azu8y9.webp'
      },
      {
        title: 'Community',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15033_1_rhi6xl.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/community_bnjl44.webp'
      }
    ]
  },
  {
    tab: 'Info Hub',
    content: [
      {
        title: 'Freelancer',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15020_1_lay3qb.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/freelancer_ix6yzb.webp'
      },
      {
        title: 'NGO',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15021_ggqnjz.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/Info_hub_ngo_m2zxgb.webp'
      },
      {
        title: 'Startup',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15029_dey6i2.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/Info_hub_start_up_kmvmo1.webp'
      },
      {
        title: 'Community',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15033_zuwvfi.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/online_community_cgerxn.webp'
      }
    ]
  },
  {
    tab: 'Individual Profiles',
    content: [
      {
        title: 'Freelancer',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Frame_41943_fvimrw.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/creative_jwd5ea.webp'
      },
      {
        title: 'NGO',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Frame_41944_t6znlu.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/ngo_mqang0.webp'
      },
      {
        title: 'Startup',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Frame_41945_matpa5.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/start_up_q27iw1.webp'
      },
      {
        title: 'Community',
        thumbnail: 'https://cdn.wizrx.wizrapps.com/Frame_41946_wvzlba.webp',
        sourceUrl: 'https://cdn.wizrx.wizrapps.com/community_mqaqze.webp'
      }
    ]
  }
];

export const THEMES = {
  default: {
    companyPage: {
      buttonColor: '#02795ff2',
      bannerGradient: 'default',
      contactGradient:
        'radial-gradient(121.94% 121.94% at 50% 50%, #1A8361 0%, rgba(16, 16, 16, 0.00) 100%)',
      assets: {
        bannerImage: 'https://cdn.wizrx.wizrapps.com/Frame_41938_tggh26.webp',
        defaultMissionImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_14654_1_dvykj1.webp',
        defaultServiceImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_14655_wrdhoy.webp',
        storyDefaultImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_14652_c392ql.webp'
      },
      missionTitle: 'What We Care About',
      missionText:
        "At our company, what we care about is more than just a mission statement; it's a driving force that guides everything we do. We care about innovation that challenges the status quo, pushing the boundaries of what's possible.",
      serviceTitle: ['Creative Branding', 'AI-Powered Data', 'Cybersecurity', 'Development'],
      serviceDescription: [
        'Create visually compelling identities, logos, and marketing materials that resonate with your target audience',
        'Harnessing the power of artificial intelligence, we provide cutting-edge data analytics services to extract actionable insights',
        'We offer a range of cybersecurity services, from penetration testing and threat assessment to ongoing monitoring and incident response',
        'Whether you need a web application, mobile app, or enterprise software, our experienced development team crafts'
      ],
      aboutTitle: 'Our Story',
      aboutDescription:
        'Our story is one of unwavering determination and boundless passion. It begins with a vision, a dream that dared to challenge the ordinary and redefine the possible. Our founders driven by an unrelenting desire to make a difference, embarked on a journey that would shape the course of our company.'
    },
    infoHub: {
      hereText: 'Sharing our work',
      assets: {
        mainBackground: 'https://cdn.wizrx.wizrapps.com/Frame_40933_lr6dhq.webp',
        defaultInfoImage: 'https://cdn.wizrx.wizrapps.com/Frame_41935_4_yibj5x.webp'
      }
    },
    singleProfile: {
      defaultProfileBackground: 'https://cdn.wizrx.wizrapps.com/Rectangle_15239_4_ksoyuk.webp'
    }
  },
  freelancer: {
    companyPage: {
      buttonColor: '#9970ad',
      bannerGradient: 'linear-gradient(90deg, #200D27 1.56%, #7D6482 97.16%)',
      contactGradient:
        'radial-gradient(121.94% 121.94% at 50% 50%, #4E3354 0%, rgba(49, 29, 53, 0.00) 100%)',
      assets: {
        bannerImage: 'https://cdn.wizrx.wizrapps.com/Group_40907_xbj9il.webp',
        defaultMissionImage: 'https://cdn.wizrx.wizrapps.com/Group_40902_yqe8cu.webp', // note, this is will be displayed if no mission image is provided
        defaultServiceImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_14655_1_zzerhi.webp',
        storyDefaultImage: 'https://cdn.wizrx.wizrapps.com/Group_40903_r7zj9t.webp' // note, this is will be displayed if no story image is provided
      },
      missionTitle: 'What I Care About',
      missionText:
        "In the world of graphic design, I care deeply about pushing creative boundaries and delivering visually captivating solutions. I am dedicated to understanding each project's unique needs, from crafting compelling brand identities to designing immersive digital experiences. I do this with passion and a relentless pursuit of perfection.",
      serviceTitle: ['Logo design', 'Ui/Ux', 'Digital Marketing', 'Content Creation'],
      serviceDescription: [
        "Dedicated to creating memorable, timeless, and meaningful logos that encapsulate your brand's essence.",
        'Crafting seamless and captivating user experiences is at the core of my UI/UX design expertise.',
        'My digital marketing strategies are designed to boost your online presence, drive traffic, and convert leads into loyal customers.',
        'Crafting engaging and informative content across various mediums to drive engagement.'
      ],
      aboutTitle: 'My Story',
      aboutDescription:
        "My journey as a graphic designer is a captivating blend of artistic passion and professional growth. From my early fascination with doodles to formal design education, I've cultivated a deep love for visual storytelling. With each project, I aim to create immersive experiences and bring ideas to life through design."
    },
    infoHub: {
      hereText: 'Sharing my work',
      bannerGradient: 'linear-gradient(90deg, #200D27 1.56%, #7D6482 97.16%)',
      assets: {
        mainBackground: 'https://cdn.wizrx.wizrapps.com/Group_20354_2_iqhc3j.webp',
        defaultInfoImage: 'https://cdn.wizrx.wizrapps.com/Frame_41934_n1la4e.webp'
      }
    },
    singleProfile: {
      defaultProfileBackground: 'https://cdn.wizrx.wizrapps.com/Rectangle_15239_1_umyqri.webp'
      // note, this is will be displayed if no profile background is provided
      // note, this background image should be also available in the backgrounds(top-right) section
    }
  },
  ngo: {
    primaryLight: '#059494',
    primaryDark: '#008080',
    companyPage: {
      buttonColor: '#258d8e',
      bannerGradient: 'linear-gradient(90deg, #008080 1.56%, #059494 97.16%)',
      contactGradient:
        'radial-gradient(121.94% 121.94% at 50% 50%, #16A0A0 0%, rgba(29, 53, 53, 0.00) 100%)',
      assets: {
        bannerImage: 'https://cdn.wizrx.wizrapps.com/Group_40908_a2oqaq.webp',
        defaultMissionImage: 'https://cdn.wizrx.wizrapps.com/Group_40905_ght6kt.webp', // note, this is will be displayed if no mission image is provided
        defaultServiceImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_14655_2_oo3ays.webp',
        storyDefaultImage: 'https://cdn.wizrx.wizrapps.com/Group_40904_ypnkhl.webp' // note, this is will be displayed if no story image is provided
      },
      missionTitle: 'What We Care About',
      missionText:
        'We stand for justice, working to break down systemic barriers that marginalize vulnerable populations. Environmental sustainability is at the core of our operations, as we believe in safeguarding our planet for future generations. Our dedication to transparency, collaboration, and accountability ensures that we deliver tangible results while upholding ethical standards.',
      serviceTitle: ['Healthcare', 'Environment', 'Education', 'Human Rights'],
      serviceDescription: [
        'Operating clinics, mobile health units, and vaccination campaigns to improve healthcare access and outcomes.',
        'Engaging in conservation efforts, wildlife protection, and sustainable resource management.',
        'Offering educational programs, vocational training, and skills development to underserved populations.',
        'Offering legal assistance to marginalized communities and advocating for the protection of human rights.'
      ],
      aboutTitle: 'Our Story',
      aboutDescription:
        'Our story is one of determination, resilience, and unwavering commitment to our mission. Over the years, we have worked tirelessly to advance the causes of human dignity, equality, and sustainability. Our achievements, milestones, and partnerships have shaped our identity and continue to drive us forward.'
    },
    infoHub: {
      hereText: 'Sharing what we care for',
      bannerGradient: 'linear-gradient(90deg, #008080 1.56%, #059494 97.16%)',
      assets: {
        mainBackground: 'https://cdn.wizrx.wizrapps.com/Group_20354_3_jluwzf.webp',
        defaultInfoImage: 'https://cdn.wizrx.wizrapps.com/Frame_41935_2_njtomi.webp'
      }
    },
    singleProfile: {
      defaultProfileBackground: 'https://cdn.wizrx.wizrapps.com/Rectangle_15239_2_dg9fkx.webp'
      // note, this is will be displayed if no profile background is provided
      // note, this background image should be also available in the backgrounds(top-right) section
    }
  },
  startup: {
    primaryLight: '#1464ad',
    primaryDark: '#074e8c',
    companyPage: {
      buttonColor: '#1d70a6',
      bannerGradient: 'linear-gradient(90deg, #1465AE 1.56%, #074E8C 97.16%)',
      contactGradient:
        'radial-gradient(121.94% 121.94% at 50% 50%, #2776BE 0%, rgba(29, 31, 53, 0.00) 100%)',
      assets: {
        bannerImage: 'https://cdn.wizrx.wizrapps.com/Group_40909_azc6te.webp',
        defaultMissionImage: 'https://cdn.wizrx.wizrapps.com/Group_40906_gcwvat.webp', // note, this is will be displayed if no mission image is provided
        defaultServiceImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_14655_3_kyffc8.webp',
        storyDefaultImage: 'https://cdn.wizrx.wizrapps.com/Group_40906_gcwvat.webp' // note, this is will be displayed if no story image is provided
      },
      missionTitle: 'What We Care About',
      missionText:
        'We deeply care about making a positive impact on the world by empowering our customers, celebrating diversity and inclusivity, supporting social causes, and fostering a culture of continuous learning and collaboration. Our values are not just words; they guide every decision we make and define our unwavering dedication to building a better future for all.',
      serviceTitle: ['Consulting', 'Web Design', 'Marketing', 'E-Commerce '],
      serviceDescription: [
        'Providing expert advice and guidance in a specific industry or field',
        'Designing and building websites and web applications',
        'Offering services such as SEO, social media marketing, and online advertising',
        'Developing and maintaining e-commerce platforms for online retailers.'
      ],
      aboutTitle: 'Our Story',
      aboutDescription:
        "Our journey began with a shared passion for solving a pressing problem. Founded by a group of visionary individuals, We emerged from a desire to innovate and make a meaningful impact. It all started in 2000, when our founders came together with a common goal: to create solutions that matter. Over the years, we've faced challenges, celebrated milestones, and learned valuable lessons that have shaped our identity."
    },
    infoHub: {
      hereText: 'Welcome dear colleagues',
      bannerGradient: 'linear-gradient(90deg, #1465AE 1.56%, #074E8C 97.16%)',
      assets: {
        mainBackground: 'https://cdn.wizrx.wizrapps.com/Group_20354_4_rhhdcz.webp',
        defaultInfoImage: 'https://cdn.wizrx.wizrapps.com/Frame_41936_1_mle37w.webp'
      }
    },
    singleProfile: {
      defaultProfileBackground: 'https://cdn.wizrx.wizrapps.com/Rectangle_15239_3_xrpveb.webp'
      // note, this is will be displayed if no profile background is provided
      // note, this background image should be also available in the backgrounds(top-right) section
    }
  },
  community: {
    primaryLight: '#14ad91',
    primaryDark: '#095446',
    companyPage: {
      buttonColor: '#3d8e78',
      bannerGradient: 'linear-gradient(90deg, #095447 1.56%, #13A48A 97.16%)',
      contactGradient:
        'radial-gradient(121.94% 121.94% at 50% 50%, #079677 0%, rgba(49, 29, 53, 0.00) 100%)',
      assets: {
        bannerImage: 'https://cdn.wizrx.wizrapps.com/Group_40910_yybswe.webp',
        defaultMissionImage: 'https://cdn.wizrx.wizrapps.com/Group_40901_gp9l7w.webp', // note, this is will be displayed if no mission image is provided
        defaultServiceImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_14655_xvkw3f.webp',
        storyDefaultImage: 'https://cdn.wizrx.wizrapps.com/Frame_41814_1_gkselk.webp' // note, this is will be displayed if no story image is provided
      },
      missionTitle: 'What I Care About',
      missionText:
        'Through our unwavering commitment, we aspire to leave a legacy of hope, sustainability, and a world where every individual has the opportunity to thrive. Together, we can shape a brighter, more equitable future for all.',
      serviceTitle: ['Resource sharing', 'Networking', 'Knowledge Base', 'Collaboration Spaces'],
      serviceDescription: [
        'Communities can provide a platform for sharing documents, articles, research papers, templates, and other valuable resources.',
        'Members can connect with like-minded individuals, professionals, and experts within their niche or industry.',
        'Building a repository of knowledge with FAQs, tutorials, and informative articles for reference.',
        'Providing areas for members to collaborate on projects, share feedback, and work together on common goals'
      ],
      aboutTitle: 'My Story',
      aboutDescription:
        'It all began with a simple idea—to create a digital space where passionate individuals from every corner of the world could come together, connect, and amplify their collective impact. Our journey started with a shared dream of fostering positive change, sparking innovation, and breaking down the barriers that divide us.'
    },
    infoHub: {
      hereText: 'Welcome to our community',
      bannerGradient: 'linear-gradient(90deg, #095447 1.56%, #13A48A 97.16%)',
      assets: {
        mainBackground: 'https://cdn.wizrx.wizrapps.com/Group_20354_5_yijc7t.webp',
        defaultInfoImage: 'https://cdn.wizrx.wizrapps.com/Frame_41937_1_ywjttb.webp'
      }
    },
    singleProfile: {
      defaultProfileBackground: 'https://cdn.wizrx.wizrapps.com/Rectangle_15239_ogo5vq.webp'
      // note, this is will be displayed if no profile background is provided
      // note, this background image should be also available in the backgrounds(top-right) section
    }
  }
};
