import React, { useState, useMemo, useEffect } from 'react';
import Container from '../../atoms/InfoHubContainerSection';
import InfoHubTitle from 'components/atoms/InfoHubTitle';
import styles from './style.module.scss';
import ContainerSection from '../../atoms/InfoHubContainerSection';
import { Loader } from 'components/ui';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import ProfilePicker from '../ProfilePicker/ProfilePicker';
import { useUpdateProfileBasedTextBlockMutation } from 'api/info-hub';
import { handleAsync } from 'helpers';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { serialize } from 'components/atoms/MWEditor/Helpers';
import defaultProfileImage from '../../../assets/images/avatar.webp';
import { getS3Url } from 'helpers/s3Handler';
import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import EditSection from 'components/atoms/EditSection/EditSection';
import { ProfilePic } from 'components/ui';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';
import wizzy from 'assets/images/wizzy.webp';
import { useTranslation } from 'react-i18next';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

export const Profile = ({ firstName, lastName, jobTitle, Email, picture, id }) => (
  <div className={styles.profileCard}>
    <ProfilePic
      extraClass="newsprofile"
      width={100}
      height={100}
      clickable
      profileId={id}
      image={picture}
    />
    <div>
      <h2>
        {firstName} {lastName}
      </h2>
      <span>{jobTitle}</span>
      <a href={`mailto:${Email}`}>{Email}</a>
      <a href={`/profile/${id}`}>
        {firstName} {lastName}
      </a>
    </div>
  </div>
);

export default function InfoHubProfileBasedTextBlock({
  data,
  isLoading,
  profileId,
  companyId,
  colorBgSwitcher
}) {
  const [edit, setEdit] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const [updateProfileBasedTextBlock, { isLoadingSave }] = useUpdateProfileBasedTextBlockMutation();
  const isAdmin = useSelector(getIsAdminSelector);
  const { t } = useTranslation();
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;
  const [titleInputs, setTitleInputs] = useState({ title: '', subtitle: '' }); ///todo:maybe combine all inputs to one state
  useEffect(() => {
    let initialProfiles = [];
    data?.info?.ProfileBasedTextBlock?.profiles?.forEach((profile) => {
      initialProfiles.push(profile.id);
    });
    setSelectedProfiles(initialProfiles);
    setTitleInputs({
      title: data?.info?.ProfileBasedTextBlock?.title,
      subtitle: data?.info?.ProfileBasedTextBlock?.subtitle
    });
  }, [data]); //Todo:this is kinda bad practice

  const saveContent = async () => {
    showSuccessToast('Saving...');
    ({
      profile: profileId,
      text: JSON.stringify(editor.children),
      profiles: selectedProfiles,
      title: titleInputs?.title,
      subtitle: titleInputs?.subtitle
    });
    const result = await handleAsync(
      updateProfileBasedTextBlock({
        companyId: companyId,
        ProfileBasedTextBlock: {
          profile: profileId,
          text: JSON.stringify(editor.children),
          profiles: selectedProfiles,
          title: titleInputs?.title,
          subtitle: titleInputs?.subtitle
        }
      }).unwrap()
    );
    if (result[0]) {
      showSuccessToast(t('editors-message-saved'));
      setEdit(false);
    } else {
      showErrorToast(t('something-went-wrong'));
    }
  };
  const onEditClicked = () => {
    setEdit(true);
  };
  const onCancelClicked = () => {
    setEdit(false);
  };
  const onSaveClicked = () => {
    saveContent();
  };
  if (isLoading) {
    return (
      <ContainerSection>
        <Loader defaultPosition />
      </ContainerSection>
    );
  } else if (!data?.info?.ProfileBasedTextBlock && !edit) {
    return (
      <Container>
        <EditSection
          edit={edit}
          onCancelClick={onCancelClicked}
          onEditClicked={onEditClicked}
          onSaveClick={onSaveClicked}
          save
        />
        <InfoHubTitle title="Greetings from Wizzy" />
        <div className={styles.content}>
          <img src={wizzy} alt="static image" />
          <div>
            <InfoHubTitle title={t('Welcome to the world of wizr')} />
            <h2>
              {'Wizzy'}
              {'Wizr'}
            </h2>
            <span className={styles.DefaultPosition}>{t('Lead Wizbot')}</span>
            <p className={styles.DefaultMessage}>
              <p>{t('Wizbot welcomes you to the world of masterwizr')}</p>
              <p>{t('here-you-can-set-up-a-message')}</p>
            </p>
          </div>
        </div>
        <div>
          <InfoHubTitle extraClass="infoSectionTitle" title="" />
          <div className={styles.profiles}>
            <SliderContainer breakpoint={1440} breakpointSlide={3} slides={3}>
              <Profile
                firstName={'Wizzy'}
                lastName={'Wizr'}
                key={''}
                Email={'Wiz@wizr.com'}
                jobTitle={'Lead wizbot'}
                picture={wizzy}
                id={''}
              />
            </SliderContainer>
          </div>
        </div>
      </Container>
    );
  }
  return (
    <Container>
      {isAdmin && userTier === APP_TIERS.REGULAR && (
        <EditSection
          edit={edit}
          onCancelClick={onCancelClicked}
          onEditClicked={onEditClicked}
          onSaveClick={onSaveClicked}
          save
          colorBgSwitcher={colorBgSwitcher}
        />
      )}

      {!edit && (
        <div>
          <InfoHubTitle
            extraClass="infoSectionTitle"
            title={data?.info?.ProfileBasedTextBlock?.title}
          />
          <div className={styles.content}>
            <img
              alt="profile image"
              src={
                data?.info?.ProfileBasedTextBlock?.profile?.picture
                  ? getS3Url(data?.info?.ProfileBasedTextBlock?.profile?.picture)
                  : defaultProfileImage
              }
            />
            <div>
              <InfoHubTitle title={data?.info?.ProfileBasedTextBlock?.subtitle} />
              <h2>
                {data?.info?.ProfileBasedTextBlock?.profile?.contact?.firstName}{' '}
                {data?.info?.ProfileBasedTextBlock?.profile?.contact?.lastName}
              </h2>
              <span>{data?.info?.ProfileBasedTextBlock?.profile?.jobPosition}</span>
              <p
                dangerouslySetInnerHTML={{
                  __html: serialize(JSON.parse(data?.info?.ProfileBasedTextBlock?.text))
                }}
              ></p>
            </div>
          </div>
          <div>
            <div className={styles.profiles}>
              <SliderContainer breakpoint={1440} breakpointSlide={3} slides={3}>
                {data?.info?.ProfileBasedTextBlock?.profiles &&
                  data?.info?.ProfileBasedTextBlock?.profiles?.map((profile, index) => {
                    return (
                      <Profile
                        firstName={profile?.contact?.firstName}
                        lastName={profile?.contact?.lastName}
                        key={profile?.id}
                        Email={profile?.contact?.email}
                        jobTitle={profile?.jobPosition}
                        picture={
                          profile?.picture ? getS3Url(profile?.picture) : defaultProfileImage
                        }
                        id={profile?.id}
                      />
                    );
                  })}
              </SliderContainer>
            </div>
          </div>
        </div>
      )}
      {edit && (
        <div className={styles.editcontent}>
          <div className={styles.textGuide}>
            <InfoHubTitle title={t('Please input a message')} />
            <Slate
              editor={editor}
              value={
                data?.info?.ProfileBasedTextBlock?.text
                  ? JSON.parse(data?.info?.ProfileBasedTextBlock?.text)
                  : initialValue
              }
            >
              <Editable placeholder={t('enter-something-memorable')} />
            </Slate>
          </div>
          <div className={styles.divider}></div>
          <div>
            <input
              type="text"
              id="titleInput"
              name="titleInput"
              required
              placeholder={t('Add a title')}
              value={titleInputs.title}
              onChange={(event) => {
                setTitleInputs({ ...titleInputs, title: event.target.value });
              }}
            />
          </div>
          <div>
            <input
              type="text"
              id="subtitleInput"
              name="subtitleInput"
              required
              placeholder={t('Add a sub title')}
              value={titleInputs.subtitle}
              onChange={(event) => {
                setTitleInputs({ ...titleInputs, subtitle: event.target.value });
              }}
            />
          </div>
          <div className={styles.divider}></div>
          <div className={styles.pickProfiles}>
            <span className={styles.pickProfilesLabel}>
              {t('Please pick the profiles you want visible')}
            </span>
            <ProfilePicker
              selectedProfiles={selectedProfiles}
              setProfiles={setSelectedProfiles}
              colorBgSwitcher={colorBgSwitcher}
            />
          </div>
        </div>
      )}
    </Container>
  );
}

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
