import React, { useState } from 'react';
import peopleMeetIm from 'assets/images/people-talking.webp';
import { SectionTag, Wrapper } from '../../components';
import styles from './Mission.module.scss';
import { DescriptionAndUploadModal } from 'components/ui';
import { EditableText } from 'components/form';
import { showErrorToast, showWarnToast } from 'utils/toast';
import { useUploadBlob } from 'hooks';
import { deleteFromS3, getS3Url, handleAsync } from 'helpers';
import { getTheme } from 'helpers/general';
function Mission({ mission, onSectionUpdate, hasEditRights, companyTheme }) {
  const theme = getTheme(companyTheme, 'companyPage');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { uploadBlob, isUploading } = useUploadBlob();
  const [missionState, setMissionState] = useState({
    description: mission?.description || ''
  });
  const [file, setFile] = useState(null);
  const appNav = document.getElementById('app-nav');
  const appNavHeight = appNav?.offsetHeight || 0;
  return (
    <>
      <div
        style={{
          position: 'relative',
          top: `-${appNavHeight}px`
        }}
        id="company-home"
      />
      <Wrapper
        onEditClick={() => {
          setIsModalOpen(true);
        }}
        isEdit={isModalOpen}
        px={4}
        py={3}
        editForbidden={!hasEditRights}
      >
        <SectionTag title="Mission" />
        <div className={styles['mission']}>
          <div className={styles['missionTitle']}>
            <h1>What We Care About</h1>
            <EditableText
              placeholder={`This is a mission description, having a clear company mission statement can build a strong identity and sense of purpose for your team.
        so take your time to write a good one, here is a tip: a good mission statement isn't just a slogan, it's an operations manual and it can (and should) be used to solve any number of business problems.`}
              className={styles['description']}
              value={mission?.description || theme.missionText}
            />
          </div>
          <div
            className={styles['mission__image']}
            style={{
              backgroundImage: `url(
    ${mission?.image ? getS3Url(mission?.image) : theme.assets?.defaultMissionImage}
    )`
            }}
          ></div>
          <DescriptionAndUploadModal
            description={missionState.description}
            userUploadedImage={mission?.image}
            inputAccept={'images'}
            isBlobUploading={isUploading}
            isModalOpen={isModalOpen}
            onSectionUpdate={onSectionUpdate}
            onBlobUpload={(image) => setFile(image)}
            onDescriptionChange={({ target: { value } }) => {
              if (value.length > 500) {
                setMissionState({
                  ...missionState,
                  description: value.substring(0, 500)
                });
                showWarnToast('Mission description should be less than 500 characters');
              } else {
                setMissionState({
                  ...missionState,
                  description: value.substring(0, 500)
                });
              }
            }}
            onClose={() => setIsModalOpen(false)}
            onSubmit={async () => {
              const resultObj = { mission: { image: mission?.image || '' } };
              // upload image if there is
              if (file) {
                const [status, uploadedFileUrl] = await handleAsync(uploadBlob(file));
                if (status) resultObj.mission['image'] = uploadedFileUrl;
                else {
                  const error =
                    typeof uploadedFileUrl === 'string' ? uploadedFileUrl : 'Error uploading image';
                  return showErrorToast(error);
                }
                // delete the old image if there is
                if (mission?.image) handleAsync(deleteFromS3(mission.image));
              }
              resultObj.mission['description'] = missionState.description;
              onSectionUpdate(resultObj);
              setIsModalOpen(false);
              setFile(null);
            }}
          />
        </div>
      </Wrapper>
    </>
  );
}

export default Mission;
