import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { addObjectToArrayIfNotExist } from 'helpers';
import { deleteQandAInListInQuery, updateQandAInListInQuery } from './util';

const secondaryUrl = 'q-and-a';

export const qAndAExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteQAndA: builder.mutation({
      query: ({ id }) => ({
        url: `/v1/${secondaryUrl}/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ id, userId }, { queryFulfilled }) {
        const deleteResult = deleteQandAInListInQuery({
          id,
          queryName: 'getQandAByUserId',
          params: { userId }
        });

        await optimisticUpdater(deleteResult, queryFulfilled);
      },
      invalidatesTags: () => [{ type: 'QandA', id: 'LIST' }]
    }),

    saveQandA: builder.mutation({
      query: ({ qAndA }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: qAndA
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdQandAData) => {
            if (!createdQandAData) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getQandAByUserId',
                {
                  userId
                },
                (draft) => {
                  draft.result.unshift(createdQandAData);
                }
              )
            );
          }
        );
      }
    }),

    updateQandA: builder.mutation({
      query: ({ qAndA }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'PATCH',
        body: qAndA
      }),
      async onQueryStarted({ qAndA, userId }, { dispatch, queryFulfilled }) {
        const patchResult = updateQandAInListInQuery({
          qAndAId: qAndA.id,
          qAndA,
          queryName: 'getQandAByUserId',
          params: { userId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'QandA', id: 'LIST' }]));
        });
      }
    }),

    getQandAByUserId: builder.query({
      query: ({ userId, page = 1, size = 3 }) => ({
        url: `/v1/${secondaryUrl}/${userId}?page=${page}&size=${size}`,
        method: 'GET'
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'QandA', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = result.result.map((item) => ({ type: 'QandA', id: item.id }));
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { userId } = queryArgs;
        return `${endpointName}({"userId":"${userId}"})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a QandA, the created QandA is added to the cache, and the last QandA
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new QandA
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    })
  })
});

export const {
  useDeleteQAndAMutation,
  useGetQandAByUserIdQuery,
  useSaveQandAMutation,
  useUpdateQandAMutation
} = qAndAExtendedSlice;
