import React, { Suspense } from 'react';
import styles from './boardingContent.module.scss';
import ImageSection from 'components/atoms/ImageSection/ImageSection';
import { INFO_CONTENT } from 'constants';
import { Loader } from 'components/ui';

export default function Boarding({ click, loading, removeBtn = false }) {
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles.container}>
        <div className={styles.infoSections}>
          {INFO_CONTENT.map((d, i) => {
            return <ImageSection {...d} key={i} />;
          })}
        </div>
        <div className={styles.empowerSection}>
          <h1>
            Empowering Careers. <br /> <span>Empowering You.</span>
          </h1>
        </div>
        {!removeBtn && (
          <>
            {loading ? (
              <div
                className={styles.onBoardingBtn}
                style={{ background: 'black', padding: '0 2rem' }}
              >
                <Loader size={2} />
              </div>
            ) : (
              <button className={styles.onBoardingBtn} onClick={click} name="finish">
                Finish
              </button>
            )}{' '}
          </>
        )}
      </div>
    </Suspense>
  );
}
