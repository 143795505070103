import React from 'react';
import { SectionTag, Wrapper } from '../../components';
import { AffiliateCard } from './sections';
import styles from './Affiliates.module.scss';
import { Modal } from 'components/modals';
import { Textarea } from 'components/form';
import { showWarnToast } from 'utils/toast';
import { Button } from 'components/ui';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { TrashI } from 'components/ui';

function Affiliates({ affiliates, onSectionUpdate, hasEditRights }) {
  const [editAffiliates, setEditAffiliates] = React.useState(false);
  const [isAddAffiliate, setIsAddAffiliate] = React.useState(false);
  const [affiliateTitle, setAffiliateTitle] = React.useState('');
  const [selectedAffiliatesForMultiDeletion, setSelectedAffiliatesForMultiDeletion] =
    React.useState([]);
  const [selectedAffiliatesForDeletion, setSelectedAffiliatesForDeletion] = React.useState(false);

  const appNav = document.getElementById('app-nav');
  const appNavHeight = appNav?.offsetHeight || 0;

  const handleToggleAffiliateSelection = (affiliate) => {
    if (selectedAffiliatesForMultiDeletion.includes(affiliate)) {
      setSelectedAffiliatesForMultiDeletion(
        selectedAffiliatesForMultiDeletion.filter((aff) => aff !== affiliate)
      );
    } else {
      setSelectedAffiliatesForMultiDeletion([...selectedAffiliatesForMultiDeletion, affiliate]);
    }
  };
  const handleDeleteSelectedAffiliates = () => {
    const affiliatesCopy = affiliates.filter(
      (aff) => !selectedAffiliatesForMultiDeletion.includes(aff)
    );
    const affiliatesData = {
      affiliates: affiliatesCopy
    };
    onSectionUpdate(affiliatesData);
    setSelectedAffiliatesForMultiDeletion([]);
    setSelectedAffiliatesForDeletion(false);
  };
  return (
    <>
      <div
        id="company-affiliates"
        style={{
          position: 'relative',
          top: `-${appNavHeight}px`
        }}
      />
      <Wrapper
        px={4}
        py={3}
        isEdit={editAffiliates}
        extraClass="company"
        onCloseEditClick={() => {
          setEditAffiliates(false);
          setSelectedAffiliatesForMultiDeletion([]);
        }}
        onEditClick={() => {
          setEditAffiliates(true);
        }}
        onAddClick={() => {
          !isAddAffiliate && setIsAddAffiliate(true);
        }}
        onSubmit={() => {
          setEditAffiliates(false);
        }}
        editForbidden={!hasEditRights}
      >
        <Modal
          isModalOpen={isAddAffiliate}
          onClose={() => {
            setIsAddAffiliate(false);
          }}
          hasHeader
        >
          <div
            className="wrapper"
            style={{
              padding: '1rem'
            }}
          >
            <p>Add a new Affiliate</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // gap: '1rem',
                margin: '1rem 0'
              }}
            >
              <Textarea
                change={(e) => {
                  const val = e.target.value.substring(0, 50);
                  if (e.target.value.length > 50)
                    showWarnToast('Affiliate title should be less than 50 characters');
                  setAffiliateTitle(val);
                }}
                placeholder="Affiliate Title"
                value={affiliateTitle}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '.5rem'
              }}
              className="buttons"
            >
              <Button
                onClick={() => {
                  const affiliatesCopy = affiliates ? [...affiliates] : [];
                  const affiliatesData = {
                    affiliates: [{ name: affiliateTitle }, ...affiliatesCopy]
                  };
                  onSectionUpdate(affiliatesData);
                  setIsAddAffiliate(false);
                  setAffiliateTitle('');
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setIsAddAffiliate(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
        <SectionTag title="Affiliates" />
        <div className={styles['affiliates']}>
          {affiliates?.length > 0 && (
            <>
              {affiliates.map((aff) => (
                <AffiliateCard
                  onToggleSelection={() => handleToggleAffiliateSelection(aff)}
                  isSelected={selectedAffiliatesForMultiDeletion.includes(aff)}
                  editMode={editAffiliates}
                  title={aff?.name}
                  key={aff?.name}
                />
              ))}
            </>
          )}
          {editAffiliates && selectedAffiliatesForMultiDeletion.length > 0 && (
            <div
              onClick={() => setSelectedAffiliatesForDeletion(true)}
              className={styles['selected-affiliates']}
            >
              <TrashI /> <p>Delete Selected Affiliates</p>
            </div>
          )}
          {selectedAffiliatesForDeletion && (
            <DeleteModal
              title="Are you sure you want to delete these affiliates ?"
              handleDelete={handleDeleteSelectedAffiliates}
              handleClose={() => {
                setSelectedAffiliatesForDeletion(false);
                setSelectedAffiliatesForMultiDeletion([]);
              }}
            />
          )}
          {!affiliates?.length && (
            <>
              <h3>No Affiliates</h3>
            </>
          )}
        </div>
      </Wrapper>
    </>
  );
}

export default Affiliates;
