import { handleAsync } from 'helpers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useUpdateIndividualProfileMutation } from 'api/individual-profile';
function useQuoteUtils() {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [updateIndividualProfile, { isLoading }] = useUpdateIndividualProfileMutation();

  const onUpdateSubmit = async (quote) => {
    handleAsync(
      updateIndividualProfile({ profile: { quote: quote }, key: 'quote' }).unwrap(),
      'Updated Successfully',
      'Something went wrong'
    );
  };
  return {
    onUpdateSubmit,
    isLoading,
    isCurrentUserProfile
  };
}

export default useQuoteUtils;
