import { Loader } from 'components/ui';
import React from 'react';
import { useGetProfileActiveTemplate } from '../hooks/templates';
import { useGetUrlQuery } from 'hooks';
import { PROFILE_SECTIONS } from 'constants';
import {
  AvailabilityTab,
  Cv,
  ItEssentialTab,
  TemplateTabs,
  UserAwardsAchievementsTab,
  UserReviewsTab,
  UserRoleTab
} from '../components';
import { IT_ESSENTIALS_INPUTS } from 'constants';
import useGetSharedProfile from '../hooks/useGetSharedProfile';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";

const useGetProfileActiveTemplateTest = () => {
  return {
    isLoading: false,
    errorMessage: '',
    templateSectionList: [
      'aboutMeProjects',
      'experience',
      'education',
      'skills',
      'languages',
      'tools',
      'whyMe',
      'contact',
      'testimonials',
      'interests',
      'techs',
      'projectExperience',
      'qanda'
    ],
    templateTabs: ['home', 'my-role', 'awards', 'reviews', 'working-hours', 'it-essentials'],
    templateType: 'general'
  };
};
function TemplateLayout({ companyVisibleTabs, userId }) {
  const currentProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id;
  const isCurrentUserProfile = currentProfileId === profileId;
  const { t } = useTranslation();

  const {
    isLoading: loading,
    errorMessage,
    templateSectionList,
    templateTabs,
    templateType
  } = useGetProfileActiveTemplate();

  // =============
  // =============
  // This is for testing purposes
  // =============
  // =============
  //   isLoading: loading,
  //   errorMessage,
  //   templateSectionsComponents,
  //   templateTabs
  // } = useGetProfileActiveTemplateTest();
  const sectionQuery = useGetUrlQuery('sec') || PROFILE_SECTIONS.home;
  const [sharedDate] = useGetSharedProfile();
  const profileData = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: isSharedMode()
    }
  );
  const {
    data: resultData,
    isLoading,
    isError,
    error
  } = !isSharedMode() ? profileData : sharedDate;
  if (loading || isLoading) return <Loader />;
  else if (errorMessage || isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {errorMessage || error?.data?.message}
      </div>
    );
  }
  const targetProfile = isSharedMode() ? resultData?.sharedProfile : resultData?.profile;

  return (
    <>
      <TemplateTabs
        companyVisibleTabs={companyVisibleTabs}
        templateTabs={templateTabs}
        isCurrentUserProfile={isCurrentUserProfile}
      />
      <div>
        {sectionQuery === PROFILE_SECTIONS.home && (
          <>
            <Cv
              templateType={templateType}
              templateSectionList={templateSectionList}
              isCurrentUserProfile={isCurrentUserProfile}
              userId={userId}
              profileId={profileId}
            />
          </>
        )}
        {sectionQuery === PROFILE_SECTIONS.role && (
          <>
            <UserRoleTab
              isCurrentUserProfile={isCurrentUserProfile}
              profileId={profileId}
              userId={userId}
              heading={t("it-essentials-allow-us-to-do-better")}
              list={IT_ESSENTIALS_INPUTS}
            />
          </>
        )}
        {sectionQuery === PROFILE_SECTIONS.awards && (
          <>
            <UserAwardsAchievementsTab
              isCurrentUserProfile={isCurrentUserProfile}
              userId={userId}
              profileId={profileId}
            />
          </>
        )}
        {sectionQuery === PROFILE_SECTIONS.reviews && (
          <>
            <UserReviewsTab profileId={profileId} isCurrentUserProfile={isCurrentUserProfile} />
          </>
        )}
        {sectionQuery === PROFILE_SECTIONS?.workingHours && (
          <>
            <AvailabilityTab
              data={targetProfile?.availability}
              profileId={profileId}
              isCurrentUserProfile={isCurrentUserProfile}
            />
          </>
        )}
        {sectionQuery === PROFILE_SECTIONS.itEssentials && (
          <ItEssentialTab
            userId={userId}
            isCurrentUserProfile={isCurrentUserProfile}
            profileId={profileId}
            heading={t("it-essentials-allow-us-to-do-better")}
            list={IT_ESSENTIALS_INPUTS}
          />
        )}
      </div>
    </>
  );
}

export default TemplateLayout;
