import React, { Suspense, useState } from 'react';
import styles from './Teams.module.scss';
import PropTypes from 'prop-types';
import TeamEmployeeCard from 'components/molecules/TeamEmployeeCard/TeamEmployeeCard';
import { Link } from 'react-router-dom';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import EditInput from '../../form/EditInput/EditInput';
import { Button, Loader } from 'components/ui';
import Slider from 'components/atoms/Slider/Slider';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { showErrorToast } from 'utils/toast';

const Teams = (props) => {
  // const [updateTeam] = useUpdateTeamByIdMutation();
  const { name, id, overview, purpose, profiles, department, deleteTeamById } = props;
  const [setActiveProfileId] = useState(profiles[0]?.id);
  const [deleteModal, setDeleteModal] = useState(false);
  const { refetch: refetchForTierUpdate } = useGetUserTierData({ skipFetching: true });

  const [edit, setEdit] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);
  // const handleSave = async () => {
  //   const status = await handleAsync(
  //     updateTeam({ teamId: id, team: { name: updatedName } }).unwrap()
  //   );
  //   if (status[0]) {
  //     setEdit(false);
  //   } else showErrorToast('Something went wrong');
  // };

  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles.teamCards}>
        <>
          <div className={styles.teamTitle}>
            {edit ? (
              <>
                <EditInput
                  placeholder="Update name"
                  value={updatedName}
                  onChange={({ target }) => setUpdatedName(target.value)}
                />
                {/* <Save onClick={() => handleSave()} />
              <Trash onClick={() => setDeleteModal(true)} /> */}
              </>
            ) : (
              <>
                <span className={styles.title}>{name}</span>

                {/* <Edit onClick={() => setEdit(true)} /> */}
              </>
            )}
            <div className={styles.teamProfileButton}>
              <Link
                to={`/team/${id}`}
                state={{
                  overview,
                  purpose
                }}
              >
                <Button color="white">See Profile</Button>
              </Link>
            </div>
          </div>
          <div className={`${styles.container} instructorsSlider`}>
            <div className={styles.nonActiveCards}>
              <Slider itemsPerSlide={6}>
                {profiles.map((card, idx) => {
                  return (
                    <TeamEmployeeCard
                      dataLength={card.length}
                      idx={idx}
                      key={card.id}
                      profile={card}
                      department={department}
                      teamName={name}
                      teamId={props?.id}
                      isCreatedByYou={props.isCreatedByYou}
                      deleteTeamById={props.deleteTeamById}
                      lastMember={profiles.length === 1}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </>
        {deleteModal && (
          <DeleteModal
            handleDelete={async () => {
              setDeleteModal(false);
              const flag = await deleteTeamById(id);
              if (!flag) return showErrorToast('Something went wrong');
              refetchForTierUpdate();
            }}
            title="Are you sure you want to delete this team ? "
            handleClose={() => setDeleteModal(false)}
          />
        )}
      </div>
    </Suspense>
  );
};

export default Teams;
Teams.propTypes = {
  dummyData: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string
};
