import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { addObjectToArrayIfNotExist } from 'helpers';
import { deleteStatsInListInQuery, updateStatsInListInQuery } from './util';

const secondaryUrl = 'stats';

export const statsExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteStats: builder.mutation({
      query: ({ statsId }) => ({
        url: `/v1/${secondaryUrl}/${statsId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ statsId, userId }, { queryFulfilled }) {
        const deleteResult = deleteStatsInListInQuery({
          statsId,
          queryName: 'getStatsByUserId',
          params: { userId }
        });

        await optimisticUpdater(deleteResult, queryFulfilled);
      },
      invalidatesTags: () => [{ type: 'Stats', id: 'LIST' }]
    }),
    updateStats: builder.mutation({
      query: ({ stats }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'PATCH',
        body: stats
      }),
      async onQueryStarted({ stats, userId }, { dispatch, queryFulfilled }) {
        const patchResult = updateStatsInListInQuery({
          statsId: stats.id,
          stats,
          queryName: 'getStatsByUserId',
          params: { userId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Stats', id: 'LIST' }]));
        });
      }
    }),

    saveStats: builder.mutation({
      query: ({ stats }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: stats
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdStatsData) => {
            if (!createdStatsData) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getStatsByUserId',
                {
                  userId
                },
                (draft) => {
                  draft.result.unshift(createdStatsData);
                }
              )
            );
          }
        );
      }
    }),

    getStatsByUserId: builder.query({
      // TODO: handle pagination properly, like educationSlice.js
      query: ({ userId, page = 1, size = 3 }) => ({
        url: `/v1/${secondaryUrl}/${userId}?page=${page}&size=${size}`,
        method: 'GET'
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'Stats', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = result.result.map((item) => ({ type: 'Stats', id: item.id })) ?? [];
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { userId } = queryArgs;
        return `${endpointName}({"userId":"${userId}"})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a Education, the created Education is added to the cache, and the last Education
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new Education
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    })
  })
});

export const {
  useDeleteStatsMutation,
  useUpdateStatsMutation,
  useSaveStatsMutation,
  useGetStatsByUserIdQuery
} = statsExtendedSlice;
