import React from 'react';
import styles from './FilterItem.module.scss';
import PropTypes from 'prop-types';
import { FILTER_ITEMS_TYPES } from 'constants';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

const FilterItem = ({
  filter,
  title,
  profilesFilter,
  handleCheckboxChange,
  handleCheckboxReset,
  type
}) => {
  const [dateFilterValue, setDateFilterValue] = useState(new Date()); //used for non checkbox filters to store the value before sending it

  if (type == FILTER_ITEMS_TYPES.FILTER_ITEM_YEAR_PICKER) {
    return (
      <div className={styles.filterItemDate}>
        <input
          onChange={(e) => {
            const tempFilter = filter;
            tempFilter.value = dateFilterValue.getFullYear().toString();
            // only for started At
            tempFilter.label = `${filter.name}_${tempFilter.value}`;
            handleCheckboxChange(e, title, tempFilter);
          }}
          className={styles.filterCheckBox}
          type="checkbox"
          id={filter.name}
          name={filter.name}
          checked={profilesFilter[title] && profilesFilter[title].includes(filter.value)}
        />{' '}
        <label htmlFor={filter.name} className={styles.filterLabel}>
          <span className={styles.radioButton}></span>
          <span className={styles.filterName}>
            {' '}
            <ReactDatePicker
              className={styles['FilterDatePicker']}
              maxDate={new Date()}
              selected={dateFilterValue}
              onChange={(date) => {
                const tempFilter = filter;
                tempFilter.value = dateFilterValue?.getFullYear()?.toString();
                handleCheckboxReset(title, tempFilter);
                setDateFilterValue(date);
              }}
              dateFormat="yyyy"
              showYearPicker
              showPopperArrow={false}
            />
          </span>
        </label>
      </div>
    );
  } else {
    return (
      <div className={styles.filterItem}>
        <input
          onChange={(e) => {
            handleCheckboxChange(e, title, filter);
          }}
          className={styles.filterCheckBox}
          type="checkbox"
          id={`${filter.name}_${title}`}
          name={`${filter.name}_${title}`}
          checked={profilesFilter[title] && profilesFilter[title].split(',').includes(filter.value)}
        />
        <label htmlFor={`${filter.name}_${title}`} className={styles.filterLabel}>
          <span className={styles.radioButton}></span>
          <span className={styles.filterName}>{filter.name}</span>
        </label>
      </div>
    );
  }
};

export default FilterItem;
