import React from 'react';
import styles from './CompanyProfileImage.module.scss';

import { capitalizeFirstLetter } from 'helpers';
import { MASTERHUB_CLOUDFRONT_URL } from 'utils/constans';
function CompanyProfileImage({ name, companyLogo }) {
  // TODO: how to read the logo url if it's provided ? they are only storing the key NOT all the url
  return (
    <div
      className={styles['company-logo-circle']}
      style={{
        backgroundImage: companyLogo ? `url(${MASTERHUB_CLOUDFRONT_URL}/${companyLogo})` : 'default'
      }}
    >
      {!companyLogo && <h2>{capitalizeFirstLetter(name)}</h2>}
    </div>
  );
}

export default CompanyProfileImage;
