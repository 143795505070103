import React, { useState } from 'react';
import styles from './Poll.module.scss';
import { useAnswerPollMutation } from 'api/social-board/postSlice';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';
export default function Poll({ questions, postId, pollId, category }) {
  const [answerPoll, { isLoading: isSaving }] = useAnswerPollMutation();
  const currentUserProfileId = useSelector(getCurrentUserProfileId);
  const [isClicked, setIsClicked] = useState(false);
  function handleAnswerPoll({ optionId, questionId }) {
    setIsClicked(true);
    answerPoll({
      answer: { postId, pollId, questionId, optionId },
      // these are passed for caching purposes
      oldCategory: category,
      postId
    });
  }

  return (
    <div className={styles.PollContainer}>
      {questions.map((question, questionIndex) => {
        return (
          <div className={styles.PollQuestionsContainer} key={`${postId}question${questionIndex}`}>
            <h3>{question.question}</h3>
            {question?.options?.map((option, optionIndex) => {
              return (
                <div
                  className={styles.options}
                  key={optionIndex}
                  onClick={() => {
                    handleAnswerPoll({
                      optionId: option?.id,
                      pollId: pollId,
                      postId,
                      questionId: question.id
                    });
                  }}
                >
                  <input
                    checked={(option?.votes ?? []).includes(currentUserProfileId)}
                    type="radio"
                    name={`option-${question.id}`}
                    className={styles.customradio}
                  />
                  <label className={styles.customRadioLabel}>{`${option?.option}`}</label>
                  <label>{`${
                    (option?.votes ?? []).length > 0 ? `v: ${(option?.votes ?? []).length}` : ''
                  }`}</label>
                </div>
              );
            })}
          </div>
        );
        // }
      })}
    </div>
  );
}
