export const MAIN_NAV = [
  {
    title: 'Individual Profiles',
    path: '/profiles'
  },
  {
    title: 'Team Profiles',
    path: '/teams'
  },
  {
    title: 'Working Hours',
    path: '/working-hours'
  }
];

export const NAV_DATA = [
  {
    title: 'News Hub',
    path: '/news-hub'
  },
  {
    title: 'Info Hub',
    path: '/info-hub'
  }
];

export const HOME_NAV = [
  {
    title: 'Explore',
    path: '/explore'
  },
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'Individual profiles',
    path: '/profiles'
  },
  {
    title: 'Hubs',
    path: '/news-hub'
  },
  {
    title: 'Templates',
    path: '/templates'
  }
];
