import { showErrorToast } from 'utils/toast';
import { useSetBioInterestsMutation } from 'api/individual-profile';
import { handleAsync } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import { getCurrentUserProfileId } from 'store/login.slice';

function useInterestUtils({ interestsList }) {
  const [edit, setEdit] = useState(false);
  const [interests, setInterests] = useState([...interestsList]);
  const [updateInterests] = useSetBioInterestsMutation();
  const globalEdit = useSelector(getGlobalEditSelector);
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  const saveInterests = async () => {
    setEdit(false);
    const [status] = await handleAsync(updateInterests({ interests }).unwrap());
    !status && showErrorToast('Something went wrong');
  };

  return {
    isCurrentUserProfile,
    saveInterests,
    globalEdit,
    edit,
    setEdit,
    interests,
    setInterests
  };
}

export default useInterestUtils;
