import { uploadBlobToAWS } from 'helpers/s3Handler';
import React from 'react';
import useGetUserTierData from './useGetUserTierData';
import { checkFileUploadLimit } from 'helpers/tier';

/**
 * @deprecated use useUploadBlobs instead
 * @description This hook is used to upload a file to s3
 * @returns {Object} { uploadBlob, isUploading }
 *
 *
 */
function useUploadBlob() {
  const { tierData } = useGetUserTierData();
  const [isUploading, setIsUploading] = React.useState(false);
  const uploadBlob = React.useCallback(async (file, path = '', onProgress) => {
    try {
      const [canUpload, message] = checkFileUploadLimit(tierData, [file]);
      if (!canUpload) {
        throw message;
      }
      setIsUploading(true);
      const response = await uploadBlobToAWS(file, `wizrx${path ? `/${path}` : ''}`, onProgress);
      return response;
    } catch (error) {
      error;
      const isErrorString = typeof error === 'string';
      throw isErrorString ? error : 'Error uploading file';
    } finally {
      setIsUploading(false);
    }
  }, []);

  return { uploadBlob, isUploading };
}

export default useUploadBlob;
