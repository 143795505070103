import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styles from './ProjectsSection.module.scss';
import { Textarea } from 'components/form';
import { useForm } from 'react-hook-form';
import { onFieldChangeProfileModules, selectObjectElements } from 'helpers';
import QueryableTools from 'components/molecules/QueryableTools/QueryableTools';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { debounce } from 'lodash';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {useTranslation} from "react-i18next";

function CreateProjectModal({ open, onClose, onSaveProject, isSaving }) {
  const originCreateValues = {
    from: new Date().toString(),
    to: new Date().toString()
  };
  const { t } = useTranslation();
  const [fieldsChange, setFieldsChange] = useState({ ...originCreateValues });
  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      company: '',
      name: '',
      location: '',
      description: '',
      position: '',
      ongoing: false
    }
  });
  const closeReset = () => {
    setFieldsChange({ ...originCreateValues });
    onClose();
    reset();
  };
  const submitFun = async (values) => {
    let project = { ...values, ...fieldsChange };
    const status = await onSaveProject(project);
    status && closeReset();
  };
  const onSubmit = debounce(handleSubmit(submitFun), 400);
  const addTechs = (techs) => {
    const techsRepo = techs.map((tech) =>
      selectObjectElements(tech, ['name', 'description', 'icon'])
    );
    const newTechs = [...techsRepo];
    setFieldsChange({ ...fieldsChange, tools: newTechs });
  };
  return (
    <>
      {open && (
        <>
          <CreateModal isSubmitting={isSaving} clickEvent={onSubmit} handleClose={closeReset}>
            <h1>{t('Create New Project')}</h1>
            <div className={styles.formHolder}>
              <form>
                {/* <Textarea
                  maxLength={100}
                  name="company"
                  placeholder={t("Add Company Name")}
                  {...register('company', { required: true })}
                /> */}
                {errors.company && <span className={styles.error}> Company Name is required</span>}
                <Textarea
                  maxLength={30}
                  name="name"
                  placeholder={t("Add Project Name")}
                  {...register('name', { required: true })}
                />
                {errors.name && <span className={styles.error}> Project Name is required</span>}
                <Textarea
                  maxLength={50}
                  name="description"
                  placeholder={t("Describe your project")}
                  {...register('description', { required: true })}
                />
                {/* {errors?.description && (
                  <span className={styles.error}> Project Description is required</span>
                )} */}
                {/* <Textarea
                  maxLength={100}
                  name="position"
                  placeholder={t("Add Your Position")}
                  {...register('position', { required: true })}
                />
                {errors?.position && <span className={styles.error}> {t("Position is required")}</span>}

                <Textarea
                  name="location"
                  placeholder={t("Add Location Name")}
                  {...register('location', { required: true })}
                />
                {errors.location && <span className={styles.error}> {t("Location is required")}</span>} */}
              </form>
              {/* <div className={styles.card}>
                <div className="onCreateDatePicker">
                  <DatePicker
                    maxDate={new Date()}
                    selected={new Date(fieldsChange.from)}
                    onChange={(date) => onFieldsChange('from', date.toString())}
                    showYearDropdown
                  />
                  <DatePicker
                    maxDate={new Date()}
                    minDate={new Date(fieldsChange.from)}
                    selected={new Date(fieldsChange.to)}
                    onChange={(date) => onFieldsChange('to', date.toString())}
                    disabled={watch('ongoing')}
                    className={watch('ongoing') ? 'disabled' : ''}
                    showYearDropdown
                  />
                </div>

                <div className={styles.ongoing}>
                  <span>{t("Ongoing")}</span>

                  <input {...register('ongoing')} name="ongoing" id="ongoing" type="checkbox" />
                  <label htmlFor="ongoing" className={styles.checkmark} />
                </div>
              </div> */}
              {/* <QueryableTools
                onToolsSelected={addTechs}
                rtkQueryName="getToolsByKeyword"
                extraClass="editTools"
                add
                bgColor="#2d2d2d"
                title="Tools"
                list={fieldsChange.tools}
                searchPlaceholder="search-the-tool-you-used"
              /> */}
            </div>
          </CreateModal>
        </>
      )}
    </>
  );
}

export default CreateProjectModal;
