import { optimisticUpdater, updateCache } from 'api/utils';
import { apiSlice } from '../apiSlice';
import { setCompanyTheme } from 'store/login.slice';

// represent the controller
const secondaryUrl = `company-profile`;

export const companyProfileExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyProfile: builder.query({
      query: ({ companyId }) => ({
        url: `/v1/${secondaryUrl}/${companyId}`,
        method: 'GET'
      }),
      providesTags: (res, err, { companyId }) => {
        if (err || !res) return [{ type: 'Company-Profile', id: 'LIST' }];
        return [{ type: 'Company-Profile', id: companyId }];
      }
    }),

    updateCompanyProfile: builder.mutation({
      query: ({ companyId, companyProfile }) => ({
        url: `/v1/${secondaryUrl}/${companyId}`,
        method: 'PATCH',
        body: companyProfile
      }),
      onQueryStarted: async ({ companyId, companyProfile }, { dispatch, queryFulfilled }) => {
        const patchResult = updateCache({
          keyToUpdate: '',
          value: companyProfile,
          queryName: 'getCompanyProfile',
          params: { companyId }
        });

        optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Company-Profile', id: 'LIST' }]));
        });
      }
    }),
    updateCompanySettings: builder.mutation({
      query: ({ companyId, settings }) => ({
        url: `/v1/${secondaryUrl}/settings/${companyId}`,
        method: 'PATCH',
        body: settings
      }),
      onQueryStarted: async ({ companyId, settings }, { dispatch, queryFulfilled }) => {
        const patchResult = updateCache({
          keyToUpdate: 'settings',
          value: settings,
          queryName: 'getCompanyProfile',
          params: { companyId }
        });

        optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Company-Profile', id: companyId }]));
        });
      }
    }),

    contactCompany: builder.mutation({
      query: ({ contact }) => ({
        url: `/v1/${secondaryUrl}/contact/`,
        method: 'POST',
        body: contact
      })
    }),
    changeTheme: builder.mutation({
      query: ({ theme }) => ({
        url: `/v1/${secondaryUrl}/theme/change/?type=${theme}`,
        method: 'PATCH'
      }),
      onQueryStarted: async ({ theme, companyId }, { dispatch, queryFulfilled }) => {
        const patchResult = updateCache({
          keyToUpdate: 'theme',
          value: theme,
          queryName: 'getCompanyProfile',
          params: { companyId }
        });
        const companyThemeDispatch = dispatch(setCompanyTheme(theme));
        optimisticUpdater([patchResult, companyThemeDispatch], queryFulfilled, () => {});
      }
    })
  })
});

export const {
  useGetCompanyProfileQuery,
  useUpdateCompanyProfileMutation,
  useUpdateCompanySettingsMutation,
  useChangeThemeMutation,
  useContactCompanyMutation
} = companyProfileExtendedSlice;
