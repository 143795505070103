import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import ClientCasesCard from 'components/molecules/ClientCasesCard/ClientCasesCard';
import ContainerSectionHub from '../../atoms/InfoHubContainerSection';
import InfoHubTitle from '../../atoms/InfoHubTitle';
import styles from './ClientCases.module.scss';
import React, { useState } from 'react';
import { getS3Url } from 'helpers/s3Handler';
import { useGetAllClientsByCompanyIdQuery } from 'api/individual-profile/clientSlice';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import Options from 'components/atoms/Options/Options';
import { Loader } from 'components/ui';
import {
  useCreateClientMutation,
  useUpdateClientByIdMutation,
  useDeleteClientByIdMutation
} from 'api/individual-profile/clientSlice';
import useUploadBlob from 'hooks/useUploadBlob';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

function ClientCases({ title }) {
  const [edit, setEdit] = useState(false);
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const { data, isFetching } = useGetAllClientsByCompanyIdQuery({ companyId });
  const [createClient, { isLoading: isSaving }] = useCreateClientMutation();
  const [updateClient] = useUpdateClientByIdMutation();
  const [deleteClient] = useDeleteClientByIdMutation();
  const { uploadBlob, isUploading } = useUploadBlob();
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;
  if (isFetching || isSaving || isUploading) {
    return (
      <ContainerSectionHub>
        <div className={`${styles.container} infoFrame`}>
          <div className={styles.titlebar}>
            <div className={styles.infoTitle}>
              <InfoHubTitle title={title} />
            </div>
          </div>
          <Loader />
        </div>
      </ContainerSectionHub>
    );
  }
  return (
    <>
      <ContainerSectionHub>
        <div className={`${styles.container} infoFrame`}>
          <div className={styles.titlebar}>
            <div className={styles.infoTitle}>
              <InfoHubTitle title={title} />
            </div>
            {userTier === APP_TIERS.REGULAR && (
              <Options
                handleAdd={() => {
                  setEdit(true);
                }}
                handleSave={() => {
                  setEdit(false);
                }}
                edit={edit}
                hideDiscard
              />
            )}
          </div>
          <SliderContainer breakpoint={1200} breakpointSlide={2} slides={3}>
            {data?.length > 0 || edit
              ? data?.map((clientCase, i) => (
                  <ClientCasesCard
                    id={clientCase.id}
                    client
                    extraClass="clientCard"
                    key={i}
                    image={getS3Url(clientCase?.headerImage)}
                    profile={getS3Url(clientCase?.profilePicture)}
                    title={clientCase?.clientName || 'Unknown Title'}
                    paragraph={clientCase?.clientSubtext || 'Unknown Company'}
                    location={clientCase?.clientLocation || 'Unknown Location'}
                    heading={clientCase?.headerText}
                    subheading={clientCase?.bodyText}
                    edit={edit}
                    companyId={companyId}
                    hideDetails
                    options
                    createClient={createClient}
                    updateClient={updateClient}
                    deleteClient={deleteClient}
                    uploadBlob={uploadBlob}
                  />
                ))
              : null}
            {edit && (
              <ClientCasesCard
                create
                client
                extraClass="clientCard"
                companyId={companyId}
                key={data?.result?.length + 1}
                edit={edit}
                cancelCreate={() => {
                  setEdit(false);
                }}
                createClient={createClient}
                uploadBlob={uploadBlob}
              />
            )}
          </SliderContainer>
        </div>
      </ContainerSectionHub>
    </>
  );
}
export default ClientCases;
