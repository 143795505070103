import { Loader } from 'components/ui';
import React from 'react';
import { motion } from 'framer-motion';
import { useGetAwardsAndAchievementsByUserIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { Achievements, Awards } from './sections';
function UserAwardsAchievementsTab({ userId, profileId, isCurrentUserProfile }) {
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});

  const result = useGetAwardsAndAchievementsByUserIdQuery({ userId }, { skip: isSharedMode() });
  const { data, isLoading, isError, isSuccess, error } = !isSharedMode() ? result : sharedData;
  let content;
  if (isLoading) {
    content = <Loader />;
  } else if (isError) {
    content = (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  } else if (isSuccess) {
    content = (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Awards
          isCurrentUserProfile={isCurrentUserProfile}
          awardsData={isSharedMode() ? data?.bio.awards : data?.award}
        />
        <Achievements isCurrentUserProfile={isCurrentUserProfile} userId={userId} />
      </motion.div>
    );
  }

  return content;
}

export default UserAwardsAchievementsTab;
