import { showErrorToast } from 'utils/toast';
import {
  useDeleteEducationMutation,
  useGetIndividualProfileByProfileIdQuery,
  useSaveEducationMutation,
  useUpdateEducationDescMutation,
  useUpdateEducationMutation
} from 'api/individual-profile';
import { handleAsync, isSharedMode, removeObjectElements } from 'helpers';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useTranslation } from 'react-i18next';
const staticDesc = 'education-section-description';
function useEducationUtils({ userId }) {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const { t } = useTranslation();

  const { data } = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },

    {
      skip: !profileId || isSharedMode()
    }
  );
  const experienceDesc = data?.bio?.educationDesc ?? '';
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [editDescription, setDescription] = useState(false);
  const [updatedDesc, setUpdatedDesc] = useState(experienceDesc);
  const [updateEducation, { isLoading: isUpdating }] = useUpdateEducationMutation();
  const [saveEducation, { isLoading: isSaving }] = useSaveEducationMutation();
  const [deleteEducation, { isLoading: isDeleting }] = useDeleteEducationMutation();
  const [updateEducationDesc, { isLoading: isUpdatingDesc }] = useUpdateEducationDescMutation();
  const onDescriptionSave = async () => {
    const educationDescDto = {
      description: JSON.stringify(updatedDesc)
    };
    setDescription(false);
    const result = await handleAsync(updateEducationDesc(educationDescDto).unwrap());
    if (!result[0]) showErrorToast(t('Error updating education description'));
  };
  const onUpdateSubmit = async (education) => {
    const { id, ...rest } = education;
    const educationDto = removeObjectElements(rest, ['img', 'userId']);
    await handleAsync(
      updateEducation({
        educationId: id,
        education: educationDto,
        userId
      }).unwrap()
    );
  };
  const onCreateSubmit = async (education) => {
    const status = await handleAsync(
      saveEducation({
        education,
        userId
      }).unwrap()
    );
    return status[0];
  };
  const onDeleteSubmit = async (educationId) => {
    const status = await handleAsync(
      deleteEducation({
        educationId,
        userId
      }).unwrap()
    );

    return status[0];
  };
  return {
    onDeleteSubmit,
    onUpdateSubmit,
    onCreateSubmit,
    isSaving,
    isUpdating,
    isDeleting,
    isUpdatingDesc,
    onDescriptionSave,
    editDescription,
    setDescription,
    setUpdatedDesc,
    isCurrentUserProfile,
    staticDesc: t(staticDesc),
    educationDesc: experienceDesc
  };
}

export default useEducationUtils;
