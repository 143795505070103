import React from 'react';
import styles from './IconText.module.scss';
import PropTypes from 'prop-types';
import Text from 'components/atoms/Text/Text';

const Icontext = ({ icon, text, bgColor, padding, onClick, subheading, opacity, radius, extraClass, tooltip }) => {
  return (
    <div
      style={{
        backgroundColor: bgColor,
        padding: padding,
        opacity: opacity,
        borderRadius: radius
      }}
      className={`${styles.icontext} ${styles[extraClass]}`}
      onClick={onClick}
    >
      {icon && icon}
      <Text tooltipContent={tooltip} paragraph={text} subheading={subheading} />
    </div>
  );
};
export default Icontext;
Icontext.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  bgColor: PropTypes.string,
  padding: PropTypes.string,
  subheading: PropTypes.string,
  opacity: PropTypes.number,
  radius: PropTypes.string,
  onClick: PropTypes.func
};
