import React from 'react';
import styles from './SectionWrapper.module.scss';
function SectionWrapper({ children, sectionTitle }) {
  return (
    <div className={styles.main}>
      <h3 className={styles.sectionTitle}>{sectionTitle}</h3>
      {children}
    </div>
  );
}

export default SectionWrapper;
