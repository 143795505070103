export const MAX_TEAM_RESOURCES_LITE = 3;
export const MAX_TEAM_RESOURCES_REGULAR = 30;
export const MAX_CREATED_TEAMS_LITE = 1;
export const MAX_EVENT_ATTACHMENTS_LITE = 1;
export const MAX_EVENT_PARTICIPANTS_LITE = 6;
export const MAX_CREATABLE_EVENTS_PER_DAY_LITE = 1;
export const MAX_FILE_SIZE_LITE = 6 * 1024 * 1024; // 6MB
export const MAX_FILE_SIZE_REGULAR = 12 * 1024 * 1024; // 12MB
export const MAX_VIDEO_SIZE_LITE = 100 * 1024 * 1024; // 100MB
export const MAX_VIDEO_SIZE_REGULAR = 100 * 1024 * 1024; // 500MB
