import React, { forwardRef } from 'react';
import styles from './Textarea.module.scss';
import ReactTextareaAutosize from 'react-textarea-autosize';

const Textarea = forwardRef(
  ({ placeholder, type, value, name, change, defaultValue, maxLength = 1000, ...rest }, ref) => {
    return (
      <div className={`${styles.formInput} ${styles[rest?.extraClass]}`}>
        <ReactTextareaAutosize
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={change}
          ref={ref}
          defaultValue={defaultValue}
          maxLength={maxLength}
          {...rest}
        />
      </div>
    );
  }
);

export default Textarea;
