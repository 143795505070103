import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { addObjectToArrayIfNotExist } from 'helpers';

const secondaryUrl = `notification`;
export const notificationExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: ({ profileId, page = 1, size = 15 }) => ({
        url: `/v1/${secondaryUrl}/${profileId}?page=${page}&size=${size}`
      }),
      providesTags: (result, error, { profileId }) => [
        { type: 'Notification', id: 'LIST' },
        { type: 'Notification', id: profileId }
      ],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    }),
    markNotificationAsRead: builder.mutation({
      query: ({ notificationId }) => ({
        url: `/v1/${secondaryUrl}/${notificationId}`,
        method: 'PATCH'
      }),
      async onQueryStarted({ notificationId, profileId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getNotifications', {}, (draft) => {
            const item = draft.result.find((item) => item.id === notificationId);
            if (item) {
              Object.assign(item, { read: [...item.read, profileId] });
              draft.total = draft.total - 1;
            }
          })
        );
        await optimisticUpdater(patchResult, queryFulfilled);
      }
    }),
    markAllNotificationAsRead: builder.mutation({
      query: ({ profileId }) => ({
        url: `/v1/${secondaryUrl}/mark-all-as-read/${profileId}`,
        method: 'PATCH'
      }),
      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getNotifications', {}, (draft) => {
            draft.result.forEach((item) => {
              if (!item.read.includes(profileId)) item.read.push(profileId);
            });
            draft.total = 0;
          })
        );
        await optimisticUpdater(patchResult, queryFulfilled);
      }
    })
  })
});

export const {
  useGetNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useMarkAllNotificationAsReadMutation
} = notificationExtendedSlice;
