import React, { useState } from 'react';
import { onFieldChangeProfileModules } from 'helpers';
import DatePicker from 'react-datepicker';
import styles from './Publications.module.scss';
import { Textarea } from 'components/form';
import { useForm } from 'react-hook-form';
import { showErrorToast } from 'utils/toast';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { debounce } from 'lodash';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {useTranslation} from "react-i18next";

function CreatePublicationModal({ open, onClose, onSavePublication, isSaving, ...rest }) {
  const { t } = useTranslation();
  const originCreateValues = {
    name: '',
    reference: '',
    description: '',
    date: new Date().toString()
  };
  const [fieldsChange, setFieldsChange] = useState({ ...originCreateValues });

  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      reference: '',
      description: ''
    }
  });
  const submitFun = async (values) => {
    let publications = { ...fieldsChange, name: values.name, reference: values.reference };
    const status = await onSavePublication(publications);
    if (status) closeReset();
    else showErrorToast(t('Error adding publication'));
  };
  const onSubmit = debounce(handleSubmit(submitFun), 400);
  const closeReset = () => {
    onClose();
    reset();
  };

  return (
    <>
      {open && (
        <>
          <CreateModal isSubmitting={isSaving} clickEvent={onSubmit} handleClose={closeReset}>
            <h1>{t('Create New Publication')}</h1>
            <form>
              <Textarea
                maxLength={100}
                name="name"
                placeholder={t("Add Publication Name")}
                {...register('name', { required: true })}
              />
              {errors.name && <span> {t("Publication Name is required")}</span>}{' '}
              <Textarea
                maxLength={300}
                name="reference"
                placeholder={t("Add Reference")}
                {...register('reference', { required: true })}
              />
              {errors.reference && <span>Reference is required</span>}
              <MWEditorLite
                data={undefined}
                placeholderText={t("Describe your publication")}
                setData={(value) => onFieldsChange('description', JSON.stringify(value))}
              />
              {/* {errors.description && <span> Description is required</span>} */}
            </form>
            <div className={styles.card}>
              <div className="onCreateDatePicker">
                <DatePicker
                  maxDate={new Date()}
                  selected={new Date(fieldsChange.date)}
                  onChange={(date) => onFieldsChange('date', date.toString())}
                  showYearDropdown
                />
              </div>
            </div>
          </CreateModal>
        </>
      )}
    </>
  );
}

export default CreatePublicationModal;
