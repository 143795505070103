import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { WIZR_API } from 'utils/constans';
import { getAuthData } from 'helpers/auth';

const customBaseQuery = fetchBaseQuery({
  baseUrl: WIZR_API,
  prepareHeaders: (headers) => {
    const tokenCookie = getAuthData();
    const companyId = getAuthData('activeProfile') || '';
    headers.set('authorization', `Bearer ${tokenCookie}`);
    headers.set('x-profile-data', JSON.stringify({ companyId }));
    headers.set('Accept-Encoding', 'gzip');
    return headers;
  }
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  tagTypes: [
    'Award',
    'Achievement',
    'Availability',
    'Experience',
    'Education',
    'Role',
    'Skill',
    'Language',
    'Technology',
    'Interest-Hobby',
    'Tool',
    'User',
    'Publication',
    'Project',
    'Profile',
    'Badge',
    'Bio',
    'Post',
    'Team',
    'Info-Hub',
    'Current-Profile',
    'Event',
    'S3',
    'Company-Profile',
    'Notification',
    'Stats',
    'QandA'
  ],
  endpoints: (builder) => ({})
});
