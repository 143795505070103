import CompanyView from 'components/organisms/Company/CompanyView';
import React from 'react';

function CompanyPage() {
  document.body.style.overflow = '';

  return <CompanyView />;
}

export default CompanyPage;
