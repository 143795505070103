import React, { useState } from 'react';
import { onFieldChangeProfileModules, selectObjectElements } from 'helpers';
import DatePicker from 'react-datepicker';
import { CustomDropdown } from 'components/form';
import styles from './ExperiencesSection.module.scss';
import { useForm } from 'react-hook-form';
import { Textarea } from 'components/form';
import QueryableTools from 'components/molecules/QueryableTools/QueryableTools';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { debounce } from 'lodash';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';

function CreateExperienceModal({ open, onClose, isSaving, onSaveExperience, ...rest }) {
  const originCreateValues = {
    contract: rest.contracts[1]?.text,
    from: new Date().toString(),
    to: new Date().toString()
  };
  const [fieldsChange, setFieldsChange] = useState({ ...originCreateValues });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      company: '',
      country: '',
      description: '',
      position: '',
      ongoing: false
    }
  });

  const closeReset = () => {
    setFieldsChange({ ...originCreateValues });
    onClose();
    reset();
  };
  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  const addTechs = (techs) => {
    const techsRepo = techs.map((tech) =>
      selectObjectElements(tech, ['name', 'description', 'icon'])
    );
    const newTechs = [...techsRepo];
    setFieldsChange({ ...fieldsChange, technologies: newTechs });
  };
  const submitFun = async (data) => {
    const exprObject = { ...data, ...fieldsChange };
    const status = await onSaveExperience(exprObject);
    status && closeReset();
  };
  const onSubmit = debounce(handleSubmit(submitFun), 400);
  return (
    <>
      {open && (
        <>
          <CreateModal
            guideButton
            isSubmitting={isSaving}
            clickEvent={onSubmit}
            handleClose={closeReset}
          >
            <h1>{'Create New Experience'}</h1>
            <form>
              <Textarea
                maxLength={50}
                name="company"
                placeholder="Add Company Name"
                {...register('company', { required: true })}
              />
              {errors.company && <span className={styles.error}> Company Name is required</span>}
              <Textarea
                maxLength={100}
                name="position"
                placeholder="Add Your Position"
                {...register('position', { required: true })}
              />
              {errors?.position && <span className={styles.error}>Position is required</span>}
              <MWEditorLite
                data={undefined}
                placeholderText="Describe your experience"
                setData={(value) => onFieldsChange('description', JSON.stringify(value))}
              />
              {/* {errors?.description && <span className={styles.error}>Description is required</span>} */}
              <Textarea
                maxLength={30}
                name="country"
                placeholder="Add Country"
                {...register('country', { required: true })}
              />
              {errors.country && <span className={styles.error}>Country is required</span>}
            </form>
            <div>
              <CustomDropdown
                extraClass="onCreateDropdown"
                list={rest.contracts}
                value={fieldsChange.contract}
                placeholder="Contract Type"
                onChange={(value) => onFieldsChange('contract', value)}
              />
            </div>
            <div className={styles.card}>
              <div className="onCreateDatePicker">
                <DatePicker
                  maxDate={new Date()}
                  selected={new Date(fieldsChange.from)}
                  onChange={(date) => onFieldsChange('from', date.toString())}
                  showYearDropdown
                />
                <DatePicker
                  maxDate={new Date()}
                  minDate={new Date(fieldsChange.from)}
                  selected={new Date(fieldsChange.to)}
                  onChange={(date) => onFieldsChange('to', date.toString())}
                  disabled={watch('ongoing')}
                  className={watch('ongoing') ? 'disabled' : ''}
                  showYearDropdown
                />
              </div>
            </div>
            <div className={styles.inprogress}>
              <span>Present</span>
              <input {...register('ongoing')} name="ongoing" id="ongoing" type="checkbox" />
              <label htmlFor="ongoing" className={styles.checkmark} />
            </div>
            <QueryableTools
              onToolsSelected={addTechs}
              rtkQueryName="getToolsByKeyword"
              extraClass="editTools"
              add
              bgColor="#2d2d2d"
              title="Tools"
              list={fieldsChange.technologies}
              searchPlaceholder="search the technology you used..."
            />
          </CreateModal>
        </>
      )}
    </>
  );
}

export default CreateExperienceModal;
