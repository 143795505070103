import React, { useState } from 'react';
import styles from './CoverLetter.module.scss';
import { useCoverLetterUtils } from 'components/organisms/Profile/hooks/templates';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import Options from 'components/atoms/Options/Options';
import { showErrorToast } from 'utils/toast';
import { Loader } from 'components/ui';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  convertStringToParagraph,
  isJsonString,
  serialize
} from 'components/atoms/MWEditor/Helpers';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
const staticDesc = `Write your paragraph here`;
const defaultSectionTitle = 'Cover Letter';
function CoverLetterSectionView({ data, isFetching, sectionTitle }) {
  const userId = useSelector(getCurrentUserIdSelector);
  const [edit, setEdit] = useState(false);
  const [coverLetter, setCoverLetter] = useState(data);
  const { onUpdateSubmit, isCurrentUserProfile } = useCoverLetterUtils({ userId });

  function countWords(text) {
    text = JSON.stringify(text);
    const words = text.split(/\s+/);
    return words.length;
  }
  const onSubmit = () => {
    const wordCount = countWords(coverLetter);
    if (wordCount > 500) return showErrorToast('Limit of 500 words reached');
    setCoverLetter(JSON.stringify(coverLetter));
    onUpdateSubmit(JSON.stringify(coverLetter));
    setEdit(false);
    coverLetter;
  };
  {
    isFetching && <Loader />;
  }
  return (
    <>
      <SectionWrapper sectionTitle={sectionTitle ?? defaultSectionTitle}>
        <div className={styles.container}>
          {isCurrentUserProfile && (
            <Options
              extraClass="coverLetterOptions"
              handleDiscard={() => {
                setEdit(false);
                setCoverLetter(data);
              }}
              handleEdit={() => setEdit(true)}
              edit={edit}
              handleSave={() => {
                if (!coverLetter || coverLetter.length === 0) {
                  showErrorToast('The Paragraph is not allowed to be empty!');
                } else {
                  onSubmit();
                }
              }}
            />
          )}
          {edit && (
            <div className={styles.letterInput}>
              <MWEditorLite
                data={
                  coverLetter
                    ? isJsonString(coverLetter)
                      ? JSON.parse(coverLetter)
                      : convertStringToParagraph(coverLetter)
                    : undefined
                }
                placeholderText="Write about yourself in 500 words or less"
                setData={setCoverLetter}
              />
            </div>
          )}
          {!edit && (
            <div
              className={styles.coverLetter}
              dangerouslySetInnerHTML={{
                __html: coverLetter
                  ? serialize(
                      coverLetter
                        ? isJsonString(coverLetter)
                          ? JSON.parse(coverLetter)
                          : convertStringToParagraph(coverLetter)
                        : undefined
                    )
                  : staticDesc
              }}
            ></div>
          )}
        </div>
      </SectionWrapper>
    </>
  );
}

function CoverLetterSection({ ...args }) {
  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId: args?.profileId
    },
    {
      skip: isSharedMode() || !args?.profileId
    }
  );
  const { data, isLoading, isError, isFetching } = result;
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {isError?.data?.message}
      </div>
    );
  }

  return (
    <CoverLetterSectionView
      sectionTitle={args.sectionTitle}
      data={data?.bio?.coverLetter ?? placeHolder}
      isFetching={isFetching}
    />
  );
}

const placeHolder = 'Placeholder - Write about yourself in 500 words or less';
export default CoverLetterSection;
