export const POST_CATEGORIES = [
  {
    label: 'Most Recent Feeds',
    text: ''
  },
  {
    label: 'Management',
    text: 'management'
  },
  {
    label: 'Human Resource',
    text: 'human resource'
  },
  {
    label: 'Engineering',
    text: 'engineering'
  },
  {
    label: 'Hiring',
    text: 'hiring'
  }
];
