import { useIsCurrentUserProfile } from 'hooks';
import React from 'react';

function useParagraphUtils() {
  const [edit, setEdit] = React.useState(false);
  const isCurrentUserProfile = useIsCurrentUserProfile();
  return { isCurrentUserProfile, edit, setEdit };
}

export default useParagraphUtils;
