import React, { useState } from 'react';
import { onFieldChangeProfileModules } from 'helpers';
import DatePicker from 'react-datepicker';
import { CustomDropdown } from 'components/form';
import { Textarea } from 'components/form';
import { useForm } from 'react-hook-form';
import styles from './EducationsSection.module.scss';
import { showErrorToast } from 'utils/toast';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { debounce } from 'lodash';

function CreateEducationModal({ open, onClose, onSaveEducation, isSaving, ...rest }) {
  const originCreateValues = {
    degree: rest.degreesList[0]?.text,
    grade: rest.gradesList[0]?.text,
    from: new Date(new Date().setMonth(0)).toString(),
    to: new Date().toString()
  };
  const [fieldsChange, setFieldsChange] = useState({ ...originCreateValues });

  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  const closeReset = () => {
    onClose();
    reset();
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      institution: '',
      specialization: '',
      location: ''
    }
  });
  const submitFun = async (data) => {
    const edcObject = { ...data, ...fieldsChange };
    const status = await onSaveEducation(edcObject);
    if (status) closeReset();
    else showErrorToast('Error adding education');
  };

  const onSubmit = debounce(handleSubmit(submitFun), 400);

  return (
    <>
      {open && (
        <>
          <CreateModal isSubmitting={isSaving} clickEvent={onSubmit} handleClose={closeReset}>
            <h1>{'Add Education'}</h1>
            <form>
              <Textarea
                maxLength={100}
                name="institution"
                placeholder="Add Institution Name"
                {...register('institution', { required: true })}
              />
              {errors.institution && (
                <span className={styles.error}> Institution Name is required</span>
              )}
              <Textarea
                maxLength={100}
                name="specialization"
                placeholder="Add Specialization"
                {...register('specialization', { required: true })}
              />
              {errors.specialization && (
                <span className={styles.error}> Specialization is required</span>
              )}
              <Textarea
                maxLength={400}
                name="location"
                placeholder="Add Location"
                {...register('location', { required: true })}
              />
              {errors.location && <span className={styles.error}>Location is required</span>}
            </form>
            <div>
              <CustomDropdown
                extraClass="onCreateDropdown"
                list={rest.degreesList}
                value={fieldsChange.degree}
                placeholder="Degree Type"
                onChange={(value) => onFieldsChange('degree', value)}
              />
            </div>
            <div>
              <CustomDropdown
                extraClass="onCreateDropdown"
                list={rest.gradesList}
                value={fieldsChange.grade}
                placeholder="Grade Type"
                onChange={(value) => onFieldsChange('grade', value)}
              />
            </div>
            <div className={styles.card}>
              <div className="onCreateDatePicker">
                <DatePicker
                  maxDate={new Date()}
                  selected={new Date(fieldsChange.from)}
                  onChange={(date) => onFieldsChange('from', date.toString())}
                  showYearDropdown
                />

                <DatePicker
                  disabled={fieldsChange.grade.toLowerCase() === 'ongoing'}
                  className={
                    fieldsChange.grade.toLowerCase() === 'ongoing' ? styles.disabledDate : ''
                  }
                  maxDate={new Date()}
                  minDate={new Date(fieldsChange.from)}
                  selected={new Date(fieldsChange.to)}
                  onChange={(date) => onFieldsChange('to', date.toString())}
                  showYearDropdown
                />
              </div>
            </div>
          </CreateModal>
        </>
      )}
    </>
  );
}

export default CreateEducationModal;
