import { BasicTooltip } from '../Tooltip/Tooltip';
import styles from './MWEditor.module.scss';
import { Icon, Button } from './Components';
export const SpecialButton = ({ editorVisible, setEditorVisible, tooltip, text, icon }) => {
  return (
    <div data-tooltip-id={`${tooltip}-tooltip`}>
      <Button
        onClick={() => {
          setEditorVisible(!editorVisible);
        }}
      >
        <div className={styles.iconWrapper}>
          <Icon>{icon}</Icon>
        </div>
        <BasicTooltip id={`${tooltip}-tooltip`} variant={'dark'} content={text} />
      </Button>
    </div>
  );
};
