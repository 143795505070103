import React from 'react';
import styles from './GeneralTools.module.scss';
import Options from 'components/atoms/Options/Options';
import QueryableTools from 'components/molecules/QueryableTools/QueryableTools';
import { useToolsUtils } from 'components/organisms/Profile/hooks/templates';
const defaultSectionTitle = 'Tools';
function GeneralTools({ toolsList = [], sectionTitle }) {
  const {
    isCurrentUserProfile,
    selectTools,
    updateProfileTools,
    globalEdit,
    edit,
    setEdit,
    selectedTools,
    setSelectedTools
  } = useToolsUtils({
    toolsList
  });

  return (
    <div className={styles.icontextsection}>
      {isCurrentUserProfile && (
        <Options
          extraClass="languageOptions"
          handleSave={() => {
            updateProfileTools();
          }}
          handleEdit={() => setEdit(true)}
          handleDiscard={() => {
            setSelectedTools([...toolsList]);
            setEdit(false);
          }}
          edit={edit}
        />
      )}
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.titleIcon}>
            <h3 className={styles.title}>{sectionTitle ?? defaultSectionTitle}</h3>
          </div>
          <div className={styles.content}>
            <QueryableTools
              onToolsSelected={selectTools}
              add={edit}
              rtkQueryName="getToolsByKeyword"
              list={edit ? [...selectedTools] : [...toolsList]}
              title="Tools"
              searchPlaceholder="search the tool you have knowledge for..."
              bgColor="#8080803b"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralTools;
