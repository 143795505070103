import React, { useState } from 'react';
import styles from './Resources.module.scss';
import Text from 'components/atoms/Text/Text';
import { handleAsync } from 'helpers';
import { useAppendResourcesMutation, useDeleteResourcesTeamByIdMutation } from 'api/team';
import { deleteFromS3, getS3Url } from 'helpers/s3Handler';
import { BlobCard, Loader, Progress } from 'components/ui';
import HiddenFileInput from '../HiddenFileInput/HiddenFileInput';
import { getIsAdminSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { showErrorToast } from 'utils/toast';
import { useUploadBlobs } from 'hooks';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { TrashI } from 'components/ui';
import { checkTeamResourcesLimit } from 'helpers/tier';
import useGetUserTierData from 'hooks/useGetUserTierData';

const FileCard = ({ item, edit, selectedResources, toggleResourceSelection }) => {
  const isSelected = selectedResources.includes(item);

  const handleToggleSelection = () => {
    toggleResourceSelection(item);
  };
  return (
    <div className={styles.item}>
      <BlobCard
        path={getS3Url(item?.link)}
        edit={edit}
        isSelected={isSelected}
        handleToggleSelection={handleToggleSelection}
      />
    </div>
  );
};
function Resources({ id, resources }) {
  const { tierData, refetch } = useGetUserTierData();
  const [edit, setEdit] = useState(false);
  const isAdmin = useSelector(getIsAdminSelector);
  const [appendResources] = useAppendResourcesMutation();
  const [deleteResourcesTeamById, { isLoading: isDeleteLoading }] =
    useDeleteResourcesTeamByIdMutation();
  const { uploadBlobs, isUploading, totalUploadProgress } = useUploadBlobs();
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedResourcesForDeletion, setSelectedResourcesForDeletion] = useState(false);

  const handleToggleResourceSelection = (resource) => {
    if (selectedResources.includes(resource)) {
      setSelectedResources(selectedResources.filter((selected) => selected !== resource));
    } else {
      setSelectedResources([...selectedResources, resource]);
    }
  };

  const handleDeleteSelectedResources = async () => {
    setSelectedResourcesForDeletion(false);
    const resourceLinks = selectedResources.map((selectedResource) => selectedResource.link);
    const [status] = await handleAsync(
      deleteResourcesTeamById({ teamId: id, resourceLinks: resourceLinks }).unwrap(),
      null,
      'Error deleting resources'
    );
    if (status) {
      refetch();
      setSelectedResources([]);
    } else showErrorToast('Error deleting resources');
  };

  async function handleUpload(files) {
    // upload all files
    const allFiles = Array.from(files);
    if (allFiles.length === 0) return;

    const [statusFlag, message] = checkTeamResourcesLimit(tierData, id, allFiles.length);
    if (!statusFlag) {
      return showErrorToast(message);
    }

    const [uploadStatus, response] = await handleAsync(uploadBlobs(allFiles, ''));
    if (!uploadStatus) {
      const error = typeof response === 'string' ? response : 'Error uploading files';
      return showErrorToast(error);
    }
    // if any failed, show error toast
    const { keys, failedOnes } = response;
    if (failedOnes.length > 0) {
      const reasons = failedOnes.map((item) => item?.reason);
      const userErrorsMessages = reasons
        .filter((item) => item?.userError)
        ?.map((item) => item?.message);
      userErrorsMessages.forEach((message) => message && showErrorToast(message));
      userErrorsMessages.length === 0 && showErrorToast('Some files failed to upload');
    }

    // append all succeeded ones to db
    const resources = keys.map((item) => ({ link: item, type: item.split('.')[1] }));
    if (resources.length === 0) return;
    const [status] = await handleAsync(
      appendResources({ teamId: id, resources: { resources } }).unwrap()
    );
    // if failed, delete all succeeded ones
    if (!status) {
      showErrorToast('Error uploading file');
      keys.forEach((item) => handleAsync(deleteFromS3(item)));
    } else refetch();
  }
  const data = [
    {
      name: 'Files',
      images: [...resources]
    }
  ];
  return (
    <div className={styles.resources}>
      {isUploading && (
        <Progress loaded={totalUploadProgress.loaded} total={totalUploadProgress.total} />
      )}
      {isAdmin && (
        <div className={styles.btns}>
          <div className={styles.input}>
            <HiddenFileInput
              id={`upload`}
              multiple={true}
              accept=""
              onChange={(e) => handleUpload(e.target.files)}
            />
            <label htmlFor={`upload`}>
              <span className={styles.imgicon}>Add File</span>
            </label>
          </div>
          <button
            className={styles.editIcon}
            onClick={() => {
              setEdit(!edit);
              setSelectedResources([]);
            }}
          >
            Edit
          </button>
        </div>
      )}
      {data.map((item) => {
        return (
          <>
            <div key={item?.link} className={styles.container}>
              <Text font="500" title={item.name} />
              <div className={styles.slider}>
                {item.images.map((item) => (
                  <FileCard
                    item={item}
                    edit={edit}
                    key={item?.link}
                    selectedResources={selectedResources}
                    toggleResourceSelection={(resource) => handleToggleResourceSelection(resource)}
                  />
                ))}

                {isDeleteLoading && <Loader />}
              </div>
              {selectedResources.length > 0 && edit && (
                <div
                  onClick={() => {
                    setSelectedResourcesForDeletion(true);
                  }}
                  className={styles['selected-resources']}
                >
                  <TrashI /> <p>Delete Selected Resources</p>
                </div>
              )}
              {selectedResourcesForDeletion && (
                <DeleteModal
                  title="Are you sure you want to delete these Resources ?"
                  handleDelete={handleDeleteSelectedResources}
                  handleClose={() => {
                    setSelectedResourcesForDeletion(false);
                    setSelectedResources([]);
                  }}
                />
              )}
            </div>
            {item.images.length === 0 && (
              <>
                <h2
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    color: '#a3a3a3',
                    marginTop: '2rem'
                  }}
                >
                  No files added
                </h2>
              </>
            )}
          </>
        );
      })}
    </div>
  );
}
export default Resources;
