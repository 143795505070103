import React, { useState, useRef } from 'react';
import styles from './FeaturedVideos.module.scss';
import Container from 'components/atoms/InfoHubContainerSection';
import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import { CloseI, EditI, AddI, SaveI, Button } from 'components/ui';
import { useUpdateFeaturedVideosMutation } from 'api/info-hub';
import { checkIsValidUrl, handleAsync } from 'helpers';
import { showSuccessToast } from 'utils/toast';
import Options from 'components/atoms/Options/Options';
import { ReactComponent as Play } from '../../../assets/icons/playIcon.svg';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';
import { Modal } from 'components/modals';
import { FileUploader, Textarea } from 'components/form';
import { useUploadBlob } from 'hooks';
import { getS3Url } from 'helpers';
import { checkIsValidUrlVideo } from 'helpers';
import { showErrorToast } from 'utils/toast';
import poster from '../../../assets/images/poster1.webp';
import poster2 from '../../../assets/images/poster2.webp';
import { useTranslation } from 'react-i18next';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

export default function FeaturedVideos({
  topVideos,
  data,
  type,
  companyId,
  colorBgSwitcher,
  ...rest
}) {
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [videoToBeUpdated, setVideoToBeUpdated] = useState({});
  const [videoToBeAdded, setVideoToBeAdded] = useState({});
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const { uploadBlob, isUploading } = useUploadBlob();
  const [updateFeaturedVideos, { isLoadingSave }] = useUpdateFeaturedVideosMutation();
  const isAdmin = useSelector(getIsAdminSelector);
  const { t } = useTranslation();
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;

  let topVideosToShow = [];
  let videosToShow = [];
  if (data?.info?.featuredVideos && data?.info?.featuredVideos?.product) {
    if (type == 'Product') {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videosToShow = [...data?.info?.featuredVideos?.product];
    }
    if (type == 'Highlights' && data?.info?.featuredVideos?.highlights) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videosToShow = [...data?.info?.featuredVideos?.highlights];
    }
    if (topVideos) {
      if (videosToShow.length >= 2) {
        topVideosToShow = [...videosToShow.splice(0, 2)];
      } else {
        topVideosToShow = [...videosToShow];
        videosToShow = [];
      }
    }
  }
  if (!topVideosToShow.length && !videosToShow.length) {
    topVideosToShow = initialTopVideos;
    videosToShow = intiialVideos;
  }

  let topVideoRefs = useRef(topVideosToShow.map(() => React.createRef()));
  let videoRefs = useRef(videosToShow.map(() => React.createRef()));

  if (topVideoRefs.current.length < topVideosToShow.length) {
    for (let i = topVideoRefs.current.length; i < topVideosToShow.length; i++)
      topVideoRefs.current.push(React.createRef());
  }
  if (videoRefs.current.length < videosToShow.length) {
    for (let i = videoRefs.current.length; i < videosToShow.length; i++)
      videoRefs.current.push(React.createRef());
  }
  const handleAdd = async () => {
    let videos = {
      highlights: [],
      product: []
    };
    let saveData = { link: videoToBeAdded.link };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : t('Error uploading thumbnail');
        return showErrorToast(error);
      }
      saveData.thumbnail = result;
    }

    if (data?.info?.featuredVideos?.highlights) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videos.highlights = [...data?.info?.featuredVideos?.highlights];
    }
    if (data?.info?.featuredVideos?.product) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videos.product = [...data?.info?.featuredVideos?.product];
    }
    if (type == 'Product') {
      videos.product.push({ link: saveData.link, thumbnail: saveData?.thumbnail });
    }
    if (type == 'Highlights') {
      videos.highlights.push({ link: saveData.link, thumbnail: saveData?.thumbnail });
    }
    showSuccessToast(t('Saving'));
    handleAsync(
      updateFeaturedVideos({
        companyId: companyId,
        featuredVideos: videos
      }).unwrap()
    );
    setThumbnailFile(null);
    showSuccessToast(t('Done!'));
    setVideoToBeAdded({});
    setThumbnailFile(null);
  };

  const handleUpdate = async () => {
    let videos = {
      highlights: [],
      product: []
    };
    let saveData = { link: videoToBeUpdated.link, thumbnail: videoToBeUpdated.thumbnail };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) return showErrorToast(t('Error uploading thumbnail'));
      saveData.thumbnail = result;
    }

    if (data?.info?.featuredVideos?.highlights) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videos.highlights = [...data?.info?.featuredVideos?.highlights];
    }
    if (data?.info?.featuredVideos?.product) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videos.product = [...data?.info?.featuredVideos?.product];
    }
    if (type == 'Product') {
      videos.product[videoToBeUpdated.index] = {
        link: saveData.link,
        thumbnail: saveData?.thumbnail
      };
    }
    if (type == 'Highlights') {
      videos.highlights[videoToBeUpdated.index] = {
        link: saveData.link,
        thumbnail: saveData?.thumbnail
      };
    }
    showSuccessToast(t('Updating'));
    handleAsync(
      updateFeaturedVideos({
        companyId: companyId,
        featuredVideos: videos
      }).unwrap()
    );
    setThumbnailFile(null);
    showSuccessToast(t('Done'));
    setVideoToBeUpdated({});
    setThumbnailFile(null);
  };

  const removeVideo = async (saveData) => {
    let videos = {
      highlights: [],
      product: []
    };

    if (data?.info?.featuredVideos?.highlights) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videos.highlights = [...data?.info?.featuredVideos?.highlights];
    }
    if (data?.info?.featuredVideos?.product) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      videos.product = [...data?.info?.featuredVideos?.product];
    }
    if (type === 'Product') {
      for (let i = 0; i < videos.product.length; i++) {
        if (videos.product[i] === saveData) {
          videos.product.splice(i, 1);
        }
      }
    }
    if (type === 'Highlights') {
      for (let i = 0; i < videos.highlights.length; i++) {
        if (videos.highlights[i] === saveData) {
          videos.highlights.splice(i, 1);
        }
      }
    }

    showSuccessToast(t('Removing'));
    const result = await handleAsync(
      updateFeaturedVideos({
        companyId: companyId,
        featuredVideos: videos
      }).unwrap()
    );
    if (result[0]) {
      showSuccessToast(t('Done'));
    }
  };

  const [playingVideoIndex, setPlayingVideoIndex] = useState({
    top: [],
    bottom: []
  });
  //Called when video is either played or paused
  const handleVideoClick = (videoIndex, isTopVideo) => {
    const currentvideoRefs = isTopVideo ? topVideoRefs.current : videoRefs.current;
    const videoElement = currentvideoRefs[videoIndex].current;
    const topVideoCount = topVideoRefs.current.length;
    const bottomVideoCount = videoRefs.current.length;
    let newPlayingStateTop = topVideoRefs.current.map(() => {
      return false;
    });
    let newPlayingStateBottom = videoRefs.current.map(() => {
      return false;
    });
    //pause previous video if playing a video
    let previousTopVideo = playingVideoIndex.top;
    let previousBottomVideo = playingVideoIndex.bottom;
    previousTopVideo.some((element, index) => {
      if (element === true && topVideoRefs.current[index].current !== videoElement) {
        topVideoRefs.current[index].current.pause();
        topVideoRefs.current[index].current.controls = false;
      }
    });
    previousBottomVideo.some((element, index) => {
      if (element === true && videoRefs.current[index].current !== videoElement) {
        videoRefs.current[index].current.pause();
        videoRefs.current[index].current.controls = false;
      }
    });
    if (videoElement) {
      videoElement.controls = !videoElement.controls;
    }

    const newPlayingStateObject = {
      top: newPlayingStateTop,
      bottom: newPlayingStateBottom
    };
    //if we're playing a video set it to the state
    if (
      (isTopVideo && playingVideoIndex.top[videoIndex] !== true) ||
      (!isTopVideo && playingVideoIndex.bottom[videoIndex] !== true)
    ) {
      isTopVideo
        ? (newPlayingStateObject.top[videoIndex] = true)
        : (newPlayingStateObject.bottom[videoIndex] = true);
    } else {
      videoElement.pause();
    }
    setPlayingVideoIndex(newPlayingStateObject);
    let currentPlayingComponent = {};
    currentPlayingComponent[type] = true;
    rest.setVideoPlayingComponent(currentPlayingComponent);
  };
  const pauseAllVideos = () => {
    let videosToBePaused = [];
    videosToBePaused = videoRefs.current;
    videosToBePaused.forEach((element) => {
      if (element.current) {
        element.current.pause();
        element.current.controls = false;
      }
    });
    videosToBePaused = topVideoRefs?.current;
    videosToBePaused.forEach((element) => {
      if (element.current) {
        element.current.pause();
        element.current.controls = false;
      }
    });
    let newPlayingStateTop = topVideoRefs.current.map(() => {
      return false;
    });
    let newPlayingStateBottom = videoRefs.current.map(() => {
      return false;
    });
    const newPlayingStateObject = {
      top: newPlayingStateTop,
      bottom: newPlayingStateBottom
    };
    setPlayingVideoIndex(newPlayingStateObject);
  };

  //pause all videos for this component if the sibling component is playing a video and we are also playing a video
  if (
    !rest.videoPlayingComponent[type] &&
    (playingVideoIndex.top.some((element) => {
      return element === true;
    }) ||
      playingVideoIndex.bottom.some((element) => {
        return element === true;
      }))
  ) {
    pauseAllVideos();
  }

  return (
    <div
      className={`${styles.container} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}
    >
      <Container>
        <div className={styles.headLiner}>
          <h1 className={styles.title}>{t(rest?.title)}</h1>
          {edit ? (
            <div className={styles.save}>
              <SaveI onClick={() => setEdit(false)} />
            </div>
          ) : (
            <>
              {isAdmin && userTier === APP_TIERS.REGULAR && (
                <Options extraClass="videosOptions" handleEdit={() => setEdit(!edit)} />
              )}
            </>
          )}
        </div>
        <p className={styles.desc}>{t(rest?.description)}</p>

        {topVideos && (
          <div className={styles.topVideos}>
            {topVideosToShow.map((item, i) => {
              return (
                <div className={styles.videos} key={`${item}vid${i}`}>
                  <div className={styles.videoWrapper}>
                    <video
                      ref={topVideoRefs.current[i]} // Use the corresponding ref
                      onClick={() => handleVideoClick(i, true)}
                      controls={false}
                      src={item?.link}
                      poster={item?.thumbnail ? getS3Url(item?.thumbnail) : poster}
                    />
                    {!playingVideoIndex?.top[i] && <Play />}
                  </div>
                  {edit && (
                    <EditI
                      onClick={() => {
                        setOpenUpdateModal(true);
                        setVideoToBeUpdated({ ...item, index: i });
                      }}
                    />
                  )}
                  {edit && (
                    <CloseI
                      onClick={() => {
                        removeVideo(item);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}

        <SliderContainer breakpoint={1440} breakpointSlide={3} slides={3}>
          {videosToShow.map((item, i) => {
            return (
              <div className={styles.bottomVideos} key={i}>
                {edit && (
                  <CloseI
                    onClick={() => {
                      removeVideo(item);
                    }}
                  />
                )}
                {edit && (
                  <EditI
                    onClick={() => {
                      setOpenUpdateModal(true);
                      //add an offset for the top videos
                      topVideos
                        ? setVideoToBeUpdated({ ...item, index: i + 2 })
                        : setVideoToBeUpdated({ ...item, index: i });
                    }}
                  />
                )}
                <div className={styles.videoWrapper}>
                  <video
                    ref={videoRefs.current[i]} // Use the corresponding ref
                    onClick={() => handleVideoClick(i, false)}
                    controls={false}
                    src={item?.link}
                    poster={item?.thumbnail ? getS3Url(item?.thumbnail) : poster2}
                  />
                  {!playingVideoIndex?.bottom[i] && <Play />}
                </div>
              </div>
            );
          })}
        </SliderContainer>
        {edit && (
          <div className={styles.addButton}>
            <AddI
              onClick={() => {
                setAdd(true);
              }}
            />
          </div>
        )}
      </Container>
      <Modal
        isModalOpen={add}
        onClose={() => {
          setAdd(false);
        }}
        hasHeader
        colorBgSwitcher={colorBgSwitcher}
      >
        <div className={styles.FeaturedVideosModalContainer}>
          <Textarea
            onChange={({ target: { value } }) => {
              setVideoToBeAdded({ link: value });
            }}
            placeholder={t('Video Link')}
            value={videoToBeAdded.link}
          />
          <FileUploader
            onChange={(files) => {
              const file = files[0];
              setThumbnailFile(file);
            }}
            text={t('Upload Video Thumbnail')}
          />

          <div className={styles.FeaturedVideosModalButtonContainer}>
            <Button
              onClick={() => {
                const isValidLink = checkIsValidUrl(videoToBeAdded.link);
                if (!isValidLink) return showErrorToast(t('link-should-be-valid'));
                handleAdd();
                setAdd(false);
              }}
              colorBgSwitcher={colorBgSwitcher}
            >
              {t('Save')}
            </Button>
            <Button onClick={() => setAdd(false)} colorBgSwitcher={colorBgSwitcher}>
              {t('Close')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isModalOpen={openUpdateModal}
        onClose={() => {
          setOpenUpdateModal(false);
        }}
        hasHeader
        colorBgSwitcher={colorBgSwitcher}
      >
        <div
          style={{
            padding: '1rem'
          }}
        >
          <Textarea
            onChange={({ target: { value } }) => {
              setVideoToBeUpdated({ ...videoToBeUpdated, link: value });
            }}
            placeholder={t('Video Link')}
            value={videoToBeUpdated.link}
          />
          <FileUploader
            onChange={(files) => {
              const file = files[0];
              setThumbnailFile(file);
            }}
            text={t('Edit Video Thumbnail')}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
              gap: '.5rem'
            }}
          >
            <Button
              onClick={() => {
                const isValidLink = checkIsValidUrlVideo(videoToBeUpdated.link);
                if (!isValidLink) return showErrorToast(t('link-should-be-valid'));
                handleUpdate();
                setOpenUpdateModal(false);
              }}
              colorBgSwitcher={colorBgSwitcher}
            >
              {t('Update')}
            </Button>
            <Button onClick={() => setOpenUpdateModal(false)} colorBgSwitcher={colorBgSwitcher}>
              {t('Close')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
const initialTopVideos = [
  {
    link: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm'
  },
  {
    link: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm'
  }
];

const intiialVideos = [
  {
    link: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm'
  },
  {
    link: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm'
  },
  {
    link: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm'
  }
];
