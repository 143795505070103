import { apiSlice } from '../apiSlice';
// represent the controller
const secondaryUrl = `team`;

// TODO: handle taging
export const teamProfileExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    /*getTeamProfilesByCompanyId: builder.query({//Deprecated
      query: ({ companyId, page = 1 }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}?page=${page}`
      }),
      providesTags: ['Team']
    }),*/
    createTeam: builder.mutation({
      query: ({ team }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: team
      }),
      invalidatesTags: () => [{ type: 'Team', id: 'LIST' }]
    }),
    getTeamById: builder.query({
      query: ({ teamId }) => ({
        url: `/v1/${secondaryUrl}/${teamId}`
      }),
      providesTags: (res, err, { teamId }) => [{ type: 'Team', id: teamId }]
    }),
    getTeamsByCompanyId: builder.query({
      query: ({ companyId, page = 1, size = 10, projection, searchQuery = '' }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}?page=${page}&size=${size}${
          projection ? `&projection=${projection}` : ''
        }${searchQuery != '' ? `&searchQuery=${searchQuery}` : ''}`
      }),
      providesTags: () => [{ type: 'Team', id: 'LIST' }]
    }),
    updateTeamById: builder.mutation({
      query: ({ teamId, team }) => ({
        url: `/v1/${secondaryUrl}/${teamId}`,
        method: 'PATCH',
        body: team
      }),
      invalidatesTags: (res, err, { teamId }) => [{ type: 'Team', id: teamId }]
    }),
    /*deleteProfilesByTeamId: builder.mutation({//Deprecated
      query: ({ teamId }) => ({
        url: `/v1/${secondaryUrl}/kick/${teamId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Team']
    }),*/
    /*deleteTeamsByCompanyId: builder.mutation({//Deprecated
      query: ({ companyId }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Team']
    }),*/
    /*,*/
    deleteTeamById: builder.mutation({
      query: ({ teamId }) => ({
        url: `/v1/${secondaryUrl}/${teamId}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [{ type: 'Team', id: 'LIST' }]
    }),
    appendResources: builder.mutation({
      query: ({ teamId, resources }) => ({
        url: `/v1/${secondaryUrl}/resource/${teamId}`,
        method: 'POST',
        body: resources
      }),
      invalidatesTags: (res, err, { teamId }) => [{ type: 'Team', id: teamId }]
    }),
    deleteResourcesTeamById: builder.mutation({
      query: ({ teamId, resourceLinks }) => {
        return {
          url: `/v1/${secondaryUrl}/resources/delete/${teamId}`,
          method: 'PATCH',
          body: { resourceLinks }
        };
      },
      invalidatesTags: (res, err, { teamId }) => [{ type: 'Team', id: teamId }]
    }),

    kickProfilesFromTeam: builder.mutation({
      query: ({ teamId, profileIds }) => ({
        url: `/v1/${secondaryUrl}/kick/${teamId}`,
        method: 'PATCH',
        body: profileIds
      }),
      invalidatesTags: (res, err, { teamId }) => [{ type: 'Team', id: teamId }]
    }),
    inviteProfiles: builder.mutation({
      query: ({ teamId, profiles }) => ({
        url: `/v1/${secondaryUrl}/invite/${teamId}`,
        method: 'PATCH',
        body: profiles
      }),
      invalidatesTags: (res, err, { teamId }) => [{ type: 'Team', id: teamId }]
    })
  })
});
export const {
  //useGetTeamProfilesByCompanyIdQuery,
  useCreateTeamMutation,
  useGetTeamByIdQuery,
  useGetTeamsByCompanyIdQuery,
  useUpdateTeamByIdMutation,
  useAppendResourcesMutation,
  useDeleteResourcesTeamByIdMutation,
  useDeleteTeamByIdMutation,
  useKickProfilesFromTeamMutation,
  useInviteProfilesMutation
  //useDeleteProfilesByTeamIdMutation,
  //useDeleteTeamsByCompanyIdMutation,
  //useDeleteTeamByIdMutation
} = teamProfileExtendedSlice;
