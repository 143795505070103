import React from 'react';
import styles from './HomeSection.module.scss';
import { Button } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function HomeSection({ img, extraClass, subtitle, ...rest }) {
  const navigate = useNavigate();
  const currentUserProfileId = useSelector(getCurrentUserProfileId);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (rest?.toLink === 'profile') {
      navigate(`/profile/${currentUserProfileId}`);
    } else {
      navigate(`/${rest?.toLink}`);
    }
  };

  return (
    <div className={`${styles.container} ${styles[extraClass]}`}>
      <img src={img} alt="Info" />
      <div className={styles.content}>
        <h1>
          {t(rest?.title)}&nbsp;
          <span>{t(subtitle)}</span>
        </h1>
        <p>{t(rest?.description)}</p>
        {rest?.toLink && (
          <Button onClick={handleButtonClick} extraClass="homeButton" color="white">
            {t(rest?.btnText)}
          </Button>
        )}
      </div>
    </div>
  );
}
