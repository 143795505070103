import React from 'react';
const styles = {
  display: 'none'
};

const HiddenFileInput = React.forwardRef(({ id, onChange, ...rest }, ref) => {
  return (
    <input
      ref={ref}
      {...rest}
      style={styles}
      type="file"
      id={id}
      onChange={(e) => {
        onChange(e);
        // reset the value of the input so that the onChange event is triggered even if the same file is selected
        e.target.value = '';
      }}
    />
  );
});

export default HiddenFileInput;
