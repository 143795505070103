import { useGetExperiencesByUserIdQuery } from 'api/individual-profile';
import { LoadMoreButton, Loader } from 'components/ui';
import React, { useState } from 'react';
import ExperienceCard from './ExperienceCard';
import { CONTRACTS } from 'constants';
import { useExperienceUtils } from 'components/organisms/Profile/hooks/templates';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import expDefaultImage from 'assets/images/star.webp';
import { useHandleRtkPagination } from 'hooks';
import { isSharedMode } from 'helpers';
import CreateExperienceModal from './CreateExperienceModal';
import styles from './ExperiencesSection.module.scss';
import Options from 'components/atoms/Options/Options';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  convertStringToParagraph,
  isJsonString,
  serialize
} from 'components/atoms/MWEditor/Helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import { useTranslation } from 'react-i18next';

const defaultSectionTitle = 'Experiences';
function ExperiencesSectionView({
  data: experiences,
  hasNextPage,
  isFetching,
  setPage,
  sectionTitle,
  isPlaceholdersActive
}) {
  const userId = useSelector(getCurrentUserIdSelector);
  const [create, setCreate] = useState(false);
  const { t } = useTranslation();

  const {
    onUpdateSubmit,
    onDeleteSubmit,
    onCreateSubmit,
    isSaving,
    globalEdit,
    isCurrentUserProfile,
    staticDesc,
    onDescriptionSave,
    editDescription,
    setDescription,
    setUpdatedDesc,
    experienceDesc: experienceDescription
  } = useExperienceUtils({ userId });

  return (
    <>
      <div className={styles.expcontainer}>
        <div className={styles.profileinfotech}>
          <div className={styles.infowrapper}>
            <div className={styles.wrapper}>
              {isCurrentUserProfile && (
                <Options
                  extraClass="topOptions"
                  handleDiscard={() => setDescription(false)}
                  handleAdd={() => setCreate(true)}
                  handleEdit={() => setDescription(true)}
                  handleSave={onDescriptionSave}
                  edit={editDescription}
                />
              )}

              <h3 className={styles.title} id="experiences-title">
                {sectionTitle ?? t(defaultSectionTitle)}
              </h3>
              <div className={styles.desc}>
                {editDescription ? (
                  <div className={styles.editDesc}>
                    <MWEditorLite
                      data={
                        experienceDescription
                          ? isJsonString(experienceDescription)
                            ? JSON.parse(experienceDescription)
                            : convertStringToParagraph(experienceDescription)
                          : undefined
                      }
                      placeholderText={t('Describe your experience')}
                      setData={setUpdatedDesc}
                    />
                  </div>
                ) : (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: experienceDescription
                        ? serialize(
                            experienceDescription
                              ? isJsonString(experienceDescription)
                                ? JSON.parse(experienceDescription)
                                : convertStringToParagraph(experienceDescription)
                              : undefined
                          )
                        : staticDesc
                    }}
                  ></div>
                )}
              </div>
              <div className={styles.content}>
                {experiences?.map((expr) => {
                  return (
                    <ExperienceCard
                      isPlaceholdersActive={isPlaceholdersActive}
                      img={expDefaultImage}
                      globalEdit={globalEdit}
                      company={expr.company}
                      // role={expr.role}
                      position={expr.position}
                      description={expr.description}
                      key={expr.id}
                      id={expr.id}
                      contract={expr.contract}
                      technologies={expr.technologies}
                      ongoing={expr.ongoing}
                      country={expr.country}
                      from={expr.from}
                      to={expr.to}
                      contracts={CONTRACTS}
                      isCurrentUserProfile={isCurrentUserProfile}
                      onUpdateSubmit={onUpdateSubmit}
                      onDeleteSubmit={onDeleteSubmit}
                    />
                  );
                })}
                {isFetching && <Loader />}

                <LoadMoreButton
                  hidden={!hasNextPage}
                  onClick={() => setPage((pre) => pre + 1)}
                  disabled={isFetching || !hasNextPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateExperienceModal
        open={create}
        onClose={() => setCreate(false)}
        onSaveExperience={(experience) => onCreateSubmit(experience)}
        contracts={CONTRACTS}
        isSaving={isSaving}
      />
    </>
  );
}

function ExperiencesSection({ ...args }) {
  const [sharedDate] = useGetSharedProfile();
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getExperiencesByUserId',
    rtkQuery: useGetExperiencesByUserIdQuery,
    rtkQueryArgs: { userId: args?.userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isLoading, isError, isFetching, error } = !isSharedMode()
    ? result
    : sharedDate ?? {};
  const { t } = useTranslation();

  const placeHolder = [
    {
      id: 1,
      company: t('apple-inc-placeholder'),
      position: t('Software Engineer'),
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi ut facere repellat molestiae explicabo et est cum repellendus? Nostrum odio placeat molestiae error impedit temporibus corrupti est magnam tempora eligendi.',
      contract: t(CONTRACTS[0].text),
      technologies: [],
      ongoing: true,
      country: 'USA',
      from: '2023-01-04T18:56:34.000Z',
      to: '2024-04-04T18:56:34.000Z'
    },
    {
      id: 2,
      company: t('google-placeholder'),
      position: t('DevOps Engineer'),
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi ut facere repellat molestiae explicabo et est cum repellendus? Nostrum odio placeat molestiae error impedit temporibus corrupti est magnam tempora eligendi.',
      contract: t(CONTRACTS[1].text),
      technologies: [],
      ongoing: false,
      country: 'USA',
      from: '2022-01-04T18:56:34.000Z',
      to: '2021-04-04T18:56:34.000Z'
    },
    {
      id: 3,
      company: t('facebook-placeholder'),
      position: t('Software Engineer'),
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi ut facere repellat molestiae explicabo et est cum repellendus? Nostrum odio placeat molestiae error impedit temporibus corrupti est magnam tempora eligendi.',
      contract: t(CONTRACTS[1].text),
      technologies: [],
      ongoing: false,
      country: 'USA',
      from: '2020-01-04T18:56:34.000Z',
      to: '2021-04-04T18:56:34.000Z'
    }
  ];
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const experiences = isSharedMode() ? data?.bio?.experiences : data?.result;
  const isPlaceholdersActive = experiences?.length === 0;

  return (
    <>
      <ExperiencesSectionView
        sectionTitle={args?.sectionTitle}
        data={experiences && experiences.length !== 0 ? experiences : placeHolder}
        isPlaceholdersActive={isPlaceholdersActive}
        hasNextPage={data?.page?.hasNextPage}
        setPage={setPage}
        isFetching={isFetching}
      />
    </>
  );
}
export default ExperiencesSection;
