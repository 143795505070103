import React, { useState, useEffect } from 'react';
import styles from './ProfilePicker.module.scss';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import defaultProfileImage from '../../../assets/images/avatar.webp';
import Paginator from '../Paginator/Paginator';
import ContainerSection from '../../atoms/InfoHubContainerSection';
import { getS3Url } from 'helpers/s3Handler';
import { Loader } from 'components/ui';
import FilterSection from '../FilterSection/FilterSection';
import { getProfilesQueryFilter } from 'store/profile.slice';
import { useProfilesServerSearch } from 'hooks';
import { useTranslation } from 'react-i18next';

function ProfilePicker({
  selectedProfiles,
  setProfiles,
  skipSelf,
  pickProfileObject,
  profileAllowedToBePickedFunction,
  colorBgSwitcher
}) {
  const { setProfileName, profiles, isFetching, isError, page, totalPages, setPaginationPage } =
    useProfilesServerSearch(10);
  const [index, setIndex] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { company, profileStatus } = useSelector(getUserStatusSelector);
  const { t } = useTranslation();

  const handleAddition = (id) => {
    if (selectedProfiles.includes(id)) {
      setProfiles([
        ...selectedProfiles.filter((item) => {
          return item != id;
        })
      ]);
    } else {
      setProfiles([...selectedProfiles, id]);
    }
  };
  let forbiddenProfiles = [];
  if (profileAllowedToBePickedFunction) {
    profiles.map((profile) => {
      if (!profileAllowedToBePickedFunction(profile)) {
        forbiddenProfiles.push(profile.id);
      }
    });
  }
  return (
    <div
      className={`${styles.profilePicker} ${
        colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''
      }`}
    >
      <FilterSection
        {...{
          index,
          icons: false,
          setIndex,
          openDropdown,
          setOpenDropdown,
          searchEmployees: setProfileName,
          hideFilter: true,
          extraClass: 'scheduleFilter',
          colorBgSwitcher: colorBgSwitcher
        }}
      />
      {isFetching && (
        <ContainerSection>
          <Loader />
        </ContainerSection>
      )}
      {!isFetching && profiles && (
        <div className={styles.profiles}>
          {profiles.map((profile, index) => {
            if (profileStatus.id === profile.id && skipSelf) {
              return <div key={profileStatus.id}></div>;
            }
            const picked = pickProfileObject
              ? selectedProfiles.includes(profile)
              : selectedProfiles.includes(profile.id);
            return (
              <div
                className={`${styles.pickableProfile} ${picked ? styles.pickedProfile : ''}`}
                key={profile.id}
                onClick={() => {
                  pickProfileObject ? handleAddition(profile) : handleAddition(profile.id);
                }}
              >
                {!forbiddenProfiles.includes(profile.id) ? (
                  <></>
                ) : (
                  <div className={styles.forbiddenProfile}>{t('Not Available')}</div>
                )}
                <div
                  className={styles.profileName}
                >{`${profile.contact.firstName} ${profile.contact.lastName}`}</div>
                <div className={styles.profileInfo}>
                  <p className={styles.profileEmail}>{`${profile.contact.email}`}</p>
                  <p className={styles.profilePosition}>{`${profile?.jobPosition}`}</p>
                </div>
                <img
                  src={profile?.picture ? getS3Url(profile?.picture) : defaultProfileImage}
                  alt="profile image"
                />
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.pagination}>
        <Paginator current={page} setCurrent={setPaginationPage} first={1} last={totalPages} />
      </div>
    </div>
  );
}

export default ProfilePicker;
