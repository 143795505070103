import video from 'assets/images/wizr.webp';
import noVideoImage from 'assets/images/novideo.webp';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { DescriptionAndUploadModal, Loader, Progress } from 'components/ui';
import { getS3Url, handleAsync, isSharedMode } from 'helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import styles from './AboutMe.module.scss';
import Options from 'components/atoms/Options/Options';
import { useAboutMeUtils } from 'components/organisms/Profile/hooks/templates';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import { showErrorToast } from 'utils/toast';
import { useTranslation } from 'react-i18next';
function AboutMeSectionView({ aboutDescription, videoLink }) {
  const {
    isCurrentUserProfile,
    description,
    setDescription,
    isModalOpen,
    setIsModalOpen,
    isUploading,
    totalUploadProgress,
    updateUserDescription,
    handelUpload,
    handleVideoUpload
  } = useAboutMeUtils({
    aboutDescription,
    videoLink
  });
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['progress-container']}>
        <Progress
          height="15px"
          total={totalUploadProgress.total}
          loaded={totalUploadProgress.loaded}
        />
      </div>
      <div className={styles.videoContainer}>
        <DescriptionAndUploadModal
          onBlobUpload={(file) => {
            const MBSize = file.size / (1024 * 1024);
            if (MBSize > 100) {
              showErrorToast(t('file-too-large-error'));
              return;
            }
            handelUpload(file);
          }}
          isBlobUploading={isUploading}
          onSubmit={() => {
            if (description !== aboutDescription)
              handleAsync(updateUserDescription({ description }).unwrap());
            handleVideoUpload();
            setIsModalOpen(false);
          }}
          description={description}
          onDescriptionChange={(e) => {
            const { value } = e.target;
            if (value.length > 500) {
              setDescription(value.slice(0, 500));
              return;
            }
            setDescription(value);
          }}
          onClose={() => {
            setDescription(aboutDescription);
            ('CLose');
            setIsModalOpen(false);
          }}
          isModalOpen={isModalOpen}
          inputAccept={'videos'}
        />

        <div className={styles.videoContainer}>
          {isCurrentUserProfile && (
            <Options extraClass="videoOptions" handleEdit={() => setIsModalOpen(true)} /> //No need to set edit here as it opens a modal
          )}

          <div className={styles.description}>
            <p className={styles.videoDesc}>{aboutDescription || t('Your role description')}</p>
          </div>
          <div className={styles.video}>
            {videoLink && !isModalOpen ? (
              <video key={videoLink} poster={video} controls>
                <source src={getS3Url(videoLink)} />
              </video>
            ) : (
              <img src={noVideoImage} alt="no video image" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function AboutMe() {
  const [sharedDate] = useGetSharedProfile();
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );
  const { isLoading, isError, data, error } = !isSharedMode() ? result : sharedDate ?? {};
  const bioDescription = data?.bio?.description;
  const profileVideo = data?.bio?.profileVideo || '';
  const { t } = useTranslation();
  const placeHolder = t(`i-am-a-seeker-of-knowledge-and-wisdom`);

  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  return (
    <AboutMeSectionView
      aboutDescription={
        bioDescription && bioDescription?.length !== 0 ? bioDescription : placeHolder
      }
      videoLink={profileVideo}
    />
  );
}

export default AboutMe;
