import React from 'react';
import styles from './Input.module.scss';
import PropTypes from 'prop-types';

const Input = ({
  label,
  type,
  onChange,
  value,
  placeholder,
  bgColor,
  name,
  inputRef,
  maxLength = 1000,
  handleKeyDown,
  ...rest
}) => {
  return (
    <label className={`${styles.input} ${styles[rest?.extraClass]}`}>
      {label}
      <input
        {...rest}
        maxLength={maxLength}
        onChange={onChange}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        className={styles.input}
        style={{ backgroundColor: bgColor, ...rest?.style }}
        ref={inputRef}
        onKeyDown={handleKeyDown}
      />
      {rest?.icon && (
        <div className={styles.iconWrapper} onClick={rest?.clickEvent}>
          {rest?.icon} <p className={styles.tooltip}>{rest?.tooltip}</p>
        </div>
      )}
    </label>
  );
};

export default Input;

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  bgColor: PropTypes.string,
  name: PropTypes.string,
  handleKeyDown: PropTypes.func
};
