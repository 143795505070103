import React from 'react';
import { useSaveReviewMutation } from 'api/individual-profile';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import { motion } from 'framer-motion';
import { ReviewForm, Reviews } from './sections';

function UserReviewsTab({ isCurrentUserProfile, profileId }) {
  const userStatus = useSelector(getUserStatusSelector);
  const [saveReview, { isLoading: isSaving }] = useSaveReviewMutation();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Reviews profileId={profileId} isCurrentUserProfile={isCurrentUserProfile} />
      {!isCurrentUserProfile && (
        <ReviewForm profileId={profileId} userStatus={userStatus} saveReview={saveReview} />
      )}{' '}
    </motion.div>
  );
}

export default UserReviewsTab;
