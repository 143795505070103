import React from 'react';
import styles from './CompanyStory.module.scss';
import { EditableText } from 'components/form';
import { showWarnToast } from 'utils/toast';
import { useSelector } from 'react-redux';
import { getCompanyThemeSelector } from 'store/login.slice';
import { getTheme } from 'helpers/general';

function CompanyStory({ editMode, storyDescription, onStoryChange }) {
  const [value, setValue] = React.useState(storyDescription);
  const companyTheme = useSelector(getCompanyThemeSelector);
  const theme = getTheme(companyTheme, 'companyPage');
  return (
    <div className={styles['company-story']}>
      <EditableText
        style={{
          textAlign: 'left'
        }}
        placeholder={theme.aboutDescription}
        editMode={editMode}
        onChange={(e) => {
          if (e.target.value.length > 500) {
            const val = e.target.value.slice(0, 500);
            setValue(val);
            onStoryChange(val);
            showWarnToast('Service title should be less than 500 characters');
          } else {
            setValue(e.target.value);
            onStoryChange(e.target.value);
          }
        }}
        value={editMode ? value : storyDescription || theme.aboutDescription}
      />
    </div>
  );
}

export default CompanyStory;
