import { useTour } from '@reactour/tour';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';

function CustomButtons({ onClickBack, visible, nextBtn, prevBtn, removeBack }) {
  const { currentStep, setCurrentStep, steps, setIsOpen } = useTour();
  const navigate = useNavigate();
  const currentUserProfileId = useSelector(getCurrentUserProfileId);

  const handleBack = () => {
    if (onClickBack) {
      navigate(-1);
      setCurrentStep(onClickBack);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (steps[currentStep + 1].pathname === '/profiles') {
      navigate(`${steps[currentStep + 1].pathname}/${currentUserProfileId}`);
    } else if (steps[currentStep + 1].pathname) {
      navigate(`${steps[currentStep + 1].pathname}`);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <div className="custom-your-buttons">
      <div>
        <button
          name="back"
          style={{ visibility: removeBack ? 'hidden' : 'visible' }}
          onClick={handleBack}
        >
          {prevBtn || 'Back'}
        </button>
      </div>
      {visible ? (
        ''
      ) : (
        <button name="next" onClick={handleNext}>
          {nextBtn || 'Next'}
        </button>
      )}
    </div>
  );
}

export default CustomButtons;
//soon to be added
export const steps = [
  // {
  //   selector: '.click-card-0',
  //   index: 0,
  //   content: (
  //     <div>
  //       <h1>What is an Individual Profile</h1>
  //       A personal profile where you can share your information with your colleagues.
  //       <CustomButtons removeBack={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 1,
  //   selector: '.click-card-1',
  //   content: (
  //     <div>
  //       <h1>How can I post </h1>
  //       You can create a team by selecting 2 or more profiles in profiles page then continue with
  //       steps.
  //       <br />
  //       <CustomButtons />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 2,
  //   selector: '.click-card-2',
  //   content: (
  //     <div>
  //       <h1>How can I create an event in calendar </h1>
  //       You can create a team by selecting 2 or more profiles in profiles page then continue with
  //       steps.
  //       <br />
  //       <CustomButtons />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 3,
  //   selector: '.guideCards',
  //   content: (
  //     <div>
  //       Click one of the options that stood out to you and let's create
  //       <br />
  //       <CustomButtons visible={true} nextBtn={''} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 4,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Here you have your profile, which you can customize. Click Next to start customizing.
  //       <br />
  //       <CustomButtons visible={false} removeBack={true} />
  //     </div>
  //   ),
  //   position: [-5, 3],
  //   pathname: '/profile/'
  // },
  // {
  //   index: 5,
  //   selector: '.Profile_editProfile__b8CIS a svg',
  //   content: (
  //     <div>
  //       Click on the pen icon if you want to change your profile picture.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 6,
  //   selector: 'label .Button_button__sBx4u',
  //   content: (
  //     <div>
  //       Click on the button to upload your picture.
  //       <br />
  //       <CustomButtons removeBack={true} visible={false} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 7,
  //   selector: '.Edit_container__3ReCq',
  //   content: (
  //     <div>
  //       This is the place where you can add or edit the other personal info. If you want to continue
  //       click Next please.
  //       <br />
  //       <CustomButtons removeBack={true} visible={false} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 8,
  //   selector: '.Form_buttons__-XmBV .Button_button__sBx4u',
  //   content: (
  //     <div>
  //       Click Save to continue.
  //       <br />
  //       <CustomButtons visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 9,
  //   selector: '.ProfileInfo_info__2SWX5',
  //   content: (
  //     <div>
  //       Here you can see you saved changes, now click Next if you want to continue customizing your
  //       profile.
  //       <br />
  //       <CustomButtons visible={false} removeBack={true} />
  //     </div>
  //   ),
  //   position: [-5, 3],
  //   pathname: '/profile'
  // },
  // {
  //   index: 10,
  //   selector: '.Options_videoOptions__MwnqA svg',
  //   content: (
  //     <div>
  //       Click on the dots to continue.
  //       <br />
  //       <CustomButtons removeBack={false} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 350]
  // },
  // {
  //   index: 11,
  //   selector: '.Options_videoOptions__MwnqA ul li',
  //   content: (
  //     <div>
  //       Click Edit to start editing.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 350]
  // },
  // {
  //   index: 12,
  //   selector: '.Modal_modalContent__MUeMI',
  //   content: (
  //     <div>
  //       Here you can write something about yourself or upload a video. If you want to continue click
  //       Save.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 13,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Your changes have been saved successfully. Click Next if you want to add an Experience.
  //       <br />
  //       <CustomButtons removeBack={true} visible={false} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 14,
  //   selector: '.Experience_wrapper__kVDoN .Options_topOptions__D0Itp svg',
  //   content: (
  //     <div>
  //       Click on the dots to continue.
  //       <br />
  //       <CustomButtons removeBack={false} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 600]
  // },
  // {
  //   index: 15,
  //   selector: '.Experience_wrapper__kVDoN .Options_topOptions__D0Itp ul li',
  //   content: (
  //     <div>
  //       Click Add to add an experience
  //       <br />
  //       <CustomButtons visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 600]
  // },
  // {
  //   index: 16,
  //   selector: '.CreateModal_frame__nH0Mv',
  //   content: (
  //     <div>
  //       Here you need to fill the inputs to add your first Experience. If you filled all inputs and
  //       you want to continue click Save.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 17,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       You have successfully created your first Experience. Click Next to change your background
  //       image.
  //       <br />
  //       <CustomButtons visible={false} nextBtn={'Next'} removeBack={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 18,
  //   selector: '.BannerProfile_upload__sv4ff',
  //   content: (
  //     <div>
  //       Click on the upload icon.
  //       <br />
  //       <CustomButtons visible={true} />
  //     </div>
  //   ),
  //   position: [-70, 55]
  // },
  // {
  //   index: 19,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Here you have the option to upload an image or choose from the ones we have recommended for
  //       you. If you added/chose an image click next if you want to continue.
  //       <br />
  //       <CustomButtons visible={false} nextBtn="Next" />
  //     </div>
  //   ),
  //   position: [-480, 45]
  // },
  // {
  //   index: 20,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Here you can navigate through different tabs like: My Role, Awards, Reviews, Availability
  //       and IT Essentials to add all types of information and experiences about yourself. Now you
  //       are ready to explore more by yourself.
  //       <br />
  //       <CustomButtons visible={true} removeBack={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 21,
  //   selector: '.Tabs_container__wFJWZ a:nth-child(4)',
  //   content: (
  //     <div>
  //       Click on Hubs tab to continue.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3],
  //   pathname: '/profiles'
  // },
  // {
  //   index: 22,
  //   selector: '.AddNewsSection_container__duFjW',
  //   content: (
  //     <div>
  //       Here you can post anything you want like paragraphs, gifs, images, polls and we can also
  //       post different profiles. Click Next to post some profiles.
  //       <br />
  //       <CustomButtons removeBack={true} visible={false} />
  //     </div>
  //   ),
  //   position: [-5, 3],
  //   pathname: '/news-hub'
  // },
  // {
  //   index: 23,
  //   selector: '.QueryableProfiles_profileQuery__3Ckin',
  //   content: (
  //     <div>
  //       Click on the profile icon to pick profiles.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 24,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Please search for the profiles you want to post then click Save.
  //       <br />
  //       <CustomButtons visible={true} removeBack={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 25,
  //   selector: '.CustomDropdown_categoryPicker__xbyGa',
  //   content: (
  //     <div>
  //       You have successfully added the profiles, If you want to select a category for the post
  //       click on the category icon to continue and choose.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 26,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Choose the category you want.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 27,
  //   selector: '.MWEditor_coverIcon__vUvgx',
  //   content: (
  //     <div>
  //       Click on Visuals icon to pick a visual that we recommended for you.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 28,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Pick a visual and then continue by clicking Save.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 29,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       You have successfully created a post. If you want to Post you can continue by clicking Save
  //       button otherwise you are ready to play around more with the options we have prepared for you
  //       inside the editor.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 30,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Select one or more profiles to schedule an event then click Next
  //       <br />
  //       <CustomButtons removeBack={true} visible={false} />
  //     </div>
  //   ),
  //   position: [-5, 3],
  //   pathname: '/profiles'
  // },
  // {
  //   index: 31,
  //   selector: '.IconText_icontext__Icu4t:nth-child(2)',
  //   content: (
  //     <div>
  //       Click on Schedule button to continue.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 32,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       Fill the data needed to schedule an event then click Save.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // },
  // {
  //   index: 33,
  //   selector: '#root',
  //   content: (
  //     <div>
  //       You have successfully created your first event.
  //       <br />
  //       <CustomButtons removeBack={true} visible={true} />
  //     </div>
  //   ),
  //   position: [-5, 3]
  // }
];
