import { useContactProfileMutation } from 'api/individual-profile';
import { handleAsync } from 'helpers';
function useContactMeUtils() {
  const [contactProfile] = useContactProfileMutation();
  const onContactSubmit = (contactObject) => {
    return handleAsync(
      contactProfile({
        contactObject
      }).unwrap()
    );
  };
  return {
    onContactSubmit
  };
}

export default useContactMeUtils;
