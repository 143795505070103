import React, { useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from 'components/ui';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from 'helpers/general';
import styles from './ImageCropModal.module.scss';
import { Modal } from 'components/modals';
import {useTranslation} from "react-i18next";

/**
 * This component is used to crop the image and return the cropped image
 *
 * @prop {File} file the file to be cropped
 * @prop {Boolean} isOpen if true the modal will be open
 * @prop {Function} onSubmit this function will be called when the modal is closed
 * @prop {Function} onClose this function will be called when the modal is closed
 * @prop {String} cropShape  round, rect
 * @prop {Number} aspect 1, 16/9, 4/3, 1/1
 *
 *
 * @returns {React.Component}
 * */

function ImageCropModal({ file, isOpen, onSubmit, onClose, cropShape = 'rect', aspect = 16 / 9 }) {
  if (!isOpen) return null;
  const {t} = useTranslation();
  const [crop, setCrop] = useState({ x: 1, y: 1 });
  const [zoom, setZoom] = useState(1);
  const [srcImg, setSrcImg] = useState(URL.createObjectURL(file));
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const blob = await getCroppedImg(file, croppedAreaPixels);
    const croppedFile = new File([blob], file.name, {
      lastModified: Date.now(),
      type: file.type
    });

    onSubmit(croppedFile);
    setSrcImg(null);
  };

  return (
    <>
      <Modal
        overlayStyle={{
          alignItems: 'normal',
          paddingTop: '3rem'
        }}
        contentStyle={{
          height: '65%'
        }}
        onClose={onClose}
        extraClass="ImageCropModal"
      >
        <div className={styles['image-crop-modal']}>
          {srcImg && (
            <div className={styles['image-crop-container']}>
              <div className={styles['image-crop']}>
                {' '}
                <Cropper
                  image={srcImg}
                  crop={crop}
                  zoom={zoom}
                  cropShape={cropShape} // round, rect
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>

              <div className={styles['image-crop-controls']}>
                {' '}
                <div className={styles['zoom-stepper']}>
                  <label htmlFor="zoom">Zoom</label>
                  <div className={styles['zoom-stepper-container']}>
                    {' '}
                    <span
                      className={styles['zoom-stepper-button']}
                      onClick={() => {
                        // check if I can zoom out if no do nothing
                        if (zoom > 1) setZoom(zoom - 0.1); // 1 is the min
                      }}
                    >
                      -
                    </span>
                    <input
                      type="range"
                      min="1"
                      max="3"
                      step="0.01"
                      value={zoom}
                      onChange={(e) => setZoom(e.target.value)}
                    />
                    <span
                      className={styles['zoom-stepper-button']}
                      onClick={() => {
                        // check if I can zoom in if no do nothing
                        if (zoom < 3) setZoom(zoom + 0.1); // 3 is the max
                      }}
                    >
                      +
                    </span>
                  </div>
                </div>
                <Button
                  style={{ width: '50%', marginTop: '.5rem' }}
                  bgColor="#414141"
                  color="#fff"
                  onClick={onCrop}
                >
                  {t("Submit")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ImageCropModal;
