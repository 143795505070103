import React from 'react';
import styles from './PollEditor.module.scss';
import PollQuestion from 'components/atoms/PollQuestion/PollQuestion';
import { ReactComponent as Delete } from 'assets/icons/deleteicon.svg';

export default function PollEditor({ questions, setQuestions, setPollEditorVisible }) {
  function handleChangeQuestions(event, index) {
    const value = event.target.value;
    let questionsModified = questions;
    questionsModified[index][event.target.name] = value;
    setQuestions([...questionsModified]);
  }

  function handleChangeOptions(event, Questionindex, OptionIndex) {
    const value = event.target.value;
    let questionsModified = questions;
    questionsModified[Questionindex].options[OptionIndex] = event.target.value;
    setQuestions([...questionsModified]);
  }

  function addOptionToQuestion(index) {
    const value = event.target.value;
    let questionsModified = questions;
    questionsModified[index].options.push({ option: '' });
    setQuestions([...questionsModified]);
  }

  function removeQuestion(index) {
    const value = event.target.value;
    let questionsModified = questions;
    questionsModified.splice(index, 1);
    setQuestions([...questionsModified]);
  }

  function removeOptionFromQuestion(index, OptionIndex) {
    const value = event.target.value;
    let questionsModified = questions;
    questionsModified[index].options.splice(OptionIndex, 1);
    setQuestions([...questionsModified]);
  }

  return (
    <div className={styles.pollsModal}>
      <h1>Create Poll</h1>
      <div className={styles.PollEditorContainer}>
        {questions.map((question, questionIndex) => {
          return (
            <div className={styles.PollQuestionsContainer} key={`question${questionIndex}`}>
              <h3 className={styles.question}>Question {questionIndex + 1}</h3>
              <PollQuestion
                question={question}
                handleChange={handleChangeQuestions}
                handleChangeOptions={handleChangeOptions}
                addOptionToQuestion={addOptionToQuestion}
                removeOptionFromQuestion={removeOptionFromQuestion}
                questionIndex={questionIndex}
              />
              <div className={styles.deleteQuestion}>
                <Delete
                  onClick={() => {
                    removeQuestion(questionIndex);
                  }}
                  className={styles.deleteQuestion}
                />
              </div>
            </div>
          );
        })}
        <div className={styles.editorButtons}>
          <div
            className={`${styles.AddQuestionButton}`}
            onClick={() => {
              setQuestions([...questions, { question: '', options: [{ option: '' }] }]);
            }}
          >
            Add Question
          </div>
        </div>
      </div>
    </div>
  );
}
