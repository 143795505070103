import React, { useRef, useState } from 'react';
import styles from './BasicInfo.module.scss';
import { Input } from 'components/form';
import Form from '../Form/Form';
import { ImageCropModal, ProfilePic, TelInput } from 'components/ui';
import { CustomDropdown } from 'components/form';
import { getCompanySelector, setProfile as setProfileInStore } from 'store/login.slice';
import {
  arraysOfObjectsEqual,
  capitalizeFirstLetter,
  checkIsValidUrl,
  getCountryContinent,
  isValidEditPagePhoneNumber,
  onFieldChangeProfileModules,
  removeObjectElements,
  trimThenLowerCase
} from 'helpers';
import { useUpdateProfileContactMutation } from 'api/individual-profile';
import { handleAsync } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import defaultPhoto from '../../../assets/images/avatar.webp';
import HiddenFileInput from '../HiddenFileInput/HiddenFileInput';
import { getS3Url, deleteFromS3 } from 'helpers/s3Handler';
import useUploadBlob from 'hooks/useUploadBlob';
import { showErrorToast } from 'utils/toast';
import { Button } from 'components/ui';
import { CONTRACTS } from 'constants';
import { COUNTRIES_BY_CONTINENTS } from 'constants';
import { CustomDatePicker } from 'components/ui';

export default function BasicInfo({ profile, bio }) {
  const [profileData, setProfileData] = useState({ ...profile });
  const [socialsLinks, setSocialsLinks] = useState(bio?.social);
  const [imageFile, setImageFile] = useState(null);
  const [openImageCroppedModal, setOpenImageCroppedModal] = useState(false);
  const { uploadBlob, isUploading: isProfileImageUploading } = useUploadBlob();
  // const [getCitiesByCountry, { isLoading }] = useGetCountryCitiesMutation();
  const { name } = useSelector(getCompanySelector);
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const navigate = useNavigate();

  // const inputs = [
  //   {
  //     label: 'City',
  //     value: profileData.contact.city,
  //     type: 'text',
  //     id: 5,
  //     name: 'city',
  //     nameInObject: 'contact.city'
  //   }
  // ];
  const socialInputs = [
    {
      label: 'Social media link',
      placeholder: 'Facebook',
      name: 'facebook',
      type: 'text',
      id: 6
    },
    {
      type: 'text',
      placeholder: 'Twitter or X',
      name: 'x',
      id: 7
    },
    {
      type: 'text',
      placeholder: 'Linkedin',
      name: 'linkedin',
      id: 8
    }
  ];

  // api endpoints
  const [updateProfileAndSocials, { isUpdatingProfileAndSocials }] =
    useUpdateProfileContactMutation();

  const checkValidSocials = (socials) => {
    const validSocials = socials?.filter((social) => social.link !== '') || [];
    let result = [true, ''];
    validSocials.some((social) => {
      if (social?.name === 'whatsapp' && !isValidEditPagePhoneNumber(social?.link)) {
        result = [false, 'Invalid phone number, make sure to include country code'];
        return true; // Returning true stops the iteration
      } else if (social?.name !== 'whatsapp' && !checkIsValidUrl(social?.link)) {
        result = [false, `Invalid ${social?.name} URL`];
        return true; // Returning true stops the iteration
      }
    });
    return result;
  };

  const handleSubmitEdit = async () => {
    //  check valid links
    const socialsCheckResult = checkValidSocials(socialsLinks);
    if (!socialsCheckResult[0]) return showErrorToast(socialsCheckResult[1]);

    // prepare data body
    const id = profileData.id;

    // remove id and pictureTestUrl from profileData
    const profileRemovedId = removeObjectElements(profileData, ['id', 'pictureTestUrl']);

    // check if image is uploaded
    let profileImgUrl = profile.picture;
    if (imageFile) {
      profile.picture && handleAsync(deleteFromS3(profile.picture));
      const [status, result] = await handleAsync(uploadBlob(imageFile, 'profile'));
      if (!status) {
        const errorType = typeof result === 'string';
        const error = errorType ? result : 'Error uploading image';
        showErrorToast(error);
      } else profileImgUrl = result;
    }
    const profileDto = {
      ...profileRemovedId,
      picture: profileImgUrl
    };

    let bodyObject = {
      profile: {
        ...profileDto,
        contact: {
          // add continent
          ...profileDto.contact,
          continent: getCountryContinent(profileDto.contact.country)
        }
      }
    };
    // check if socials are changed
    if (!arraysOfObjectsEqual(socialsLinks || [], bio.social || [])) {
      bodyObject = { ...bodyObject, social: socialsLinks };
    }
    // update profile
    const status = await handleAsync(
      updateProfileAndSocials({ body: bodyObject, profileId: id }).unwrap()
    );
    if (status[0]) {
      profileDto.picture !== profile.picture &&
        dispatch(setProfileInStore({ id: id, picture: profileDto.picture }));
      navigate(-1);
    } else {
      showErrorToast('Error updating profile');
    }
  };
  const handleProfileImageChange = (imgFile) => {
    const url = URL.createObjectURL(imgFile);
    setImageFile(imgFile);
    setProfileData({ ...profileData, pictureTestUrl: url });
  };

  const onFieldChanged = (name, value) => {
    onFieldChangeProfileModules(name, value, setProfileData, profileData);
  };
  const handleSocialInputChange = (e) => {
    const link = e.target.value.trim();
    const name = trimThenLowerCase([e.target.name])[0];
    const social = { name, link };
    const socials = socialsLinks?.filter((s) => trimThenLowerCase([s.name])[0] !== name) || [];
    setSocialsLinks([...socials, social]);
  };
  return (
    <div className={styles.basicinfo}>
      <ImageCropModal
        cropShape="round"
        aspect={1}
        onClose={() => {
          setOpenImageCroppedModal(false);
          fileInputRef.current.value = '';
          setImageFile(null);
        }}
        isOpen={openImageCroppedModal}
        file={imageFile}
        onSubmit={(file) => {
          fileInputRef.current.value = '';
          handleProfileImageChange(file);
          setOpenImageCroppedModal(false);
        }}
      ></ImageCropModal>
      <div className={styles.replaceimg}>
        <ProfilePic
          extraClass="replace"
          image={
            imageFile
              ? profileData.pictureTestUrl
              : profileData.picture
              ? getS3Url(profileData.picture)
              : defaultPhoto
          }
        />
        <label htmlFor="file-photo">
          <Button span type="default" color="#fff" bgColor="#079677">
            Replace image
          </Button>
        </label>
        <HiddenFileInput
          ref={fileInputRef}
          id="file-photo"
          accept="image/*"
          onChange={(e) => {
            const f = e.target.files[0];
            handleProfileImageChange(f);
            setImageFile(f);
            setOpenImageCroppedModal(true);
          }}
        />
      </div>
      <div className={styles.formHolder}>
        <div className={styles.inputs}>
          <Form
            isLoading={isUpdatingProfileAndSocials || isProfileImageUploading}
            button={'Save'}
            handleSubmit={handleSubmitEdit}
            onCancel={() => navigate(-1)}
          >
            <CustomDropdown
              label="Country"
              extraClass="infoDropdown"
              search
              list={COUNTRIES_BY_CONTINENTS}
              placeholder={'Country Not Selected'}
              value={profileData.contact.country}
              onChange={(country) => {
                onFieldChanged('contact.country', country);
              }}
            />
            {/* {selectedCountryCities.isSuccess && (
              <>
                <CustomDropdown
                  label="City"
                  extraClass="infoDropdown"
                  search
                  list={selectedCountryCities.cities}
                  placeholder={'City Not Selected'}
                  value={profileData.contact.city}
                  onChange={(city) => {
                    const [loweredCity] = trimThenLowerCase([city]);
                    onFieldChanged('contact.city', loweredCity);
                  }}
                />
              </>
            )} */}
            {/* {!selectedCountryCities.isSuccess && ( */}
            <>
              <Input
                type={'text'}
                label={'City'}
                value={profileData.contact.city}
                bgColor="#333333"
                onChange={({ target }) => {
                  onFieldChanged('contact.city', target.value);
                }}
              />
            </>
            {/* )} */}

            {socialInputs?.map((item) => {
              return (
                <>
                  <Input
                    placeholder={item.placeholder}
                    type={item?.type}
                    label={item?.label}
                    value={
                      socialsLinks?.find(
                        (s) =>
                          trimThenLowerCase([s?.name])[0] === trimThenLowerCase([item?.name])[0]
                      )?.link || ''
                    }
                    bgColor="#333333"
                    key={item?.id}
                    name={item?.name}
                    onChange={handleSocialInputChange}
                  />
                </>
              );
            })}

            <TelInput
              name="whatsapp"
              value={
                socialsLinks?.find((s) => trimThenLowerCase([s?.name])[0] === 'whatsapp')?.link ||
                ''
              }
              onChange={handleSocialInputChange}
            />

            {/* {socialMedia.map((singleSocialMedia, index) => (
              <div key={index} className={styles.socialmedia}>
                <input
                  name="socialmedia"
                  onChange={(e) => handleSocialMediaChange(e, index)}
                  value={singleSocialMedia.socialmedia}
                  type="text"
                  placeholder="Add another social media"
                />
                {socialMedia.length - 1 === index && socialMedia.length < 4 && (
                  <Button onClick={handleSocialMediaAdd} color="#bfbfbf" bgColor="transparent">
                    + Load more
                  </Button>
                )}
              </div>
            ))} */}
            <div
              style={{
                margin: '0px 0 25px 0'
              }}
            >
              <label className={styles['input-label']}>
                Started At {capitalizeFirstLetter(name)}:
              </label>
              <CustomDatePicker
                showYearDropdown
                selectedData={
                  profileData?.startedAt ? new Date(profileData?.startedAt) : new Date()
                }
                onChange={(date) => onFieldChanged('startedAt', date.toDateString())}
              />
            </div>
            <CustomDropdown
              extraClass="infoDropdown"
              title="Contract"
              label="Type of agreement"
              list={CONTRACTS}
              placeholder={'Choose contract type'}
              onChange={(value) => onFieldChanged('contract', value)}
              value={profileData.contract}
            />
            {/* Pending */}
            {/* <CustomDropdown extraClass="infoDropdown" title="Experience" label="Years" list={years} />
            <CustomDropdown extraClass="infoDropdown" title="Available" label="Time" list={time} />
            <CustomDropdown extraClass="infoDropdown" title="Team" label="Choose Team" list={team} /> */}
          </Form>
        </div>
      </div>
    </div>
  );
}
