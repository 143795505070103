import React, { useRef, useEffect, useState } from 'react';
import styles from './CreateModal.module.scss';
import { Button } from 'components/ui';
import { rest } from 'lodash';
import {useTranslation} from "react-i18next";

function CreateModal({
  children,
  handleClose,
  clickEvent,
  extraClass,
  changeBg,
  isSubmitting,
  ignoreOutsideClick,
  showCancel = true,
  textCancel = 'Cancel',
  showSave = true,
  textSave = 'Save',
  ...rest
}) {
  const modalRef = useRef(null);
  const containerRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const startClosingTransition = () => {
    if (containerRef.current) {
      containerRef.current.classList.add(styles.closeModal);
    }
  };
  const { t } = useTranslation();

  const handleCloseWithTransition = (closeType) => {
    setIsClosing(true);
  };

  useEffect(() => {
    const animateModalOpen = () => {
      if (isAnimating && containerRef.current) {
        containerRef.current.classList.add(styles.popOut);
        setIsAnimating(false);
      }
    };

    const animateModalClose = () => {
      if (isClosing && containerRef.current) {
        startClosingTransition();
        const transitionEnd = () => {
          setIsClosing(false);
          handleClose();
        };
        containerRef.current.addEventListener('animationend', transitionEnd);
        return () => containerRef.current.removeEventListener('animationend', transitionEnd);
      }
    };

    const handleClickOutside = (event) => {
      if (ignoreOutsideClick) return;
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseWithTransition({ action: 'outside-click' });
      }
    };

    setTimeout(animateModalOpen, 0);
    animateModalClose();

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAnimating, isClosing, handleClose, ignoreOutsideClick]);
  return (
    <div
      ref={containerRef}
      className={`${styles.modal} ${styles[extraClass]}`}
      style={{ transform: 'translateY(-100vh)' }}
    >
      <div className={styles.backdrop} />
      <div ref={modalRef} className={styles.frame}>
        <div className={styles.modalContainer}>
          <div className={styles.formHolder}>{children}</div>
          <div className={styles.btns}>
            {showCancel && (
              <Button
                onClick={() => handleCloseWithTransition('cancel')}
                guideButton={rest?.guideButton}
                color="white"
                bgColor="#41414185"
              >
                {t(textCancel)}
              </Button>
            )}
            {showSave && (
              <Button
                disabled={isSubmitting}
                onClick={() => clickEvent?.()}
                guideButton={rest?.guideButton}
                color="white"
                bgColor={isSubmitting ? 'gray' : changeBg || '#079677'}
              >
                {isSubmitting ? t('loading') : t(textSave)}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateModal;
