import React from 'react';
import styles from './WizrXLogo.module.scss';
import { ReactComponent as Logo } from 'assets/icons/wizrxlogo.svg';
import { Link } from 'react-router-dom';
import { isSharedMode } from 'helpers';
const WizrXLogo = () => {
  return (
    <>
      {isSharedMode() ? (
        <div className={styles.logo}>
          <Logo />
        </div>
      ) : (
        <div className={styles.logo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
      )}
    </>
  );
};

export default WizrXLogo;
