import React, { useState } from 'react';
import styles from './QAndACard.module.scss';
import Options from 'components/atoms/Options/Options';
import { useQandAUtils } from 'components/organisms/Profile/hooks/templates';
import { getCurrentUserIdSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { EditInput } from 'components/form';
import { handleAsync } from 'helpers';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
function QAndACard({ question, answer, color, id, isPlaceholdersActive }) {
  const currentUserId = useSelector(getCurrentUserIdSelector);
  const { updateQandA, deleteQandA } = useQandAUtils();
  const submitButtonRef = React.useRef();
  // const [updateStats, { isLoading }] = useUpdateStatsMutation();
  // const [deleteStats, { isLoading: isDeleting }] = useDeleteStatsMutation();
  let index = 0;
  const {
    register,
    handleSubmit,
    // watch,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      question: question,
      answer: answer
    }
  });
  const closeReset = () => {
    index++;
    if (index !== 1) reset();

    setEdit(false);
  };
  const [edit, setEdit] = useState(false);
  const { isCurrentUserProfile } = useQandAUtils();
  const [deleteModal, setDeleteModal] = useState(false);

  const onSubmit = async (data) => {
    const [status] = await handleAsync(
      updateQandA({ qAndA: { id, ...data }, userId: currentUserId }).unwrap(),
      'Updated successfully',
      'Failed to update'
    );
    if (status) {
      setValue('question', data.question);
      setValue('answer', data.answer);
    }
    index = 0;
    setEdit(false);
  };

  return (
    <div className={styles['wrapper']}>
      {isCurrentUserProfile && !isPlaceholdersActive && (
        <Options
          extraClass="profileOptions"
          handleDiscard={closeReset}
          handleSave={() => {
            submitButtonRef.current.click();
          }}
          handleDelete={() => {
            setDeleteModal(true);
          }}
          handleEdit={() => {
            setEdit(true);
          }}
          edit={edit}
        />
      )}

      {edit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <div>
              {' '}
              <EditInput
                extraClass="QAInput"
                autoFocus
                {...register('question', {
                  required: {
                    value: true,
                    message: 'Question is required'
                  },
                  maxLength: {
                    value: 100,
                    message: 'Question must be less than 100 characters'
                  },
                  validate: (value) => value.trim().length > 0 || 'Question cannot be empty'
                })}
              />
              {errors.question && <span className="input-error">{errors.question.message}</span>}
            </div>
            <div>
              {' '}
              <EditInput
                extraClass="QAInput"
                placeholder="Answer"
                {...register('answer', {
                  required: {
                    value: true,
                    message: 'Answer is required'
                  },
                  maxLength: {
                    value: 500,
                    message: 'Answer must be less than 500 characters'
                  },
                  validate: (value) => value.trim().length > 0 || 'Answer cannot be empty'
                })}
              />
              {errors.answer && <span className="input-error">{errors.answer.message}</span>}
            </div>
          </div>
          <input
            type="submit"
            style={{
              display: 'none'
            }}
            ref={submitButtonRef}
          />
        </form>
      )}
      {!edit && (
        <>
          <p
            style={{
              color: color
            }}
            className={styles['question']}
          >
            {question}
          </p>
          <p className={styles['answer']}>{answer}</p>
        </>
      )}

      {deleteModal && (
        <DeleteModal
          title="Are you sure you want to delete it ?"
          handleClose={() => setDeleteModal(false)}
          handleDelete={async () => {
            const [status] = await handleAsync(
              deleteQandA({ id, userId: currentUserId }).unwrap(),
              'Deleted successfully',
              'Failed to delete'
            );
            if (status) closeReset();
          }}
        />
      )}
    </div>
  );
}

export default QAndACard;
