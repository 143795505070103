import { handleAsync } from 'helpers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useUpdateIndividualProfileMutation } from 'api/individual-profile';
function useCoverLetterUtils() {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [updateIndividualProfile, { isLoading }] = useUpdateIndividualProfileMutation();

  const onUpdateSubmit = (coverLetter) => {
    handleAsync(
      updateIndividualProfile({ profile: { coverLetter }, key: 'coverLetter' }).unwrap(),
      'Updated Successfully',
      'Something went wrong'
    );
  };
  return {
    onUpdateSubmit,
    isLoading,
    isCurrentUserProfile
  };
}

export default useCoverLetterUtils;
