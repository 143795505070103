import React from 'react';
import styles from './Progress.module.scss';

/**
 * @description This component is used to show the progress of a task
 * @param {object} props - The props object
 * @param {number} props.loaded - The loaded value
 * @param {number} props.total - The total value
 * @param {string} props.height - The height of the progress bar
 * @param {string} props.bg - The background color of the progress bar
 * @param {string} props.pbg - The progress bar color
 * @param {string} props.pc - The progress bar text color
 * @param {boolean} props.showPercentage - Whether to show the percentage
 * @param {string} props.label - The label to show
 * @example
 * <Progress loaded={loaded} total={total} />
 * @returns {JSX.Element}
 *
 *
 */

const Progress = ({
  loaded,
  total,
  height = '17px',
  bg = '#ffffff26',
  pbg = 'linear-gradient(89.99deg, rgb(2 70 55) 0.01%, rgb(0 255 200) 99.99%)',
  pc = 'white',
  showPercentage = true,
  label = ''
}) => {
  if (!total || !loaded) return null;
  // Ensure the value is within the range [0, 100]
  const normalizedValue = Math.round((loaded / total) * 100);

  return (
    <>
      {label && <div className={styles['progress-label']}>{label}</div>}
      <div
        className={styles['progress']}
        style={{
          height,
          backgroundColor: bg
        }}
      >
        <div
          className={styles['progress-bar']}
          style={{ width: `${normalizedValue}%`, background: pbg, color: pc }}
        >
          {`${showPercentage ? normalizedValue + '%' : ''}`}
        </div>
      </div>
    </>
  );
};

export default Progress;
