import React, { Suspense, useState } from 'react';
import { useGetEventByIdQuery } from 'api/event';
import { Navigate, useParams } from 'react-router-dom';
import { getUserStatusSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { getDayMonthYearFormat, convertTimeToUserTimezone } from 'helpers/dateHandler';
import SidewaysProfile from 'components/atoms/SidewaysProfile/SidewaysProfile';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components/ui';
import { getS3Url } from 'helpers';
import styles from './SingleEvent.module.scss';
import { isVideoURL, isImageURL } from 'helpers';
import EventAttachmentModal from 'components/molecules/eventAttachmentModal/eventAttachmentModal';
import EventResponseModal from 'components/molecules/EventResponseModal/EventResponseModal';
import EventResponsesModal from 'components/molecules/EventResponsesModal/EventResponsesModal';
import CalendarCard from 'components/molecules/Calendar/Calendar';
import {
  getThisMonthsMeetings,
  getComparedProfileIdsFromArray,
  CurrentUserPartOfThisEvent,
  hasCurrentUserResponded,
  getUserResponse,
  getAttendanceComponent as GetAttendanceComponent,
  getWithinDisputePeriod
} from 'helpers/calendar';
import { useGetEventsQuery } from 'api/event';
import CreateEventModal from 'components/molecules/CreateEventModal/CreateEventModal';
import { ReactComponent as Edit } from 'assets/icons/editicon.svg';
import { ReactComponent as Add } from 'assets/icons/addicon.svg';
import SidewaysEvent from 'components/atoms/SidewaysEvent/SidewaysEvent';
import { getMonthNameShort } from 'helpers/dateHandler';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { copyCurrentPageURLToClipBoard } from 'helpers';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { handleAsync } from 'helpers';
import { useDeleteEventMutation } from 'api/event';
import { useTranslation } from 'react-i18next';
function SingleEvent() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [responsesModalOpen, setResponsesModalOpen] = useState(false);
  const [create, setCreate] = useState(false);
  const eventId = useParams().id;
  const navigate = useNavigate();
  const userStatus = useSelector(getUserStatusSelector);
  const profileId = userStatus?.profileStatus?.id;
  const { data, isLoading, isError, isSuccess, error } = useGetEventByIdQuery({ eventId });
  const [selectedAttachmentURL, setSelectedAttachmentURL] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { data: getEventsResult } = useGetEventsQuery({
    profileId,
    start: new Date(selectedDate).setMonth(Math.max(0, selectedDate.getMonth() - 2)),
    end: new Date(selectedDate).setMonth(Math.min(11, selectedDate.getMonth() + 2)),
    comparisons: getComparedProfileIdsFromArray()
  });
  const { t } = useTranslation();
  const [deleteEvent] = useDeleteEventMutation();
  async function deleteEventAndReturn(eventId) {
    const status = await handleAsync(deleteEvent({ eventId }).unwrap());
    if (status[0]) {
      showSuccessToast(t('Event Deleted'));
      navigate(-1);
    } else {
      showErrorToast(t('Something went wrong'));
    }
  }

  if (!data) {
    return <Loader fullpage />;
  }
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles.calendarSectionContainer}>
        {deleteModalOpen && (
          <DeleteModal
            title="Are you sure you want to delete this event?"
            handleDelete={() => {
              deleteEventAndReturn(data.id);
              setDeleteModalOpen(false);
            }}
            handleClose={() => {
              setDeleteModalOpen(false);
            }}
          />
        )}
        <EventAttachmentModal
          url={selectedAttachmentURL}
          setAttachmentModalVisible={setSelectedAttachmentURL}
          onClose={() => {
            setSelectedAttachmentURL('');
          }}
          open={selectedAttachmentURL != ''}
        />
        <EventResponseModal
          open={responseModalOpen}
          onClose={() => {
            setResponseModalOpen(false);
          }}
          onRespond={() => {
            setResponseModalOpen(false);
            //setSelectedEvent(undefined);
          }}
          dispute={data.mandatory}
          eventId={data.id}
        />
        <EventResponsesModal
          open={responsesModalOpen}
          onClose={() => {
            setResponsesModalOpen(false);
          }}
          translate={t}
          eventResponses={data.responses}
          participants={data.participants}
        />
        <div className={styles.headerBar}>
          <div className={styles.headerNavigation}>
            <div
              className={styles.calendarnavigator}
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('Back')}
            </div>
            <div
              className={styles.calendarnavigator}
              onClick={() => {
                copyCurrentPageURLToClipBoard();
                showSuccessToast('Link copied to clipboard!');
              }}
            >
              {t('Share')}
            </div>
            <div className={styles.navigatorSelected}>{t('Event')}</div>
          </div>
        </div>
        {data.host.id == profileId && (
          <div className={styles.headerButtons}>
            <Add
              className={styles.deleteEvent}
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            />
            <Edit className={styles.editEvent} onClick={() => setCreate(true)} />
          </div>
        )}
        <div className={styles.calendarTimelineContainer}>
          <div className={styles.calendarContainer}>
            <CalendarCard
              value={selectedDate}
              onChange={setSelectedDate}
              events={getEventsResult?.result}
              preventChange={true}
            />
            <div className={styles.upcomingMeetings}>
              <div className={styles.upcomingMeetingsButton}>{t('Upcoming Group Meetings')}</div>
              {getThisMonthsMeetings(getEventsResult).map((event, index) => {
                return (
                  <SidewaysEvent
                    timeString={`${convertTimeToUserTimezone(
                      new Date(event.startDate)
                    )}-${convertTimeToUserTimezone(new Date(event.endDate))}`}
                    fullName={event.name}
                    day={new Date(event.startDate).getDate()}
                    month={getMonthNameShort(new Date(event.startDate))}
                    key={event.id}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.eventPageContainer}>
            <div className={styles.eventHeaderContainer}>
              <h2>{`${data.name}`}</h2>
              <h6>{`${getDayMonthYearFormat(new Date(data.startDate))}`}</h6>
              <h6>{`${convertTimeToUserTimezone(
                new Date(data.startDate)
              )}-${convertTimeToUserTimezone(new Date(data.endDate))}`}</h6>
            </div>
            {CurrentUserPartOfThisEvent(data, profileId) ? (
              !hasCurrentUserResponded(data, profileId) ? (
                <div className={styles.responseSection}>
                  {data.mandatory && (
                    <div className={styles.disputeResponse}>
                      {getWithinDisputePeriod(data.disputeHours, data) ? (
                        <>
                          <span>
                            {t('This Event is mandatory a dispute can be filled')}{' '}
                            {data.disputeHours} {t('hours before the event starts')}
                          </span>
                          <span
                            className={styles.responseButton}
                            onClick={() => {
                              setResponseModalOpen(true);
                            }}
                          >
                            {t('Dispute')}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>{t('This event is mandatory, the dispute period is over.')}</span>
                        </>
                      )}
                    </div>
                  )}
                  {!data.mandatory && (
                    <div className={styles.eventResponse}>
                      <span>{t('Please respond with your attendance')}</span>
                      <span
                        className={styles.responseButton}
                        onClick={() => {
                          setResponseModalOpen(true);
                        }}
                      >
                        {t('Respond')}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.responseSection}>
                  <div className={styles.eventResponse}>
                    <span>
                      {t('You have responded to this event with')}:{' '}
                      {getUserResponse(profileId, data)}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <div className={styles.responseSection}>
                <div className={styles.eventResponse}>
                  <span>{t('You are not a part of this event')}</span>
                </div>
              </div>
            )}
            <div className={styles.detailsAndParticipants}>
              <div className={styles.details}>
                <div className={styles.description}>
                  <h3>{t('Description')}</h3>
                  <p>{data.description}</p>
                </div>
                <div className={styles.attachments}>
                  <h3>{t('Attachments')}</h3>
                  <div className={styles.attachmentsListed}>
                    {data.attachments.map((attachment) => {
                      if (isVideoURL(getS3Url(attachment)) || isImageURL(getS3Url(attachment))) {
                        return (
                          <button
                            onClick={() => {
                              setSelectedAttachmentURL(attachment);
                            }}
                            key={attachment}
                          >
                            {attachment.split('/')[1]}
                          </button>
                        );
                      } else {
                        return (
                          <a href={getS3Url(attachment)} key={attachment}>
                            {attachment.split('/')[1]}
                          </a>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className={styles.links}>
                  <h3>{t('Links')}</h3>
                  {data?.wizrConnectLink ? (
                    <a
                      href={data?.wizrConnectLink}
                      style={{ color: '#0058ff' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('Meeting Link')}
                    </a>
                  ) : (
                    <span>{t('Link Not provided')}</span>
                  )}
                </div>
              </div>
              <div className={styles.participants}>
                <h3>Participants</h3>
                <div className={styles.participantsListed}>
                  {data.participants.map((participant, index) => {
                    return (
                      <div className={styles.sideWaysProfileAttendance}>
                        <SidewaysProfile
                          fullName={`${participant.contact.firstName} ${participant.contact.lastName}`}
                          image={participant.picture}
                          role={participant.jobPosition}
                          key={participant.id}
                        />
                        <GetAttendanceComponent
                          attendanceType={getUserResponse(participant.id, data)}
                        ></GetAttendanceComponent>
                      </div>
                    );
                  })}
                  <div
                    className={styles.showResponsesButton}
                    onClick={() => {
                      setResponsesModalOpen(true);
                    }}
                  >
                    {t('Show responses')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateEventModal
          open={create}
          onClose={() => {
            setCreate(false);
          }}
          onSaveEvent={() => {
            setCreate(false);
          }}
          eventToBeEditted={data}
          t={t}
        />
      </div>
    </Suspense>
  );
}

export default SingleEvent;
