import {
  useDeleteAchievementMutation,
  useSaveAchievementMutation,
  useUpdateAchievementMutation
} from 'api/individual-profile';
import { handleAsync, removeObjectElements } from 'helpers';
import { useIsCurrentUserProfile } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';

function useAchievementUtils() {
  const [updateAchievement] = useUpdateAchievementMutation();
  const [saveAchievement, { isLoading: isSaving }] = useSaveAchievementMutation();
  const [deleteAchievement] = useDeleteAchievementMutation();
  const isCurrentUserProfile = useIsCurrentUserProfile();
  const userId = useSelector(getCurrentUserIdSelector);

  const onUpdateSubmit = async (achievement) => {
    const { id, ...rest } = achievement;
    const achievementDto = removeObjectElements(rest, ['img', 'userId']);
    await handleAsync(
      updateAchievement({
        userId,
        achievementId: id,
        achievement: achievementDto
      }).unwrap()
    );
  };
  const onDeleteSubmit = async (achievementId) => {
    const status = await handleAsync(
      deleteAchievement({
        userId,
        achievementId
      }).unwrap()
    );
    return status[0];
  };
  const onCreateSubmit = async (achievement) => {
    const status = await handleAsync(
      saveAchievement({
        userId,
        achievement: achievement
      }).unwrap()
    );
    return status[0];
  };
  return { onUpdateSubmit, onDeleteSubmit, onCreateSubmit, isCurrentUserProfile, isSaving };
}

export default useAchievementUtils;
