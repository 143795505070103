import React, { Suspense, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showInfoToast, showSuccessToast, showWarnToast } from 'utils/toast';
import {
  useCreateSharableProfilesLinkMutation,
  useGetProfilesByCompanyIdQuery
} from 'api/individual-profile';
import { useCreateTeamMutation } from 'api/team';
import CreateEventModal from 'components/molecules/CreateEventModal/CreateEventModal';
import EmployeeCard from 'components/molecules/EmployeeCard/EmployeeCard';
import EmployeeIcons from 'components/molecules/EmployeeIcons/EmployeeIcons';
import FilterSection from 'components/molecules/FilterSection/FilterSection';
import TeamModal from 'components/molecules/TeamModal/TeamModal';
import { Button, Loader, ProfileSmallCard } from 'components/ui';
import ShareProfileModal from 'components/ui/ShareProfileModal/ShareProfileModal';
import { getProfilesQueryFilter, setProfilesQueryFilter } from 'store/profile.slice';
import {
  getCurrentCompanyIdSelector,
  getIsAdminSelector,
  getUserStatusSelector,
  getIsUserTouringSelector,
  setTouring
} from 'store/login.slice';
import { getAppUrl, isSharedMode, prepareProfilesQuery, handleAsync, getS3Url } from 'helpers';
import { useDebounce } from 'hooks';
import usePagination from 'hooks/usePagination';
import useGetSharedProfiles from './hooks/useGetSharedProfiles';
import { ResultShareProfileModal } from '../Profile/sections';
import styles from './Employees.module.scss';
import routes from 'routes';
import useGetMenuFilters from './hooks/useGetMenuFilters';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import Joyride from 'react-joyride';
import { debounce } from 'lodash';
import { mailToEmail } from 'helpers/general';
import { useTour } from '@reactour/tour';
import { checkTeamCreationLimit } from 'helpers/tier';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';
import { useTranslation } from 'react-i18next';
import { CHAT_CLIENT_URL, CHAT_SERVER_URL } from 'utils/constans';

const Employees = ({ isTeam }) => {
  //Constants
  const { tierData, refetch: refetchForTierUpdate } = useGetUserTierData();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createTeamClicked] = useState(false);
  const cachedProfilesFilter = useSelector(getProfilesQueryFilter);
  const isTouring = useSelector(getIsUserTouringSelector);
  const { profileStatus } = useSelector(getUserStatusSelector);
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const isAdmin = useSelector(getIsAdminSelector);
  const { currentStep, setCurrentStep } = useTour();
  const steps = [
    {
      content: <h2>{t('welcome-to-wizrx-individual-profiles')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'center',
      target: 'body'
    },
    {
      content: <h2>{t('you-can-use-the-options-here')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.JoyridebannerNavigation'
    },
    {
      content: <h2>{t('the-filters-here-can-be-used')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.JoyridefilterContainer'
    },
    {
      content: <h2>{t('if-you-have-the-required-permissions')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.JoyrideTeamBtn'
    },
    {
      content: <h2>{t('finally-you-can-checkout-your-workers')}</h2>,
      locale: { skip: <strong aria-label="skip">{t('Skip')}</strong> },
      placement: 'bottom',
      target: '.joyrideEmployees'
    }
  ];
  const [teamCreatorIncludedCheck, seTeamCreatorIncludedCheck] = React.useState(null);

  const handleSaveTeam = debounce(async ({ createDto, isCreatorIncluded }) => {
    // check tier limits
    const [statusFlag, message] = checkTeamCreationLimit(tierData);
    if (!statusFlag) {
      showErrorToast(t(message));
      return;
    }
    // check if the name is not provided
    if (!createDto.name) {
      showWarnToast(t('provide-a-name-for-the-team'));
      setIconsActions({ ...iconsActions, isCreateTeam: true });
      return;
    }
    const teamDto = {
      ...createDto,
      companyId,
      profileIds: isCreatorIncluded
        ? [...selectedProfilesIds, profileStatus.id]
        : selectedProfilesIds,
      profileId: profileStatus.id
    };
    const status = await handleAsync(createTeam({ team: teamDto }).unwrap());
    if (status[0]) {
      refetchForTierUpdate();
      setSelectedEmployeesData([]);
      // setIconsActions({ ...iconsActions, isCreateTeam: false });
      showSuccessToast(t('Team created successfully'));
      navigate('/teams');
    } else {
      showErrorToast(t('Team creation failed'));
    }
  }, 300);

  const iconActionsDefaultObject = {
    isCreateTeam: false,
    isShareProfiles: false,
    isEventModal: false
  };
  const resultShareDefaultObject = {
    open: false,
    shareLink: ''
  };

  //UseStates
  const [index, setIndex] = useState(2);
  const [selectedEmployeesData, setSelectedEmployeesData] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [iconsActions, setIconsActions] = useState(iconActionsDefaultObject);
  const [profilesFilter, setProfilesFilter] = useState('');
  const [searchNameQuery, setSearchNameQuery] = useState('');
  const [createTeam, { isLoading: isCreatingTeam }] = useCreateTeamMutation();
  const [resultShare, setResultShare] = useState(resultShareDefaultObject);
  const [createSharableProfilesLink, { isLoading: isShareProfilesLoading }] =
    useCreateSharableProfilesLinkMutation();
  const extraFilters = useGetMenuFilters();
  const { rtkResult, setPage, paginationResult } = usePagination(
    useGetProfilesByCompanyIdQuery,
    {
      companyId,
      size: window.innerWidth > 1800 ? 28 : 21,
      query: prepareProfilesQuery(profilesFilter),
      projection: 'contact picture availability'
    },
    {
      skip: isSharedMode()
    }
  );
  const [result, passKey] = useGetSharedProfiles();
  const { data, isLoading, isFetching, isSuccess, isError, error } = isSharedMode()
    ? result
    : rtkResult;
  useDebounce(
    () => {
      dispatch(
        setProfilesQueryFilter({
          ...profilesFilter,
          regex: { 'contact.firstName': searchNameQuery, 'contact.lastName': searchNameQuery }
        })
      );
    },
    300,
    [searchNameQuery]
  );

  useEffect(() => {
    setPage(1);
    setProfilesFilter(cachedProfilesFilter);
  }, [cachedProfilesFilter]);

  useEffect(() => {
    dispatch(setProfilesQueryFilter(''));
  }, []);

  const selectedProfilesIds = useMemo(() => {
    return selectedEmployeesData.map((p) => p.id);
  }, [selectedEmployeesData]);

  const addOrRemoveEmployeeId = (employeeId) => {
    if (!selectedProfilesIds.includes(employeeId)) {
      const employeeData = paginationResult.find((item) => item.id === employeeId);
      setSelectedEmployeesData([...selectedEmployeesData, employeeData]);
    } else {
      setSelectedEmployeesData(selectedEmployeesData.filter((item) => item.id !== employeeId));
    }
  };

  const searchEmployees = (textInput) => {
    setSearchNameQuery(textInput?.trim());
  };

  const handleEmailIconClick = () => {
    if (selectedProfilesIds.length > 0) {
      const emails = selectedEmployeesData.map((profile) => profile.contact.email);
      const emailString = emails.join(';');
      mailToEmail(emailString);
    } else {
      showWarnToast(t('Please select at least one profile'));
    }
  };
  const handleChatIconClick = async () => {
    const IAmSelected = selectedProfilesIds.some((id) => id === profileStatus.id);
    showInfoToast(t(`Redirecting to Wizr Chat`));
    try {
      const createChat = await fetch(`${CHAT_SERVER_URL}/chats/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          creator: profileStatus.id,
          type: selectedProfilesIds.length > 2 ? 'group' : 'message',
          profileIds: IAmSelected
            ? selectedProfilesIds
            : [...selectedProfilesIds, profileStatus.id],
          companyId: profileStatus.company,
          description: '',
          name: '',
          picture: ''
        })
      });
      if (createChat.ok) {
        const chat = await createChat.json();
        if (chat) window.open(`${CHAT_CLIENT_URL}/chat/${chat._id}`, '_blank');
      }
    } catch (error) {
      showErrorToast(t(`${error}`), { delay: 2000 });
    }
  };
  const handleSetIconActions = (id) => {
    if (id === 'share') {
      const userIsLiteTier = tierData?.tier === APP_TIERS.LITE;
      if (userIsLiteTier) {
        showWarnToast(t('This feature is not available in Lite Tier'));
        return;
      }
      return setIconsActions({ ...iconsActions, isShareProfiles: true });
    }

    switch (id) {
      case 'team':
        setIconsActions({ ...iconsActions, isCreateTeam: true });
        break;
      case 'schedule':
        setIconsActions({ ...iconsActions, isEventModal: true });
        break;
      case 'cancelSchedule':
        setIconsActions({ ...iconsActions, isEventModal: false });
        break;
      case 'email':
        handleEmailIconClick();
        break;

      case 'chat':
        handleChatIconClick();
        break;

      default:
        break;
    }
  };
  const getOptions = () => {
    const isMeOnlySelected =
      selectedEmployeesData.length === 1 && selectedEmployeesData[0].id === profileStatus.id;
    if (isMeOnlySelected) return ['share'];
    return isAdmin ? ['all', '-remove'] : ['all', '-remove', '-team'];
  };

  if (isLoading) {
    return <Loader fullpage />;
  } else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const profilesResult = isSharedMode() ? data?.sharedProfiles : paginationResult;
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      {!isSharedMode() && (
        <>
          <div className={styles.mainBanner}>
            <h1 className={styles.bannerTitle}>
              {t('Individual')} <br /> {t('Profiles')}
            </h1>
          </div>
        </>
      )}
      <div
        className={
          isSharedMode()
            ? `${styles.sharedSection} ${styles.employeesContainer}`
            : styles.employeesContainer
        }
      >
        {isSharedMode() && (
          <div className={styles.sharedBanner}>
            <h1>{t('Dedicated Collaborative Professionals')}</h1>
          </div>
        )}
        {!isSharedMode() && (
          <FilterSection
            {...{
              index,
              extraFilters,
              icons: true,
              setIndex,
              extraClass: isTeam ? 'absolute' : '',
              openDropdown,
              setOpenDropdown,
              searchEmployees
            }}
          />
        )}
        <>
          {isAdmin && (
            <>
              <p
                className={
                  selectedEmployeesData.length < 2 && createTeamClicked
                    ? styles.notselectedProfiles
                    : styles.selectedProfiles
                }
              >
                Select 2 or more profiles to create a team.
              </p>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
            className={
              index === 1
                ? `${styles.employeesSection} ${styles.expandedSection} joyrideEmployees`
                : index === 0
                ? `${styles.employeesSection} ${styles.listSection} joyrideEmployees`
                : `${styles.employeesSection} joyrideEmployees`
            }
          >
            {selectedEmployeesData.length !== 0 && !isSharedMode() && (
              <>
                <div className="employee-options">
                  <EmployeeIcons
                    // selectedEmployeesData.length === 1  && selectedEmployeesData[0].id === profileStatus.id
                    displayOptions={getOptions()}
                    onIconClicked={handleSetIconActions}
                    selectedEmployeesLength={selectedEmployeesData.length}
                  />
                </div>
              </>
            )}
            <div className={styles['selected-profiles']}>
              {selectedEmployeesData.map((profile) => (
                <ProfileSmallCard
                  key={profile.key}
                  contact={profile.contact}
                  picture={getS3Url(profile.picture)}
                  onRemoveClicked={() => addOrRemoveEmployeeId(profile.id)}
                />
              ))}
            </div>

            <div
              className={
                index === 2
                  ? styles.gridCards
                  : index === 1
                  ? `${styles.gridCards} ${styles.gridExpanded}`
                  : ''
              }
            >
              {profilesResult?.map((profile) => {
                return (
                  <EmployeeCard
                    showContactButton
                    showProfileButton
                    key={profile.id}
                    extraClass={
                      createTeamClicked
                        ? index === 0
                          ? 'list-section teamActive'
                          : 'teamActive'
                        : index === 0
                        ? `list-card`
                        : `grid-card`
                    }
                    isList={index === 0}
                    {...profile}
                    id={profile.id}
                    selectedEmployeesIds={selectedProfilesIds}
                    clickEvent={addOrRemoveEmployeeId}
                    passKey={passKey}
                  />
                );
              })}

              {isFetching && <Loader />}
            </div>
          </div>
          <CreateEventModal
            open={iconsActions.isEventModal}
            onClose={() => handleSetIconActions('cancelSchedule')}
            onSaveEvent={() => {
              handleSetIconActions('cancelSchedule');
              navigate('/calendar');
            }}
            selectedSlot={new Date()}
            t={t}
            preSelectedProfileIds={selectedProfilesIds}
          />
          {teamCreatorIncludedCheck && (
            <CreateModal
              guidedButton
              textCancel="No"
              textSave="Yes"
              isSubmitting={isCreatingTeam}
              extraClass="employees-popup"
              clickEvent={() => {
                handleSaveTeam({
                  createDto: teamCreatorIncludedCheck.createDto,
                  isCreatorIncluded: true
                });
                seTeamCreatorIncludedCheck(null);

                setCurrentStep(currentStep + 1);
              }}
              //handle close represents the no button
              handleClose={() => {
                seTeamCreatorIncludedCheck(null);
                setIconsActions({ ...iconsActions, isCreateTeam: false });
                handleSaveTeam({
                  createDto: teamCreatorIncludedCheck.createDto
                });
              }}
            >
              <h3 className={styles.teamPart}>{t('Would you like to be part of the team')}</h3>
            </CreateModal>
          )}
          <TeamModal
            isSubmitting={isCreatingTeam}
            openTeamModal={iconsActions.isCreateTeam}
            onClose={() => setIconsActions({ ...iconsActions, isCreateTeam: false })}
            selectedEmployees={selectedEmployeesData}
            onRemoveClicked={(id) => {
              setSelectedEmployeesData(selectedEmployeesData.filter((item) => item.id !== id));
              if (selectedEmployeesData.length - 1 === 1)
                setIconsActions({ ...iconsActions, isCreateTeam: false });
            }}
            handleSave={async (createDto) => {
              if (createDto.name.trim().length == 0) {
                showErrorToast(t("Team name can't be made up of spaces"));
                return;
              }
              const isCreatorIncluded = selectedProfilesIds.includes(profileStatus.id);
              if (!isCreatorIncluded) {
                seTeamCreatorIncludedCheck({ createDto });
                setIconsActions({ ...iconsActions, isCreateTeam: false });
              } else {
                handleSaveTeam({ createDto });
              }
            }}
          />

          <ShareProfileModal
            openShareModal={iconsActions.isShareProfiles}
            onProfileRemove={({ id }) => {
              setSelectedEmployeesData(selectedEmployeesData.filter((item) => item.id !== id));
              if (selectedEmployeesData.length === 1)
                setIconsActions({ ...iconsActions, isShareProfiles: false });
            }}
            onClose={() => setIconsActions({ ...iconsActions, isShareProfiles: false })}
            selectedEmployees={selectedEmployeesData}
            onSubmit={async (shareDto) => {
              const status = await handleAsync(
                createSharableProfilesLink({
                  sharingData: {
                    ...shareDto,
                    sharedBy: profileStatus?.id,
                    sharedProfiles: selectedProfilesIds
                  }
                }).unwrap()
              );
              if (status[0]) {
                setIconsActions({ ...iconsActions, isShareProfiles: false });
                const result = status[1];
                const { slug } = result;
                const profilePagePath = routes.find((route) => route.name === 'All Profiles').path;
                setResultShare({
                  ...resultShare,
                  open: true,
                  shareLink: `${getAppUrl()}${profilePagePath}?slug=${slug}`
                });
              }
            }}
          />
          <ResultShareProfileModal
            value={resultShare.shareLink}
            open={resultShare.open}
            onClose={() => {
              setResultShare({ ...resultShare, open: false });
            }}
          />
        </>
      </div>
      <Joyride
        callback={(data) => {
          if (data?.action == 'reset') {
            dispatch(setTouring(false));
          }
        }}
        continuous
        hideCloseButton
        run={isTouring}
        scrollToFirstStep
        scrollOffset={400} //Size of largest modal
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            arrowColor: 'ffffff',
            backgroundColor: 'rgb(13, 13, 13)',
            overlayColor: 'rgb(13, 13, 13,0.5)',
            primaryColor: 'rgb(11, 137, 110)',
            textColor: 'rgb(207, 207, 207)',
            zIndex: 1000
          }
        }}
      />
    </Suspense>
  );
};
export default Employees;

Employees.propTypes = {
  isTeam: PropTypes.bool
};
