import React, { Suspense, useState } from 'react';
import ChatBody from 'components/molecules/ChatBody/ChatBody';
import ChatNewGroupModal from 'components/molecules/ChatNewGroupModal/ChatNewGroupModal';
import ChatSideMenu from 'components/molecules/ChatSideMenu/ChatSideMenu';
import styles from './styles.module.scss';
import { Loader } from 'components/ui';

export default function Chat() {
  const [openGroupModal, setGroupModal] = useState(false);

  const handleGroupOpen = () => {
    setGroupModal(true);
  };
  const handleGroupClose = () => {
    setGroupModal(false);
  };

  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles.chatcontainer}>
        <ChatSideMenu openAddGroupModal={handleGroupOpen} />
        <ChatBody />
        {openGroupModal && <ChatNewGroupModal closeAddGroupModal={handleGroupClose} />}
      </div>
    </Suspense>
  );
}
