import React, { useEffect } from 'react';

function useScrollToItem(itemId, condition = false) {
  useEffect(() => {
    const item = document.getElementById(itemId);
    if (item && condition) {
      item.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [condition]);
}

export default useScrollToItem;
