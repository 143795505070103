import { apiSlice } from 'api/apiSlice';
// represent the controller
const secondaryUrl = `badges`;

export const badgesExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSkillsByKeyword: builder.query({
      query: ({ keyword, rtkMethodQueries }) => ({
        url: `/v1/${secondaryUrl}/skills/${keyword ? keyword : 'most-used'}${
          rtkMethodQueries ? `?${rtkMethodQueries}` : ''
        }`
      }),
      keepUnusedDataFor: 10 * 60
    }),
    getInterestsByKeyword: builder.query({
      query: ({ keyword }) => ({
        url: `/v1/${secondaryUrl}/interests/${keyword ? keyword : 'most-used'}`
      }),
      keepUnusedDataFor: 10 * 60
    }),
    getToolsByKeyword: builder.query({
      query: ({ keyword }) => ({
        url: `/v1/${secondaryUrl}/tools/${keyword ? keyword : 'most-used'}`
      }),
      keepUnusedDataFor: 10 * 60
    })
  })
});
export const {
  useGetSkillsByKeywordQuery,
  useGetInterestsByKeywordQuery,
  useGetToolsByKeywordQuery
} = badgesExtendedSlice;
