import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { deleteEducationInListInQuery, updateEducationInListInQuery } from './util';
import { addObjectToArrayIfNotExist } from 'helpers';
// represent the controller
const secondaryUrl = `education`;

export const educationExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteEducation: builder.mutation({
      query: ({ educationId }) => ({
        url: `/v1/${secondaryUrl}/${educationId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ educationId, userId }, { queryFulfilled }) {
        const deleteResult = deleteEducationInListInQuery({
          educationId,
          queryName: 'getEducationByUserId',
          params: { userId }
        });

        await optimisticUpdater(deleteResult, queryFulfilled);
      },
      invalidatesTags: () => [{ type: 'Education', id: 'LIST' }]
    }),
    updateEducation: builder.mutation({
      query: ({ educationId, education }) => ({
        url: `/v1/${secondaryUrl}/${educationId}`,
        method: 'PATCH',
        body: education
      }),
      // invalidatesTags: (result, error, { educationId }) =>
      //   result ? [{ type: 'Education', id: educationId }] : [{ type: 'Education', id: 'LIST' }],
      // ======================= OPTIMISTIC UPDATE on education =======================
      async onQueryStarted({ educationId, education, userId }, { dispatch, queryFulfilled }) {
        const patchResult = updateEducationInListInQuery({
          educationId,
          education,
          queryName: 'getEducationByUserId',
          params: { userId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Education', id: 'LIST' }]));
        });
      }
    }),

    saveEducation: builder.mutation({
      query: ({ education }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: education
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdEducData) => {
            if (!createdEducData) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getEducationByUserId',
                {
                  userId
                },
                (draft) => {
                  draft.result.unshift(createdEducData);
                }
              )
            );
          }
        );
      }
    }),
    getEducationByUserId: builder.query({
      query: ({ userId, page = 1, size = 3 }) => ({
        url: `/v1/${secondaryUrl}/user/${userId}?page=${page}&size=${size}&sort=from,desc`
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'Education', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = result.result.map((item) => ({ type: 'Education', id: item.id }));
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { userId } = queryArgs;
        return `${endpointName}({"userId":"${userId}"})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a Education, the created Education is added to the cache, and the last Education
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new Education
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    })
  })
});

export const {
  useDeleteEducationMutation,
  useUpdateEducationMutation,
  useSaveEducationMutation,
  useGetEducationByUserIdQuery
} = educationExtendedSlice;
