import { showErrorToast } from 'utils/toast';
import {
  useDeleteProjectMutation,
  useSaveProjectMutation,
  useUpdateProjectMutation
} from 'api/individual-profile';
import { handleAsync, removeObjectElements } from 'helpers';
import { useIsCurrentUserProfile, useUploadBlobs } from 'hooks';
import { useState } from 'react';

function useProjectUtils({ userId }) {
  const isCurrentUserProfile = useIsCurrentUserProfile();
  const [updateProject, { isLoading: isUpdating }] = useUpdateProjectMutation();
  const [saveProject, { isLoading: isSaving }] = useSaveProjectMutation();
  const [deleteProject, { isLoading: isDeleting }] = useDeleteProjectMutation();
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const { uploadBlobs } = useUploadBlobs();
  async function handelUpload(file) {
    if (!file.type.includes('image')) {
      showErrorToast('Please upload an image file');
      return;
    }
    return handleMediaUpload(file);
  }

  const handleMediaUpload = async (imageFile) => {
    if (!imageFile) return;

    // upload the video
    const [status, response] = await handleAsync(uploadBlobs([imageFile], 'project_cover'));
    const keys = response?.keys ?? [];

    // check if the media was uploaded
    if (!status) {
      const error = typeof response === 'string' ? response : 'Error uploading image';
      return showErrorToast(error);
    }
    return keys[0];
  };
  const onUpdateSubmit = async (project) => {
    const { tools } = project;
    if (tools && tools.length > 20) {
      showErrorToast('You can add up to 20 tools');
      return false;
    }
    const { id, ...rest } = project;
    const projectDto = removeObjectElements(rest, ['userId']);
    await handleAsync(
      updateProject({
        userId,
        projectId: id,
        project: projectDto
      }).unwrap(),
      'Project updated successfully',
      'Failed to update project'
    );
  };
  const onDeleteSubmit = async (id) => {
    const status = await handleAsync(
      deleteProject({
        userId,
        projectId: id
      }).unwrap(),
      'Project deleted successfully',
      'Failed to delete project'
    );
    return status[0];
  };

  const onCreateSubmit = async (project) => {
    const { tools } = project;
    if (tools && tools.length > 30) {
      showErrorToast('You can add up to 20 tools');
      return false;
    }
    const status = await handleAsync(
      saveProject({ userId, project }).unwrap(),
      'Project created successfully',
      'Failed to create project'
    );
    return status[0];
  };
  return {
    onDeleteSubmit,
    onUpdateSubmit,
    onCreateSubmit,
    isSaving,
    isUpdating,
    isDeleting,
    isCurrentUserProfile,
    edit,
    setEdit,
    create,
    setCreate,
    handelUpload,
    handleMediaUpload
  };
}

export default useProjectUtils;
