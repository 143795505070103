import React from 'react';
import { useSlate } from 'slate-react';
import { Button, Icon } from './Components';
import { isBlockActive, toggleBlock } from './Helpers';
import { TEXT_ALIGN_TYPES } from './constants';
import styles from './MWEditor.module.scss';
import { BasicTooltip } from '../Tooltip/Tooltip';
const BlockButton = ({ format, icon, tooltip, clickEvent }) => {
  const editor = useSlate();
  return (
    <div data-tooltip-id={`${tooltip}-tooltip`}>
      <Button
        active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        onClick={clickEvent}
      >
        <div className={styles.iconWrapper} data-tooltip-id={'my-tooltip-styles'}>
          <Icon>{icon}</Icon>
        </div>

        <BasicTooltip id={`${tooltip}-tooltip`} variant={'dark'} content={tooltip} />
      </Button>
    </div>
  );
};

export default BlockButton;
