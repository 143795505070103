import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { deletePublicationInListInQuery, updatePublicationInListInQuery } from './util';
import { addObjectToArrayIfNotExist } from 'helpers';

const secondaryUrl = 'publication';

export const publicationExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicationsByUserId: builder.query({
      query: ({ userId, page = 1, size = 3 }) => ({
        url: `/v1/${secondaryUrl}/user/${userId}?page=${page}&size=${size}&sort=date,desc`
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'Publication', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = result.result.map((item) => ({ type: 'Publication', id: item.id }));
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { userId } = queryArgs;
        return `${endpointName}({"userId":"${userId}"})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a Publication, the created Publication is added to the cache, and the last Publication
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new Publication
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    }),
    deletePublication: builder.mutation({
      query: ({ publicationId }) => ({
        url: `/v1/${secondaryUrl}/${publicationId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ publicationId, userId }, { queryFulfilled }) {
        const deleteResult = deletePublicationInListInQuery({
          publicationId,
          queryName: 'getPublicationsByUserId',
          params: { userId }
        });

        await optimisticUpdater(deleteResult, queryFulfilled);
      },
      invalidatesTags: () => [{ type: 'Publication', id: 'LIST' }]
    }),

    updatePublication: builder.mutation({
      query: ({ publicationId, publication }) => ({
        url: `/v1/${secondaryUrl}/${publicationId}`,
        method: 'PATCH',
        body: publication
      }),
      async onQueryStarted({ publicationId, publication, userId }, { dispatch, queryFulfilled }) {
        const patchResult = updatePublicationInListInQuery({
          publication,
          publicationId,
          queryName: 'getPublicationsByUserId',
          params: { userId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Publication', id: 'LIST' }]));
        });
      }
    }),

    savePublication: builder.mutation({
      query: ({ publication }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: publication
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdPublicationData) => {
            if (!createdPublicationData) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getPublicationsByUserId',
                {
                  userId
                },
                (draft) => {
                  draft.result.unshift(createdPublicationData);
                }
              )
            );
          }
        );
      }
    })
  })
});

export const {
  useGetPublicationsByUserIdQuery,
  useDeletePublicationMutation,
  useUpdatePublicationMutation,
  useSavePublicationMutation
} = publicationExtendedSlice;
