import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalEdit: false,
  sharingPassKey: '',
  notificationMenu: false
};

const individualProfile = createSlice({
  name: 'individualProfile',
  initialState,
  reducers: {
    setGlobalEdit(state, action) {
      state.globalEdit = action.payload;
    },
    setSharingPassKey(state, action) {
      state.sharingPassKey = action.payload;
    },
    setNotificationMenu(state, action) {
      state.notificationMenu = action.payload;
    }
  }
});

export const { setGlobalEdit, setSharingPassKey, setNotificationMenu } = individualProfile.actions;
export default individualProfile.reducer;
export const getGlobalEditSelector = (state) => state.individualProfile.globalEdit;
export const getSharingPassKeySelector = (state) => state.individualProfile.sharingPassKey;
export const getNotificationMenuSelector = (state) => state.individualProfile.notificationMenu;
