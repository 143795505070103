import { optimisticUpdater } from 'api/utils';
import { apiSlice } from '../apiSlice';
import { store } from '../../store';

// represent the controller
const secondaryUrl = `Info-Hub`;

export const infoHubExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPracticalGuides: builder.query({
      query: ({ companyId }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}`
      }),
      providesTags: ['Info-Hub']
    }),
    getInfoHub: builder.query({
      query: ({ companyId }) => ({
        url: `/v1/${secondaryUrl}/company/${companyId}`
      }),
      providesTags: ['Info-Hub']
    }),
    updatePracticalGuide: builder.mutation({
      query: ({ guide, companyId }) => ({
        url: `/v1/${secondaryUrl}/practical-guides/company/${companyId}`,
        method: 'PATCH',
        body: guide
      }),
      invalidatesTags: ['Info-Hub']
    }),
    updateFeaturedVideos: builder.mutation({
      query: ({ featuredVideos, companyId }) => ({
        url: `/v1/${secondaryUrl}/featured-videos/company/${companyId}`,
        method: 'PATCH',
        body: featuredVideos
      }),
      invalidatesTags: ['Info-Hub']
    }),
    updateMajorPresentations: builder.mutation({
      query: ({ dataToBeSaved, companyId }) => ({
        url: `/v1/${secondaryUrl}/majorPresentations/company/${companyId}`,
        method: 'PATCH',
        body: dataToBeSaved
      }),
      invalidatesTags: ['Info-Hub']
    }),
    updateMinorPresentations: builder.mutation({
      query: ({ dataToBeSaved, companyId }) => ({
        url: `/v1/${secondaryUrl}/minorPresentations/company/${companyId}`,
        method: 'PATCH',
        body: dataToBeSaved
      }),
      invalidatesTags: ['Info-Hub']
    }),
    updateSpecialities: builder.mutation({
      query: ({ dataToBeSaved, companyId }) => ({
        url: `/v1/${secondaryUrl}/specialties/company/${companyId}`,
        method: 'PATCH',
        body: dataToBeSaved
      }),
      invalidatesTags: ['Info-Hub']
    }),
    updateProfileBasedTextBlock: builder.mutation({
      query: ({ ProfileBasedTextBlock, companyId }) => ({
        url: `/v1/${secondaryUrl}/profileBasedTextBlock/company/${companyId}`,
        method: 'PATCH',
        body: ProfileBasedTextBlock
      }),
      invalidatesTags: ['Info-Hub']
    }),
    updateMoreRecommended: builder.mutation({
      query: ({ moreRecommended, companyId }) => ({
        url: `/v1/${secondaryUrl}/more-recommended/company/${companyId}`,
        method: 'PATCH',
        body: moreRecommended
      }),
      invalidatesTags: ['Info-Hub']
    }),
    createCompanyPortal: builder.mutation({
      query: ({ portal, companyId }) => ({
        url: `/v1/${secondaryUrl}/portal/company/${companyId}`,
        method: 'POST',
        body: portal
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (portals) => {
            if (!portals) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getInfoHub',
                {
                  companyId
                },
                (draft) => {
                  Object.assign(draft?.info?.portals, portals);
                }
              )
            );
          }
        );
      }
    }),

    createCompanyReport: builder.mutation({
      query: ({ report, companyId }) => ({
        url: `/v1/${secondaryUrl}/report/company/${companyId}`,
        method: 'POST',
        body: report
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (reports) => {
            if (!reports) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getInfoHub',
                {
                  companyId
                },
                (draft) => {
                  Object.assign(draft?.info?.reports, reports);
                }
              )
            );
          }
        );
      }
    }),

    updateCompanyReport: builder.mutation({
      query: ({ report, companyId, reportId }) => ({
        url: `/v1/${secondaryUrl}/report/company/${companyId}/${reportId}`,
        method: 'PATCH',
        body: report
      }),
      async onQueryStarted({ companyId, reportId, report }, { queryFulfilled }) {
        const updateResult = store.dispatch(
          apiSlice.util.updateQueryData('getInfoHub', { companyId }, (draft) => {
            const itemReports = draft?.info?.reports;
            const toBeUpdatedReport = itemReports.find((rep) => rep.id === reportId);
            if (!toBeUpdatedReport) return;
            toBeUpdatedReport.link = report.link;
            toBeUpdatedReport.thumbnail = report.thumbnail;
          })
        );
        await optimisticUpdater(updateResult, queryFulfilled);
      }
    }),

    updateCompanyPortal: builder.mutation({
      query: ({ portal, companyId, portalId }) => ({
        url: `/v1/${secondaryUrl}/portal/company/${companyId}/${portalId}`,
        method: 'PATCH',
        body: portal
      }),
      async onQueryStarted({ companyId, portalId, portal }, { queryFulfilled }) {
        const updateResult = store.dispatch(
          apiSlice.util.updateQueryData('getInfoHub', { companyId }, (draft) => {
            const itemPortals = draft?.info?.portals;
            const toBeUpdatedPortal = itemPortals.find((rep) => rep.id === portalId);
            if (!toBeUpdatedPortal) return;
            toBeUpdatedPortal.link = portal.link;
            toBeUpdatedPortal.thumbnail = portal.thumbnail;
          })
        );
        await optimisticUpdater(updateResult, queryFulfilled);
      }
    }),

    deleteCompanyPortal: builder.mutation({
      query: ({ portalId, companyId }) => ({
        url: `/v1/${secondaryUrl}/portal/${portalId}/company/${companyId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ companyId, portalId }, { queryFulfilled }) {
        const deleteResult = store.dispatch(
          apiSlice.util.updateQueryData('getInfoHub', { companyId }, (draft) => {
            if (!draft) return;
            const removedItemPortals = draft?.info?.portals?.filter(
              (portal) => portal?.id !== portalId
            );
            if (!removedItemPortals) return;
            Object.assign(draft?.info, { portals: removedItemPortals });
          })
        );
        await optimisticUpdater(deleteResult, queryFulfilled);
      }
    }),
    deleteCompanyReport: builder.mutation({
      query: ({ reportId, companyId }) => ({
        url: `/v1/${secondaryUrl}/report/${reportId}/company/${companyId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ companyId, reportId }, { queryFulfilled }) {
        const deleteResult = store.dispatch(
          apiSlice.util.updateQueryData('getInfoHub', { companyId }, (draft) => {
            if (!draft) return;
            const removedItemReports = draft?.info?.reports?.filter(
              (report) => report?.id !== reportId
            );
            if (!removedItemReports) return;
            Object.assign(draft?.info, { reports: removedItemReports });
          })
        );
        await optimisticUpdater(deleteResult, queryFulfilled);
      }
    }),
    // TODO: we can remove the companyId form here, and always get it from the store
    updateDefaultPortal: builder.mutation({
      query: ({ companyId, defaultPortal }) => ({
        url: `/v1/${secondaryUrl}/default-portal/company/${companyId}`,
        method: 'PATCH',
        body: defaultPortal
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (infoHubObj) => {
            if (!infoHubObj) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getInfoHub',
                {
                  companyId
                },
                (draft) => {
                  Object.assign(draft?.info, infoHubObj?.info);
                }
              )
            );
          }
        );
      }
    }),
    updateInfohubBackground: builder.mutation({
      query: ({ companyId, backgroundImage }) => ({
        url: `/v1/${secondaryUrl}/background/company/${companyId}`,
        method: 'PATCH',
        body: backgroundImage
      }),
      async onQueryStarted({ companyId, backgroundImage }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          () => {
            dispatch(
              apiSlice.util.updateQueryData(
                'getInfoHub',
                {
                  companyId
                },
                (draft) => {
                  draft.info.backgroundImage = backgroundImage.url;
                }
              )
            );
          }
        );
      }
    }),

    createCompanyDocument: builder.mutation({
      query: ({ document, companyId }) => ({
        url: `/v1/${secondaryUrl}/document/company/${companyId}`,
        method: 'POST',
        body: document
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (documents) => {
            if (!documents) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getInfoHub',
                {
                  companyId
                },
                (draft) => {
                  Object.assign(draft?.info?.documents, documents);
                }
              )
            );
          }
        );
      }
    }),
    updateCompanyDocument: builder.mutation({
      query: ({ document, companyId, documentId }) => ({
        url: `/v1/${secondaryUrl}/document/company/${companyId}/${documentId}`,
        method: 'PATCH',
        body: document
      }),
      async onQueryStarted({ companyId, documentId, document }, { queryFulfilled }) {
        const updateResult = store.dispatch(
          apiSlice.util.updateQueryData('getInfoHub', { companyId }, (draft) => {
            const itemDocuments = draft?.info?.documents;
            const toBeUpdatedDocument = itemDocuments.find((rep) => rep.id === documentId);
            if (!toBeUpdatedDocument) return;
            toBeUpdatedDocument.link = document.link;
            toBeUpdatedDocument.thumbnail = document.thumbnail;
          })
        );
        await optimisticUpdater(updateResult, queryFulfilled);
      }
    }),
    deleteCompanyDocument: builder.mutation({
      query: ({ documentId, companyId }) => ({
        url: `/v1/${secondaryUrl}/document/${documentId}/company/${companyId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ companyId, documentId }, { queryFulfilled }) {
        const deleteResult = store.dispatch(
          apiSlice.util.updateQueryData('getInfoHub', { companyId }, (draft) => {
            if (!draft) return;
            const removedItemDocuments = draft?.info?.documents?.filter(
              (document) => document?.id !== documentId
            );
            if (!removedItemDocuments) return;
            Object.assign(draft?.info, { documents: removedItemDocuments });
          })
        );
        await optimisticUpdater(deleteResult, queryFulfilled);
      }
    })
  })
});

export const {
  useGetInfoHubQuery,
  useUpdatePracticalGuideMutation,
  useUpdateFeaturedVideosMutation,
  useUpdateMajorPresentationsMutation,
  useUpdateMinorPresentationsMutation,
  useUpdateSpecialitiesMutation,
  useUpdateProfileBasedTextBlockMutation,
  useUpdateMoreRecommendedMutation,
  useCreateCompanyPortalMutation,
  useDeleteCompanyPortalMutation,
  useDeleteCompanyReportMutation,
  useCreateCompanyReportMutation,
  useUpdateCompanyReportMutation,
  useDeleteCompanyDocumentMutation,
  useCreateCompanyDocumentMutation,
  useUpdateCompanyDocumentMutation,
  useUpdateCompanyPortalMutation,
  useUpdateDefaultPortalMutation,
  useUpdateInfohubBackgroundMutation
} = infoHubExtendedSlice;
