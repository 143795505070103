import {
  getAwardsAchievementsTags,
  getIndividualProfileTags,
  getUserRoleTags
} from 'api/individual-profile/util';
import { store } from '../../store';
import { optimisticUpdater, updateCache } from 'api/utils';
import { apiSlice } from '../apiSlice';

// represent the controller
const secondaryUrl = `individual-profile`;
export const individualProfilesExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIndividualProfileByProfileId: builder.query({
      query: ({ profileId }) => ({
        url: `/v1/${secondaryUrl}/profile/${profileId}`
      }),
      providesTags: (result) => {
        const mainTags = [
          { type: 'Profile', id: 'LIST' },
          { type: 'Bio', id: 'LIST' },
          { type: 'Availability', id: 'LIST' },
          { type: 'Experience', id: 'Profile' }
        ];
        if (!result) return mainTags;
        let invalidatesTags = getIndividualProfileTags(result);
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      }
    }),

    getUserRole: builder.query({
      query: ({ profileId }) => ({
        url: `/v1/${secondaryUrl}/profile/${profileId}/role`
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'Role', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = getUserRoleTags(result);
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      }
    }),

    getAwardsAndAchievementsByUserId: builder.query({
      query: ({ userId }) => ({
        url: `/v1/${secondaryUrl}/user/${userId}/award-achievement?page=1&size=1000`
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      providesTags: (result) => {
        const mainTags = [{ type: 'Award', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = getAwardsAchievementsTags(result);
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      }
    }),
    setBioTools: builder.mutation({
      query: ({ tools }) => ({
        url: `/v1/${secondaryUrl}/tools`,
        method: 'PATCH',
        body: { tools }
      }),
      async onQueryStarted({ tools }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'bio.tools',
          value: tools,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    setBioSkills: builder.mutation({
      query: ({ skills }) => ({
        url: `/v1/${secondaryUrl}/skills`,
        method: 'PATCH',
        body: { skills }
      }),
      async onQueryStarted({ skills }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'bio.skills',
          value: skills,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    setBioInterests: builder.mutation({
      query: ({ interests }) => ({
        url: `/v1/${secondaryUrl}/interests`,
        method: 'PATCH',
        body: { interests }
      }),
      async onQueryStarted({ interests }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'bio.interests',
          value: interests,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    setBioLanguages: builder.mutation({
      query: ({ languages }) => ({
        url: `/v1/${secondaryUrl}/languages`,
        method: 'PATCH',
        body: { languages }
      }),
      async onQueryStarted({ languages }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'bio.languages',
          value: languages,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    updateBioDescription: builder.mutation({
      query: ({ description }) => ({
        url: `/v1/${secondaryUrl}/description`,
        method: 'PATCH',
        body: { description }
      }),

      async onQueryStarted({ description }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'bio.description',
          value: description,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    updateProfileVideoByUserId: builder.mutation({
      query: ({ videoKey }) => ({
        url: `/v1/${secondaryUrl}/video`,
        method: 'PATCH',
        body: { videoKey }
      }),
      async onQueryStarted({ videoKey }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;
        const patchResult = updateCache({
          keyToUpdate: 'bio.profileVideo',
          value: videoKey,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    updateExperiencesDesc: builder.mutation({
      query: ({ description }) => ({
        url: `/v1/${secondaryUrl}/experience-desc`,
        method: 'PATCH',
        body: { description }
      }),
      async onQueryStarted({ description }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'bio.experienceDesc',
          value: description,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    updateAchievementDesc: builder.mutation({
      query: ({ description }) => ({
        url: `/v1/${secondaryUrl}/achievement-desc`,
        method: 'PATCH',
        body: { description }
      })
    }),
    updateEducationDesc: builder.mutation({
      query: ({ description }) => ({
        url: `/v1/${secondaryUrl}/education-desc`,
        method: 'PATCH',
        body: { description }
      }),

      async onQueryStarted({ description }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'bio.educationDesc',
          value: description,
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    }),
    updatePublicationDesc: builder.mutation({
      query: ({ description }) => ({
        url: `/v1/${secondaryUrl}/publication-desc`,
        method: 'PATCH',
        body: { description }
      }),
      async onQueryStarted({ description }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;

        const patchResult = updateCache({
          keyToUpdate: 'publicationDesc',
          value: description,
          queryName: 'getUserRole',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Publication', id: 'LIST' }]));
        });
      }
    }),
    updateIsNewUser: builder.mutation({
      query: ({ isNewUser }) => ({
        url: `/v1/${secondaryUrl}/isNewUser`,
        method: 'PATCH',
        body: { isNewUser }
      }),
      invalidatesTags: ['User']
    }),

    getCountryCities: builder.mutation({
      query: ({ country }) => ({
        url: 'https://countriesnow.space/api/v0.1/countries/cities',
        method: 'POST',
        body: { country }
      })
    }), //TODO: this could be touched up
    updateIndividualProfile: builder.mutation({
      query: ({ profile }) => ({
        url: `/v1/${secondaryUrl}/user`,
        method: 'PATCH',
        body: profile
      }),
      async onQueryStarted({ profile, key }, { queryFulfilled }) {
        const profileId = store.getState().user.userStatus.profileStatus.id;
        const patchResult = updateCache({
          keyToUpdate: `bio.${key}`,
          value: profile[key],
          queryName: 'getIndividualProfileByProfileId',
          params: { profileId }
        });

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          // dispatch(apiSlice.util.invalidateTags([{ type: 'Bio', id: 'LIST' }]));
        });
      }
    })
  })
});

export const {
  useGetIndividualProfileByProfileIdQuery,
  useGetUserRoleQuery,
  useSetBioToolsMutation,
  useSetBioSkillsMutation,
  useSetBioLanguagesMutation,
  useUpdateBioDescriptionMutation,
  useSetBioInterestsMutation,
  useUpdateProfileVideoByUserIdMutation,
  useGetAwardsAndAchievementsByUserIdQuery,
  useUpdateExperiencesDescMutation,
  useUpdateAchievementDescMutation,
  useUpdateEducationDescMutation,
  useUpdatePublicationDescMutation,
  useUpdateIsNewUserMutation,
  useGetCountryCitiesMutation,
  useUpdateIndividualProfileMutation
} = individualProfilesExtendedSlice;
