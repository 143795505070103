import styles from './SelectableItems.module.scss';
import defaultProfileImage from '../../../assets/images/avatar.webp';
import { getS3Url } from 'helpers/s3Handler';
export default function SelectableItems({
  selectableItems,
  addIfNotSelected,
  removeFromSelection
}) {
  return (
    <>
      <div className={styles.selectableItems}>
        {selectableItems?.map((item) => {
          return (
            <div
              className={styles.profileItem}
              key={item?.contact.firstName}
              onClick={() => {
                if (addIfNotSelected) {
                  addIfNotSelected(item);
                }
              }}
            >
              <img
                alt="profile image"
                className={styles.profileImage}
                src={item.picture ? getS3Url(item.picture) : defaultProfileImage}
              />
              {`${item?.contact.firstName} ${item?.contact.lastName}`}
              {removeFromSelection && (
                <span className={styles.delete} onClick={() => removeFromSelection(item)}>
                  X
                </span>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
