import React from "react";
import styles from "./styles.module.scss";

const ChatCollapsibleList = ({ setExpanded, isExpanded, title, openAddGroupModal }) => {
  return (
    <div className={styles.header}>
      <div onClick={setExpanded}><span>{!isExpanded ? "ᐅ" : "ᐁ"}</span> <span className={styles.title}>{title}</span></div>
      <div className={styles.add} onClick={openAddGroupModal}>+</div>
    </div>
  )
}

export default ChatCollapsibleList
