import { AvailabilityView } from 'components/organisms/Availability';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const Availability = () => {
  const { t } = useTranslation();
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>{t('Working Hours')}</title>
        <meta name="description" content="Schedules, working hours and everything else" />
      </Helmet>
      <AvailabilityView />
    </>
  );
};

export default Availability;
