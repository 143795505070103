import React from 'react';
import styles from './infoHubTitile.module.scss'
import {useTranslation} from "react-i18next";

export default function InfoHubTitle({title, extraClass}) {
  const {t} = useTranslation();
  return (
    <h3 className={`${styles.title} ${styles[extraClass]}`}>{t(title)}</h3>
  )
}
