import React from 'react';
import CreateModal from '../CreateModal/CreateModal';
import styles from './NavProfile.module.scss';
import ReactDatePicker from 'react-datepicker';
import { getDifferenceInMilliseconds } from 'helpers/dateHandler';
import { showErrorToast } from 'utils/toast';

const STATUS_VALUES = [
  {
    value: 'online',
    label: 'Online'
  },
  {
    value: 'offline',
    label: 'Offline'
  }
];

const RESET_VALUES = [
  {
    value: -1,
    label: 'Never'
  },
  {
    label: 'After 15 minutes',
    value: 10 * 60 * 1000 // 10 minutes, since the cron job runs every 10 minutes
  },
  {
    label: 'After 30 minutes',
    value: 25 * 60 * 1000
  },
  {
    label: 'After 1 hour',
    value: 55 * 60 * 1000
  },
  {
    label: 'After 3 hours',
    value: (3 * 60 - 5) * 60 * 1000
  },
  {
    label: 'After 6 hours',
    value: (6 * 60 - 5) * 60 * 1000
  },
  {
    label: 'After 12 hours',
    value: (12 * 60 - 5) * 60 * 1000
  },
  {
    label: 'After 24 hours',
    value: (24 * 60 - 5) * 60 * 1000
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

function UpdateStatusModal({ handleClose, handleSave }) {
  const [status, setStatus] = React.useState({
    status: STATUS_VALUES[0].value,
    reset: RESET_VALUES[0].value
  });
  const [isCustomStatus, setIsCustomStatus] = React.useState(false);
  const [customStatusDate, setCustomStatusDate] = React.useState(
    new Date(new Date().setDate(new Date().getDate()))
  );
  return (
    <CreateModal
      extraClass="actionModal"
      handleClose={handleClose}
      clickEvent={() => {
        if (status.reset === 'custom') {
          showErrorToast('Please select a time to reset your status');
          return;
        }
        handleSave(status);
      }}
    >
      <h1>Update Status</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '5px 0px'
        }}
      >
        <select
          value={status.status}
          onChange={(e) => {
            setStatus({ ...status, status: e.target.value });
          }}
          name="status"
          id="status"
          style={{
            width: '100%',
            height: '40px',
            borderRadius: '5px',
            padding: '5px',
            border: 'none',
            outline: 'none',
            // update placeholder color
            color: '#fff',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'inherit',
            cursor: 'pointer',
            backgroundColor: '#1E1E1E',
            marginBottom: '20px'
          }}
        >
          {STATUS_VALUES.map((status) => {
            return (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            );
          })}
        </select>

        {/* we need to let the user select when to reset the status, use a dropdown with the following */}

        <p className={styles.statusDesc}>When should we reset your status ?</p>
        <select
          onChange={(e) => {
            if (e.target.value === 'custom') {
              setIsCustomStatus(true);
              setStatus({ ...status, reset: new Date(customStatusDate).getTime() });
            } else {
              setIsCustomStatus(false);
              const timestamp = new Date().getTime() + e.target.value * 1;
              setStatus({ ...status, reset: timestamp });
            }
          }}
          style={{
            width: '100%',
            height: '40px',
            borderRadius: '5px',
            padding: '5px',
            border: 'none',
            outline: 'none',
            backgroundColor: '#1E1E1E',
            // update placeholder color
            color: '#fff',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'inherit',
            cursor: 'pointer'
          }}
          name="reset"
          id="reset"
        >
          {RESET_VALUES.map((reset) => {
            return (
              <option key={reset.value} value={reset.value}>
                {reset.label}
              </option>
            );
          })}
        </select>
        {isCustomStatus && (
          <div className={styles.datePicker}>
            <ReactDatePicker
              selected={customStatusDate}
              minDate={new Date(new Date().setDate(new Date().getDate()))}
              withPortal
              onChange={(date) => {
                setStatus({ ...status, reset: new Date(date).getTime() });
                setCustomStatusDate(date);
              }}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
            />
          </div>
        )}
      </div>
    </CreateModal>
  );
}

export default UpdateStatusModal;
