import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import styles from './EditableText.module.scss';
function EditableText({
  name,
  defaultValue,
  value,
  onChange,
  placeholder,
  editMode,
  style,
  className,
  maxLength = 1000
}) {
  return (
    <>
      {editMode ? (
        <ReactTextareaAutosize
          rows={7}
          style={style}
          maxLength={maxLength}
          className={`${styles['textarea']} ${className}`}
          placeholder={placeholder}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
        />
      ) : (
        <p className={`${styles['p']} ${className}`} style={style}>
          {value || placeholder}
        </p>
      )}
    </>
  );
}

export default EditableText;
