import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { useInterestUtils } from 'components/organisms/Profile/hooks/templates';
import { Loader } from 'components/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import styles from './InterestsSection.module.scss';
import Options from 'components/atoms/Options/Options';
import QueryableTools from 'components/molecules/QueryableTools/QueryableTools';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import { useTranslation } from 'react-i18next';
const defaultSectionTitle = 'interests-hobbies';
function InterestSectionView({ interestsList }) {
  const {
    isCurrentUserProfile,
    saveInterests,
    edit,
    setEdit,
    interests,
    setInterests,
    sectionTitle
  } = useInterestUtils({ interestsList });
  const { t } = useTranslation();

  return (
    <div className={styles.interests}>
      {isCurrentUserProfile && (
        <Options
          extraClass="languageOptions"
          handleEdit={() => setEdit(true)}
          handleSave={saveInterests}
          handleDiscard={() => {
            setInterests([...interestsList]);
            setEdit(false);
          }}
          edit={edit}
        />
      )}
      <div className={styles.buttons}>
        <button className={styles.active} name="sectionTitle">
          {sectionTitle ?? t(defaultSectionTitle)}
        </button>
      </div>
      <div className={styles.interestsContainer}>
        <QueryableTools
          add={edit}
          onToolsSelected={(tools) => {
            setInterests([...tools]);
          }}
          rtkQueryName="getInterestsByKeyword"
          list={edit ? [...interests] : [...interestsList]}
          title="Interests"
          searchPlaceholder="search the interests/hobbies that you want to add..."
          bgColor="#2D2D2D"
        />
      </div>
    </div>
  );
}

function InterestSection({ sectionTitle }) {
  const [sharedDate] = useGetSharedProfile();

  const { t } = useTranslation();

  const placeHolder = [
    {
      name: t('reading-placeholder')
    },
    {
      name: t('cooking-placeholder')
    },
    {
      name: t('travelling-placeholder')
    },
    {
      name: t('photography-placeholder')
    }
  ];

  const currentProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || currentProfileId;
  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );
  const { data, isLoading, isError, error } = !isSharedMode() ? result : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }

  const interests = data?.bio?.interests;
  return (
    <InterestSectionView
      interestsList={interests && interests?.length !== 0 ? data.bio.interests : placeHolder}
      sectionTitle={sectionTitle}
    />
  );
}

export default InterestSection;
