import NotFoundProfile from 'pages/NotFoundProfile';
import React from 'react';
import { useSelector } from 'react-redux';
import { getProfileStatusSelector } from 'store/login.slice';

function withCheck(WrapperComponent, profileData) {
  function Check(props) {
    const storedProfileData = useSelector(getProfileStatusSelector);
    return profileData ? (
      storedProfileData ? (
        <WrapperComponent {...props} />
      ) : null
    ) : (
      <NotFoundProfile />
    );
  }

  return Check;
}

export default withCheck;
