import React from 'react';
import CommentCard from 'components/molecules/CommentCard/CommentCard';
import styles from './CommentBoard.module.scss';
import { Loader, Progress } from 'components/ui';
import { isSharedMode } from 'helpers';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
export default function CommentBoard({
  comments,
  isLoading,
  isFetching,
  setCurrentPageSize,
  thereIsNextPage,
  isFilesUploading,
  uploadingData
}) {
  const userStatus = useSelector(getUserStatusSelector);

  if (isLoading) return <Loader />;
  return (
    <>
      <div>
        {comments?.map((comment) => (
          <CommentCard
            comment={comment}
            allowedToModify={
              isSharedMode() ? false : comment?.profile?.userId === userStatus?.user?._id
            }
            key={comment.id}
            userStatus={userStatus}
          />
        ))}
        {isFilesUploading && (
          <Progress
            label="uploading contents"
            height="15px"
            loaded={uploadingData.loaded}
            total={uploadingData.total}
          />
        )}
        {isFetching && <Loader />}
      </div>

      {thereIsNextPage && !isFetching && (
        <div className={styles.addmore}>
          <button
            onClick={() => {
              setCurrentPageSize();
            }}
          >
            Load more...
          </button>
        </div>
      )}
    </>
  );
}
