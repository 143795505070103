import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import styles from './SliderContainer.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function SliderContainer({ slides, children, row, breakpoint, breakpointSlide, extraClass }) {
  const settings = {
    arrows: false,
    speed: 500,
    infinite: false,
    slidesToShow: slides,
    slidesToScroll: slides,
    swipeToSlide: true,
    dots: true,
    slidesPerRow: row ? row : 1,
    responsive: [
      {
        breakpoint: breakpoint,
        settings: {
          slidesToShow: breakpointSlide,
          slidesToScroll: breakpointSlide,
          infinite: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false
        }
      }
    ]
  };
  return (
    <div className={`${styles.slider} ${styles[extraClass]} sliderWrapper`}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
export default SliderContainer;
SliderContainer.propTypes = {
  children: PropTypes.any,
  slides: PropTypes.number,
  row: PropTypes.number
};
