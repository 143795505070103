import CalendarTopMenu from 'components/molecules/CalendarTopMenu/CalendarTopMenu';
import React, { Suspense, useState } from 'react';
import styles from './AvailabilityView.module.scss';
import AvailabilityFilter from 'components/molecules/AvailabilityFilter/AvailabilityFiler';
import EmployeeCard from 'components/molecules/EmployeeCard/EmployeeCard';
import Banner from 'components/molecules/Banner/Banner';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { useGetProfilesByCompanyIdQuery } from 'api/individual-profile';
import { Loader } from 'components/ui';
import usePagination from 'hooks/usePagination';
import { useGetTeamsByCompanyIdQuery } from 'api/team';
import { useGetCompanyProfileQuery } from 'api/company-profile';

function AvailabilityView() {
  const [index, setIndex] = useState(2);
  const [option, setOption] = useState({});
  const [employees, setEmployees] = useState([]);
  const addEmployee = (employee) => {
    if (!employees.includes(employee)) {
      setEmployees([...employees, employee]);
    } else {
      setEmployees(employees.filter((item) => item !== employee));
    }
  };
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const { data } = useGetCompanyProfileQuery(
    { companyId },
    {
      skip: !companyId
    }
  );
  const [selectedTeamsId, setSelectedTeamsId] = useState([]);
  let queryString = option.value ? `availability.status=${option.value}` : '';
  if (selectedTeamsId.length > 0) {
    queryString = queryString
      ? `${queryString}&team=${selectedTeamsId.join(',')}`
      : `team=${selectedTeamsId.join(',')}`;
  }
  const { rtkResult, setPage, paginationResult } = usePagination(
    useGetProfilesByCompanyIdQuery,
    {
      companyId,
      size: window.innerWidth > 2000 ? 28 : 21,
      query: queryString
    },
    {
      pollingInterval: 1000 * 60 * 5 // 5 minutes
    }
  );
  // TODO : add pagination
  const { data: companyTeams } = useGetTeamsByCompanyIdQuery({
    companyId,
    projection: 'name id -profiles',
    size: 100
  });

  const { isLoading, isError, error, isFetching } = rtkResult;
  if (isLoading) return <Loader fullpage />;
  else if (isError) return <p style={{ color: 'red' }}>Error: {error.data.message}</p>;
  return (
    <Suspense fallback={<Loader fullpage={true} />}>

        {/* {employees.length > 0 && <CalendarTopMenu title="Working Hours" employees={employees} />} */}
        <Banner
          extraClass="availabilityBanner"
          image="https://cdn.wizrx.wizrapps.com/Group_40553_1_fdllhb.webp"
          subheading=""
        />
        <div className={styles.availability}>
          <div className={styles.section}>
            <AvailabilityFilter
              setSelectedTeamsId={setSelectedTeamsId}
              selectedTeamsId={selectedTeamsId}
              companyTeams={companyTeams?.result}
              index={index}
              setIndex={setIndex}
              selectedOption={option.item}
              onOptionSelect={(option) => {
                setOption(option);
                setPage(1);
              }}
            />
            <div
              className={
                index === 2
                  ? styles.gridCards
                  : index === 1
                  ? `${styles.gridCards} ${styles.gridColumn}`
                  : styles.gridList
              }
            >
              {paginationResult?.map((profile) => {
                return (
                  <EmployeeCard
                    showContactButton
                    showProfileButton
                    disableCheckbox
                    key={profile.id}
                    extraClass={
                      index === 0 ? `list-card` : index === 1 ? `column-card` : `grid-card`
                    }
                    isList={index === 0 || index === 1 ? true : false}
                    {...{
                      array: employees,
                      id: profile.id || profile._id,
                      clickEvent: addEmployee,
                      workingHoursVisible: data?.settings?.visibleProfileSegments?.workingHours,
                      ...profile
                    }}
                  />
                );
              })}
              {isFetching && <Loader />}
            </div>
          </div>
        </div>

    </Suspense>
  );
}
export default AvailabilityView;
