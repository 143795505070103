import React, { Suspense, useMemo } from 'react';
import TemplateSection from 'components/molecules/TemplateSection/TemplateSection';
import styles from './ProfileTemplates.module.scss';
import { CvTemplates, TemplatesCategories } from '../Profile/templates/utils';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/ui';
export default function ProfileTemplates() {
  const { t } = useTranslation();
  const popularTemplates = useMemo(() => {
    return Object.values(CvTemplates).filter(
      (item) => item.category === TemplatesCategories.popularTemplates
    );
  });
  const personalizedTemplates = useMemo(() => {
    return Object.values(CvTemplates).filter(
      (item) => item.category === TemplatesCategories.personalizedTemplates
    );
  });
  const templatesData = [
    {
      title: 'Popular Templates',
      templates: popularTemplates
    },
    {
      title: 'Personalized Templates',
      templates: personalizedTemplates
    }
  ];
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div>
        <div className={styles.templatebanner}>
          <h1 className={styles.title}>{t('Showcase Yourself')}</h1>
          <p className={styles.subtitle}>{t('in the best way')}</p>
        </div>
        <div className={styles.templates}>
          {templatesData.map((item, i) => (
            <TemplateSection key={i} {...item} />
          ))}
        </div>
      </div>
    </Suspense>
  );
}
