import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import React, { useState } from 'react';
import styles from './Reports.module.scss';
import InfoHubContainerSection from 'components/atoms/InfoHubContainerSection';
import InfoHubTitle from 'components/atoms/InfoHubTitle';
import { AddI, Button, Loader, CloseI, SaveI, EditI } from 'components/ui';
import Options from 'components/atoms/Options/Options';
import { Modal } from 'components/modals';
import { FileUploader, Textarea } from 'components/form';
import { showErrorToast } from 'utils/toast';
import { checkIsValidUrl, getS3Url, handleAsync } from 'helpers';
import { useUploadBlob } from 'hooks';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector, getIsAdminSelector } from 'store/login.slice';
import DeleteModal from '../DeleteModal/DeleteModal';
import {
  useCreateCompanyReportMutation,
  useDeleteCompanyReportMutation,
  useUpdateCompanyReportMutation
} from 'api/info-hub';
import { useTranslation } from 'react-i18next';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';

export default function Reports({ title, reports, colorBgSwitcher }) {
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [reportToBeUpdated, setReportToBeUpdated] = useState({});
  const [edit, setEdit] = useState(false);
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const [createReportMutation, { isLoading: isCreateLoading }] = useCreateCompanyReportMutation();
  const [updateReportMutation, { isLoading: isUpdateLoading }] = useUpdateCompanyReportMutation();
  const [deleteReportMutation, { isLoading: isDeleteLoading }] = useDeleteCompanyReportMutation();
  const isAdmin = useSelector(getIsAdminSelector);
  const { t } = useTranslation();
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;
  // the query won't run since it's already cached

  const [reportData, setReportData] = useState({
    link: ''
  });
  const { uploadBlob, isUploading } = useUploadBlob();
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const createReport = async () => {
    // close model
    setOpenModal(false);
    setReportData({
      link: ''
    });
    const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
    if (!status) {
      const error = typeof result === 'string' ? result : t('Error uploading thumbnail');
      return showErrorToast(error);
    }
    handleAsync(
      createReportMutation({
        report: { ...reportData, thumbnail: result },
        companyId
      }).unwrap()
    );
  };

  const updateReport = async () => {
    // close model
    let report = { ...reportToBeUpdated };
    if (thumbnailFile) {
      const [status, result] = await handleAsync(uploadBlob(thumbnailFile));
      if (!status) {
        const error = typeof result === 'string' ? result : t('Error uploading thumbnail');
        return showErrorToast(error);
      }
      report.thumbnail = result;
    }
    handleAsync(
      updateReportMutation({
        report: { ...report },
        companyId,
        reportId: reportToBeUpdated.id
      }).unwrap()
    );
    setOpenUpdateModal(false);
    setReportToBeUpdated({});
  };

  const [reportToDeleteId, setReportToDeleteId] = useState(null);

  const deleteReport = async (id) => {
    const report = reports.find((item) => item.id === id);
    const thumbnail = report?.thumbnail;
    handleAsync(deleteReportMutation({ reportId: id, companyId }).unwrap());
    setReportToDeleteId(null);
    handleClose();
  };
  const handleClose = () => {
    setReportToDeleteId(null);
  };

  // the query won't run since it's already cached
  return (
    <div className={`${styles.reports} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}>
      <InfoHubContainerSection>
        {edit ? (
          <div className={styles.icons}>
            <AddI onClick={() => setOpenModal(true)} classN={styles['addIcon']} />
            <div className={styles.save}>
              <SaveI onClick={() => setEdit(false)} classN={styles['closeIcon']} />
            </div>
          </div>
        ) : (
          <>
            {isAdmin && userTier === APP_TIERS.REGULAR && (
              <Options extraClass="videosOptions" handleEdit={() => setEdit(true)} />
            )}
          </>
        )}
        <>
          <InfoHubTitle title={title} />
          <SliderContainer
            breakpoint={1440}
            breakpointSlide={3}
            slides={5}
            extraClass="reportsSlider"
          >
            {reports?.map((item, i) => (
              <div className={styles['portal-wrapper']} key={item.id}>
                {edit && <CloseI onClick={() => setReportToDeleteId(item.id)} />}
                {edit && (
                  <EditI
                    onClick={() => {
                      setOpenUpdateModal(true);
                      setReportToBeUpdated(item);
                    }}
                  />
                )}
                <a
                  style={{
                    cursor: 'pointer'
                  }}
                  rel="noreferrer"
                  target="_blank"
                  href={item?.link}
                >
                  <img
                    alt="thumbnail image"
                    src={item.initial ? item.thumbnail : getS3Url(item.thumbnail)}
                    key={item.id}
                  />
                </a>
              </div>
            ))}
            {(isCreateLoading || isUploading) && <Loader />}
          </SliderContainer>
          <Modal
            isModalOpen={openModal}
            onClose={() => setOpenModal(false)}
            hasHeader
            colorBgSwitcher={colorBgSwitcher}
          >
            <div
              style={{
                padding: '1rem'
              }}
            >
              <Textarea
                onChange={({ target: { value } }) => {
                  setReportData({ ...reportData, link: value });
                }}
                placeholder={t('Report Link')}
              />
              <FileUploader
                onChange={(files) => {
                  const file = files[0];
                  setThumbnailFile(file);
                }}
                text={t('Upload Report Thumbnail')}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1rem',
                  gap: '.5rem'
                }}
              >
                <Button
                  onClick={() => {
                    const isValidLink = checkIsValidUrl(reportData.link);
                    if (!isValidLink) return showErrorToast(t('Link is should be valid'));
                    if (!thumbnailFile) return showErrorToast(t('Thumbnail is required'));
                    createReport();
                  }}
                  colorBgSwitcher={colorBgSwitcher}
                >
                  {t('Save')}
                </Button>
                <Button onClick={() => setOpenModal(false)} colorBgSwitcher={colorBgSwitcher}>
                  {t('Close')}
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            isModalOpen={openUpdateModal}
            onClose={() => setOpenUpdateModal(false)}
            hasHeader
            colorBgSwitcher={colorBgSwitcher}
          >
            <div
              style={{
                padding: '1rem'
              }}
            >
              <Textarea
                onChange={({ target: { value } }) => {
                  setReportToBeUpdated({ ...reportToBeUpdated, link: value });
                }}
                placeholder={t('Report Link')}
                value={reportToBeUpdated.link}
              />
              <FileUploader
                onChange={(files) => {
                  const file = files[0];
                  setThumbnailFile(file);
                }}
                text={t('Upload Report Thumbnail')}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1rem',
                  gap: '.5rem'
                }}
              >
                <Button
                  onClick={() => {
                    const isValidLink = checkIsValidUrl(reportToBeUpdated.link);
                    if (!isValidLink) return showErrorToast('Link is should be valid');
                    if (!thumbnailFile && !reportToBeUpdated.thumbnail)
                      return showErrorToast('Thumbnail is required');
                    updateReport();
                  }}
                  colorBgSwitcher={colorBgSwitcher}
                >
                  {t('Save')}
                </Button>
                <Button onClick={() => setOpenUpdateModal(false)} colorBgSwitcher={colorBgSwitcher}>
                  {t('Close')}
                </Button>
              </div>
            </div>
          </Modal>
          {reportToDeleteId !== null && (
            <DeleteModal
              title="delete-this-report"
              handleDelete={() => deleteReport(reportToDeleteId)}
              handleClose={handleClose}
              colorBgSwitcher={colorBgSwitcher}
            />
          )}
        </>
      </InfoHubContainerSection>
    </div>
  );
}
