import { handleAsync } from 'helpers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useUpdateIndividualProfileMutation } from 'api/individual-profile';
import { showErrorToast, showSuccessToast } from 'utils/toast';
function useWhyMeUtils() {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [updateIndividualProfile, { isLoading }] = useUpdateIndividualProfileMutation();

  const onUpdateSubmit = async (whyMe) => {
    const result = await handleAsync(
      updateIndividualProfile({ profile: { whyMe: whyMe }, key: 'whyMe' }).unwrap()
    );
    if (result[0]) {
      showSuccessToast('Saved!');
    } else {
      showErrorToast('Something went wrong');
    }
  };
  return {
    onUpdateSubmit,
    isLoading,
    isCurrentUserProfile
  };
}

export default useWhyMeUtils;
