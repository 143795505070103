import React from 'react';
import Employees from 'components/organisms/Employees/Employees';
import { Helmet } from 'react-helmet';
const Homepage = () => {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>Employees</title>
        <meta name="description" content="All employees are listed here" />
      </Helmet>
      <Employees />
    </>
  );
};

export default Homepage;
