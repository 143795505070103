import { useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showErrorToast } from 'utils/toast';

import { deleteFromS3, handleAsync } from 'helpers';
import { useUploadBlobs } from 'hooks';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useUpdateIndividualProfileMutation } from 'api/individual-profile';

function useProjectExperienceUtils({ projectDescription, projectMedia }) {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [description, setDescription] = useState(projectDescription);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const { uploadBlobs, isUploading, totalUploadProgress } = useUploadBlobs();
  const [updateIndividualProfile, { isLoading }] = useUpdateIndividualProfileMutation();
  async function handelUpload(file) {
    if (!file.type.includes('image')) {
      showErrorToast('Please upload an image file');
      return;
    }

    setImage(file);
  }

  const handleMediaUpload = async () => {
    if (!image) return;

    // upload the video
    const [status, response] = await handleAsync(uploadBlobs([image], 'project_experience_image'));
    const keys = response?.keys ?? [];

    // check if the media was uploaded
    if (!status) {
      const error = typeof response === 'string' ? response : 'Error uploading image';
      return showErrorToast(error);
    }

    return keys[0];
  };

  const onUpdateSubmit = async () => {
    const mediaResult = await handleAsync(handleMediaUpload());

    if (!mediaResult[1]) 'Error uploading image';

    const result = handleAsync(
      updateIndividualProfile({
        profile: {
          projectExperience: {
            media: mediaResult[1] || '',
            description: description
          }
        },
        key: 'projectExperience'
      }).unwrap(),
      'Updated Successfully',
      'Something went wrong'
    );

    //Remove previous media if all steps above are successfully passed
    if (result[0] && mediaResult[1] && projectMedia) {
      handleAsync(deleteFromS3(projectMedia));
    }
  };

  return {
    isCurrentUserProfile,
    description,
    setDescription,
    isModalOpen,
    setIsModalOpen,
    isUploading,
    totalUploadProgress,
    handelUpload,
    onUpdateSubmit,
    isLoading
  };
}

export default useProjectExperienceUtils;
