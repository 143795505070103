import { store } from '../store';
import { apiSlice } from 'api/apiSlice';

const checkForRestrictions = (file) => {
  const MBSize = file.byteLength / (1024 * 1024);
  return MBSize <= 100;
};

const uploadFileWithProgress = (file, preSignedUrl, onProgress, fileKey) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', preSignedUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentComplete = event.loaded / event.total;
        onProgress?.({ loaded: event.loaded, total: event.total, percent: percentComplete });
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(fileKey);
        } else {
          reject('File upload failed');
        }
      }
    };

    xhr.send(file);
  });
};

export const addBlobToS3Bucket = async (body, file, path = '', onProgress) => {
  const restrictionsPassed = checkForRestrictions(body, file, path);
  if (!restrictionsPassed) {
    return Promise.reject({
      message: 'File ' + file.name + ' too large. Please upload files up to 100MB.',
      userError: true
    });
  }
  let oldFileName = file.name.split('.');
  let fileExtension = oldFileName.pop();
  oldFileName = oldFileName.join('').replace(/[^a-zA-Z0-9.]/g, '-');
  // if you want to change the way used to generate the file name, take a look at the following function: getFileUrlName
  const newFileName = `${oldFileName}-${Date.now()}.${fileExtension}`;
  const fileKey = `${path}/${newFileName}`;
  const fileSize = (file.size / (1024 * 1024)).toFixed(2);
  const { data } = await store.dispatch(
    apiSlice.endpoints.createPreSignedUrl.initiate({
      body: {
        keys: [{ key: fileKey, size: fileSize }],
        operation: 'PUT',
        contentTypes: [file.type]
      }
    })
  );
  const preSignedUrl = data && data[0]?.url;
  if (!preSignedUrl) return Promise.reject('No preSignedUrl found');
  return uploadFileWithProgress(file, preSignedUrl, onProgress, fileKey);
};

export const getS3Url = (name) => {
  if (!name) return;
  if (name.includes('http')) {
    return name;
  }
  return `${import.meta.env.REACT_APP_CLOUDFRONT_URL}/${name}`;
};

export const uploadBlobToAWS = async (file, path = '', onProgress) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = async () => {
      const buffer = new Uint8Array(fileReader.result).buffer;
      try {
        const response = await addBlobToS3Bucket(buffer, file, path, onProgress);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    };
  });
};

export const deleteFromS3UsingPreSignedUrls = async (keys) => {
  if (!keys || keys.length === 0) {
    return;
  }
  const { data } = await store.dispatch(
    apiSlice.endpoints.createPreSignedUrl.initiate({
      keys: keys,
      operation: 'DELETE'
    })
  );

  const preSignedUrls = data.map((item) => item?.url);
  if (!preSignedUrls && preSignedUrls.some((url) => !url))
    throw new Error('No preSignedUrls found');
  const promises = preSignedUrls.map((url) => {
    return fetch(url, {
      method: 'DELETE'
    });
  });

  await Promise.all(promises);
  return true;
};

export const deleteFromS3 = async (keys) => {
  const isArray = Array.isArray(keys);
  const targetKeys = isArray ? keys : [keys];
  const { data } = await store.dispatch(
    apiSlice.endpoints.deleteByKeys.initiate({
      body: {
        keys: targetKeys
      }
    })
  );
  return data;
};
