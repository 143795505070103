import React from 'react';
import SingleEvent from 'components/organisms/SingleEvent/SingleEvent';
import { Helmet } from 'react-helmet';
const SingleEventPage = () => {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>Event</title>
        <meta name="description" content="All the special details about an event" />
      </Helmet>
      <SingleEvent />
    </>
  );
};

export default SingleEventPage;
