import { Button } from 'components/ui';
import { directUserToMasterHub } from 'helpers/auth';
import React from 'react';
import { Helmet } from 'react-helmet';
function NotFoundProfile() {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>Profile Not Found</title>
        <meta name="description" content="Profile not found!" />
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: '3rem',
          gap: '1rem'
        }}
      >
        <h1 style={{ color: 'white' }}>Profile Not Found 🥲</h1>
        <Button onClick={directUserToMasterHub} color="white" bgColor={'#079677'}>
          navigate to masterhub{' '}
        </Button>
      </div>
    </>
  );
}

export default NotFoundProfile;
