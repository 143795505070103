import React from 'react';
import Calendar from 'react-calendar';
import './Calendar.scss';
function CalendarCard({ onChange, value, events }) {
  function checkIfEventExistsAtDate(date) {
    let convertedEvents = [];
    events?.forEach((element) => {
      convertedEvents.push({
        start: new Date(element.startDate)
      });
    });
    return convertedEvents.some((element) => {
      return (
        date.getDate() == element.start.getDate() && date.getMonth() == element.start.getMonth()
      );
    });
  }
  return (
    <Calendar
      onChange={onChange}
      value={value}
      tileContent={({ date, view }) =>
        view === 'month' && checkIfEventExistsAtDate(date) ? <p className="dateEvent"></p> : null
      }
    />
  );
}
export default CalendarCard;
