export const checkoutCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_smh0vb.webp'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_i88prn.webp'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_vrq7tl.webp'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_fo60w8.webp'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_epujwt.webp'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_e057oz.webp'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_oybixz.webp'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_t6oikh.webp'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_h3mgte.webp'
  }
];

export const announcementCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_dzwkld.webp'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_bzxtlf.webp'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_lbznrd.webp'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_i0uouy.webp'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_ia7qzy.webp'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_jbwfzm.webp'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_s11s6f.webp'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_rrnnxk.webp'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_oebjni.webp'
  }
];

export const funCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_an0lq7.webp'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_usvka6.webp'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_xu4qzl.webp'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_rjbeuv.webp'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_qeiced.webp'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_u00tcd.webp'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_tjkgib.webp'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_oi4o0l.webp'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_p9rg14.webp'
  }
];

export const updateCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_midolt.webp'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_k13tmy.webp'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_shdujj.webp'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_nwoqtp.webp'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_nhwqm6.webp'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_ftu3oi.webp'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_fxrpht.webp'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_lntbbk.webp'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_ihcanx.webp'
  }
];

export const mpublisherCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42150_1_w2hwbt.webp'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42159_1_ge0bxj.webp'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42149_1_jkdyjx.webp'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42153_1_sobfvz.webp'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42158_1_gck4dz.webp'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42152_1_ysspgq.webp'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42154_1_xjzaaz.webp'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42156_1_v5yf3b.webp'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42157_1_rhqwue.webp'
  },
  {
    id: 10,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42155_1_vafwyk.webp'
  }
];
