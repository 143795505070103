import { Input } from 'components/form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import styles from './SharePostModal.module.scss';

const resetData = {
  passKey: '',
  expireAt: new Date(new Date().setDate(new Date().getDate() + 1))
};
const SharePostModal = ({ openShareModal, onClose, onSubmit, isLoading }) => {
  const [data, setData] = useState({ ...resetData });

  if (!openShareModal) return null;
  return (
    <CreateModal
      isSubmitting={isLoading}
      handleClose={onClose}
      clickEvent={() => {
        const dataDto = {
          ...data,
          passKey: data.passKey.trim()
        };
        onSubmit(dataDto);
        setData({ ...resetData });
      }}
    >
      <div className="share-wrapper">
        <h1>Share Post</h1>
        <Input
          label="Pass key"
          type="text"
          placeholder="If no pass key is added, anyone with the link can view the Post"
          extraClass="teamInput"
          value={data.passKey}
          name="passKey"
          autoFocus
          onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
        />
        <div className={styles['datePickerContainer']}>
          <p> Expire At </p>
          <ReactDatePicker
            className={styles['datePicker']}
            // maxDate={new Date()}
            selected={data.expireAt}
            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
            onChange={(date) => {
              setData({ ...data, expireAt: date });
            }}
          />
        </div>
      </div>
    </CreateModal>
  );
};

export default SharePostModal;
