import React from 'react';
import { SettingSection } from '../../components';
import styles from './ProfilesSetting.module.scss';
import CheckInput from 'components/atoms/CheckInput/CheckInput';
import { Button } from 'components/ui';
import { useUpdateCompanySettingsMutation } from 'api/company-profile';
import { handleAsync } from 'helpers';
import { debounce } from 'lodash';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { COMPANY_INITIAL_SETTINGS } from 'constants';
import {useTranslation} from "react-i18next";
const TABS_TO_SHOW = [
  { label: 'My Role', value: 'role' },
  { label: 'Awards', value: 'awards' },
  { label: 'Reviews', value: 'reviews' },
  { label: 'Working Hours', value: 'workingHours' },
  { label: 'IT Essentials', value: 'itEssentials' }
];
function ProfilesSetting({ settingsData }) {
  const [settings, setSettings] = React.useState({ ...COMPANY_INITIAL_SETTINGS, ...settingsData });
  const [updateCompanySettings, { isLoading }] = useUpdateCompanySettingsMutation();
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const { t } = useTranslation();

  const onSave = debounce(() => {
    handleAsync(
      updateCompanySettings({ settings, companyId }).unwrap(),
      'Settings updated successfully',
      'Error updating the settings'
    );
  }, 100);

  return (
    <>
      <SettingSection title="Profiles">
        <SettingSection.Content>
          <p
            style={{
              marginBottom: '20px'
            }}
          >
            {t("Tabs to show on the profiles pages")}{' '}
          </p>
          <ul className={styles['check-list']}>
            {TABS_TO_SHOW.map((item) => {
              return (
                <li key={item.value}>
                  <CheckInput
                    checked={settings.visibleProfileSegments?.[item.value]}
                    onChange={({ target: { checked } }) => {
                      const settingsDto = Object.assign({}, settings, {
                        visibleProfileSegments: {
                          ...settings.visibleProfileSegments,
                          [item.value]: checked
                        }
                      });
                      setSettings(settingsDto);
                    }}
                    label={t(item.label)}
                  />
                </li>
              );
            })}
          </ul>
          <Button disabled={isLoading} onClick={onSave} className={styles['save-button']}>
            {isLoading ? t('Saving') : t('Save')}
          </Button>
        </SettingSection.Content>
      </SettingSection>
    </>
  );
}

export default ProfilesSetting;
