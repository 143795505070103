import { apiSlice } from 'api/apiSlice';
import { useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @description This hook is used to handle pagination for rtk queries, YOU MUST USE THE FOLLOWING RTK FUNCTIONS TO BE ABLE TO USE THIS HOOK:
 * serializeQueryArgs, merge, forceRefetch
 *
 *
 *
 */
function useHandleRtkPagination({ queryName, rtkQuery, rtkQueryArgs, rtkQueryOptions = {} }) {
  if (!apiSlice.endpoints[queryName])
    throw new Error(`Query name ${queryName} is not defined in apiSlice`);
  const { data: cachedData } = useSelector(
    apiSlice.endpoints[queryName].select({ ...rtkQueryArgs })
  );
  const pageToUse = cachedData?.page?.number ? cachedData.page.number : 1;
  const [page, setPage] = useState(pageToUse);
  const result = rtkQuery(
    {
      ...rtkQueryArgs,
      page
    },
    {
      ...rtkQueryOptions
    }
  );

  return { result, page, setPage };
}
export default useHandleRtkPagination;
