import React, { Suspense } from 'react';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import styles from './CompanySettingView.module.scss';
import BannerNavigation from 'components/molecules/BannerNavigation/BannerNavigation';
import { ProfilesSetting } from './sections';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import { Loader } from 'components/ui';
import { useGetCompanyProfileQuery } from 'api/company-profile';
function CompanySettingView() {
  const companyId = useSelector(getCurrentCompanyIdSelector);

  const [active, setActive] = React.useState(0);
  const { data, isLoading, isError } = useGetCompanyProfileQuery(
    { companyId },
    {
      skip: !companyId
    }
  );

  if (isLoading) return <Loader fullpage />;
  if (isError)
    return (
      <p
        style={{
          color: 'red'
        }}
      >
        Something went wrong
      </p>
    );

  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <BannerNavigation
        data={[
          {
            title: 'Profiles',
            isButton: true,
            onClick: () => setActive(1),
            isActive: active === 1
          }
        ]}
      />
      <ResponsiveWrapper>
        <div className={styles['main-wrapper']}>
          {active === 0 && <ProfilesSetting settingsData={data.settings} />}
          {/* {active === 0 && <RolesSetting />} */}
        </div>
      </ResponsiveWrapper>
    </Suspense>
  );
}

export default CompanySettingView;
