import React from 'react';
import RatingStars from '../RatingStars/RatingStars';
import { useState } from 'react';
import styles from './RatingStarsDropdown.module.scss';
function RatingStarsDropdown({ onChooseRating, rating }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const options = [
    { placeholder: 'Choose rating', value: 0 },
    { placeholder: 'Option 1', view: <RatingStars size={2} value={1} />, value: 1 },
    { placeholder: 'Option 2', view: <RatingStars size={2} value={2} />, value: 2 },
    { placeholder: 'Option 3', view: <RatingStars size={2} value={3} />, value: 3 },
    { placeholder: 'Option 4', view: <RatingStars size={2} value={4} />, value: 4 },
    { placeholder: 'Option 5', view: <RatingStars size={2} value={5} />, value: 5 }
  ];
  return (
    <div className={styles.dropdown} onClick={() => setDropdownOpen(!dropdownOpen)}>
      <p>{rating || 'Choose rating'}</p>
      {dropdownOpen && (
        <div className={styles.options}>
          {options.map((item, i) => {
            return (
              <div
                style={{ width: 'fit-content', margin: '4px', display: 'flex' }}
                key={i}
                onClick={() => onChooseRating(item.value)}
              >
                {item.view}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default RatingStarsDropdown;
