import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';

function useGetUserTierData(options = {}) {
  const skipFetching = options.skipFetching;
  const profileId = useSelector(getCurrentUserProfileId);
  const { data: userProfileResult, refetch } = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: isSharedMode() ?? skipFetching
    }
  );
  const tierData = userProfileResult?.profile?.tierData;

  return { tierData, refetch };
}

export default useGetUserTierData;
