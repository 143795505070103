import React from 'react';
import styles from './Navbar.module.scss';
import NavProfile from 'molecules/NavProfile/NavProfile';
import { isSharedMode } from 'helpers';
import { WizrXLogo } from 'components/ui';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';
import { HOME_NAV } from 'constants';
import { useLocation } from 'react-router-dom';
import { getTheme } from 'helpers/general';
import { getCompanyThemeSelector } from 'store/login.slice';

export default function Navbar({ profileData, showMenu }) {
  const isAdmin = useSelector(getIsAdminSelector);
  const location = useLocation();
  const companyTheme = useSelector(getCompanyThemeSelector);
  const theme = getTheme(companyTheme, 'infoHub');

  let tabsData = [...HOME_NAV];

  if (!isAdmin) {
    tabsData = tabsData.slice(1);
  }

  const isExplorePage = location.pathname === '/explore';

  return (
    <div
      className={styles.container}
      style={{ background: location.pathname === '/info-hub' ? theme?.bannerGradient : '' }}
      id="app-nav"
    >
      <div className={styles.logoContainer}>
        <div className={showMenu ? styles.visible : styles.hidden}>
          <WizrXLogo />
        </div>
      </div>
      {!isSharedMode() && profileData && <NavProfile />}
    </div>
  );
}
