import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './ProjectExperienceSection.module.scss';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { DescriptionAndUploadModal, Loader } from 'components/ui';
import { getS3Url, isSharedMode } from 'helpers';
import { getCurrentUserProfileId } from 'store/login.slice';
import Options from 'components/atoms/Options/Options';
import defaultProfileImage from 'assets/images/modalbanner.webp';
import { useProjectExperienceUtils } from 'components/organisms/Profile/hooks/templates';
import { useTranslation } from 'react-i18next';

function ProjectExperience({ projectDescription, projectMedia }) {
  const {
    isCurrentUserProfile,
    description,
    setDescription,
    isModalOpen,
    setIsModalOpen,
    isUploading,
    handelUpload,
    onUpdateSubmit,
    isLoading: requestLoading
  } = useProjectExperienceUtils({
    projectDescription,
    projectMedia
  });
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <DescriptionAndUploadModal
        onBlobUpload={handelUpload}
        isBlobUploading={isUploading}
        onSubmit={() => {
          onUpdateSubmit();
          setIsModalOpen(false);
        }}
        description={description}
        onDescriptionChange={(e) => {
          const { value } = e.target;
          if (value.length > 500) {
            setDescription(value.slice(0, 500));
            return;
          }
          setDescription(value);
        }}
        onClose={() => {
          setDescription(projectDescription);
          setIsModalOpen(false);
        }}
        isModalOpen={isModalOpen}
      />
      {isCurrentUserProfile && (
        <Options extraClass="videoOptions" handleEdit={() => setIsModalOpen(true)} />
      )}
      {requestLoading || isUploading ? (
        <Loader />
      ) : (
        <>
          <img
            alt="project image"
            className={styles.image}
            src={projectMedia ? getS3Url(projectMedia) : defaultProfileImage}
          />
          <div className={styles.text}>
            <h3>{t('Project Experience')}</h3>
            <p className={styles.paragraph}>{projectDescription}</p>
          </div>
        </>
      )}
    </div>
  );
}
function ProjectExperienceSection() {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const { t } = useTranslation();
  const placeHolder = t('placeholder-in-my-project-experience');
  const profileId = useParams()?.id || loggedInProfileId;
  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );
  const { isLoading, isError, data, error } = result;

  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const description = data?.bio?.projectExperience?.description;
  const media = data?.bio?.projectExperience?.media ?? '';
  return (
    <ProjectExperience
      projectDescription={description && description.length !== 0 ? description : placeHolder}
      projectMedia={media}
    />
  );
}
export default ProjectExperienceSection;
