import {
  useDeleteQAndAMutation,
  useSaveQandAMutation,
  useUpdateQandAMutation
} from 'api/individual-profile';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserIdSelector, getCurrentUserProfileId } from 'store/login.slice';

function useQandAUtils() {
  const [createQandA, { isLoading: isCreatingQandA }] = useSaveQandAMutation();
  const [updateQandA, { isLoading: isUpdatingQandA }] = useUpdateQandAMutation();
  const [deleteQandA, { isLoading: isDeletingQandA }] = useDeleteQAndAMutation();
  const currentUserId = useSelector(getCurrentUserIdSelector);
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  return {
    isCurrentUserProfile,
    createQandA,
    isCreatingQandA,
    updateQandA,
    isUpdatingQandA,
    deleteQandA,
    isDeletingQandA,
    currentUserId
  };
}

export default useQandAUtils;
