import React, { useState } from 'react';
import styles from './RoleDescription.module.scss';
import { EditInput } from 'components/form';
import { useUpdateProfileRoleMutation } from 'api/individual-profile';
import { handleAsync } from 'helpers';
import Options from 'components/atoms/Options/Options';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  serialize,
  isJsonString,
  convertStringToParagraph
} from 'components/atoms/MWEditor/Helpers';
import {useTranslation} from "react-i18next";
export default function RoleDescription({ role, isCurrentUserProfile, profileId }) {
  const [edit, setEdit] = useState(false);
  const [roleState, setRoleState] = useState(role);
  const [updateRole, isLoading] = useUpdateProfileRoleMutation();
  const { t } = useTranslation();

  const onDescriptionSave = async () => {
    const stringifiedRole = JSON.stringify(roleState);
    const result = await handleAsync(
      updateRole({
        profileId,
        role: { description: stringifiedRole }
      }).unwrap()
    );

    if (result[0]) {
      setEdit(false);
    }
  };

  const staticDesc = t('here-you-can-write-some-description');
  return (
    <div className={styles.roleDescriptionWrapper}>
      {isCurrentUserProfile && (
        <Options
          extraClass="roleOptions"
          handleEdit={() => setEdit(true)}
          handleSave={onDescriptionSave}
          handleDiscard={() => setEdit(false)}
          edit={edit}
        />
      )}
      <div className={styles.roleDescription}>
        {edit ? (
          <div className={styles.editMode}>
            <div className={styles.card}>
              <div className={styles.content}>
                <MWEditorLite
                  data={
                    role?.description
                      ? isJsonString(role?.description)
                        ? JSON.parse(role?.description)
                        : convertStringToParagraph(role?.description)
                      : undefined
                  }
                  setData={setRoleState}
                  placeholderText={t('Please describe your role')}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            {role?.description ? (
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: serialize(
                    role?.description
                      ? isJsonString(role?.description)
                        ? JSON.parse(role?.description)
                        : convertStringToParagraph(role?.description)
                      : undefined
                  )
                }}
              ></div>
            ) : (
              <p>{staticDesc}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
}
