import React from 'react';
import styles from './NavProfile.module.scss';
import { ProfilePic } from 'components/ui';
import { capitalizeFirstLetter } from 'helpers';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector, getCurrentUserProfileId } from 'store/login.slice';
import { deleteAuthData } from 'helpers/auth';
import { ReactComponent as Smile } from 'assets/icons/user-edit.svg';
import { ReactComponent as Logout } from 'assets/icons/logouticon.svg';
import { useTranslation } from 'react-i18next';
function NavProfileModel({ image, onUpdateStatusClick, status, contact }) {
  const currentUserId = useSelector(getCurrentUserIdSelector);
  const { t } = useTranslation();
  const currentUserProfile = useSelector(getCurrentUserProfileId);
  return (
    <>
      <div className={styles['nav-profile-model']}>
        <div className={styles['wrapper']}>
          <div className={styles['name-picture']}>
            <ProfilePic
              clickable
              profileId={currentUserProfile}
              extraClass="navprofile"
              image={image}
            />
            <div className={styles['name-and-status']}>
              <Link className={styles.nameLink} to={`/profile/${currentUserProfile}`}>
                {capitalizeFirstLetter(contact.firstName) +
                  ' ' +
                  capitalizeFirstLetter(contact.lastName)}
              </Link>
              <div className={styles.statusWrapper}>
                <div
                  style={{
                    backgroundColor: status === 'online' ? '#08dcae' : '#FF0000'
                  }}
                  className={styles['status-circle']}
                />
                <h5>{capitalizeFirstLetter(status)}</h5>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              onUpdateStatusClick();
            }}
            className={styles.updateStatus}
          >
            <div className={styles.icon}>
              <Smile />
            </div>
            <button className={styles.updateBtn} defaultValue="Update Your Status" name="Update">
              {t('Update Your Profile Status')}
            </button>
          </div>
          <div
            onClick={() => {
              deleteAuthData();
            }}
            className={styles.updateStatus}
          >
            <div className={styles.icon}>
              <Logout />
            </div>
            <button className={styles.updateBtn} defaultValue="Logout" name="logout">
              {t('Logout')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavProfileModel;
