import React, { useState } from 'react';
import styles from './EventBullet.module.scss';
function EventBullet({ event, onEventSelect, setShowSideAgendaProperties }) {
    const currentHour = new Date(event.resource.startDate).getHours();
    const currentMinute = new Date(event.resource.startDate).getMinutes();
    return (
        <div className={styles.eventContainer} onClick={() => {
            onEventSelect(event); setShowSideAgendaProperties({
                show: false,
                date: new Date(),
                events: [],
            })
        }}>
            <div className={styles.eventBullet}>
                <h3>{event.title}</h3>
                <div>{`${currentHour}:${currentMinute}`}</div>
            </div>
        </div>
    );
}
export default EventBullet;