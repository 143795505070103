import { showErrorToast } from 'utils/toast';
import { FileUploader, Textarea } from 'components/form';
import { Modal } from 'components/modals';
import { Button } from 'components/ui';
import React from 'react';
import styles from './Awards.module.scss';

function AwardCreateModal({ onClose, isOpen, onSubmit }) {
  const [awardD, setAwardD] = React.useState({
    awardName: '',
    awardFile: ''
  });

  const handleSubmit = () => {
    const { awardName, awardFile } = awardD;
    if (!awardName) return showErrorToast('Please enter award name');
    if (!awardFile) return showErrorToast('Please upload award file');
    setAwardD({
      awardName: '',
      awardFile: ''
    });
    onSubmit({ awardName, awardFile });
  };
  return (
    <Modal extraClass="awardsModal" isModalOpen={isOpen} onClose={onClose} hasHeader>
      <div
        className="modal-container"
        style={{
          padding: '2rem'
        }}
      >
        <h1 className={styles.awardTitle}>Upload Award File</h1>
        <Textarea
          onChange={({ target: { value } }) => {
            setAwardD((prev) => ({ ...prev, awardName: value }));
          }}
          placeholder="Award Name"
          value={awardD.awardName}
        />

        <FileUploader
          accept="image/*, application/pdf"
          onChange={(files) => {
            const file = files[0];
            setAwardD((prev) => ({ ...prev, awardFile: file }));
          }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            gap: '.5rem'
          }}
        >
          <Button onClick={onClose}>Close</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </div>
      </div>
    </Modal>
  );
}

export default AwardCreateModal;
