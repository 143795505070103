import React, { useRef, useEffect, useState } from 'react';
import styles from './Modal.module.scss';
import { ReactComponent as Close } from 'assets/icons/closeIcon.svg';
import { useTour } from '@reactour/tour';

function Modal({ isModalOpen = true, onClose, children, extraClass, colorBgSwitcher, ...rest }) {
  const modalContentRef = useRef(null);
  const containerRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);
  const { isOpen } = useTour();

  useEffect(() => {
    if (extraClass !== 'ImageCropModal' && !isOpen && isModalOpen && containerRef.current) {
      containerRef.current.classList.add(styles.popOut);
    }
  }, [isModalOpen, extraClass]);

  useEffect(() => {
    const handleCloseAnimationEnd = () => {
      setIsClosing(false);
      onClose();
    };

    if (isClosing && containerRef.current) {
      containerRef.current.classList.add(styles.closeModal);
      containerRef.current.addEventListener('animationend', handleCloseAnimationEnd);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('animationend', handleCloseAnimationEnd);
      }
    };
  }, [isClosing, onClose]);

  // Effect for outside click handling
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        setIsClosing(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isModalOpen) return null;

  return (
    <div ref={containerRef} className={styles['modal-overlay']} style={rest.overlayStyle}>
      <div className={styles.backdrop} />
      <div
        style={rest.contentStyle}
        className={`${styles.modalContent} ${styles[extraClass]} ${
          colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''
        }`}
        ref={modalContentRef}
      >
        <button
          className={styles['close-button']}
          onClick={() => setIsClosing(true)}
          name="close button"
        >
          <Close />
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;
