import React, { useState } from 'react'
import ChatCollapsibleList from 'components/atoms/ChatCollapsibleList/ChatCollapsibleList';
import ChatChannelList from '../ChatChannelList/ChatChannelList';
import ChatList from '../ChatList/ChatList';
import ChatQuickAccess from '../ChatQuickAccess/ChatQuickAccess';
import styles from './styles.module.scss';

const ChatSideMenu = ({ openAddGroupModal }) => {
    const [msgExpand, setMsgExpand] = useState(false);
    const [grpExpand, setGrpExpand] = useState(false);

    return (
        <div className={styles.sideMenu}>
            <input placeholder="search" className={styles.search} />

            <ChatQuickAccess />

            <div className={`${styles.collapsible} ${styles.horiSpacing}`}>
                <ChatCollapsibleList
                    title="Groups"
                    isExpanded={grpExpand}
                    setExpanded={() => setGrpExpand(!grpExpand)}
                    openAddGroupModal={openAddGroupModal}
                />
                {grpExpand && <ChatChannelList />}
            </div>

            <div className={`${styles.collapsible} ${styles.horiSpacing}`}>
                <ChatCollapsibleList
                    title="Direct Messages"
                    isExpanded={msgExpand}
                    setExpanded={() => setMsgExpand(!msgExpand)}
                />
                {msgExpand && <ChatList />}
            </div>
        </div>
    )
}

export default ChatSideMenu