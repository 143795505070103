import { React, useState } from 'react';
import styles from './WhyMeSection.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import Options from 'components/atoms/Options/Options';
import { Loader } from 'components/ui';
import { isSharedMode } from 'helpers';
import useWhyMeUtils from 'components/organisms/Profile/hooks/templates/useWhyMeUtils';
import { Textarea } from 'components/form';
import { Button } from 'components/ui';
import { showErrorToast } from 'utils/toast';
import { CloseI } from 'components/ui';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import {useTranslation} from "react-i18next";
const defaultSectionTitle = 'Why Me';
function WhyMeSectionView({ data, sectionTitle }) {
  const userId = useSelector(getCurrentUserIdSelector);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [reasons, setReasons] = useState([...data]);
  const { onUpdateSubmit, isCurrentUserProfile } = useWhyMeUtils({ userId });
  const onSubmit = () => {
    const reasonsD = (reasons ?? []).map((reason) => reason.trim());
    onUpdateSubmit(reasonsD);
    setEdit(false);
  };

  return (
    <div className={styles.whyMeContainer}>
      {isCurrentUserProfile && (
        <Options
          extraClass="WhyMeOptions"
          handleDiscard={() => setEdit(false)}
          handleEdit={() => setEdit(true)}
          edit={edit}
          handleSave={() => {
            if (
              reasons.some((element) => {
                return element.length == 0 || element == 'New Reason';
              })
            ) {
              showErrorToast(t('one-of-the-reasons-is-empty'));
            } else {
              onSubmit();
            }
          }}
        />
      )}
      <div className={styles.reasons}>
        <h3 className={styles.title} id="whyMe-title">
          {sectionTitle ?? t(defaultSectionTitle)}
        </h3>
        {!edit && (
          <div className={styles.reasonsWrapper}>
            {data?.map((item, index) => {
              return (
                <div className={styles.reason} key={item}>
                  <h3>{index + 1}</h3>
                  <p>{item}</p>
                </div>
              );
            })}
          </div>
        )}
        {edit && (
          <div className={styles.reasonsWrapper}>
            {reasons.map((item, index) => {
              return (
                <div className={styles.reasonInput} key={index}>
                  <Textarea
                    maxLength={100}
                    value={item}
                    onChange={({ target: { value } }) => {
                      let tempReasons = [...reasons];
                      tempReasons[index] = value;
                      setReasons(tempReasons);
                    }}
                  />
                  <CloseI
                    onClick={() => {
                      setReasons([...reasons.filter((word) => word !== item)]);
                    }}
                  />
                </div>
              );
            })}
            <Button
              bgColor={'#316249'}
              color={'white'}
              onClick={() => {
                if (reasons.length <= 10) {
                  setReasons([...reasons, '']);
                } else {
                  showErrorToast(t('reason-limit-reached'));
                }
              }}
            >
              {t("Add reason")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
function WhyMeSection({ ...args }) {
  const [sharedDate] = useGetSharedProfile();
  const { t } = useTranslation();

  const placeHolder = [
    t('placeholder-i-am-a-hardworking-individual'),
    t('placeholder-i-am-team-player'),
    t('placeholder-i-am-a-fast-learner')
  ];

  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId: args?.profileId
    },
    {
      skip: isSharedMode() || !args?.profileId
    }
  );
  const { data, isLoading, isError, isFetching } = !isSharedMode() ? result : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {isError?.data?.message}
      </div>
    );
  }

  const whyMe = data?.bio?.whyMe;
  return (
    <WhyMeSectionView
      data={whyMe && whyMe?.length !== 0 ? whyMe : placeHolder}
      isFetching={isFetching}
      sectionTitle={args?.sectionTitle}
    />
  );
}

export default WhyMeSection;
