import React, { useState, useEffect } from 'react';
import CreateModal from '../CreateModal/CreateModal';
import styles from './EventResponseModal.module.scss';
import { Textarea } from 'components/form';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { useRespondToEventMutation } from 'api/event';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { combineDateWithTime } from 'helpers/dateHandler';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import { EVENT_RESPONSE_ENUM } from 'constants';
function EventResponseModal({ open, onClose, dispute, eventId, onRespond }) {
  if (!open) return null;
  const { profileStatus: currentProfile } = useSelector(getUserStatusSelector);
  const [attendanceResponse, setAttendanceResponse] = useState({
    response: '',
    message: ''
  });
  const [rescheduleProposal, setRescheduleProposal] = useState([
    {
      startDate: new Date(),
      starTime: undefined,
      endTime: undefined
    }
  ]);
  const [ProposalVisible, setProposalVisible] = useState(false);
  const [respondToEvent] = useRespondToEventMutation();

  async function handleSave() {
    if (attendanceResponse.response == EVENT_RESPONSE_ENUM.NA) {
      showErrorToast('Please select an attendance response');
      return;
    }
    for (let index = 0; index < rescheduleProposal.length; index++) {
      if (
        ProposalVisible &&
        !rescheduleProposal[index].startDate &&
        !rescheduleProposal[index].startTime &&
        !rescheduleProposal[index].endTime
      ) {
        showErrorToast('Please define the date start and end hour of your reschedule proposal');
        return;
      }
    }

    let startDateToBeChecked = undefined;
    let endDateToBeChecked = undefined;
    let modifiedProposals = [];
    for (let index = 0; index < rescheduleProposal.length; index++) {
      if (ProposalVisible) {
        //Check if dates are valid
        startDateToBeChecked = combineDateWithTime(
          rescheduleProposal[index].startDate,
          rescheduleProposal[index].startTime
        );
        endDateToBeChecked = combineDateWithTime(
          rescheduleProposal[index].startDate,
          rescheduleProposal[index].endTime
        );
        modifiedProposals.push({
          startDate: startDateToBeChecked ? startDateToBeChecked : undefined,
          endDate: endDateToBeChecked ? endDateToBeChecked : undefined
        });
        if (endDateToBeChecked < startDateToBeChecked) {
          showErrorToast('End time cannot be earlier than start time');
          return;
        }
      }
    }
    showSuccessToast('Saving response');
    await respondToEvent({
      eventResponse: {
        responder: currentProfile.id,
        message: attendanceResponse.message,
        response: attendanceResponse.response,
        rescheduleProposal: modifiedProposals
      },
      eventId: eventId
    });
    showSuccessToast('Saved');
    resetFields();
    onRespond();
  }

  function resetFields() {
    setAttendanceResponse({
      response: '',
      message: ''
    });
    setRescheduleProposal([
      {
        startDate: undefined,
        starTime: undefined,
        endTime: undefined
      }
    ]);
  }
  return (
    <>
      <>
        <CreateModal
          extraClass="eventModal"
          clickEvent={() => {
            handleSave();
          }}
          handleClose={() => {
            resetFields();
            onClose();
          }}
          ignoreOutsideClick={true}
        >
          <h1>{'Respond to event'}</h1>
          <div className={styles.responseButtons}>
            {!dispute && (
              <div
                className={
                  attendanceResponse.response == EVENT_RESPONSE_ENUM.YES
                    ? styles.responseButtonSelected
                    : styles.responseButton
                }
                onClick={() => {
                  setAttendanceResponse({
                    ...attendanceResponse,
                    response: EVENT_RESPONSE_ENUM.YES
                  });
                }}
              >
                Yes ✅
              </div>
            )}
            <div
              className={
                attendanceResponse.response == EVENT_RESPONSE_ENUM.NO
                  ? styles.responseButtonSelected
                  : styles.responseButton
              }
              onClick={() => {
                setAttendanceResponse({ ...attendanceResponse, response: EVENT_RESPONSE_ENUM.NO });
              }}
            >
              No ❌
            </div>
            <div
              className={
                attendanceResponse.response == EVENT_RESPONSE_ENUM.MAYBE
                  ? styles.responseButtonSelected
                  : styles.responseButton
              }
              onClick={() => {
                setAttendanceResponse({
                  ...attendanceResponse,
                  response: EVENT_RESPONSE_ENUM.MAYBE
                });
              }}
            >
              Maybe ❓
            </div>
          </div>
          <div className={styles.responseMessage}>
            <Textarea
              maxLength={100}
              name="message"
              placeholder="Add a small message"
              change={(e) => {
                setAttendanceResponse({ ...attendanceResponse, message: e.target.value });
              }}
            />
          </div>
          {attendanceResponse.response == EVENT_RESPONSE_ENUM.NO && (
            <div className={styles.rescheduleCheck}>
              <label htmlFor="rescheduleCheck">Would you like to send a reschedule proposal?</label>
              <input
                name="rescheduleCheck"
                id="rescheduleCheck"
                type="checkbox"
                onClick={(e) => {
                  setProposalVisible(e.target.checked);
                }}
              />
            </div>
          )}
          {attendanceResponse.response == EVENT_RESPONSE_ENUM.NO && ProposalVisible && (
            <div>
              <h2>{'Reschedule proposals'}</h2>
              <div className={styles.proposalCount}>
                <label htmlFor="proposalCount">Proposal count </label>
                <input
                  onChange={(e) => {
                    const targetValue = e.target.value;
                    let updatedProposals = [];
                    for (let index = 0; index < targetValue; index++) {
                      if (rescheduleProposal[index]) {
                        updatedProposals.push(rescheduleProposal[index]);
                      } else {
                        updatedProposals.push({
                          startDate: new Date(),
                          starTime: undefined,
                          endTime: undefined
                        });
                      }
                    }
                    setRescheduleProposal(updatedProposals);
                  }}
                  name="proposalCount"
                  id="proposalCount"
                  type="number"
                  min={1}
                  max={5}
                  defaultValue={1}
                />
              </div>
              {rescheduleProposal.map((arrayElement, index) => {
                return (
                  <div className="onCreateDatePicker" key={index}>
                    <DatePicker
                      selected={arrayElement.startDate}
                      onChange={(date) => {
                        let temp = rescheduleProposal;
                        temp[index].startDate = date;
                        setRescheduleProposal([...temp]);
                      }}
                      minDate={new Date()}
                      showYshowYearDropdownearPicker
                    />
                    <TimePicker
                      popupClassName={styles.timePicker}
                      onChange={(date) => {
                        let temp = rescheduleProposal;
                        temp[index].startTime = date;
                        setRescheduleProposal(temp);
                      }}
                      defaultValue={moment()}
                      showSecond={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                    <TimePicker
                      popupClassName={styles.timePicker}
                      onChange={(date) => {
                        let temp = rescheduleProposal;
                        temp[index].endTime = date;
                        setRescheduleProposal(temp);
                      }}
                      defaultValue={moment()}
                      showSecond={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </CreateModal>
      </>
    </>
  );
}

export default EventResponseModal;
