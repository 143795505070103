export const navigateToElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const copyCurrentPageURLToClipBoard = (link = window.location) => {
  const inputLink = document.createElement('input');
  inputLink.value = link;
  document.body.appendChild(inputLink);
  inputLink.select();
  document.execCommand('copy');
};
