import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  profilesFilter: {}
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfilesQueryFilter(state, action) {
      state.profilesFilter = action.payload;
    }
  }
});

export const { setProfilesQueryFilter } = profileSlice.actions;
export default profileSlice.reducer;
const getProfilesQueryFilterSelector = (state) => state.profile;
export const getProfileTierSelector = (state) =>
  state?.user?.userStatus?.profileStatus?.tierData?.tier;

export const getProfilesQueryFilter = createSelector(
  getProfilesQueryFilterSelector,
  (result) => result.profilesFilter
);
