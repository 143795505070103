import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { deleteProjectInListInQuery, updateProjectInListInQuery } from './util';
import { addObjectToArrayIfNotExist } from 'helpers';
// represent the controller
const secondaryUrl = `project`;

export const projectExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectsByUserId: builder.query({
      query: ({ userId, page = 1, size = 5 }) => ({
        url: `/v1/${secondaryUrl}/user/${userId}?page=${page}&size=${size}&sort=createdAt,desc`
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'Project', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = result.result.map((item) => ({ type: 'Project', id: item.id }));
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { userId } = queryArgs;
        return `${endpointName}({"userId":"${userId}"})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a Project, the created Project is added to the cache, and the last Project
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new Project
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    }),

    updateProject: builder.mutation({
      query: ({ projectId, project }) => ({
        url: `/v1/${secondaryUrl}/${projectId}`,
        method: 'PATCH',
        body: project
      }),
      async onQueryStarted({ projectId, project, userId }, { dispatch, queryFulfilled }) {
        const patchResult = updateProjectInListInQuery({
          project,
          projectId,
          queryName: 'getProjectsByUserId',
          params: { userId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Project', id: 'LIST' }]));
        });
      }
    }),

    saveProject: builder.mutation({
      query: ({ project }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: project
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdProjectData) => {
            if (!createdProjectData) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getProjectsByUserId',
                {
                  userId
                },
                (draft) => {
                  draft.result.unshift(createdProjectData);
                }
              )
            );
          }
        );
      }
    }),

    deleteProject: builder.mutation({
      query: ({ projectId }) => ({
        url: `/v1/${secondaryUrl}/${projectId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ projectId, userId }, { queryFulfilled }) {
        const patchResult = deleteProjectInListInQuery({
          projectId,
          queryName: 'getProjectsByUserId',
          params: { userId }
        });
        await optimisticUpdater(patchResult, queryFulfilled);
      },
      invalidatesTags: () => [{ type: 'Project', id: 'LIST' }]
    })
  })
});

export const {
  useGetProjectsByUserIdQuery,
  useUpdateProjectMutation,
  useSaveProjectMutation,
  useDeleteProjectMutation
} = projectExtendedSlice;
