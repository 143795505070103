import React, { Suspense, useState, useEffect } from 'react';
import styles from './Explore.module.scss';
import { Button } from 'components/ui';
import { thumbnailsData } from 'constants/company';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components/ui';
import { useChangeThemeMutation } from 'api/company-profile';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector, getCompanyThemeSelector } from 'store/login.slice';
import { getTheme } from 'helpers/general';
import { showSuccessToast } from 'utils/toast';
import { withAdminAccess } from 'HOCs';
import { useTranslation } from 'react-i18next';

function Explore({ isModalOpen }) {
  if (isModalOpen) document.body.style.overflow = 'hidden';
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(thumbnailsData[0]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(thumbnailsData[0].content[0]);
  const [scale, setScale] = useState({ number: 60, status: 125 });
  const [isLoading, setIsLoading] = useState(true);
  const [changeTheme] = useChangeThemeMutation();
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const companyTheme = useSelector(getCompanyThemeSelector);
  const theme = getTheme(companyTheme, 'companyPage');
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleThumbnailClick = (item) => {
    setSelectedThumbnail(item);
  };

  const handleTabClick = (item) => {
    setSelectedTab(item);
    const initialThumbnail =
      item.content.find(
        (thumb) => thumb.title.toLowerCase() === selectedThumbnail?.title.toLowerCase()
      ) || item.content[0];

    setSelectedThumbnail(initialThumbnail);
  };

  const handlePlus = () => {
    if (scale.number <= 90) {
      setScale({ number: scale.number + 10, status: scale.status + 25 });
    }
  };

  const handleMinus = () => {
    if (scale.number >= 20) {
      setScale({ number: scale.number - 10, status: scale.status - 25 });
    }
  };

  if (isLoading) {
    return <Loader fullpage />;
  }

  const handleThemeChange = () => {
    if (companyTheme === selectedThumbnail.title.toLowerCase()) {
      changeTheme({ theme: 'default', companyId });
      showSuccessToast(t('theme-deactivated'));
    } else {
      if (selectedThumbnail && selectedThumbnail.title) {
        const themeToActivate = selectedThumbnail.title.toLowerCase();
        changeTheme({ theme: themeToActivate, companyId });
        showSuccessToast(t('good-news-text'));
      }
    }
  };

  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <IoArrowBack size="24" />
            <span>{t('Back')}</span>
          </div>
          <div className={styles.topNav}>
            <div className={styles.buttonsWrapper}>
              <div className={styles.topbtns}>
                {thumbnailsData.map((item) => (
                  <Button
                    bgColor="transparent"
                    color={selectedTab?.tab === item.tab && theme.buttonColor}
                    key={item.tab}
                    style={{
                      borderBottom:
                        selectedTab?.tab === item.tab
                          ? `1px solid ${theme.buttonColor}`
                          : '1px solid transparent'
                    }}
                    onClick={() => handleTabClick(item)}
                  >
                    {t(item.tab)}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.imageResize}>
            {selectedTab && (
              <>
                <p>{scale.status}%</p>
                <button
                  disabled={scale.number <= 20 ? true : false}
                  onClick={handleMinus}
                  className={styles.minusbtn}
                >
                  -
                </button>
                <button disabled={scale.number >= 90} name="add" onClick={handlePlus}>
                  +
                </button>
              </>
            )}
          </div>
          <div className={styles.thumbnails}>
            {selectedTab?.content?.map((item) => (
              <div
                className={
                  selectedThumbnail?.sourceUrl === item.sourceUrl
                    ? `${styles.thumbnailGuide} ${styles.selected}`
                    : styles.thumbnailGuide
                }
                key={item.title}
                onClick={() => handleThumbnailClick(item)}
              >
                <img aria-hidden="true" src={item.thumbnail} alt={item.title} loading="lazy" />
                <p>{t(item.title)}</p>
                {selectedThumbnail?.sourceUrl === item.sourceUrl && (
                  <Button
                    onClick={handleThemeChange}
                    style={{ marginTop: '20px' }}
                    color={
                      companyTheme === item.title.toLowerCase() ? theme.buttonColor : '#747474'
                    }
                  >
                    <p style={{ color: 'gray' }}>
                      {companyTheme === item.title.toLowerCase()
                        ? t('deactivate-template')
                        : t('activate-template')}
                    </p>
                  </Button>
                )}
              </div>
            ))}
          </div>
          {selectedTab && (
            <div
              className={styles.imageWrapper}
              style={{
                width: `${scale.number}vw`
              }}
              width={`${scale.number}vw`}
            >
              <img loading="lazy" src={selectedThumbnail?.sourceUrl} alt="thumbnail" />
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
}

export default withAdminAccess(Explore);
