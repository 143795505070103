import React, { useState, useEffect } from 'react';
import { onFieldChangeProfileModules } from 'helpers';
import DatePicker from 'react-datepicker';
import CreateModal from '../CreateModal/CreateModal';
import styles from './CreateEventModal.module.scss';
import { useForm } from 'react-hook-form';
import { Textarea } from 'components/form';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import ProfilePicker from '../ProfilePicker/ProfilePicker';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import {
  useSaveEventMutation,
  useSaveTeamEventMutation,
  useUpdateEventMutation,
  useGetEventsQuery
} from 'api/event';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { handleAsync } from 'helpers';
import { ReactComponent as Add } from 'assets/icons/addicon.svg';
import { deleteFromS3 } from 'helpers/s3Handler';
import HiddenFileInput from '../HiddenFileInput/HiddenFileInput';
import useUploadBlob from 'hooks/useUploadBlob';
import { combineDateWithTime } from 'helpers/dateHandler';
import { DAY_IN_MILLISECONDS } from 'constants';
import { Loader } from 'components/ui';
import {
  checkEventAttachmentsLimit,
  checkEventParticipantsLimit,
  checkLastCreatedEventDate
} from 'helpers/tier';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { useTranslation } from 'react-i18next';
function CreateEventModal({
  open,
  onClose,
  onSaveEvent,
  selectedSlot,
  eventToBeEditted,
  preSelectedProfileIds,
  teamId,
  t
}) {
  if (!open) return null;
  const { tierData } = useGetUserTierData();
  const [fieldsChange, setFieldsChange] = useState({});
  const [selectedProfiles, setSelectedProfiles] = useState(
    eventToBeEditted || preSelectedProfileIds ? getParticipantsIds() : []
  );
  const [attachmentToBeRemoved, setAttachmentToBeRemoved] = useState([]);
  const [eventAttachments, setEventAttachments] = useState(
    eventToBeEditted ? eventToBeEditted.attachments : []
  );
  const [selectedDate, setSelectedDate] = useState(
    moment(selectedSlot ? selectedSlot : eventToBeEditted?.startDate).toDate()
  );
  const { company, user, profileStatus: currentProfile } = useSelector(getUserStatusSelector);
  const { uploadBlob, isUploading } = useUploadBlob();
  const inputFileRef = React.useRef(null);
  const [saveEvent] = useSaveEventMutation();
  const [saveTeamEvent] = useSaveTeamEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: eventToBeEditted ? eventToBeEditted.name : '',
      description: eventToBeEditted ? eventToBeEditted.description : '',
      mandatory: eventToBeEditted ? eventToBeEditted.mandatory : false,
      disputeHours: eventToBeEditted ? eventToBeEditted.disputeHours : 0
    }
  });
  const { data, isLoading, isError, isSuccess, error } = useGetEventsQuery({
    profileId: currentProfile.id,
    start: new Date(selectedDate).setTime(new Date(selectedDate).getTime() - DAY_IN_MILLISECONDS),
    end: new Date(selectedDate).setTime(new Date(selectedDate).getTime() + DAY_IN_MILLISECONDS),
    comparisons: selectedProfiles
  });
  const closeReset = () => {
    setFieldsChange({});
    onClose();
    reset();
  };
  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  const onSubmitUpdate = async (data) => {
    const [success, message] = checkEventParticipantsLimit(tierData, selectedProfiles.length);
    if (!success) {
      showErrorToast(message);
      return;
    }
    closeReset();
    showSuccessToast(t('Updating Event'));
    const exprObject = {
      ...data,
      from: new Date(eventToBeEditted.startDate),
      startTime: new Date(eventToBeEditted.startDate),
      endTime: getSelectedSlotEndTime(true),
      ...fieldsChange
    };

    //Check if fields are empty
    if (!checkIfFieldsAreValid(exprObject)) return;
    //Check if dates are valid
    const startDateToBeChecked = combineDateWithTime(
      selectedDate,
      fieldsChange.startTime ? fieldsChange.startTime : exprObject.startTime
    );
    const endDateToBeChecked = combineDateWithTime(
      selectedDate,
      fieldsChange.endTime ? fieldsChange.endTime : exprObject.endTime
    );

    if (endDateToBeChecked < startDateToBeChecked) {
      showErrorToast(t('End time cannot be earlier than start time'));
      return;
    }

    //first handle the uploads
    let uploadedAttachments = [];
    if (eventAttachments.length) {
      showSuccessToast(t('Uploading attachments'));
      for (let i = 0; i < eventAttachments.length; i++) {
        if (!eventAttachments[i].file) {
          continue; //for events we already uploaded
        }
        const [status, response] = await handleAsync(
          uploadBlob(eventAttachments[i].file, `events`)
        );
        if (!status) {
          showErrorToast(t('Uploading failed'));
          for (let i = 0; i < uploadedAttachments.length; i++) {
            await handleAsync(deleteFromS3(uploadedAttachments[i]));
          }
          return;
        } else {
          uploadedAttachments.push(response);
        }
      }
    }

    if (eventToBeEditted.attachments) {
      eventToBeEditted.attachments.forEach((attachment) => {
        if (!attachmentToBeRemoved.includes(attachment)) {
          uploadedAttachments.push(attachment);
        }
      });
    }

    //Delete events set to be deleted;
    if (attachmentToBeRemoved.length) {
      showSuccessToast(t('Deleting attachments set to be deleted'));
      await deleteArrayOfLinksFromAws(attachmentToBeRemoved);
    }
    const nonUniqueProfiles = [...selectedProfiles, currentProfile.id];
    let uniqueProfiles = nonUniqueProfiles.filter(function (item, pos) {
      return nonUniqueProfiles.indexOf(item) == pos;
    });

    const eventObject = {
      companyId: company.id,
      startDate: combineDateWithTime(
        selectedDate,
        fieldsChange.startTime ? fieldsChange.startTime : exprObject.startTime
      ),
      endDate: combineDateWithTime(
        selectedDate,
        fieldsChange.endTime ? fieldsChange.endTime : exprObject.endTime
      ),
      participants: uniqueProfiles,
      host: currentProfile.id,
      name: exprObject.name,
      description: exprObject.description,
      attachments: uploadedAttachments ? uploadedAttachments : [],
      mandatory: exprObject.mandatory,
      disputeHours: Number(exprObject.disputeHours),
      userId: user._id,
      userName: user.fullName,
      userEmail: user.email
    };

    const status = await handleAsync(
      updateEvent({ event: { ...eventObject }, eventId: eventToBeEditted.id }).unwrap()
    );

    if (status[0]) {
      showSuccessToast(t('Event Updated'));
      onSaveEvent();
    }
  };

  const onSubmit = async (data) => {
    const participantsCount = selectedProfiles.length + 1;

    const [canCreateEvent, canCreateEventMessage] = checkLastCreatedEventDate(tierData);
    if (!canCreateEvent) {
      showErrorToast(canCreateEventMessage);
      return;
    }

    const [success, message] = checkEventParticipantsLimit(tierData, participantsCount);
    if (!success) {
      showErrorToast(message);
      return;
    }

    // check attachments limit
    const [canUploadAttachments, canUploadAttachmentsMessage] = checkEventAttachmentsLimit(
      tierData,
      eventAttachments.length
    );

    if (!canUploadAttachments) {
      showErrorToast(canUploadAttachmentsMessage);
      return;
    }

    closeReset();
    showSuccessToast(t('Saving Event'));
    //Check if dates are valid
    const startDateToBeChecked = combineDateWithTime(selectedDate, fieldsChange.startTime);
    const endDateToBeChecked = combineDateWithTime(selectedDate, fieldsChange.endTime);

    if (endDateToBeChecked < startDateToBeChecked) {
      showErrorToast(t('End time cannot be earlier than start time'));
      return;
    }

    // TODO: check the logic starting from here, especially the upload part
    //first handle the uploads
    let UploadedAttachments = [];
    if (eventAttachments.length) {
      showSuccessToast(t('Uploading attachments'));
      for (let i = 0; i < eventAttachments.length; i++) {
        const [status, response] = await handleAsync(
          uploadBlob(eventAttachments[i].file, `events`)
        );
        if (!status) {
          showErrorToast(t('Uploading failed'));
          await deleteArrayOfLinksFromAws(UploadedAttachments);
          return;
        } else {
          UploadedAttachments.push(response);
        }
      }
    }

    const exprObject = {
      ...data,
      from: selectedSlot,
      startTime: selectedSlot,
      endTime: getSelectedSlotEndTime(true),
      ...fieldsChange
    };
    const eventObject = {
      companyId: company.id,
      startDate: combineDateWithTime(
        selectedDate,
        fieldsChange.startTime ? fieldsChange.startTime : exprObject.startTime
      ),
      endDate: combineDateWithTime(
        selectedDate,
        fieldsChange.endTime ? fieldsChange.endTime : exprObject.endTime
      ),
      participants: [...selectedProfiles, currentProfile.id],
      host: currentProfile.id,
      name: exprObject.name,
      description: exprObject.description,
      attachments: UploadedAttachments ? UploadedAttachments : [],
      mandatory: exprObject.mandatory,
      disputeHours: Number(exprObject.disputeHours),
      userId: user._id,
      userName: user.fullName,
      userEmail: user.email
    };
    //Check if fields are empty
    if (!checkIfFieldsAreValid(exprObject)) return;

    let status;
    if (!teamId) {
      status = await handleAsync(saveEvent({ event: { ...eventObject } }).unwrap());
    } else {
      status = await handleAsync(
        saveTeamEvent({ event: { ...eventObject }, teamId: teamId }).unwrap()
      );
    }

    if (status[0]) {
      onSaveEvent();
      showSuccessToast(t('Event Created'));
    }
  };

  const checkIfFieldsAreValid = (data) => {
    if (data.name.trim().length === 0) {
      showErrorToast(t('Name cannot be made out of spaces'));
      return false;
    } else if (data.description.trim().length === 0) {
      showErrorToast(t('Description cannot be made out of spaces'));
      return false;
    }
    return true;
  };

  function getSelectedSlotEndTime(isDate) {
    let endDate = selectedSlot ? selectedSlot : new Date(eventToBeEditted?.startDate);
    endDate = new Date(endDate.getTime() + 30 * 60000);

    if (isDate) {
      return endDate;
    }
    return moment(endDate);
  }

  function getParticipantsIds() {
    let profileIds = [];
    if (preSelectedProfileIds) {
      return [...preSelectedProfileIds];
    }
    if (!eventToBeEditted) {
      return [];
    } else {
      eventToBeEditted.participants.forEach((element) => {
        profileIds.push(element.id);
      });
    }
    return profileIds;
  }

  async function deleteArrayOfLinksFromAws(array) {
    for (let i = 0; i < array.length; i++) {
      await handleAsync(deleteFromS3(array[i]));
    }
  }

  const handleFileAddition = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    const objectUrl = URL.createObjectURL(file);
    fileReader.onloadend = async () => {
      setEventAttachments([...eventAttachments, { file, objectUrl }]);
    };
    inputFileRef.current.value = '';
  };

  const removeAttachment = (attachment) => {
    URL.revokeObjectURL(attachment.objectUrl);
    setEventAttachments(
      eventAttachments.filter((current) => {
        return current.objectUrl !== attachment.objectUrl;
      })
    );
  };

  const checkProfileNotBusy = (profile) => {
    let available = true;
    data?.result.forEach((event) => {
      if (eventToBeEditted && event.id === eventToBeEditted.id)
        //don't check for clashes against your own event!
        return;
      if (
        event.participants.some((participant) => participant.id === profile.id) &&
        checkEventBetweenTimeSlots(event)
      ) {
        available = false;
      }
    });
    return available;
  };

  const checkEventBetweenTimeSlots = (event) => {
    let defaults = {};
    if (eventToBeEditted) {
      defaults = {
        from: new Date(eventToBeEditted.startDate),
        startTime: new Date(eventToBeEditted.startDate),
        endTime: getSelectedSlotEndTime(true),
        ...fieldsChange
      };
    } else {
      defaults = {
        from: selectedSlot,
        startTime: selectedSlot,
        endTime: getSelectedSlotEndTime(true)
      };
    }

    const selectedStart = combineDateWithTime(
      selectedDate,
      fieldsChange.startTime ? fieldsChange.startTime : defaults.startTime
    );
    const selectedEnd = combineDateWithTime(
      selectedDate,
      fieldsChange.endTime ? fieldsChange.endTime : defaults.endTime
    );

    const eventStart = new Date(event.startDate);
    const eventEnd = new Date(event.endDate);

    const selectedStartTime = selectedStart.getTime();
    const selectedEndTime = selectedEnd.getTime();
    const eventStartTime = eventStart.getTime();
    const eventEndTime = eventEnd.getTime();
    if (
      (selectedStartTime < eventStartTime &&
        selectedEndTime < eventEndTime &&
        selectedEndTime > eventStartTime) || //is our start point before the eventstart and our end point before the eventend
      (selectedStartTime > eventStartTime &&
        selectedStartTime < eventEndTime &&
        selectedEndTime < eventEndTime) || //is our start and end between the event start and end
      (selectedStartTime > eventStartTime &&
        selectedStartTime < eventEndTime &&
        selectedEndTime > eventEndTime) || //is our start smaller than event end but our end greater than event end
      (selectedStartTime <= eventStartTime && selectedEndTime >= eventEndTime) || //does our selection completely encompas the event
      selectedStartTime == eventStartTime //are both the starts the same?
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setSelectedDate(eventToBeEditted ? new Date(eventToBeEditted?.startDate) : selectedSlot);
  }, [selectedSlot, eventToBeEditted]);

  return (
    <>
      <>
        <CreateModal
          extraClass="eventModal"
          clickEvent={eventToBeEditted ? handleSubmit(onSubmitUpdate) : handleSubmit(onSubmit)}
          handleClose={closeReset}
          ignoreOutsideClick={true}
          guidedButton
        >
          <h1>{!eventToBeEditted ? t('Create New Event') : t('Edit Event')}</h1>
          <form>
            <Textarea
              name="name"
              placeholder={t('Add Event Name')}
              {...register('name', { required: true })}
            />
            {errors.name && <span> {t('Event Name is required')}</span>}
            <Textarea
              name="description"
              placeholder={t('Add description')}
              {...register('description', { required: true })}
            />
            {errors.description && <span>{t('description is required')}</span>}
          </form>
          <div className={styles.card}>
            <div className="onCreateDatePicker">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
                minDate={new Date()}
                showYearDropdown
              />
              <TimePicker
                popupClassName={styles.timePicker}
                onChange={(date) => onFieldsChange('startTime', date.toString())}
                defaultValue={moment(selectedSlot ? selectedSlot : eventToBeEditted?.startDate)}
                showSecond={false}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
              <TimePicker
                popupClassName={styles.timePicker}
                onChange={(date) => onFieldsChange('endTime', date.toString())}
                defaultValue={getSelectedSlotEndTime()}
                showSecond={false}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </div>
          </div>
          <div className={styles.mandatory}>
            <div className={styles.mandatoryTitle}>
              <span>{t('Mandatory Meeting')}</span>
            </div>
            <div className={styles.mandatoryCheck}>
              <label htmlFor="mandatory">{t('Is this meeting mandatory')}: </label>
              <input {...register('mandatory')} name="mandatory" id="mandatory" type="checkbox" />
            </div>
            {watch('mandatory') && (
              <div className={styles.disputeHours}>
                <label htmlFor="disputeHours">{t('Dispute Threshold')}: </label>
                <input
                  {...register('disputeHours')}
                  name="disputeHours"
                  id="disputeHours"
                  type="number"
                  min={0}
                  defaultValue={0}
                />
              </div>
            )}
          </div>
          <div className={styles.attachmentsCard}>
            <span>{t('Attachments')}</span>
            <div className={styles.attachments}>
              {eventAttachments.map((attachment, index) => {
                return (
                  <div className={styles.attachmentcontainer} key={attachment.objectUrl}>
                    <Add
                      className={styles.deletecontent}
                      onClick={() => {
                        if (attachment.file) return removeAttachment(attachment);
                        setAttachmentToBeRemoved([...attachmentToBeRemoved, attachment]);
                        setEventAttachments(
                          eventAttachments.filter((current) => {
                            return current !== attachment;
                          })
                        );
                      }}
                    />
                    {/* {!attachmentToBeRemoved.includes(attachment) ? (
                      <Add
                        className={styles.deletecontent}
                        onClick={() => {
                          ({ attachment });
                          attachment.file
                            ? removeAttachment(attachment)
                            : setAttachmentToBeRemoved([...attachmentToBeRemoved, attachment]);
                        }}
                      />
                    ) : (
                      <Add
                        className={styles.keepcontent}
                        onClick={() => {
                          attachment.file
                            ? removeAttachment(attachment)
                            : setAttachmentToBeRemoved([
                                ...attachmentToBeRemoved.filter((removedAttachment) => {
                                  removedAttachment != attachment;
                                })
                              ]);
                        }}
                      />
                    )} */}
                    <p>
                      {attachment.file
                        ? attachment.file.name.substring(0, 20)
                        : attachment.substring(0, 20)}
                    </p>
                  </div>
                );
              })}
              <HiddenFileInput
                id={`uploadEvent`}
                multiple={false}
                onChange={(e) => handleFileAddition(e.target.files[0])}
                type="file"
                ref={inputFileRef}
              />
              <label htmlFor={`uploadEvent`}>
                <Add className={styles.addContent} />
              </label>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            !teamId && (
              <div className={styles.card}>
                <ProfilePicker
                  selectedProfiles={selectedProfiles}
                  setProfiles={setSelectedProfiles}
                  skipSelf={true}
                  profileAllowedToBePickedFunction={checkProfileNotBusy}
                />
              </div>
            )
          )}
          {teamId?.length && (
            <div className={styles.teamPicked}>
              <h6>{t('Creating Event For Entire Team')}</h6>
            </div>
          )}
        </CreateModal>
      </>
    </>
  );
}

export default CreateEventModal;
