import React from 'react';
import { useSlate } from 'slate-react';
import { Button, Icon } from './Components';
import { isMarkActive, toggleMark } from './Helpers';
import styles from './MWEditor.module.scss';
import { useTranslation } from 'react-i18next';
import { BasicTooltip } from '../Tooltip/Tooltip';

const MarkButton = ({ format, icon, tooltip }) => {
  const editor = useSlate();
  const { t } = useTranslation();
  return (
    <div data-tooltip-id={`${tooltip}-tooltip`}>
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <div className={styles.iconWrapper}>
          <Icon>{icon}</Icon>
        </div>
      </Button>
      <BasicTooltip id={`${tooltip}-tooltip`} variant={'dark'} content={tooltip} />
    </div>
  );
};

export default MarkButton;
