import React from 'react';
import styles from './Text.module.scss';
import PropTypes from 'prop-types';

const Text = ({ title, subheading, paragraph, align, extraClass, tooltipContent, ...rest }) => {
  return (
    <div style={{ textAlign: align }} className={`${styles.sharedtext} ${styles[extraClass]}`}>
      {title && <h2 style={{ fontWeight: rest?.font, fontSize: rest?.fontSize }}>{title}</h2>}
      {subheading && (
        <h3 className={styles.tooltipContainer} style={{ color: rest?.color }}>
          {subheading}
          {tooltipContent && <span className={styles.tooltip}>{tooltipContent}</span>}
        </h3>
      )}
      {paragraph && (
        <p
          style={{
            color: rest?.subColor
          }}
        >
          {paragraph}
        </p>
      )}
    </div>
  );
};

export default Text;

Text.propTypes = {
  align: PropTypes.string,
  title: PropTypes.string,
  subheading: PropTypes.string,
  paragraph: PropTypes.string,
  extraClass: PropTypes.string
};
