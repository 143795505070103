import React, { useState } from 'react';
import { ProfilePic } from 'components/ui';
import AddContentInput from 'components/atoms/AddContentInput/AddContentInput';
import styles from './AddNewsSection.module.scss';
import MWEditor from '../../atoms/MWEditor';
import profileImage from 'assets/images/avatar.webp';
import { getS3Url } from 'helpers/s3Handler';
export default function AddNewsSection({ userStatus }) {
  // const [openEditor, setOpenEditor] = useState(false);
  // const firstName = userStatus.user.fullName.split(' ')[0];
  return (
    <div className={styles.container}>
      <div className={styles.inputHolder}>
        <ProfilePic
          extraClass="newsprofile"
          width={45}
          height={45}
          image={
            userStatus.profileStatus.picture
              ? getS3Url(userStatus.profileStatus.picture)
              : profileImage
          }
        />
        {/* {openEditor ? (
          <AddContentInput placeholder="What's on your mind, " firstName={firstName} />
        ) : (
        )} */}
        <MWEditor userStatus={userStatus} key={'mainEditor'} mediaLinks={[]} postId={'NONE'} />
      </div>
    </div>
  );
}
