import React from 'react';
import styles from './BlobCard.module.scss';
import PdfPng from 'assets/images/pdf.webp';
import WizrPng from 'assets/images/wizr.webp';
import { downloadByUrl, getFileUrlName, getUrlFileType } from 'helpers';
import { MEDIA_TYPES } from 'constants';
const BlobCard = ({
  path,
  name = `file_${Date.now()}`,
  showName = true,
  onImageClick,
  edit,
  isSelected,
  handleToggleSelection
}) => {
  if (!path) return null;
  const blobType = getUrlFileType(path);
  const blobName = getFileUrlName(path) || name;
  const viewItem = () => {
    if (edit) {
      return;
    }

    // NOTE: Customize this function to open the file in a new tab or view it in a modal, etc.
    if (blobType === MEDIA_TYPES.PDF) {
      window.open(path, '_blank');
    } else if (blobType === MEDIA_TYPES.IMAGE) {
      onImageClick ? onImageClick(path) : window.open(path, '_blank');
    } else {
      window.open(path, '_blank');
    }
  };

  const backGroundImage = () => {
    if (blobType === MEDIA_TYPES.PDF) {
      return PdfPng;
    } else if (blobType === MEDIA_TYPES.IMAGE) {
      return path;
    } else {
      return WizrPng;
    }
  };

  const handleImageClick = (event) => {
    if (!edit) {
      event.preventDefault();
      handleToggleSelection();
    }
    viewItem();
  };

  return (
    <label
      onClick={handleImageClick}
      style={{
        backgroundImage: `url(${backGroundImage()})`
      }}
      className={styles.award}
    >
      {edit && (
        <>
          <input type="checkbox" checked={isSelected} onClick={handleToggleSelection} />
        </>
      )}
      {showName && (
        <p className={styles.name}>
          {blobName.length > 25 ? `${blobName.slice(0, 25)}...` : blobName}
        </p>
      )}
    </label>
  );
};

export default BlobCard;
