import React from 'react';
import styles from './Wrapper.module.scss';
import { AddI, CloseI, EditI, SaveI } from 'components/ui';
import Options from 'components/atoms/Options/Options';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';

function Wrapper({
  children,
  px,
  py,
  bg,
  onEditClick,
  onCloseEditClick,
  onAddClick,
  onSubmit,
  isEdit,
  editForbidden,
  extraClass,
  ...rest
}) {
  const isAdmin = useSelector(getIsAdminSelector);
  return (
    <div
      {...rest}
      className={`${styles['wrapper']} ${styles[extraClass]}`}
      style={{
        background:
          bg || 'radial-gradient(121.94% 121.94% at 50% 50%, rgb(62 62 62) 0%, #000000 100%)',
        padding: `${py}rem ${px}rem`
      }}
    >
      {isAdmin && (
        <>
          {' '}
          {onEditClick && (
            <>
              {isEdit ? (
                <div className={styles.closeIcon}>
                  <CloseI onClick={() => onCloseEditClick?.()} />
                  {onAddClick && <AddI onClick={onAddClick} />}
                </div>
              ) : (
                !editForbidden && <Options extraClass="companyOptions" handleEdit={onEditClick} />
              )}
            </>
          )}
          <div className={onAddClick ? styles.editIcons : styles.saveIcon}>
            {isEdit && onSubmit && <SaveI onClick={onSubmit} />}
          </div>
        </>
      )}

      {children}
    </div>
  );
}

export default Wrapper;
