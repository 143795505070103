import React, { useEffect, useRef, useState } from 'react';
import useOnScrollToPageEnd from './useOnScrollToPageEnd';
import { replaceOrAddObjectInArray } from 'helpers';
// NOTE: This hook is used to fetch data from the server with pagination
// IT should be used ONLY when you have data that is fetched when you scroll to the end of the page
// OTHERWISE, CONSIDER USING "useHandleRtkPagination"

/**
 *
 * @description This hook is used to fetch data from the server with pagination
 * @param {function} rtkQuery - the rtk query function
 * @param {object} args - the query args
 * @param {object} options - the query options
 * @param {boolean} disableScroll - if true, the scroll event will be disabled, and in this case you should use the setPage function to fetch the next page
 *
 *
 *
 */
function usePagination(rtkQuery, args, options) {
  const [paginationResult, setPaginationResult] = useState([]);
  const stopFetching = useRef(false); // to prevent fetching when there is no more data hasNextPage = false
  const [page, setPage] = useState(1);
  const rtkResult = rtkQuery({ ...args, page }, options);

  useEffect(() => {
    if (rtkResult?.data?.result) {
      if (page === 1) {
        setPaginationResult(rtkResult?.data.result);
      } else {
        const prevData = [...paginationResult];
        rtkResult?.data.result.map((item) => {
          replaceOrAddObjectInArray(prevData, item, 'id');
        });
        setPaginationResult(prevData);
      }
    }
  }, [rtkResult.data]);

  useEffect(() => {
    if (rtkResult.data?.page?.hasNextPage && !rtkResult.isFetching) stopFetching.current = false;
    else stopFetching.current = true;
  }, [rtkResult.data]);

  useOnScrollToPageEnd(() => {
    if (!stopFetching.current) setPage((prev) => prev + 1);
    stopFetching.current = true;
  }, [rtkResult.data]);
  return { rtkResult, page, setPage, paginationResult };
}
export default usePagination;
