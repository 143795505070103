import React, { useEffect, useState } from 'react';
import styles from './TemplateCard.module.scss';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { ImageOptimized } from 'components/ui/ImageOptimized/ImageOptimized';
import {
  useCreateProfileTemplateMutation,
  useUpdateBannerImageMutation
} from 'api/individual-profile';
import {
  CustomTemplateSections,
  CvTemplates,
  ProjectsLayouts,
  TemplatesNames
} from 'components/organisms/Profile/templates/utils';
import { handleAsync } from 'helpers';
import { useGetProfileActiveTemplate } from 'components/organisms/Profile/hooks/templates';
import SelectedLabel from './SelectedLabel';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';
import { showWarnToast } from 'utils/toast';
import {useTranslation} from "react-i18next";

export default function TemplateCard({ label, description, thumbnail, previewImage, name }) {
  const { tierData } = useGetUserTierData();
  const [updateBannerImage] = useUpdateBannerImageMutation();
  const profileId = useSelector(getCurrentUserProfileId);
  const { t } = useTranslation();

  const { templateType, templateSectionList } = useGetProfileActiveTemplate();
  const isTemplateActive = templateType === name;
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState(
    templateType === CvTemplates['custom'].name ? templateSectionList : []
  );
  const [updateOrCreateTemplate] = useCreateProfileTemplateMutation();

  const handleItemClick = ({ value }) => {
    if (selectedItems.includes(value)) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== value)
      );
    } else {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
    }
  };

  const handleClick = () => {
    const userTier = tierData?.tier;
    if (name === TemplatesNames.custom && userTier === APP_TIERS.LITE) {
      return showWarnToast(t('This feature is not available in the lite plan'));
    }
    setShowModal(true);
  };
  const onTemplateActivate = async () => {
    if (name === TemplatesNames.custom) {
      if (selectedItems.length === 0) return alert(t('Please select at least one section'));

      const sectionList = selectedItems;
      const tabList = CvTemplates?.general?.tabList;
      const type = TemplatesNames.custom;
      const projectLayout = ProjectsLayouts.tripleColumn;
      const body = { type, sectionList, tabList, projectLayout };
      const [status] = await handleAsync(
        updateOrCreateTemplate({ template: body }).unwrap(),
        t('template created successfully'),
        t('error creating template')
      );
      if (status) {
        const bannerImage = CvTemplates[name]?.backgroundImage;
        handleAsync(updateBannerImage({ bannerShade: false, bannerImage, profileId }).unwrap());
        setShowModal(false);
      }
    } else if (!CvTemplates[name]?.sectionList) return alert(t('This template is not available yet'));
    else {
      const dto = {
        type: name,
        sectionList: CvTemplates[name]?.sectionList,
        tabList: CvTemplates[name]?.tabList,
        projectLayout: ProjectsLayouts.tripleColumn
      };
      const [status] = await handleAsync(
        updateOrCreateTemplate({ template: dto }).unwrap(),
        t('template updated successfully'),
        t('error updating template')
      );

      if (status) {
        const bannerImage = CvTemplates[name]?.backgroundImage;
        handleAsync(updateBannerImage({ bannerShade: false, bannerImage, profileId }).unwrap());
        setShowModal(false);
      }
    }
  };

  const ActiveTemplateLabel = () => {
    return (
      <div className={styles.activeLabel}>
        <p>{t("Active Template")}</p>
      </div>
    );
  };
  useEffect(() => {
    if (templateSectionList && templateType === CvTemplates['custom'].name)
      setSelectedItems(templateSectionList);
  }, [templateSectionList]);

  return (
    <div className={styles.profileCard} onClick={handleClick}>
      <div className={`${styles.profileimage} ${styles.imageContainer}`}>
        {isTemplateActive && <ActiveTemplateLabel />}
        <img src={thumbnail} alt={label} className={styles.profileimage} />
      </div>
      <h3 className={styles.name}>{t(label)}</h3>
      <p className={styles.desc}>{t(description)}</p>

      {showModal && (
        <CreateModal
          textSave="Activate"
          clickEvent={onTemplateActivate}
          extraClass={name === TemplatesNames.custom ? 'createTemplates' : 'templatesModal'}
          handleClose={() => setShowModal(false)}
          showSave={true}
        >
          {name === TemplatesNames.custom ? (
            <div>
              <h1 className={styles.title}>{t("The sections will be ordered as you select them")}</h1>
              <div className={styles.createTemplate}>
                {CustomTemplateSections.map((item) => (
                  <div key={item.title}>
                    <div
                      className={styles.imageWrapper}
                      onClick={() => {
                        if (!item.value) return alert(t('This section is not available yet'));
                        handleItemClick(item);
                      }}
                    >
                      <ImageOptimized {...item} />
                      {selectedItems.includes(item.value) && (
                        <SelectedLabel label={selectedItems.indexOf(item.value) + 1} />
                      )}
                    </div>
                    <p className={styles.title}>{t(item.title)}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <h1 className={styles.title}>{label} {t("Template")}</h1>
              <ImageOptimized src={previewImage} alt={label} />
            </div>
          )}
        </CreateModal>
      )}
    </div>
  );
}
