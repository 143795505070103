import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { addObjectToArrayIfNotExist } from 'helpers';
const secondaryUrl = 'post';
import { store } from '../../store';
export const postExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPost: builder.mutation({
      query: ({ post }) => {
        return {
          url: `/v1/${secondaryUrl}`,
          method: 'POST',
          body: post
        };
      },
      async onQueryStarted({ post }, { queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdPostData) => {
            if (!createdPostData) return;
            if (createdPostData.category) {
              store.dispatch(
                apiSlice.util.updateQueryData(
                  'getPostsByCompanyId',
                  { category: post.category },
                  (draft) => {
                    if (draft?.result) draft.result.unshift(createdPostData);
                  }
                )
              );
            }

            store.dispatch(
              apiSlice.util.updateQueryData('getPostsByCompanyId', {}, (draft) => {
                if (draft?.result) draft.result.unshift(createdPostData);
              })
            );
          }
        );
      }
    }),
    getPostsByCompanyId: builder.query({
      query: ({ companyId, category, activePostId, page = 1 }) => {
        const queryString = `page=${page}${category ? `&category=${category}` : ''}${
          activePostId ? `&_id=${activePostId}` : ''
        }`;
        return {
          url: `/v1/${secondaryUrl}/company/${companyId}?${queryString}`
        };
      },
      // always fetch don't cache
      //10/10/23 this must be done because since sometimes we send the activePostId to the query, it's required for notification, when you clicked, it should fetch
      //TODO: 11/17/23  we are after 10/10 changes, we're not using the cache anymore when it comes to categories, so check what can we do here
      keepUnusedDataFor: 0,
      providesTags: (result) => {
        if (result) {
          const { result: posts } = result;
          if (posts) {
            const tags = posts.map((post) => ({
              type: 'Post',
              id: post.id || post._id
            }));
            return [...tags, { type: 'Post', id: 'LIST' }];
          } else {
            return [{ type: 'Post', id: 'LIST' }];
          }
        }
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { category } = queryArgs;

        return category ? `${endpointName}({"category":"${category}"})` : endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a post, the created post is added to the cache, and the last post
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new post
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    }),

    deletePostById: builder.mutation({
      query: ({ postId }) => ({
        url: `/v1/${secondaryUrl}/${postId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ postId, category }, { queryFulfilled }) {
        // TODO: create a usable util function to delete an item from the cache
        let dispatches = [];
        const deleteResult = store.dispatch(
          apiSlice.util.updateQueryData('getPostsByCompanyId', {}, (draft) => {
            const item = draft.result.find((item) => item.id === postId);
            if (item) draft.result.splice(draft.result.indexOf(item), 1);
          })
        );
        dispatches.push(deleteResult);
        if (category) {
          const categoryDeleteResult = store.dispatch(
            apiSlice.util.updateQueryData('getPostsByCompanyId', { category }, (draft) => {
              if (!draft) return;
              const item = draft.result.find((item) => item.id === postId);
              if (item) draft.result.splice(draft.result.indexOf(item), 1);
            })
          );
          dispatches.push(categoryDeleteResult);
        }

        await optimisticUpdater(dispatches, queryFulfilled);
      },
      // fetch the current page .e.g if you deleted in page 2 then fetch page 2 again and only
      // add non-existed posts, this is because the last first post in page 3 will be shifted to page 2
      // and we will not be able to get it in page 3
      invalidatesTags: () => [{ type: 'Post', id: 'LIST' }]
    }),

    updatePostById: builder.mutation({
      query: ({ postId, post }) => ({
        url: `/v1/${secondaryUrl}/${postId}`,
        method: 'PATCH',
        body: post
      }),

      async onQueryStarted({ postId, oldCategory }, { queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (returnedPostData) => {
            if (!returnedPostData) return;
            let isCategoryChanged = false;
            store.dispatch(
              apiSlice.util.updateQueryData(
                'getPostsByCompanyId',
                { category: oldCategory },
                (draft) => {
                  if (!draft) return;
                  isCategoryChanged = oldCategory !== returnedPostData.category;
                  const item = draft.result.find((item) => item.id === returnedPostData.id);
                  // deleted the old post if the category is changed
                  if (isCategoryChanged && item) draft.result.splice(draft.result.indexOf(item), 1);
                  // just update the post if the category is not changed
                  else if (item) Object.assign(item, returnedPostData);
                }
              )
            );

            // here we're adding the post in case the category is changed
            if (isCategoryChanged) {
              store.dispatch(
                apiSlice.util.updateQueryData(
                  'getPostsByCompanyId',
                  { category: returnedPostData.category },
                  (draft) => {
                    if (!draft) return;
                    draft.result.unshift(returnedPostData);
                  }
                )
              );
            }

            store.dispatch(
              apiSlice.util.updateQueryData('getPostsByCompanyId', {}, (draft) => {
                const item = draft.result.find((item) => item.id === postId);
                if (item) Object.assign(item, returnedPostData);
                // item = {...item,...returnedPostData }
              })
            );
          }
        );
      }
    }),
    answerPoll: builder.mutation({
      query: ({ answer }) => {
        return {
          url: `/v1/${secondaryUrl}/poll/answer`,
          method: 'PATCH',
          body: answer
        };
      },
      async onQueryStarted({ postId, oldCategory }, { queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (returnedPostData) => {
            if (!returnedPostData) return;
            let isCategoryChanged = false;
            store.dispatch(
              apiSlice.util.updateQueryData(
                'getPostsByCompanyId',
                { category: oldCategory },
                (draft) => {
                  if (!draft) return;
                  isCategoryChanged = oldCategory !== returnedPostData.category;
                  const item = draft.result.find((item) => item.id === returnedPostData.id);
                  // deleted the old post if the category is changed
                  if (isCategoryChanged && item) draft.result.splice(draft.result.indexOf(item), 1);
                  // just update the post if the category is not changed
                  else if (item) Object.assign(item, returnedPostData);
                }
              )
            );

            // here we're adding the post in case the category is changed
            if (isCategoryChanged) {
              store.dispatch(
                apiSlice.util.updateQueryData(
                  'getPostsByCompanyId',
                  { category: returnedPostData.category },
                  (draft) => {
                    if (!draft) return;
                    draft.result.unshift(returnedPostData);
                  }
                )
              );
            }

            store.dispatch(
              apiSlice.util.updateQueryData('getPostsByCompanyId', {}, (draft) => {
                const item = draft.result.find((item) => item.id === postId);
                const postWithPoll = { poll: returnedPostData.poll };
                if (item) Object.assign(item, postWithPoll);
                // item = {...item,...returnedPostData }
              })
            );
          }
        );
      }
    }),
    getPostById: builder.query({
      query: ({ postId }) => ({
        url: `/v1/${secondaryUrl}/${postId}`
      })
    }),
    sharePostById: builder.mutation({
      query: ({ sharingData }) => ({
        url: `/v1/${secondaryUrl}/share`,
        method: 'POST',
        body: sharingData
      })
    }),
    getSharedPostBySlug: builder.query({
      query: ({ slug, passKey = '' }) => ({
        url: `/v1/${secondaryUrl}/share/post/${slug}?passKey=${passKey}`
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      keepUnusedDataFor: 0
    }),
    reactToPost: builder.mutation({
      query: ({ reaction, postId }) => ({
        url: `/v1/${secondaryUrl}/${postId}/react`,
        method: 'POST',
        body: reaction
      }),
      async onQueryStarted({ postId, reaction, category }, { dispatch, queryFulfilled }) {
        // get the posts in the cache, and update the post with the new reaction
        // check if the user already reacted to the post, if yes then remove the reaction
        // if no then add the reaction
        // do the same for the category
        // update the post reactions in the cache
        const dispatches = [];
        const withOutCategory = dispatch(
          apiSlice.util.updateQueryData('getPostsByCompanyId', {}, (draft) => {
            const item = draft.result.find((item) => item.id === postId);
            if (item) {
              const foundReaction = item.reactions.find(
                (obj) => obj.profileId === reaction.profileId
              );

              if (foundReaction) item.reactions.splice(item.reactions.indexOf(foundReaction), 1);

              if (!foundReaction || (foundReaction && foundReaction.type !== reaction.type)) {
                // we're adding a profile since it's not there when changing the reaction
                item.reactions.push(reaction);
              }
            }
          })
        );

        dispatches.push(withOutCategory);

        if (category) {
          const withCategory = dispatch(
            apiSlice.util.updateQueryData('getPostsByCompanyId', { category }, (draft) => {
              const item = draft.result.find((item) => item.id === postId);
              if (item) {
                const foundReaction = item.reactions.find(
                  (obj) => obj.profileId === reaction.profileId
                );
                if (foundReaction) item.reactions.splice(item.reactions.indexOf(foundReaction), 1);

                if (!foundReaction || (foundReaction && foundReaction.type !== reaction.type)) {
                  // we're adding a profile since it's not there when changing the reaction
                  item.reactions.push(reaction);
                }
              }
            })
          );
          dispatches.push(withCategory);
        }

        const dispatchRes = dispatch(
          apiSlice.util.updateQueryData('getPostReactions', { postId }, (draft) => {
            if (!draft?.reactions) return;
            const foundReaction = draft?.reactions?.find(
              (obj) => obj.profileId === reaction.profileId
            );
            if (foundReaction) draft.reactions.splice(draft.reactions.indexOf(foundReaction), 1);

            if (!foundReaction || (foundReaction && foundReaction.type !== reaction.type)) {
              // we're adding a profile since it's not there when changing the reaction
              draft.reactions.push({
                ...reaction,
                profile: store.getState().user.userStatus.profileStatus
              });
            }
          })
        );

        dispatches.push(dispatchRes);

        await optimisticUpdater(dispatches, queryFulfilled);
      }
    }),

    getPostReactions: builder.query({
      query: ({ postId }) => ({
        url: `/v1/${secondaryUrl}/reactions/${postId}`
      })
    })
  })
});

export const {
  useCreatePostMutation,
  useGetPostsByCompanyIdQuery,
  useDeletePostByIdMutation,
  useUpdatePostByIdMutation,
  useAnswerPollMutation,
  useGetPostByIdQuery,
  useSharePostByIdMutation,
  useGetSharedPostBySlugQuery,
  useReactToPostMutation,
  useGetPostReactionsQuery
} = postExtendedSlice;
