import React from 'react';
import { Helmet } from 'react-helmet';
import SinglePost from 'components/organisms/SinglePost/SinglePost';
import { Loader } from 'components/ui';
import { getS3Url } from 'helpers';
import profileImage from 'assets/images/avatar.webp';
import { serialize } from 'components/atoms/MWEditor/Helpers';
import useGetSharedPost from 'hooks/useGetSharedPost';
const SinglePostPage = () => {
  document.body.style.overflow = '';

  const [result, goBack] = useGetSharedPost();
  if (result.isLoading || result.status != 'fulfilled') {
    return <Loader fullpage />;
  }
  return (
    <>
      <Helmet>
        <title>Post</title>
        <meta name="description" content="Single post from the news hub" />
      </Helmet>
      <SinglePost
        employeeImage={
          result.data?.profile?.picture ? getS3Url(result.data?.profile?.picture) : profileImage
        }
        employeeName={`${result.data?.profile?.contact?.firstName || 'Unknown'} ${
          result.data?.profile?.contact?.lastName || 'Unknown'
        }`}
        time={result.data.createdAt}
        postId={result.data.id || result.data._id}
        ownerProfileId={result.data?.profile?.id}
        content={result.data.content}
        media={result.data.media}
        commentCount={result.data.commentsCount || 0}
        profiles={result.data.profiles}
        category={result.data.category}
        poll={result.data.poll}
        pollAnswers={result.data.pollAnswers}
        isLoading={false}
        cover={result.data.cover}
      >
        <div dangerouslySetInnerHTML={{ __html: serialize(JSON.parse(result.data.content)) }} />
      </SinglePost>
    </>
  );
};

export default SinglePostPage;
