import styles from './SidewaysProfile.module.scss';
import defaultProfileImage from '../../../assets/images/avatar.webp';
import { getS3Url } from 'helpers/s3Handler';
export default function SidewaysProfile({ image, fullName, role }) {
  return (
    <div className={styles.profileRow}>
      <img
        className={styles.profileImage}
        src={image ? getS3Url(image) : defaultProfileImage}
        alt="profile image"
      />
      <div className={styles.nameRole}>
        <h4>{fullName}</h4>
        <h5>{role}</h5>
      </div>
    </div>
  );
}
