import React from 'react';
import styles from "./styles.module.scss";

const ChatNewGroupModal = ({ closeAddGroupModal }) => {
  return (
    <>
      <div onClick={closeAddGroupModal} className={styles.modalUnderlay} />
      <div className={styles.modalContainer}>
        <div onClick={closeAddGroupModal} className={styles.close}>x</div>
        <h3 className={styles.title}>Create new group</h3>
        <div className={styles.desc}>Create a space where your teams can communicate, best organised around topic of discussion/team name e.g #marketing</div>

        <div className={styles.inputLabel}>Group members</div>
        <input className={styles.inputbox} placeholder="#Design_team" />

        <div className={styles.inputLabel}>Add Members</div>
        <input className={styles.inputbox} />

        <div className={styles.footer}>
          <button>Create</button>
        </div>

      </div>
    </>
  )
}

export default ChatNewGroupModal;
