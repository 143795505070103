import React, { useEffect, useState } from 'react';
import styles from './EducationsSection.module.scss';
import { ProfilePic } from 'components/ui';
import { EditInput } from 'components/form';
import DatePicker from 'react-datepicker';
import { CustomDropdown } from 'components/form';
import { objectsEqual_ByKeys, onFieldChangeProfileModules } from 'helpers';
import { getMonthAndYearName } from 'helpers/dateHandler';
import { useSelector } from 'react-redux';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import Options from 'components/atoms/Options/Options';

export default function EducationCard({
  institution,
  id,
  from,
  to,
  degree,
  grade,
  specialization,
  location,
  isCurrentUserProfile,
  ongoing,
  isPlaceholdersActive,
  ...rest
}) {
  const originValues = {
    institution,
    id,
    from,
    to,
    ongoing,
    degree,
    grade,
    specialization,
    location
  };
  const [edit, setEdit] = useState(false);
  // const [ongoing, setOngoing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState({ ...originValues });
  const globalEdit = useSelector(getGlobalEditSelector);

  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  return (
    <div className={styles.eduCard}>
      {isCurrentUserProfile && !isPlaceholdersActive && (
        <Options
          extraClass="profileOptions"
          handleEdit={() => setEdit(true)}
          handleDiscard={() => setEdit(false)}
          handleSave={() => {
            const objectsEqual = objectsEqual_ByKeys(fieldsChange, originValues);
            if (objectsEqual) return setEdit(false);
            rest.onUpdateSubmit(fieldsChange);
            setEdit(false);
          }}
          handleDelete={() => setDeleteModal(true)}
          edit={edit}
        />
      )}
      {edit ? (
        <>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic extraClass={rest?.extraClass} image={rest?.img} width={52} height={52} />
              <h2 className={styles.editField}>
                <EditInput
                  maxLength={100}
                  placeholder="Insitution Name"
                  onChange={({ target }) => onFieldsChange('institution', target.value)}
                  fontWeight="bold"
                  value={fieldsChange.institution}
                />
              </h2>
            </div>
            <div className={styles.content}>
              <h3 className={styles.editField}>
                <EditInput
                  maxLength={100}
                  placeholder="Your specialization"
                  value={fieldsChange.specialization}
                  onChange={({ target }) => onFieldsChange('specialization', target.value)}
                />
              </h3>
              <p className={`${styles.location} ${styles.editField} ${styles.locationField}`}>
                <EditInput
                  maxLength={100}
                  placeholder="Institution location"
                  value={fieldsChange.location}
                  onChange={({ target }) => onFieldsChange('location', target.value)}
                />
              </p>
              <div>
                <CustomDropdown
                  onChange={(value) => onFieldsChange('grade', value)}
                  placeholder="Choose"
                  value={fieldsChange.grade}
                  extraClass="educationDropdown"
                  list={rest.gradesList}
                />
              </div>

              {/* ON HOLD */}
              <div>
                <CustomDropdown
                  onChange={(value) => onFieldsChange('degree', value)}
                  value={fieldsChange.degree}
                  placeholder="Choose"
                  extraClass="degreeDropdown"
                  list={rest.degreesList}
                />
              </div>
              <div className={styles.datepicker}>
                {from && (
                  <DatePicker
                    selected={new Date(fieldsChange.from)}
                    maxDate={new Date()}
                    onChange={(date) => onFieldsChange('from', date.toString())}
                    showYearDropdown
                  />
                )}
                {to && (
                  <>
                    <DatePicker
                      selected={new Date(fieldsChange.to)}
                      maxDate={new Date()}
                      minDate={new Date(fieldsChange.from)}
                      onChange={(date) => onFieldsChange('to', date.toString())}
                      showYearDropdown
                    />
                  </>
                )}
                {/* <div className={styles.inprogress}>
                  <span>In progress</span>
                  <input
                    name="ongoing"
                    id="ongoing"
                    checked={fieldsChange.ongoing}
                    type="checkbox"
                    onChange={(event) => {
                      onFieldsChange('ongoing', event.target.checked);
                    }}
                  />
                  <label htmlFor="ongoing" className={styles.checkmark} />
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic extraClass={rest?.extraClass} image={rest?.img} width={52} height={52} />
              <h2>{institution}</h2>
            </div>
            <div className={styles.content}>
              <h3>{specialization}</h3>
              <p className={styles.location}>{location}</p>
              <p className={styles.contract}>{grade} </p>
              {/* ON HOLD */}
              <p className={`${styles.contract} ${styles.degree}`}>{degree} </p>
              {from && to && (
                <p className={styles.date}>
                  {from && getMonthAndYearName(from)} - {to && getMonthAndYearName(to)}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          title="Are you sure you want to delete this education ?"
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => rest.onDeleteSubmit(id)}
        />
      )}
    </div>
  );
}
