import React, { useState } from 'react';
import styles from './QuoteSection.module.scss';
import quoteBg from 'assets/images/quoteSectionBG.webp';
import { useQuoteUtils } from 'components/organisms/Profile/hooks/templates';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import Options from 'components/atoms/Options/Options';
import { showErrorToast } from 'utils/toast';
import { Textarea } from 'components/form';
import { Loader } from 'components/ui';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import { getTheme } from 'helpers/general';
import { getCompanyThemeSelector } from 'store/login.slice';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { getS3Url } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';

const defaultSectionName = 'Quote I Live By';
function QuoteSectionView({ data, sectionTitle, isFetching }) {
  const userId = useSelector(getCurrentUserIdSelector);
  const [edit, setEdit] = useState(false);
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});
  const [quoteText, setQuoteText] = useState(data);
  const { onUpdateSubmit, isCurrentUserProfile } = useQuoteUtils({ userId });
  const [selectedImage, setSelectedImage] = useState(null);
  const [editBanner, setEditBanner] = useState(false);
  const getBannerImage = () => {
    if (isSharedMode()) {
      if (sharedData?.data) {
        const bnrImg = sharedData.data.sharedProfile?.bannerImage;
        return bnrImg && getS3Url(bnrImg);
      }
    }
  };
  const [bannerImage, setBannerImage] = useState(getBannerImage());
  const onSubmit = () => {
    const quote = quoteText.trim();
    onUpdateSubmit(quote);
    setEdit(false);
  };
  const companyTheme = useSelector(getCompanyThemeSelector);
  const theme = getTheme(companyTheme, 'singleProfile');
  const getBannerImageUrl = () => {
    if (companyTheme === 'default') {
      return editBanner ? URL.createObjectURL(selectedImage) : bannerImage;
    } else if (isSharedMode()) return bannerImage;
    return theme?.defaultProfileBackground;
  };
  return (
    <div className={styles.quoteSection}>
      <div className={styles.container}>
        {isCurrentUserProfile && (
          <Options
            extraClass="quoteOptions"
            handleDiscard={() => setEdit(false)}
            handleEdit={() => setEdit(true)}
            edit={edit}
            handleSave={() => {
              if (!quoteText || quoteText.length === 0) {
                showErrorToast('Quote is not allowed to be empty!');
              } else {
                onSubmit();
              }
            }}
          />
        )}
        <div
          className={styles.textContainer}
          style={{
            backgroundImage: `url(${quoteBg})`
          }}
        >
          <h3 className={styles.sectionTitle}>{sectionTitle ?? defaultSectionName}</h3>
          {edit && (
            <div className={styles.quoteInput}>
              <Textarea
                maxLength={140}
                value={quoteText}
                onChange={({ target: { value } }) => {
                  setQuoteText(value);
                }}
              />
            </div>
          )}
          {!edit && <p className={styles.quote}>{quoteText}</p>}
        </div>
      </div>
    </div>
  );
}

function QuoteSection({ ...args }) {
  const [sharedDate] = useGetSharedProfile();

  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId: args?.profileId
    },
    {
      skip: isSharedMode() || !args?.profileId
    }
  );
  const { data, isLoading, isError, isFetching } = !isSharedMode() ? result : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {isError?.data?.message}
      </div>
    );
  }
  const quote = data?.bio?.quote;
  return (
    <QuoteSectionView
      sectionTitle={defaultSectionName}
      data={quote && quote?.length !== 0 ? quote : placeHolder}
      isFetching={isFetching}
    />
  );
}

const placeHolder =
  'Placeholder - Embrace the journey, cherish the moments, and thrive in the unknown. Life is not about waiting for the storm to pass, but learning to dance in the rain.';
export default QuoteSection;
