import { uploadBlobToAWS } from 'helpers/s3Handler';
import React from 'react';
import useUpdateEffect from './useUpdateEffect';
import { checkFileUploadLimit } from 'helpers/tier';
import useGetUserTierData from './useGetUserTierData';

/**
 * @description This hook is used to upload multiple files to AWS S3
 * @returns {Object} {uploadBlobs, isUploading, totalUploadProgress}
 * @example
 * const { uploadBlobs, isUploading, totalUploadProgress } = useUploadBlobs();
 * const handleUpload = async (files) => {
 * const { keys, failedOnes } = await uploadBlobs(files);
 */

function useUploadBlobs() {
  const { tierData } = useGetUserTierData();
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState([]); // [{loaded, total, name}]
  const [totalUploadProgress, setTotalUploadProgress] = React.useState({}); // {loaded, total}
  useUpdateEffect(() => {
    // set total progress by adding all upload progress
    const total = uploadProgress.reduce((acc, item) => {
      return acc + item.total;
    }, 0);

    const loaded = uploadProgress.reduce((acc, item) => {
      return acc + item.loaded;
    }, 0);

    setTotalUploadProgress({ total, loaded });
  }, [uploadProgress]);
  const uploadBlobs = React.useCallback(async (files, path = '', onUploadDone) => {
    const [canUpload, message] = checkFileUploadLimit(tierData, files);
    if (!canUpload) {
      throw message;
    }

    try {
      setIsUploading(true);
      const response = await Promise.allSettled(
        files.map((file) =>
          uploadBlobToAWS(file, `wizrx${path ? `/${path}` : ''}`, ({ total, loaded }) => {
            // update total progress
            const fileName = file.name;
            setUploadProgress((prev) => {
              const updated = prev.filter((item) => item.name !== fileName);
              return [...updated, { total, loaded, name: fileName }];
            });
          })
        )
      );
      const succeededOnes = response
        .filter((item) => item.status === 'fulfilled')
        .map((item) => item.value);

      const failedOnes = response.filter((item) => item.status !== 'fulfilled');
      return { keys: succeededOnes, failedOnes };
    } catch (error) {
      error;
      const isErrorString = typeof error === 'string';
      throw isErrorString ? error : 'Error uploading files';
    } finally {
      setIsUploading(false);
      setUploadProgress([]);
      setTotalUploadProgress({});
      onUploadDone?.();
    }
  }, []);

  return { uploadBlobs, isUploading, totalUploadProgress };
}

export default useUploadBlobs;
