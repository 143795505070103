import React from 'react';
import styles from './AddContentInput.module.scss';
import PropTypes from 'prop-types';
import { ReactComponent as Emoji } from '../../../assets/icons/emoji.svg';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg';
import ReactTextareaAutosize from 'react-textarea-autosize';

export default function AddContentInput({ placeholder, firstName, maxLength = 1000 }) {
  return (
    <div className={styles.container}>
      <button className={styles.emoji} name="emoji">
        <Emoji />
      </button>
      <ReactTextareaAutosize maxLength={maxLength} placeholder={placeholder + firstName} />
      <button className={styles.arrow} name="arrow">
        <Arrow />
      </button>
    </div>
  );
}

AddContentInput.propTypes = {
  placeholder: PropTypes.string,
  firstName: PropTypes.string
};
