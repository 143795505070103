import ErrorPageView from 'components/organisms/ErrorPage/ErrorPageView';
import React from 'react';

function ErrorPage() {
  document.body.style.overflow = '';

  return <ErrorPageView />;
}

export default ErrorPage;
