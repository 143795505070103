import { Textarea } from 'components/form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { Button } from 'components/ui';
import { debounce } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import styles from './StatisticsSection.module.scss';

function CreateStatsModal({ isSaving, onClose, open, onSaveStats }) {
  if (!open) return null;
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      proficiency: ''
    }
  });

  const closeReset = () => {
    onClose();
    reset();
  };
  const submitFun = async (data) => {
    const statObject = { ...data };
    const status = await onSaveStats(statObject);
    status && closeReset();
  };
  const onSubmit = debounce(handleSubmit(submitFun), 300);

  return (
    <>
      <CreateModal
        isSubmitting={isSaving}
        clickEvent={submitFun}
        showCancel={false}
        showSave={false}
      >
        <h1>{'Create New Stats'}</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Textarea
            placeholder="Stats Name"
            {...register('name', {
              required: {
                value: true,
                message: 'Name is required'
              },
              maxLength: {
                value: 50,
                message: 'Name must be less than 50 characters'
              },
              validate: (value) => value.trim().length > 0 || 'Name cannot be empty'
            })}
          />
          {errors.name && <span className="input-error">{errors.name.message}</span>}

          <Textarea
            placeholder="Stats description"
            {...register('description', {
              required: {
                value: true,
                message: 'Description is required'
              },
              maxLength: {
                value: 300,
                message: 'Description must be less than 300 characters'
              },
              validate: (value) => value.trim().length > 0 || 'Description cannot be empty'
            })}
          />
          {errors.description && <span className="input-error"> {errors.description.message}</span>}

          <Textarea
            placeholder="Stats proficiency"
            {...register('proficiency', {
              required: {
                value: true,
                message: 'Proficiency is required'
              },
              validate: {
                isNumber: (value) => Number.isInteger(value) || 'Proficiency must be a number',
                lessThan100: (value) => value <= 100 || 'Proficiency must be less than 100',
                moreThan0: (value) => value > 0 || 'Proficiency must be more than 0'
              },
              valueAsNumber: true
            })}
          />
          {errors.proficiency && <span className="input-error"> {errors.proficiency.message}</span>}

          <div className={styles.createModal}>
            <Button color="white" bgColor="#41414185" type="submit" disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
            <Button
              onClick={() => {
                closeReset();
              }}
              color="white"
              type="button"
              bgColor={isSaving ? 'gray' : '#079677'}
            >
              cancel
            </Button>
          </div>
        </form>
      </CreateModal>
    </>
  );
}

export default CreateStatsModal;
