import { optimisticUpdater } from 'api/utils';
import { apiSlice } from '../apiSlice';

// represent the controller
const secondaryUrl = `award`;

export const awardExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteAward: builder.mutation({
      query: ({ selectedAwardIds }) => ({
        url: `/v1/${secondaryUrl}/delete`,
        method: 'PATCH',
        body: { awards: selectedAwardIds }
      }),
      onQueryStarted: async ({ selectedAwardIds }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getAwardsAndAchievementsByUserId', {}, (draft) => {
            const result = draft.award.filter((award) => !selectedAwardIds.includes(award.id));
            draft.award = result;
          })
        );

        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Award', id: 'LIST' }]));
        });
      }
    }),

    /*updateAward: builder.mutation({//Deprecated
      query: ({ awardId, award }) => ({
        url: `/v1/${secondaryUrl}/${awardId}`,
        method: 'PATCH',
        body: award
      }),
      invalidatesTags: ['Award']
    }),*/

    saveAward: builder.mutation({
      query: ({ award }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: award
      }),

      invalidatesTags: () => [{ type: 'Award', id: 'LIST' }]
    })
    /*getAwardsByUserId: builder.query({//Deprecated
      query: ({ userId, page = 1 }) => ({
        url: `/v1/${secondaryUrl}/user/${userId}?page=${page}`
      })
    })*/
  })
});

export const {
  useDeleteAwardMutation,
  //useUpdateAwardMutation,
  useSaveAwardMutation
  //useGetAwardsByUserIdQuery
} = awardExtendedSlice;
