import CreativeT from '../CreativeT';
import FashionT from '../FashionT';
import GeneralT from '../GeneralT';
import MentorT from '../MentorT';
import OlympicsT from '../OlympicsT';
import QuestionsAnswersT from '../QuestionsAnswersT';
import ResearcherT from '../ResearcherT';
import ShortT from '../ShortT';
import SwimT from '../SwimT';
import TechMentorT from '../TechMentorT';
import TechT from '../TechT';
import VideoEditorT from '../VideoEditorT';

import {
  ContactMeSection,
  InspireInstructSection,
  TestimonialsSection,
  WhyMeSection,
  ExperiencesSection,
  QuotesSection,
  EducationsSection,
  InterestsSection,
  InvolvementsSection,
  LanguagesSection,
  SkillsSection,
  ToolsSection,
  AboutMe,
  TechsSection,
  ProjectExperienceSection,
  CoverLetterSection,
  QandASection,
  ParagraphSection,
  StatisticsSection,
  AchievementsSection,
  WhyThisOpportunitySection,
  ResourcesSection
} from '../sections';
import ProjectsSection from '../sections/ProjectsSection/ProjectsSection';

const TemplatesSectionsNames = {
  aboutMeProjects: 'aboutMeProjects',
  education: 'education',
  experience: 'experience',
  achievements: 'achievements',
  interests: 'interests',
  involvements: 'involvements',
  languages: 'languages',
  quotes: 'quotes',
  shortBio: 'shortBio',
  skills: 'skills',
  tools: 'tools',
  whyMe: 'whyMe',
  contact: 'contact',
  testimonials: 'testimonials',
  techs: 'techs',
  projectExperience: 'projectExperience',
  projects: 'projects',
  portfolio: 'portfolio',
  resources: 'resources',
  statistics: 'statistics',
  inspireInstruct: 'inspireInstruct',
  coverLetter: 'coverLetter',
  qanda: 'qanda',
  paragraph: 'paragraph',
  whyThisOpportunity: 'whyThisOpportunity'
};
export const TabsList = {
  home: 'home',
  role: 'role',
  awards: 'awards',
  reviews: 'reviews',
  workingHours: 'workingHours',
  itEssentials: 'itEssentials'
};
export const TemplatesNames = {
  creative: 'creative',
  fashionDesigner: 'fashionDesigner',
  general: 'general',
  mentor: 'mentor',
  olympics: 'olympics',
  questionsAnswers: 'question_answers',
  qa: 'qa',
  researcher: 'researcher',
  short: 'short',
  swim: 'swim',
  techMentor: 'techMentor',
  tech: 'tech',
  videoEditor: 'videoEditor',
  custom: 'custom',
  paragraph: 'paragraph',
  statistics: 'statistics'
};

export const TemplatesCategories = {
  personalizedTemplates: 'personalized-templates',
  popularTemplates: 'popular-templates'
};

// create ENUM
export const TemplatesSectionsComponents = {
  [TemplatesSectionsNames.aboutMeProjects]: null,
  [TemplatesSectionsNames.education]: EducationsSection,
  [TemplatesSectionsNames.experience]: ExperiencesSection,
  [TemplatesSectionsNames.interests]: InterestsSection,
  [TemplatesSectionsNames.involvements]: InvolvementsSection,
  [TemplatesSectionsNames.languages]: LanguagesSection,
  [TemplatesSectionsNames.quotes]: QuotesSection,
  [TemplatesSectionsNames.shortBio]: AboutMe,
  [TemplatesSectionsNames.skills]: SkillsSection,
  [TemplatesSectionsNames.tools]: ToolsSection,
  [TemplatesSectionsNames.whyMe]: WhyMeSection,
  [TemplatesSectionsNames.contact]: ContactMeSection,
  [TemplatesSectionsNames.testimonials]: TestimonialsSection,
  [TemplatesSectionsNames.techs]: TechsSection,
  [TemplatesSectionsNames.projects]: ProjectsSection,
  [TemplatesSectionsNames.projectExperience]: ProjectExperienceSection,
  [TemplatesSectionsNames.resources]: ResourcesSection,
  [TemplatesSectionsNames.statistics]: StatisticsSection,
  [TemplatesSectionsNames.inspireInstruct]: InspireInstructSection,
  [TemplatesSectionsNames.coverLetter]: CoverLetterSection,
  [TemplatesSectionsNames.qanda]: QandASection,
  [TemplatesSectionsNames.paragraph]: CoverLetterSection,
  [TemplatesSectionsNames.achievements]: AchievementsSection,
  [TemplatesSectionsNames.paragraph]: ParagraphSection,
  [TemplatesSectionsNames.whyThisOpportunity]: WhyThisOpportunitySection
};
export const ProjectsLayouts = {
  doubleColumn: 'double column',
  tripleColumn: 'triple column',
  mixedColumns: 'mixed columns',
  hierarchicalGrid: 'hierarchical grid',
  tallColumnCarousel: 'tall column carousel',
  carouselEdited: 'carousel'
};

export const CvTemplates = {
  [TemplatesNames.creative]: {
    name: TemplatesNames.creative,
    component: CreativeT,
    sectionsTitles: {
      education: 'Education-creative'
    },
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15346_rhphi5.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/creative_person_zg81h3.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Creative',
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.tools,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.projects,
      TemplatesSectionsNames.portfolio,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [TabsList.home, TabsList.reviews, TabsList.workingHours, TabsList.itEssentials],
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15035_2_1_t1w5we.webp'
  },
  [TemplatesNames.fashionDesigner]: {
    name: TemplatesNames.fashionDesigner,
    component: FashionT,
    sectionsTitles: {
      education: 'courses-and-certifications',
      skills: 'Skills I bring'
    },
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15347_fjidb0.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/fashion_designer_ngfgvv.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Fashion Designer',
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.projects,
      TemplatesSectionsNames.portfolio,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [TabsList.home, TabsList.reviews, TabsList.workingHours, TabsList.itEssentials],
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15366_1_ds5lyu.webp'
  },
  [TemplatesNames.general]: {
    name: TemplatesNames.general,
    component: GeneralT,
    sectionsTitles: {
      [TemplatesSectionsNames.education]: 'courses-and-certifications',
      [TemplatesSectionsNames.tools]: 'Technical Skills',
      [TemplatesSectionsNames.skills]: 'Skills I bring'
    },
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15340_v6rb1i.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/general_enq3uo.webp',
    category: TemplatesCategories.popularTemplates,
    label: 'General',
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.projectExperience,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.tools,
      TemplatesSectionsNames.projects,
      TemplatesSectionsNames.whyThisOpportunity,
      TemplatesSectionsNames.whyMe,
      TemplatesSectionsNames.testimonials,
      TemplatesSectionsNames.portfolio,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.languages,
      TemplatesSectionsNames.contact
    ],
    tabList: [
      TabsList.home,
      TabsList.awards,
      TabsList.reviews,
      TabsList.workingHours,
      TabsList.itEssentials
    ],
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15035_4_1_tnf0zx.webp'
  },
  [TemplatesNames.mentor]: {
    name: TemplatesNames.mentor,
    component: MentorT,
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.tools,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.inspireInstruct,
      TemplatesSectionsNames.languages,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [
      TabsList.home,
      TabsList.awards,
      TabsList.reviews,
      TabsList.workingHours,
      TabsList.itEssentials
    ],
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/mentor_n3knas.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/mentor_n3knas.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Mentor',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/mentor_background_pthv7n.webp'
  },
  [TemplatesNames.olympics]: {
    name: TemplatesNames.olympics,
    component: OlympicsT,
    sectionsTitles: {
      education: 'courses-and-certifications'
    },
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.statistics,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.achievements,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.languages,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.resources,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [TabsList.home, TabsList.reviews, TabsList.workingHours, TabsList.itEssentials],
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15345_1_jn0yj4.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/olympics_5_l3pbld.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Olympics',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15035_1_zxyxfh.webp'
  },
  [TemplatesNames.questionsAnswers]: {
    name: TemplatesNames.questionsAnswers,
    component: QuestionsAnswersT,
    sectionsTitles: {
      education: 'Education-questionsAnswers'
    },

    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15342_lekd0b.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/questions_and_answers_1_mxvvlq.webp',
    label: 'questions-and-answers',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15364_1_rjxlap.webp'
  },
  [TemplatesNames.researcher]: {
    name: TemplatesNames.researcher,
    component: ResearcherT,
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.projects,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.languages,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [
      TabsList.home,
      TabsList.awards,
      TabsList.reviews,
      TabsList.workingHours,
      TabsList.itEssentials
    ],
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/researcher_pxdmde.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/researcher_pxdmde.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Researcher',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/researcher_background_mzmbbe.webp'
  },
  [TemplatesNames.short]: {
    name: TemplatesNames.short,
    component: ShortT,
    sectionsTitles: {
      education: 'Education-short'
    },
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15341_ai75vc.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/short_cv_kbfwee.webp',
    category: TemplatesCategories.popularTemplates,
    label: 'Short CV',
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.tools,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.portfolio,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.languages,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [TabsList.home, TabsList.reviews, TabsList.workingHours, TabsList.itEssentials],
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15035_2_se6ao3.webp'
  },
  [TemplatesNames.swim]: {
    name: TemplatesNames.swim,
    component: SwimT,
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.achievements,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.languages,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.inspireInstruct,
      TemplatesSectionsNames.resources,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [
      TabsList.home,
      TabsList.awards,
      TabsList.reviews,
      TabsList.workingHours,
      TabsList.itEssentials
    ],
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/swim_wpsg9m.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/swim_wpsg9m.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Swim',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/swim_background_kl8nsw.webp'
  },
  [TemplatesNames.techMentor]: {
    name: TemplatesNames.techMentor,
    component: () => <div>techMentor</div>,
    sectionsTitles: {
      [TemplatesSectionsNames.paragraph]: 'Cover Letter',
      [TemplatesSectionsNames.coverLetter]: 'Cover Letter'
    },
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.coverLetter,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [
      TabsList.home,
      TabsList.awards,
      TabsList.reviews,
      TabsList.workingHours,
      TabsList.itEssentials
    ],
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15343_db7kv6.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/tech_mentor_krpmzy.webp',
    category: TemplatesCategories.popularTemplates,
    label: 'Tech Mentor',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15363_1_1_rloa45.webp'
  },
  [TemplatesNames.tech]: {
    name: TemplatesNames.tech,
    component: TechT,
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.projects,
      TemplatesSectionsNames.testimonials,
      TemplatesSectionsNames.portfolio,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [
      TabsList.home,
      TabsList.awards,
      TabsList.reviews,
      TabsList.workingHours,
      TabsList.itEssentials
    ],
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/tech_person_qo9its.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/tech_person_qo9its.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Tech Person',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/tech_person_background_jpxsfe.webp'
  },
  [TemplatesNames.videoEditor]: {
    name: TemplatesNames.videoEditor,
    component: VideoEditorT,
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.experience,
      TemplatesSectionsNames.education,
      TemplatesSectionsNames.projects,
      TemplatesSectionsNames.portfolio,
      TemplatesSectionsNames.skills,
      TemplatesSectionsNames.interests,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [
      TabsList.home,
      TabsList.awards,
      TabsList.reviews,
      TabsList.workingHours,
      TabsList.itEssentials
    ],
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/video_editor_lorg8v.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/video_editor_lorg8v.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Video Editor',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/video_editor_background_diz1n4.webp'
  },
  [TemplatesNames.paragraph]: {
    name: TemplatesNames.paragraph,
    component: () => <div>paragraph</div>
  },
  [TemplatesNames.custom]: {
    name: TemplatesNames.custom,
    component: null,
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15344_m4rwcg.webp',
    category: TemplatesCategories.personalizedTemplates,
    label: 'Create your own',
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15362_1_zwd9gd.webp'
  },
  [TemplatesNames.qa]: {
    name: TemplatesNames.qa,
    sectionList: [
      TemplatesSectionsNames.shortBio,
      TemplatesSectionsNames.qanda,
      TemplatesSectionsNames.quotes,
      TemplatesSectionsNames.contact
    ],
    tabList: [TabsList.home, TabsList.reviews, TabsList.workingHours, TabsList.itEssentials],
    label: 'Q & A',
    component: QuestionsAnswersT,
    description: 'This CV has all the sections available to edit at your own preferences.',
    thumbnail: 'https://cdn.wizrx.wizrapps.com/Rectangle_15342_lekd0b.webp',
    previewImage: 'https://cdn.wizrx.wizrapps.com/questions_and_answers_1_mxvvlq.webp',
    category: TemplatesCategories.popularTemplates,
    backgroundImage: 'https://cdn.wizrx.wizrapps.com/Rectangle_15364_1_rjxlap.webp'
  }
};

export const CustomTemplateSections = [
  {
    title: 'Short Bio',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22112_cap7mo.webp',
    value: TemplatesSectionsNames.shortBio
  },
  {
    title: 'Experience',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22113_d4shfg.webp',
    value: TemplatesSectionsNames.experience
  },
  {
    title: 'courses-and-certifications',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22114_gisqh9.webp',
    value: TemplatesSectionsNames.education
  },
  {
    title: 'Skills',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22115_w5ngmt.webp',
    value: TemplatesSectionsNames.skills
  },
  {
    title: 'Tools I use',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22116_p7hhlu.webp',
    value: TemplatesSectionsNames.tools
  },
  {
    title: 'Languages',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22117_fmogmn.webp',
    value: TemplatesSectionsNames.languages
  },
  {
    title: 'interests-and-hobbies',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22128_hssjnl.webp',
    value: TemplatesSectionsNames.interests
  },
  {
    title: 'Visual Slider',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22119_lh9eax.webp'
    // value: TemplatesSectionsNames.projects
  },
  {
    title: 'Video Slider',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22120_hcclxj.webp'
    // value: TemplatesSectionsNames.projects
  },
  {
    title: 'Contact Form',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22121_cveiru.webp',
    value: TemplatesSectionsNames.contact
  },
  {
    title: 'Quote',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22122_azkbp5.webp',
    value: TemplatesSectionsNames.quotes
  },
  {
    title: 'Question and answer',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22123_ombhu7.webp',
    value: TemplatesSectionsNames.qanda
  },
  {
    title: 'Cover Letter',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22124_nyfgix.webp',
    value: TemplatesSectionsNames.coverLetter
  },
  {
    title: 'Projects',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22125_jbmlgi.webp',
    value: TemplatesSectionsNames.projects
  },
  {
    title: 'Statistics',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22126_1_syrwp5.webp',
    value: TemplatesSectionsNames.statistics
  },
  {
    title: 'Why Me',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22120_1_yqdx9i.webp',
    value: TemplatesSectionsNames.whyMe
  },
  {
    title: 'Testimonials',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22121_1_frgas3.webp',
    value: TemplatesSectionsNames.testimonials
  },
  {
    title: 'Project Experience',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22122_1_hcp2vj.webp',
    value: TemplatesSectionsNames.projectExperience
  },
  {
    title: 'Resources',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22123_1_jnpynf.webp',
    value: TemplatesSectionsNames.resources
  },
  {
    title: 'Inspire',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22124_1_pqvusi.webp',
    value: TemplatesSectionsNames.inspireInstruct
  },
  {
    title: 'Paragraph',
    src: 'https://cdn.wizrx.wizrapps.com/Frame_22125_1_jpdhnn.webp',
    value: TemplatesSectionsNames.paragraph
  }
];
