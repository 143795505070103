import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sharedPostKey: ''
};

const socialBoardSlice = createSlice({
  name: 'socialBoard',
  initialState,
  reducers: {
    setPostKey(state, action) {
      state.sharedPostKey = action.payload;
    }
  }
});

export const { setPostKey } = socialBoardSlice.actions;
export default socialBoardSlice.reducer;
export const getSharedPostKeySelector = (state) => state.socialBoard.sharedPostKey;
