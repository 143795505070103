import React from 'react';
import styles from './styles.module.scss';

const ChatBody = () => {
    return (
        <div className={styles.chatBody}>
            <div className={styles.header}>Ana Smith</div>
            <div className={styles.content}>
                <div className={styles.username}>
                    <h3>Ana Smith</h3>
                </div>
                <div>
                    This is the very beginning of your direct message history with @
                    <b>Ana Smith</b>. Only the two of you are in this conversation.
                    <input className={styles.messageInput} />
                </div>
            </div>
        </div>
    )
}

export default ChatBody;
