import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { deleteExperienceInListInQuery, updateExperienceInListInQuery } from './util';
import { addObjectToArrayIfNotExist } from 'helpers';
// represent the controller
const secondaryUrl = `experience`;

export const experienceExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteExperience: builder.mutation({
      query: ({ experienceId }) => ({
        url: `/v1/${secondaryUrl}/${experienceId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ experienceId, userId }, { queryFulfilled }) {
        const deleteResult = deleteExperienceInListInQuery({
          experienceId,
          queryName: 'getExperiencesByUserId',
          params: { userId }
        });

        await optimisticUpdater(deleteResult, queryFulfilled);
      },
      // invalidatesTags: (result, error, { experienceId }) => [
      invalidatesTags: () => [
        { type: 'Experience', id: 'LIST' },
        // This tag is needed to refresh the experience sum in the profile right side
        { type: 'Experience', id: 'Profile' }
      ]
    }),

    updateExperience: builder.mutation({
      query: ({ experienceId, experience }) => ({
        url: `/v1/${secondaryUrl}/${experienceId}`,
        method: 'PATCH',
        body: experience
      }),
      // In the future, if this query will be used in other then individual profile, then we can update
      // getIndividualProfileByUserCompanyId from within the component
      async onQueryStarted({ experienceId, experience, userId }, { dispatch, queryFulfilled }) {
        const patchResult = updateExperienceInListInQuery({
          experienceId,
          experience,
          queryName: 'getExperiencesByUserId',
          params: { userId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Experience', id: 'LIST' }]));
        });
      },
      // This tag is needed to refresh the experience sum in the profile right side
      invalidatesTags: () => [{ type: 'Experience', id: 'Profile' }]
    }),

    saveExperience: builder.mutation({
      query: ({ experience }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: experience
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdExprData) => {
            if (!createdExprData) return;
            dispatch(
              apiSlice.util.updateQueryData(
                'getExperiencesByUserId',
                {
                  userId
                },
                (draft) => {
                  draft.result.unshift(createdExprData);
                }
              )
            );
          }
        );
      },
      // This tag is needed to refresh the experience sum in the profile right side
      invalidatesTags: () => [{ type: 'Experience', id: 'Profile' }]
    }),

    getExperiencesByUserId: builder.query({
      query: ({ userId, page = 1, size = 3 }) => ({
        url: `/v1/${secondaryUrl}/user/${userId}?page=${page}&size=${size}&sort=from,desc`
      }),
      providesTags: (result) => {
        const mainTags = [{ type: 'Experience', id: 'LIST' }];
        if (!result) return mainTags;
        let invalidatesTags = result.result.map((item) => ({ type: 'Experience', id: item.id }));
        invalidatesTags = invalidatesTags.concat(mainTags);
        return invalidatesTags;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { userId } = queryArgs;
        return `${endpointName}({"userId":"${userId}"})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a Experience, the created Experience is added to the cache, and the last Experience
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new Experience
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    })
  })
});

export const {
  useDeleteExperienceMutation,
  useUpdateExperienceMutation,
  useSaveExperienceMutation,
  useGetExperiencesByUserIdQuery
} = experienceExtendedSlice;
