import React, { useState } from 'react';
import styles from './TeamModal.module.scss';
import CreateModal from '../CreateModal/CreateModal';
import { Input } from 'components/form';
import { capitalizeFirstLetter, getS3Url, isStringLimited } from 'helpers';
import { ProfileSmallCard } from 'components/ui';
import { showErrorToast } from 'utils/toast';
import {useTranslation} from "react-i18next";

export default function TeamModal({
  teamData,
  onClose,
  isSubmitting,
  handleSave,
  openTeamModal,
  selectedEmployees,
  onRemoveClicked
}) {
  if (!openTeamModal) return null;
  const [createTeamDto, setCreateTeamDto] = useState({
    name: teamData?.name || '',
    description: teamData?.description || '',
    overview: teamData?.overview || '',
    purpose: teamData?.purpose || '',
    department: teamData?.department || ''
  });
  const { t } = useTranslation();
  const handleTeamInputChange = (event, stringLength) => {
    const { value: originValue, name } = event.target;
    const [isIt, value] = isStringLimited(originValue, stringLength);
    if (!isIt)
      showErrorToast(
        `${capitalizeFirstLetter(name)} ${t("should be less than")} ${stringLength} ${t("characters")}`
      );
    setCreateTeamDto({ ...createTeamDto, [name]: value });
  };

  return (
    <div>
      <CreateModal
        isSubmitting={isSubmitting}
        extraClass="teamsModal"
        handleClose={onClose}
        clickEvent={() => handleSave(createTeamDto)}
      >
        <div className={styles.container}>
          <h1>{teamData ? t('Update') : t('Create')} {t("Group Team")}</h1>
          {/* <p>
            Create a space where your teams can communicate, best organised around topic of
            discussion/team name e.g #tech
          </p> */}
          <Input
            label={t("Group name")}
            type="text"
            placeholder={'tech_team'}
            extraClass="teamInput"
            value={createTeamDto.name}
            name="name"
            autoFocus
            onChange={(e) => handleTeamInputChange(e, 50)}
          />
          {selectedEmployees.length > 0 && (
            <div className={styles.members}>
              <p>{t("Add members profiles")}</p>
              <div className={styles['small-profiles-container']}>
                {selectedEmployees?.map((profile) => (
                  <ProfileSmallCard
                    key={profile.id}
                    contact={profile.contact}
                    picture={getS3Url(profile.picture)}
                    onRemoveClicked={() => onRemoveClicked(profile.id)}
                  />
                ))}
              </div>
            </div>
          )}
          <label>{t("Description")}</label>
          <textarea
            maxLength={1000}
            cols="30"
            rows="10"
            placeholder={t('Add team description')}
            value={createTeamDto.description}
            name="description"
            onChange={(e) => handleTeamInputChange(e, 500)}
          />
        </div>
      </CreateModal>
    </div>
  );
}
