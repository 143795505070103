import React from 'react';
import styles from './InspireInstructSection.module.scss';
import myMotivationImage from 'assets/images/myMotivation.webp';
import myTeachingApproachImage from 'assets/images/myTeachingApproach.webp';
import { useInspireInstructUtils } from 'components/organisms/Profile/hooks/templates';
import Options from 'components/atoms/Options/Options';
import { DescriptionAndUploadModal, Loader, Progress } from 'components/ui';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import { CLOUDFRONT_URL } from 'utils/constans';

const validTitles = {
  swim: 'My Motivation',
  mentor: 'My Teaching Approach',
  custom: 'My Motivations'
};
const validDefaultImages = {
  swim: myMotivationImage,
  mentor: myTeachingApproachImage,
  custom: myMotivationImage
};
function InspireInstructSectionView({ data, sectionTitle, defaultImage, isFetching }) {
  const {
    isCurrentUserProfile,
    description,
    setDescription,
    isModalOpen,
    setIsModalOpen,
    isUploading,
    link,
    totalUploadProgress,
    handleMediaUpload,
    updateIndividualProfile,
    handleUpload
  } = useInspireInstructUtils({
    inspireInstructText: data?.text,
    mediaLink: data?.link
  });
  {
    isFetching && <Loader />;
  }
  return (
    <div className={styles.inspireInstrcutSection}>
      <div className={styles.container}>
        <Progress
          height="15px"
          total={totalUploadProgress.total}
          loaded={totalUploadProgress.loaded}
        />
        <DescriptionAndUploadModal
          onBlobUpload={handleUpload}
          isBlobUploading={isUploading}
          onSubmit={async () => {
            handleMediaUpload();
            setIsModalOpen(false);
          }}
          description={description}
          onDescriptionChange={(e) => {
            const { value } = e.target;
            if (value.length > 450) {
              setDescription(value.slice(0, 450));
              return;
            }
            setDescription(value);
          }}
          onClose={() => {
            setDescription(description);
            setIsModalOpen(false);
          }}
          isModalOpen={isModalOpen}
        />
        {isCurrentUserProfile && (
          <Options extraClass="inspireInstructOptions" handleEdit={() => setIsModalOpen(true)} />
        )}
        <h3 className={styles.sectionTitle}>{sectionTitle}</h3>
        <div className={styles.innerContainer}>
          <p className={styles.description}>
            {description
              ? description
              : `
            In my digital marketing mentorship, I prioritize hands-on learning through real projects and interactive workshops. By analyzing industry case studies and conducting live campaign assessments, I ensure mentees grasp both theory and practical application. Personalized coaching, guest lectures, and continuous learning platforms enrich the experience, fostering adaptability.
        `}
          </p>
          <img src={data?.link ? `${CLOUDFRONT_URL}/${data.link}` : defaultImage} alt="" />
        </div>
      </div>
    </div>
  );
}

function InspireInstructSection({ ...args }) {
  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId: args?.profileId
    },
    {
      skip: isSharedMode() || !args?.profileId
    }
  );
  const { data, isLoading, isError, isFetching } = result;
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {isError?.data?.message}
      </div>
    );
  }
  data.bio.motivation;
  const templateType = data?.profile?.activeTemplate?.type;
  const sectionTitle = validTitles[templateType];
  const defaultImage = validDefaultImages[templateType];
  return (
    <InspireInstructSectionView
      sectionTitle={sectionTitle}
      defaultImage={defaultImage}
      data={data?.bio?.motivation ?? placeHolder}
      isFetching={isFetching}
    />
  );
}
const placeHolder = {
  text: `
    Placeholder - In my digital marketing mentorship, I prioritize hands-on learning through real projects and interactive workshops. By analyzing industry case studies and conducting live campaign assessments, I ensure mentees grasp both theory and practical application. Personalized coaching, guest lectures, and continuous learning platforms enrich the experience, fostering adaptability.
`,
  link: ''
};
export default InspireInstructSection;
