import React, { useEffect, useState } from 'react';
import styles from './StatisticCard.module.scss';
import { Progress } from 'components/ui';
import Options from 'components/atoms/Options/Options';
import { EditInput } from 'components/form';
import { useForm } from 'react-hook-form';
import { useDeleteStatsMutation, useUpdateStatsMutation } from 'api/individual-profile/statsSlice';
import { handleAsync } from 'helpers';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
function StatisticCard({
  id,
  name,
  description,
  proficiency,
  isCurrentUserProfile,
  isPlaceholdersActive
}) {
  const currentUserId = useSelector(getCurrentUserIdSelector);
  const submitButtonRef = React.useRef();
  const [updateStats] = useUpdateStatsMutation();
  const [deleteStats] = useDeleteStatsMutation();
  const [deleteModal, setDeleteModal] = useState(false);

  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: name,
      description: description,
      proficiency: proficiency
    }
  });

  const [edit, setEdit] = useState(false);
  const closeReset = () => {
    setEdit(false);
    reset();
  };
  const onSubmit = async (data) => {
    const [status] = await handleAsync(
      updateStats({ stats: { id, ...data }, userId: currentUserId }).unwrap(),
      'Statistics updated successfully',
      'Failed to update statistics'
    );
    if (status) closeReset();
  };

  const handleDelete = async () => {
    const [status] = await handleAsync(
      deleteStats({ statsId: id, userId: currentUserId }).unwrap(),
      'Statistics deleted successfully',
      'Failed to delete statistics'
    );
    if (status) closeReset();
  };

  useEffect(() => {
    reset({ name, description, proficiency });
  }, [id, name, description, proficiency]);

  return (
    <div className={styles.cardContainer}>
      {isCurrentUserProfile && !isPlaceholdersActive && (
        <Options
          extraClass="WhyMeOptions"
          handleDiscard={() => closeReset()}
          handleEdit={() => setEdit(true)}
          handleDelete={() => setDeleteModal(true)}
          edit={edit}
          handleSave={() => {
            submitButtonRef.current.click();
          }}
        />
      )}
      {edit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <div>
              {' '}
              <EditInput
                autoFocus
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Name is required'
                  },
                  maxLength: {
                    value: 50,
                    message: 'Name must be less than 50 characters'
                  }
                })}
              />
              {errors.name && <span className="input-error">{errors.name.message}</span>}
            </div>
            <div>
              {' '}
              <EditInput
                placeholder="Description"
                {...register('description', {
                  required: {
                    value: true,
                    message: 'Description is required'
                  },
                  maxLength: {
                    value: 300,
                    message: 'Description must be less than 300 characters'
                  }
                })}
              />
              {errors.description && (
                <span className="input-error">{errors.description.message}</span>
              )}
            </div>
            <div>
              {' '}
              <EditInput
                placeholder="Proficiency"
                {...register('proficiency', {
                  valueAsNumber: {
                    value: true,
                    message: 'Proficiency must be a number'
                  },
                  required: {
                    value: true,
                    message: 'Proficiency is required'
                  },
                  validate: {
                    isNumber: (value) =>
                      Number.isInteger(value) || 'Proficiency must be an integer',
                    lessThan100: (value) => value <= 100 || 'Proficiency must be less than 100',
                    moreThan0: (value) => value > 0 || 'Proficiency must be more than 0'
                  }
                })}
              />
              {errors.proficiency && (
                <span className="input-error">{errors.proficiency.message}</span>
              )}
            </div>
          </div>
          <input
            type="submit"
            style={{
              display: 'none'
            }}
            ref={submitButtonRef}
          />
        </form>
      )}

      {!edit && (
        <div className={styles.card}>
          <h4 className={styles.name}>{name}</h4>
          <p className={styles.description}>{description}</p>
          <Progress loaded={proficiency} total={100} />
        </div>
      )}

      {deleteModal && (
        <DeleteModal
          title="Are you sure you want to delete this stats ?"
          handleClose={() => setDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
}

export default StatisticCard;
