import { useGetSharedProfileBySlugQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import { useGetUrlQuery } from 'hooks';
import useUpdateEffect from 'hooks/useUpdateEffect';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useGetSharedProfile() {
  const isShared = isSharedMode();
  const slug = useGetUrlQuery('slug');
  const { state } = useLocation();
  const passKey = state?.passKey;
  const navigate = useNavigate();
  const navigateWithSharedMode = (url) => {
    navigate(`${url}&slug=${slug}`, { state: { passKey } });
  };

  const goBack = () => {
    const split = slug.split('-');
    const [_, ...rest] = split;
    const newSlug = rest.join('-');
    navigate(`/profiles?slug=${newSlug}`);
  };
  const result = useGetSharedProfileBySlugQuery(
    { slug, passKey },
    {
      skip: !isShared || !slug || (slug?.includes('private') && !passKey)
    }
  );

  return [result, navigateWithSharedMode, goBack];
}

export default useGetSharedProfile;
