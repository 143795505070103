import React from 'react';

/**
 * A hook to trigger a function when user clicks outside the node
 *
 * @param {object} ref - ref of the node
 * @param {function} trigger - function to be triggered when user clicks outside the node
 * @param {string} [excludedNodeId] - id of the node which should be excluded from the click
 * @returns {void}
 */
function useOutSideClick(ref, trigger, excludedNodeId) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      const excludeNodeRef = document.getElementById(excludedNodeId);
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        excludeNodeRef &&
        !excludeNodeRef?.contains(event.target)
      ) {
        trigger();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [excludedNodeId, trigger, ref]);
}

export default useOutSideClick;
