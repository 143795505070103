import { showErrorToast, showSuccessToast } from 'utils/toast';

/**
 * @description This function is used to handle async functions
 * @param {Promise} promise
 * @param {string} successMessage
 * @param {string} errorMessage
 * @returns {Promise<[boolean, any]>}
 * @example
 * const [status, result] = await handleAsync(promise, 'Success message', 'Error message');
 * if(status) ("success");
 * else ("error");
 */

export const handleAsync = async (promise, successMessage, errorMessage) => {
  try {
    const result = Array.isArray(promise) ? await Promise.all(promise) : await promise;
    successMessage && showSuccessToast(successMessage);
    return [true, result];
  } catch (error) {
    //add the failure toast here
    if (error.status === 403) showErrorToast(error.data.message);
    if (error.status === 400) showErrorToast(error.data.message);
    else errorMessage && showErrorToast(errorMessage);
    return [false, error];
  }
};
