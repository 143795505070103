import React, { useMemo } from 'react';
import styles from './Cv.module.scss';
import { CvTemplates, TemplatesSectionsComponents } from '../../templates/utils';

function Cv({ profileId, userId, templateSectionList, templateType }) {
  const sectionComponents = useMemo(() => {
    const mappedValues = templateSectionList.map((section) => {
      const SectionComponent = TemplatesSectionsComponents[section] || null;
      const sectionTitle = CvTemplates[templateType]?.sectionsTitles?.[section];
      if (!SectionComponent) return null;
      return (
        <SectionComponent
          key={sectionTitle}
          profileId={profileId}
          userId={userId}
          sectionTitle={sectionTitle}
        />
      );
    });

    return mappedValues.filter((value) => value !== null);
  }, [templateSectionList, profileId, userId]);

  return <div className={styles.contentWrapper}>{sectionComponents}</div>;
}

export default Cv;
