import React from 'react';
import NotFoundPage from 'components/organisms/NotFoundPage/NotFoundPage';

const NotFound = () => {
  document.body.style.overflow = '';

  return <NotFoundPage />;
};

export default NotFound;
