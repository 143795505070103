import React from "react";
import styles from "./styles.module.scss";

const ChatList = () => {
    return (
        <div className={styles.list}>
            <div className={styles.listItem}>User1</div>
            <div className={styles.listItem}>User1</div>
            <div className={styles.listItem}>User1</div>
            <div className={styles.listItem}>User1</div>
        </div>
    )
}

export default ChatList;
