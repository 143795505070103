import { useGetSharedProfilesBySlugQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import { useGetUrlQuery } from 'hooks';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSharingPassKeySelector, setSharingPassKey } from 'store/individualProfile.slice';

function useGetSharedProfiles() {
  const sharedData = useSelectCacheData('getSharedProfilesBySlug', {});
  const pk = useSelector(getSharingPassKeySelector);
  const [passKey, setPassKey] = useState(pk);
  const isShared = isSharedMode();
  const dispatch = useDispatch();
  const slug = useGetUrlQuery('slug');

  const result = useGetSharedProfilesBySlugQuery(
    { slug, passKey },
    {
      skip: !isShared || !slug || (slug?.includes('private') && !passKey) || sharedData?.data
    }
  );

  useEffect(() => {
    if (sharedData?.data) return;
    if (slug?.includes('private') && !passKey) {
      const passKey = prompt('Please enter the pass key');
      setPassKey(passKey?.trim());
      dispatch(setSharingPassKey(passKey?.trim()));
    }
  }, []);
  if (sharedData?.data) return [sharedData, passKey];
  return [result, passKey];
}

export default useGetSharedProfiles;
