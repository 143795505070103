import { deleteAuthData, directUserToMasterHub } from 'helpers/auth';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserData } from 'store/login.slice';
import { useUpdateIsNewUserMutation } from 'api/individual-profile';
import { handleAsync } from 'helpers';
import OnBoarding from 'components/organisms/OnBoarding';

import Routers from './Routers';
import { Loader } from 'components/ui';
import { ADMINS_ROLES } from 'constants';
import { showErrorToast } from 'utils/toast';
import { EDITOR_ROLE, PUBLISHER_ROLE } from 'constants';
import { useGetCompanyProfileQuery } from 'api/company-profile';
export default function ProfileAccess({ UserStatus, company, profileData }) {
  let content = null;
  const [updateIsNewUser, status] = useUpdateIsNewUserMutation();

  const onClickUpdateNewUser = async () => {
    await handleAsync(updateIsNewUser({ isNewUser: false }).unwrap());
  };

  const dispatch = useDispatch();
  if (!UserStatus.isAuthenticated || !company) {
    return deleteAuthData();
  }
  // todo: remove the hard coded theme after implementing the endpoint
  const userCompany = { id: company?.id, name: company?.name, theme: company?.theme || 'ngo' };

  useEffect(() => {
    if (profileData) {
      if (!profileData?.profileRole) {
        showErrorToast('You are not authorized, please check your profile role');
        return setTimeout(() => {
          directUserToMasterHub();
        }, 4000);
      }

      const profileStatus = {
        ...profileData,
        isAdmin: ADMINS_ROLES.includes(profileData?.profileRole.toLowerCase()),
        isAdminOrEditorOrPublisher: [...ADMINS_ROLES, EDITOR_ROLE, PUBLISHER_ROLE].includes(
          profileData?.profileRole.toLowerCase()
        )
      };
      dispatch(
        setUserData({
          company: userCompany,
          profile: profileStatus,
          user: { ...UserStatus.user, token: UserStatus.token }
        })
      );
    }
  }, [profileData]);
  if (profileData?.isNewUser)
    return <OnBoarding click={() => onClickUpdateNewUser()} loading={status.isLoading} />;
  else content = <Routers profileData={profileData} />;
  return content;
}
