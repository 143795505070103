import React, { useState, useEffect } from 'react';
import styles from './Projects.module.scss';
import { ProfilePic } from 'components/ui';
import PropTypes from 'prop-types';
import { EditInput } from 'components/form';
import DatePicker from 'react-datepicker';
import { objectsEqual_ByKeys, onFieldChangeProfileModules, selectObjectElements } from 'helpers';
import { getMonthAndYearName } from 'helpers/dateHandler';
import { useSelector } from 'react-redux';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import QueryableTools from 'components/molecules/QueryableTools/QueryableTools';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import Options from 'components/atoms/Options/Options';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  serialize,
  isJsonString,
  convertStringToParagraph
} from 'components/atoms/MWEditor/Helpers';

export default function Card({
  company,
  location,
  id,
  from,
  to,
  description,
  position,
  name,
  tools,
  isCurrentUserProfile,
  ongoing,
  ...rest
}) {
  const originValues = {
    company,
    location,
    id,
    from,
    to,
    description,
    position,
    name,
    tools,
    ongoing
  };
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState({ ...originValues });
  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };
  const globalEdit = useSelector(getGlobalEditSelector);

  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  const addTechs = (techs) => {
    const techsRepo = techs.map((tech) =>
      selectObjectElements(tech, ['name', 'description', 'icon'])
    );
    const newTechs = [...techsRepo];
    setFieldsChange({ ...fieldsChange, tools: newTechs });
  };
  return (
    <div className={styles.projCard}>
      {isCurrentUserProfile && (
        <Options
          extraClass="profileOptions"
          handleSave={() => {
            const objectsEqual = objectsEqual_ByKeys(fieldsChange, originValues);
            if (objectsEqual) return setEdit(false);
            rest.onUpdateSubmit(fieldsChange);
            setEdit(false);
          }}
          handleDiscard={() => setEdit(false)}
          handleDelete={() => setDeleteModal(true)}
          handleEdit={() => setEdit(true)}
          edit={edit}
        />
      )}
      {edit ? (
        <>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic
                extraClass={rest?.extraClass}
                image={rest?.img}
                width={rest?.width}
                height={rest?.height}
              />

              <p className={`${styles.location} ${styles.editField}`}>
                <EditInput
                  maxLength={100}
                  value={fieldsChange.name}
                  onChange={({ target }) => onFieldsChange('name', target.value)}
                />
              </p>
            </div>
            <div className={styles.content}>
              <p className={`${styles.location} ${styles.editField} ${styles.descField}`}>
                <MWEditorLite
                  data={
                    description
                      ? isJsonString(description)
                        ? JSON.parse(description)
                        : convertStringToParagraph(description)
                      : undefined
                  }
                  placeholderText="Describe your project"
                  setData={(value) => onFieldsChange('description', JSON.stringify(value))}
                />
              </p>
              <h3 className={`${styles.editField} ${styles.countryField}`}>
                <EditInput
                  maxLength={400}
                  onChange={({ target }) => onFieldsChange('location', target.value)}
                  fontWeight="bold"
                  value={fieldsChange.location}
                />
              </h3>
              <h2 className={styles.editField}>
                <EditInput
                  maxLength={100}
                  onChange={({ target }) => onFieldsChange('company', target.value)}
                  fontWeight="bold"
                  value={fieldsChange.company}
                />
              </h2>
              <div className={styles.datepicker}>
                {from && (
                  <DatePicker
                    maxDate={new Date()}
                    selected={new Date(fieldsChange.from)}
                    onChange={(date) => onFieldsChange('from', date.toString())}
                    showYearDropdown
                  />
                )}
                {to && (
                  <DatePicker
                    maxDate={new Date()}
                    selected={new Date(fieldsChange.to)}
                    minDate={new Date(fieldsChange.from)}
                    onChange={(date) => onFieldsChange('to', date.toString())}
                    disabled={fieldsChange.ongoing}
                    className={fieldsChange.ongoing ? styles.disabledDate : ''}
                    showYearDropdown
                  />
                )}
                <div className={styles.ongoing}>
                  <span>Ongoing</span>
                  <input
                    name="ongoing"
                    id="ongoing"
                    type="checkbox"
                    checked={fieldsChange.ongoing}
                    onChange={(event) => {
                      onFieldsChange('ongoing', event.target.checked);
                    }}
                  />
                  <label htmlFor="ongoing" className={styles.checkmark} />
                </div>
              </div>
            </div>
            <QueryableTools
              onToolsSelected={addTechs}
              rtkQueryName="getToolsByKeyword"
              extraClass="editTools"
              add
              bgColor="#2d2d2d"
              title="Tools"
              list={fieldsChange?.tools}
              searchPlaceholder="search the tool you used in this project..."
            />
          </div>
        </>
      ) : (
        <div>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic
                extraClass={rest?.extraClass}
                image={rest?.img}
                width={rest?.width}
                height={rest?.height}
              />
              <h2>{name}</h2>
            </div>
            <div className={styles.content}>
              <div
                className={styles.descriptionHolder}
                dangerouslySetInnerHTML={{
                  __html: serialize(
                    description
                      ? isJsonString(description)
                        ? JSON.parse(description)
                        : convertStringToParagraph(description)
                      : undefined
                  )
                }}
              ></div>
              <h3>{location}</h3>
              <p className={styles.location}>{company}</p>
              {from && to && !ongoing && (
                <p className={styles.date}>
                  {getMonthAndYearName(from)} - {getMonthAndYearName(to)}
                </p>
              )}
              {from && ongoing && (
                <p className={styles.date}>{getMonthAndYearName(from)} - Ongoing</p>
              )}
            </div>
          </div>
          <QueryableTools bgColor="#2d2d2d" list={tools} />
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          title="Are you sure you want to delete this project ?"
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => rest.onDeleteSubmit(id)}
        />
      )}
    </div>
  );
}

Card.propTypes = {
  company: PropTypes.string,
  img: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  location: PropTypes.string,
  description: PropTypes.string,
  position: PropTypes.string,
  name: PropTypes.string,
  extraClass: PropTypes.string,
  to: PropTypes.string,
  from: PropTypes.string
};
