import React, { useEffect, useState } from 'react';
import styles from './ExperiencesSection.module.scss';
import PropTypes from 'prop-types';
import { EditInput } from 'components/form';
import DatePicker from 'react-datepicker';
import { CustomDropdown } from 'components/form';
import {
  objectsEqual_ByKeys,
  onFieldChangeProfileModules,
  removeObjectElements,
  selectObjectElements
} from 'helpers';
import { getMonthAndYearName } from 'helpers/dateHandler';
import QueryableTools from 'components/molecules/QueryableTools/QueryableTools';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import Options from 'components/atoms/Options/Options';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  serialize,
  isJsonString,
  convertStringToParagraph
} from 'components/atoms/MWEditor/Helpers';

function ExperienceCard({
  company,
  position,
  description,
  id,
  from,
  to,
  country,
  contract,
  technologies,
  isCurrentUserProfile,
  ongoing,
  isPlaceholdersActive,
  ...rest
}) {
  const originValues = {
    company,
    position,
    description,
    id,
    from,
    to,
    country,
    contract,
    technologies,
    ongoing
  };
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState({ ...originValues });
  const [companyInitials, setCompanyInitials] = useState(
    fieldsChange.company
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')
      .substring(0, 2)
  );

  const onFieldsChange = (name, value) => {
    if (name === 'company') {
      const initials = value
        .trim()
        .split(' ')
        .map((word) => word.charAt(0))
        .join('')
        .substring(0, 2);
      setCompanyInitials(initials);
    }
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  const addTechs = (techs) => {
    const techsRepo = techs.map((tech) =>
      selectObjectElements(tech, ['name', 'description', 'icon'])
    );
    const newTechs = [...techsRepo];
    setFieldsChange({ ...fieldsChange, technologies: newTechs });
  };

  useEffect(() => {
    setEdit(rest.globalEdit);
  }, [rest.globalEdit]);

  return (
    <div className={styles.expCard}>
      {isCurrentUserProfile && !isPlaceholdersActive && (
        <Options
          extraClass="profileOptions"
          handleDiscard={() => setEdit(false)}
          handleEdit={() => {
            setFieldsChange({ ...originValues });
            setEdit(true);
          }}
          handleSave={() => {
            const objectsEqual = objectsEqual_ByKeys(fieldsChange, originValues);
            if (objectsEqual) return setEdit(false);
            const clearedTechs = fieldsChange.technologies.map((tech) =>
              removeObjectElements(tech, ['id', '__v', 'updatedAt', 'createdAt'])
            );
            // (fieldsChange.from);
            if (fieldsChange.ongoing) {
              rest.onUpdateSubmit({
                ...fieldsChange,
                technologies: clearedTechs,
                to: new Date().toISOString()
              });
            } else rest.onUpdateSubmit({ ...fieldsChange, technologies: clearedTechs });
            setEdit(false);
          }}
          handleDelete={() => setDeleteModal(true)}
          edit={edit}
        />
      )}
      <div className={styles.expContent}>
        <div className={styles.leftContent}>
          <div className={styles.companyInitials}></div>
          <div className={`${!fieldsChange.ongoing && styles.finished} ${styles.circle}`}></div>
          <div className={styles.line}></div>
        </div>

        {edit ? (
          <>
            <div className={styles.card}>
              <div className={styles.profile}>
                <h2 className={styles.editField}>
                  <EditInput
                    maxLength={50}
                    placeholder="Company Name"
                    fontWeight="bold"
                    value={fieldsChange.company}
                    onChange={({ target, nativeEvent }) =>
                      onFieldsChange('company', target.value, nativeEvent)
                    }
                  />
                </h2>
              </div>
              <div className={styles.content}>
                <h3 className={styles.editField}>
                  <EditInput
                    maxLength={100}
                    placeholder="Your position"
                    fontWeight="bold"
                    value={fieldsChange.position}
                    onChange={({ target }) => onFieldsChange('position', target.value)}
                  />
                </h3>
                <p className={`${styles.location} ${styles.editField} ${styles.country}`}>
                  <MWEditorLite
                    data={
                      description
                        ? isJsonString(description)
                          ? JSON.parse(description)
                          : convertStringToParagraph(description)
                        : undefined
                    }
                    placeholderText="Describe your experience"
                    setData={(value) => onFieldsChange('description', JSON.stringify(value))}
                  />
                </p>
                <p className={styles.editField}>
                  <EditInput
                    maxLength={400}
                    placeholder="Company location"
                    value={fieldsChange.country}
                    onChange={({ target }) => onFieldsChange('country', target.value)}
                  />
                </p>

                <div>
                  <CustomDropdown
                    extraClass="contractDropdown"
                    list={rest.contracts}
                    value={fieldsChange.contract}
                    placeholder="Contract Type"
                    onChange={(value) => onFieldsChange('contract', value)}
                  />
                </div>
                <div className={styles.datepicker}>
                  {from && (
                    <DatePicker
                      maxDate={new Date()}
                      selected={new Date(fieldsChange.from)}
                      onChange={(date) => onFieldsChange('from', date.toString())}
                      showYearDropdown
                    />
                  )}
                  <DatePicker
                    selected={new Date(fieldsChange.to)}
                    disabled={fieldsChange.ongoing}
                    maxDate={new Date()}
                    minDate={new Date(fieldsChange.from)}
                    onChange={(date) => {
                      onFieldsChange('to', date.toString());
                    }}
                    className={fieldsChange.ongoing ? styles.disabledDate : ''}
                    showYearDropdown
                  />
                  <div className={styles.inprogress}>
                    <span>Present</span>
                    <input
                      name="present"
                      id="present"
                      type="checkbox"
                      checked={fieldsChange.ongoing}
                      onChange={(event) => {
                        onFieldsChange('ongoing', !fieldsChange.ongoing);
                        fieldsChange.ongoing;
                      }}
                    />
                    <label htmlFor="present" className={styles.checkmark} />
                  </div>
                </div>
              </div>
              <QueryableTools
                bgColor="#2d2d2d"
                padding="2px 18px"
                onToolsSelected={addTechs}
                extraClass="editTools"
                add
                title="Tools"
                list={fieldsChange.technologies}
                rtkQueryName="getToolsByKeyword"
                searchPlaceholder="search the technology you used..."
              />
            </div>
          </>
        ) : (
          <div>
            <div className={styles.card}>
              <div className={styles.profile}>
                <h2>{company}</h2>
              </div>
              <div className={styles.content}>
                <h3>{position}</h3>
                <p className={styles.country}>{country}</p>
                <div
                  className={`${styles.location} ${styles.descriptionField}`}
                  dangerouslySetInnerHTML={{
                    __html: serialize(
                      description
                        ? isJsonString(description)
                          ? JSON.parse(description)
                          : convertStringToParagraph(description)
                        : undefined
                    )
                  }}
                ></div>
                <p className={styles.contract}>{contract} </p>
                {
                  <p className={styles.date}>
                    {from && getMonthAndYearName(from)} -{' '}
                    {to && ongoing ? 'Present' : getMonthAndYearName(to)}
                  </p>
                }
              </div>
            </div>
            <QueryableTools bgColor="#8080803b" list={technologies} />
          </div>
        )}
        {deleteModal && (
          <DeleteModal
            title="Are you sure you want to delete this experience ?"
            handleClose={() => setDeleteModal(false)}
            handleDelete={() => rest.onDeleteSubmit(id)}
          />
        )}
      </div>
    </div>
  );
}

ExperienceCard.propTypes = {
  company: PropTypes.string,
  country: PropTypes.string,
  description: PropTypes.string,
  position: PropTypes.string,
  contract: PropTypes.string,
  img: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  extraClass: PropTypes.string,
  to: PropTypes.string,
  from: PropTypes.string,
  contracts: PropTypes.array,
  technologies: PropTypes.array,
  onUpdateSubmit: PropTypes.func,
  globalEdit: PropTypes.bool
};

export default ExperienceCard;
