import React, { useState } from 'react';
import CreateModal from '../CreateModal/CreateModal';
import styles from './eventAttachmentModal.module.scss';
import { isImageURL, isVideoURL } from 'helpers';
import { getS3Url } from 'helpers';
function EventAttachmentModal({ url, setAttachmentModalVisible, open }) {
  if (!open) return null;
  const extension = url?.split('.').pop();
  const urlIsImage = isImageURL(extension.toLowerCase());
  const urlIsVideo = isVideoURL(extension.toLowerCase());
  const attachmentURL = getS3Url(url);
  return (
    <CreateModal
      clickEvent={() => {
        setAttachmentModalVisible(false);
      }}
      handleClose={() => {
        setAttachmentModalVisible(false);
      }}
      textCancel="close"
      showSave={false}
    >
      <h1>{'Attachment'}</h1>
      <div className={styles.container}>
        {urlIsImage && (
          <img className={styles.attachment} src={attachmentURL} alt="attachement image" />
        )}
        {urlIsVideo && (
          <video className={styles.attachment} controls>
            <source src={attachmentURL} />
          </video>
        )}
      </div>
    </CreateModal>
  );
}

export default EventAttachmentModal;
