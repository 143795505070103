import Text from "components/atoms/Text/Text";
import React from "react";
import styles from "./Banner.module.scss";
import PropTypes from 'prop-types';

function Banner({image,title,subheading, extraClass}){
    return(
        <div className={`${styles.banner} ${styles[extraClass]}`} style={{backgroundImage:`url(${image})`}}>
          <Text {...{title,subheading,color:'#BBBBBB', fontSize:"28px", font:"bolder"}}/>
        </div>
    );
}
export default Banner;
Banner.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    subheading: PropTypes.string,
    extraClass: PropTypes.string,
};