import { apiSlice } from 'api/apiSlice';
import { store } from '../../store';

// How to get the post from the query data ?????

// const getCachedPostByPostIdFromQuery = ({ postId, queryName }) => {
//   const targetPost = store
//     .getState()
//     [apiSlice.name]?.queries[queryName]?.data?.result?.find((post) => post.id === postId);
//   return targetPost;
// };

export const increasePostCommentsNum = ({ postId, queryName }) => {
  return store.dispatch(
    apiSlice.util.updateQueryData(queryName, {}, (draft) => {
      const targetPost = draft.result?.find((post) => post.id === postId);
      if (!targetPost) return;
      targetPost.commentsCount++;
    })
  );
};

export const decreasePostCommentsNum = ({ postId, queryName }) => {
  return store.dispatch(
    apiSlice.util.updateQueryData(queryName, {}, (draft) => {
      const targetPost = draft.result?.find((post) => post.id === postId);
      if (!targetPost) return;
      targetPost.commentsCount--;
    })
  );
};
