import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const THRESHOLD_PERCENTAGE = 0.65;

function useOnScrollToPageEnd(callback, deps) {
  useEffect(() => {
    const handleScroll = debounce(() => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      const threshold = scrollHeight * THRESHOLD_PERCENTAGE;
      if (scrollTop + clientHeight >= threshold) {
        callback();
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [...deps]);
}

useOnScrollToPageEnd.prototype = {
  callback: PropTypes.func.isRequired
};

export default useOnScrollToPageEnd;
