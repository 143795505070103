import React, { useState } from 'react';
import styles from './QandASection.module.scss';
import { useQandAUtils } from 'components/organisms/Profile/hooks/templates';
import Options from 'components/atoms/Options/Options';
import QAndACard from './QAndACard/QAndACard';
import CreateQAModal from './CreateQAModal/CreateQAModal';
import { handleAsync, isSharedMode } from 'helpers';
import { useHandleRtkPagination } from 'hooks';
import { useGetQandAByUserIdQuery } from 'api/individual-profile';
import { LoadMoreButton, Loader } from 'components/ui';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';

const defaultSectionTitle = 'Q and A';
const QUESTIONS_COLORS = [
  '#A13838',
  '#BFA137',
  '#37BF96',
  '#378EBF',
  '#AC37BF',
  '#3776BF',
  '#81D4C5',
  '#B56161',
  '#B5A861'
];
function QandASectionView({
  sectionTitle,
  data,
  hasNextPage,
  isFetching,
  setPage,
  isPlaceholdersActive
}) {
  const { isCurrentUserProfile, createQandA, currentUserId, isCreatingQandA } = useQandAUtils();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  return (
    <div className={styles.wrapper}>
      {isCurrentUserProfile && (
        <Options
          extraClass="profileOptions"
          handleDiscard={() => {}}
          handleSave={() => {}}
          handleEdit={() => {}}
          handleAdd={() => {
            setOpenCreateModal(true);
          }}
          edit={false}
        />
      )}
      <h3 className={styles.sectionTitle}>{sectionTitle ?? defaultSectionTitle}</h3>

      <div className={styles['qa-content']}>
        {data.map((item, i) => (
          <QAndACard
            isPlaceholdersActive={isPlaceholdersActive}
            key={item.id}
            question={item.question}
            answer={item.answer}
            id={item.id}
            color={QUESTIONS_COLORS[i % QUESTIONS_COLORS.length]}
          />
        ))}
      </div>
      {isFetching && <Loader />}
      <LoadMoreButton
        className={styles.loadMoreButton}
        hidden={!hasNextPage}
        onClick={() => setPage((pre) => pre + 1)}
        disabled={isFetching || !hasNextPage}
      />
      {openCreateModal && (
        <CreateQAModal
          open
          isCreating={isCreatingQandA}
          onQASave={async (data) => {
            const [state] = await handleAsync(
              createQandA({ qAndA: data, userId: currentUserId }).unwrap(),
              'saved successfully',
              'Failed to save'
            );
            if (state) {
              setOpenCreateModal(false);
            }
            return state;
          }}
          onClose={() => {
            setOpenCreateModal(false);
          }}
        />
      )}
    </div>
  );
}
function QandASection({ userId, sectionTitle }) {
  const [sharedDate] = useGetSharedProfile();
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getQandAByUserId',
    rtkQuery: useGetQandAByUserIdQuery,
    rtkQueryArgs: { userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isLoading, isError, isFetching, error } = !isSharedMode()
    ? result
    : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const qAndAData = isSharedMode() ? data?.bio?.qAndA : data?.result;
  const isPlaceholdersActive = qAndAData?.length === 0;
  return (
    <QandASectionView
      sectionTitle={sectionTitle}
      data={qAndAData && qAndAData.length !== 0 ? qAndAData : QAPlaceholder}
      isPlaceholdersActive={isPlaceholdersActive}
      hasNextPage={data?.page?.hasNextPage}
      setPage={setPage}
      isFetching={isFetching}
    />
  );
}
const QAPlaceholder = [
  {
    id: 1,
    question: 'How would I describe my professional background and experience? - (Placeholder)',
    answer: `With a solid track record spanning 10, I've navigated dynamic roles at prominent companies like Microsoft and Canva. My journey has seen successful contributions in [imaginative responsibilities], shaping a rich and diverse professional background.`
  },
  {
    id: 2,
    question: 'What educational background do I possess? - (Placeholder)',
    answer: `I hold a prestigious Bachelor’s in Management and Leadership, graduating with honors in [Year]. This educational foundation has fortified my skills and ignited a passion for continual learning.`
  },
  {
    id: 3,
    question: 'What key skills do I bring to the table? - (Placeholder)',
    answer: `I bring a versatile skill set to the table, encompassing effective communication, strategic problem-solving, and a keen eye for detail. Proficient in cutting-edge technologies such as Microsoft tools, I pride myself on adaptability and a quick grasp of emerging tools.`
  },
  {
    id: 4,
    question: 'What key skills do I bring to the table? - (Placeholder)',
    answer: `I bring a versatile skill set to the table, encompassing effective communication, strategic problem-solving, and a keen eye for detail. Proficient in cutting-edge technologies such as Microsoft tools, I pride myself on adaptability and a quick grasp of emerging tools.`
  },
  {
    id: 5,
    question: 'What key skills do I bring to the table? - (Placeholder)',
    answer: `I bring a versatile skill set to the table, encompassing effective communication, strategic problem-solving, and a keen eye for detail. Proficient in cutting-edge technologies such as Microsoft tools, I pride myself on adaptability and a quick grasp of emerging tools.`
  },
  {
    id: 6,
    question: 'How would I describe my professional background and experience? - (Placeholder)',
    answer: `With a solid track record spanning 10, I've navigated dynamic roles at prominent companies like Microsoft and Canva. My journey has seen successful contributions in [imaginative responsibilities], shaping a rich and diverse professional background.`
  },
  {
    id: 7,
    question: 'What educational background do I possess? - (Placeholder)',
    answer: `I hold a prestigious Bachelor’s in Management and Leadership, graduating with honors in [Year]. This educational foundation has fortified my skills and ignited a passion for continual learning.`
  }
];
export default QandASection;
