import React from 'react';
import { Checkbox } from 'components/form';
import styles from './ResourcesSection.module.scss';
function ResourceCard({ edit, link, type, title, checked, onSelect }) {
  const navigateToResource = () => {
    window.open(link, '_blank');
  };
  return (
    <div
      style={{ marginLeft: '1rem' }}
      onClick={() => {
        type.includes('image') && edit ? onSelect(link) : navigateToResource();
      }}
      className={styles['card-container']}
    >
      {edit && (
        <div className={styles['checkbox-container']}>
          <Checkbox
            checked={checked}
            onChange={() => {
              onSelect(link);
            }}
          />
        </div>
      )}
      {/*======================== Image ========================*/}
      {type.includes('image') && (
        <div className={styles['image-container']}>
          <img
            style={{
              width: '100%',
              maxWidth: '250px'
            }}
            src={link}
            alt="resource-card"
          />
        </div>
      )}

      {/*======================== Video ========================*/}

      {type.includes('video') && (
        <div className={styles['video-container']}>
          <video
            style={{
              width: '100%',
              height: '100%'
            }}
            preload="metadata"
            controls
            src={link}
            alt="resource-card"
          />
        </div>
      )}

      {/*======================== Title ========================*/}
      <h2 className={styles['title']}>{title}</h2>
    </div>
  );
}

export default ResourceCard;
