import CalendarSection from 'components/organisms/CalendarSection/CalendarSection';
import React from 'react';
import { Helmet } from 'react-helmet';
const CalendarPage = () => {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>Calendar</title>
      </Helmet>
      <CalendarSection />
    </>
  );
};

export default CalendarPage;
