import React, { Suspense } from 'react';
import EmployeeCarousel from '../../molecules/EmployeeCarousel/EmployeeCarousel';
import InfoHubProfileBasedTextBlock from 'components/molecules/InfoHubProfileBasedTextBlock';
import FAQ from 'components/molecules/Faq';
import FeaturedVideos from 'components/molecules/FeaturedVideos/FeaturedVideos';
import InfohubDocuments from 'components/molecules/InfohubDocuments/InfohubDocuments';
import LinkCarousel from 'components/molecules/LinkCarousel/LinkCarousel';
import SingleItemLinkCarousel from 'components/molecules/SingleItemLinkCarousel/SingleItemLinkCarousel';
import { Loader } from 'components/ui';
function StartupT({
  companyId,
  data,
  isLoading,
  lastSelectedImageIndex,
  profileId,
  updateMajorPresentations,
  updateMinorPresentations,
  isMinorPresentationsLoading,
  videoPlayingComponent,
  setVideoPlayingComponent
}) {
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
        <SingleItemLinkCarousel
          companyId={companyId}
          title={'Presentations'}
          subTitle={'Here are the major presentations that we would like to share'}
          data={data?.info?.majorPresentations}
          updateMutation={updateMajorPresentations}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <EmployeeCarousel filter={'sort=createdAt,dsc'} title={'Our Core Team'} />
        <LinkCarousel
          companyId={companyId}
          title={'Presentations'}
          subTitle={'Recommended presentations'}
          data={data?.info?.minorPresentations}
          updateMutation={updateMinorPresentations}
          colorBgSwitcher={lastSelectedImageIndex}
          isLoading={isLoading || isMinorPresentationsLoading}
          topRowEnabled
        />
        <FeaturedVideos
          title="Videos"
          description="Videos we recommend based on your searching."
          data={data}
          type={'Product'}
          companyId={companyId}
          videoPlayingComponent={videoPlayingComponent}
          setVideoPlayingComponent={setVideoPlayingComponent}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <InfohubDocuments
          title={'Documents'}
          documents={data?.info?.documents.length ? data?.info?.documents : []}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <InfoHubProfileBasedTextBlock
          data={data}
          isLoading={isLoading}
          profileId={profileId}
          companyId={companyId}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <FAQ colorBgSwitcher={lastSelectedImageIndex} />
    </Suspense>
  );
}

export default StartupT;
