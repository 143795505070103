import React from 'react';

function useEnterClicked(callback, ref = null, deps = []) {
  const handleEnterClicked = (event) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  React.useEffect(() => {
    const element = ref ? ref.current : document;
    element.addEventListener('keydown', handleEnterClicked);
    return () => {
      element.removeEventListener('keydown', handleEnterClicked);
    };
  }, [...deps]);
}

export default useEnterClicked;
