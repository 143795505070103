import React, { useState } from 'react';
import InfoHubContainerSection from '../../atoms/InfoHubContainerSection';
import styles from './faq.module.scss';
import faqImage from '../../../assets/images/FAQ.webp';
import { useTranslation } from 'react-i18next';

function FAQ({ colorBgSwitcher }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };
  const { t } = useTranslation();

  const faqData = [
    {
      question: 'What is the purpose of WiZR X',
      answer: 'by-having-access-to-personal-profiles'
    },
    {
      question: 'How do I create a Personal profile',
      answer: 'there-is-a-profile-template'
    },
    {
      question: 'How do I login into the message board',
      answer: 'only-admin-has-the-right'
    },
    {
      question: 'Who has access to Info Hub',
      answer: 'only-admin-can-add-and-edit'
    },
    {
      question: 'How do I use the Info Hub',
      answer: 'click-on-videos-presentations-documents'
    }
  ];

  return (
    <InfoHubContainerSection>
      <div className={`${styles.faq} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}>
        <div>
          <h1>{t('FAQ')}</h1>
          {faqData.map((faq, index) => (
            <div key={index} className={styles.title}>
              <button
                className={activeIndex === index ? styles.activeBtn : ''}
                onClick={() => handleClick(index)}
              >
                {t(faq.question)}
                <div className={styles.arrow} />
              </button>
              <div className={`${styles.content} ${activeIndex === index && styles.active}`}>
                {t(faq.answer)}
              </div>
            </div>
          ))}
        </div>
        <img src={faqImage} className={styles.faqimg} alt="FAQ image" />
      </div>
    </InfoHubContainerSection>
  );
}

export default FAQ;
