import { showErrorToast } from 'utils/toast';
import { useSetBioToolsMutation } from 'api/individual-profile';
import { handleAsync, selectObjectElements } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import { getCurrentUserProfileId } from 'store/login.slice';

function useToolsUtils({ toolsList }) {
  const [edit, setEdit] = useState(false);
  const [selectedTools, setSelectedTools] = useState([...toolsList]);
  const [updateTools] = useSetBioToolsMutation();
  const globalEdit = useSelector(getGlobalEditSelector);
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  useEffect(() => {
    setSelectedTools([...toolsList]);
  }, [toolsList]);
  const updateProfileTools = async () => {
    setEdit(false);
    if (selectedTools.length > 30) {
      showErrorToast('You can add up to 30 tools');
      return false;
    }
    const [status] = await handleAsync(updateTools({ tools: selectedTools }).unwrap());
    !status && showErrorToast('Something went wrong');
  };

  const selectTools = (tools) => {
    const toolsRepo = tools.map((tool) =>
      selectObjectElements(tool, ['name', 'description', 'icon'])
    );
    const newtools = [...toolsRepo];
    setSelectedTools([...newtools]);
  };

  return {
    isCurrentUserProfile,
    selectTools,
    updateProfileTools,
    globalEdit,
    edit,
    setEdit,
    selectedTools,
    setSelectedTools
  };
}

export default useToolsUtils;
