import React from 'react';
import styles from './SettingSection.module.scss';
import {useTranslation} from "react-i18next";
function SettingSection({ title, children }) {
    const { t } = useTranslation();
  return (
    <div className={styles['setting-section']}>
      <h2>{t(title)}</h2>
      <div className={styles['line']} />
      {children}
    </div>
  );
}

SettingSection.Content = function SettingSectionContent({ children }) {
  return <div className={styles['setting-section-content']}>{children}</div>;
};

export default SettingSection;
