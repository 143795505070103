import Options from 'components/atoms/Options/Options';
import { useAchievementUtils } from 'components/organisms/Profile/hooks/templates';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import styles from './AchievementsSection.module.scss';
import { LoadMoreButton, Loader } from 'components/ui';
import CreateAchievementModal from './CreateAchievementModal';
import AchievementCard from './AchievementCard';
import img from 'assets/images/star.webp';
import { useHandleRtkPagination } from 'hooks';
import { useGetAchievementsByUserIdQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';

const defaultSectionTitle = 'Achievements';
function AchievementsSectionView({
  data: achievements,
  hasNextPage,
  isFetching,
  setPage,
  sectionTitle,
  isPlaceholdersActive
}) {
  const userId = useSelector(getCurrentUserIdSelector);
  const [create, setCreate] = useState(false);

  const { onUpdateSubmit, onDeleteSubmit, onCreateSubmit, isSaving, isCurrentUserProfile } =
    useAchievementUtils({ userId });

  return (
    <div className={styles.achievements}>
      {isCurrentUserProfile && (
        <Options extraClass="roleOptions" handleAdd={() => setCreate(true)} />
      )}
      <div className={styles.desc}>
        <h3 className={styles.title} id="achievements-title">
          {sectionTitle ?? defaultSectionTitle}
        </h3>
      </div>

      <div className={styles.achievementsContainer}>
        {achievements?.map((achievement) => {
          return (
            <AchievementCard
              img={img}
              isPlaceholdersActive={isPlaceholdersActive}
              key={achievement.id}
              name={achievement.name}
              institution={achievement.institution}
              description={achievement.description}
              location={achievement.location}
              achievement={achievement.achievement}
              from={achievement.from}
              to={achievement.to}
              id={achievement.id}
              isCurrentUserProfile={isCurrentUserProfile}
              onUpdateSubmit={onUpdateSubmit}
              onDeleteSubmit={onDeleteSubmit}
            />
          );
        })}
        {isFetching && <Loader />}
        <LoadMoreButton
          hidden={!hasNextPage}
          onClick={() => setPage((pre) => pre + 1)}
          disabled={isFetching || !hasNextPage}
        />{' '}
      </div>

      <CreateAchievementModal
        isSaving={isSaving}
        open={create}
        onClose={() => setCreate(false)}
        userId={userId}
        onCreateSubmit={onCreateSubmit}
      />
    </div>
  );
}

function AchievementsSection({ userId, ...args }) {
  const [sharedDate] = useGetSharedProfile();
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getAchievementsByUserId',
    rtkQuery: useGetAchievementsByUserIdQuery,
    rtkQueryArgs: { userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isLoading, isError, isFetching, error } = !isSharedMode()
    ? result
    : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const achievements = isSharedMode() ? data?.bio?.achievements : data?.result;
  const isPlaceholdersActive = achievements?.length === 0;

  return (
    <AchievementsSectionView
      data={achievements && achievements.length !== 0 ? achievements : placeHolder}
      hasNextPage={data?.page?.hasNextPage}
      setPage={setPage}
      isFetching={isFetching}
      sectionTitle={args?.sectionTitle}
      isPlaceholdersActive={isPlaceholdersActive}
    />
  );
}

const placeHolder = [
  {
    id: 1,
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem nobis veniam obcaecati ab reprehenderit perspiciatis doloremque sapiente, incidunt nam modi eos autem adipisci dolore repellendus provident dolores, corporis laborum corrupti.',
    institution: 'Cambridge University',
    location: 'Cambridge, UK',
    name: 'First Class Honours',
    from: '2024-01-26T12:18:42.000Z',
    to: '2024-03-26T12:18:42.000Z'
  },
  {
    id: 2,
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem nobis veniam obcaecati ab reprehenderit perspiciatis doloremque sapiente, incidunt nam modi eos autem adipisci dolore repellendus provident dolores, corporis laborum corrupti.',
    institution: 'Oxford University',
    location: 'Oxford, UK',
    name: 'Second Class Honours',
    from: '2024-01-26T12:18:42.000Z',
    to: '2024-03-26T12:18:42.000Z'
  },
  {
    id: 3,
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem nobis veniam obcaecati ab reprehenderit perspiciatis doloremque sapiente, incidunt nam modi eos autem adipisci dolore repellendus provident dolores, corporis laborum corrupti.',
    institution: 'Cambridge University',
    location: 'Cambridge, UK',
    name: 'First Class Honours',
    from: '2024-01-26T12:18:42.000Z',
    to: '2024-03-26T12:18:42.000Z'
  }
];

export default AchievementsSection;
