import React from 'react';
import styles from './Icons.module.scss';
import { ReactComponent as FB } from 'assets/icons/fb.svg';
import { ReactComponent as TW } from 'assets/icons/x.svg';
import { ReactComponent as LI } from 'assets/icons/linkedin.svg';
import { ReactComponent as WP } from 'assets/icons/wapp.svg';
import { getDialAndNumber, trimThenLowerCase } from 'helpers';
import LinkChecker from '../LinkChecker/LinkChecker';
export default function Icons({ social, extraClass }) {
  const fb = social?.find((item) => trimThenLowerCase([item.name])[0] === 'facebook')?.link;
  const x = social?.find((item) => trimThenLowerCase([item.name])[0] === 'x')?.link;
  const li = social?.find((item) => trimThenLowerCase([item.name])[0] === 'linkedin')?.link;
  const wp = social?.find((item) => trimThenLowerCase([item.name])[0] === 'whatsapp')?.link;
  return (
    <div className={`${styles.icons} ${styles[extraClass]}`}>
      {fb && (
        <LinkChecker link={fb}>
          <FB />
        </LinkChecker>
      )}
      {x && (
        <LinkChecker link={x}>
          <TW />
        </LinkChecker>
      )}
      {li && (
        <LinkChecker link={li}>
          <LI />
        </LinkChecker>
      )}
      {wp && (
        <LinkChecker isWhatsApp link={getDialAndNumber(wp)[2]}>
          <WP />
        </LinkChecker>
      )}
      {social?.length === 0 && <span>No Links Set</span>}
    </div>
  );
}
