import React, { useState, useEffect } from 'react';
import styles from './Achievements.module.scss';
import { ProfilePic } from 'components/ui';
import PropTypes from 'prop-types';
import { EditInput } from 'components/form';
import { objectsEqual_ByKeys, onFieldChangeProfileModules } from 'helpers';
import { getMonthAndYearName } from 'helpers/dateHandler';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import Options from 'components/atoms/Options/Options';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  serialize,
  isJsonString,
  convertStringToParagraph
} from 'components/atoms/MWEditor/Helpers';
export default function Card({
  name,
  institution,
  achievement,
  id,
  from,
  to,
  description,
  location,
  ...rest
}) {
  const originValues = { name, institution, achievement, id, from, to, description, location };
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState({ ...originValues });
  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };
  const globalEdit = useSelector(getGlobalEditSelector);

  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  return (
    <div className={styles.achievementCard}>
      {rest.isCurrentUserProfile && (
        <Options
          extraClass="profileOptions"
          handleDiscard={() => setEdit(false)}
          handleSave={() => {
            const objectsEqual = objectsEqual_ByKeys(fieldsChange, originValues);
            if (objectsEqual) return setEdit(false);
            rest.onUpdateSubmit(fieldsChange);
            setEdit(false);
          }}
          handleDelete={() => setDeleteModal(true)}
          handleEdit={() => {
            setFieldsChange({ ...originValues });
            setEdit(true);
          }}
          edit={edit}
        />
      )}
      {edit ? (
        <>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic
                extraClass={rest?.extraClass}
                image={rest?.img}
                width={rest?.width}
                height={rest?.height}
              />
              <h2 className={styles.editField}>
                <EditInput
                  maxLength={100}
                  onChange={({ target }) => onFieldsChange('name', target.value)}
                  fontWeight="bold"
                  value={fieldsChange.name}
                />
              </h2>
            </div>
            <div className={styles.content}>
              <h3 className={styles.editField}>
                <EditInput
                  maxLength={100}
                  value={fieldsChange.institution}
                  fontWeight="bold"
                  onChange={({ target }) => onFieldsChange('institution', target.value)}
                />
              </h3>
              <p className={`${styles.location} ${styles.editField}`}>
                <EditInput
                  onChange={({ target }) => onFieldsChange('location', target.value)}
                  value={fieldsChange.location}
                />
              </p>
              <p className={`${styles.location} ${styles.editField}`}>
                <MWEditorLite
                  data={
                    description
                      ? isJsonString(description)
                        ? JSON.parse(description)
                        : convertStringToParagraph(description)
                      : undefined
                  }
                  placeholderText="Describe your achievement"
                  setData={(value) => onFieldsChange('description', JSON.stringify(value))}
                />
              </p>
              <div className={styles.datepicker}>
                {from && (
                  <DatePicker
                    maxDate={new Date()}
                    selected={new Date(fieldsChange.from)}
                    onChange={(date) => onFieldsChange('from', date.toString())}
                    showYearDropdown
                  />
                )}
                {to && (
                  <DatePicker
                    maxDate={new Date()}
                    selected={new Date(fieldsChange.to)}
                    minDate={new Date(fieldsChange.from)}
                    onChange={(date) => onFieldsChange('to', date.toString())}
                    showYearDropdown
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic
                extraClass={rest?.extraClass}
                image={rest?.img}
                width={rest?.width}
                height={rest?.height}
              />
              <h2>{name}</h2>
            </div>
            <div className={styles.content}>
              <h3>{institution}</h3>
              <p className={styles.location}>{location}</p>
              <div
                className={styles.location}
                dangerouslySetInnerHTML={{
                  __html: serialize(
                    description
                      ? isJsonString(description)
                        ? JSON.parse(description)
                        : convertStringToParagraph(description)
                      : undefined
                  )
                }}
              ></div>
              <div>
                {from && <p className={styles.date}>From: {getMonthAndYearName(from)}</p>}
                {to && <p className={styles.date}>To: {getMonthAndYearName(to)}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          title="Are you sure you want to delete this achievement ?"
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => rest.onDeleteSubmit(id)}
        />
      )}
    </div>
  );
}

Card.propTypes = {
  img: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  achievement: PropTypes.string,
  name: PropTypes.string,
  institution: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
  extraClass: PropTypes.string
};
