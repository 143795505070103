import React, { useCallback, useEffect, useState } from 'react';
import CreateModal from '../CreateModal/CreateModal';
import { GIPHY_KEY } from 'utils/constans';
import styles from './GifPickerModal.module.scss';
// TODO Create new Giphy saerchbox Using
import axios from 'axios';
import Search from 'components/atoms/Search/Search';
import { handleAsync } from 'helpers';
import _ from 'lodash';
// Import other necessary modules and components

function GifPickerModal({ setGifPickerVisible, insertGifs }) {
  const [url, setUrl] = useState('');
  const [giphyData, setGiphyData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const API_URL = 'https://api.giphy.com/v1/gifs/';

  const fetchGifs = useCallback(async () => {
    // (searchQuery);
    let endpoint = '';
    if (searchQuery) {
      endpoint = `${API_URL}search?api_key=${GIPHY_KEY}&q=${searchQuery}&limit=12&offset=0&rating=Y&lang=en`;
    } else {
      endpoint = `${API_URL}trending?api_key=${GIPHY_KEY}&limit=12&offset=0&rating=Y&lang=en`;
    }

    const results = await handleAsync(axios.get(endpoint));
    setGiphyData(results[1].data.data);
  }, [searchQuery]);
  useEffect(() => {
    fetchGifs();
  }, [fetchGifs]);

  const handleGifClick = (gif) => {
    insertGifs(gif.images.downsized.url);
    setGifPickerVisible(false);
  };
  return (
    <CreateModal
      clickEvent={() => {
        insertGifs(url);
        setGifPickerVisible(false);
      }}
      handleClose={() => {
        setGifPickerVisible(false);
      }}
      ignoreOutsideClick={true}
      extraClass="gifModal"
    >
      <h2 className={styles.gifTitle}>{'Pick a GIF'}</h2>
      <div className={styles.searchBoxWrapper}>
        <Search
          searchQuery={searchQuery}
          limit={25}
          setSearchQuery={setSearchQuery}
          placeholder="Search for a GIF"
        />
      </div>
      <GifGallery giphyData={giphyData} handleGifClick={handleGifClick} />
    </CreateModal>
  );
}

const GifGallery = ({ giphyData, handleGifClick }) => {
  return (
    <div className={styles.gif}>
      {giphyData.map((gif, index) => {
        return (
          <div
            onClick={() => {
              handleGifClick(gif);
            }}
            className={`${styles.gif_container}`}
            key={index}
          >
            <img className={styles.gif_image} src={gif.images.downsized.url} alt="gif" />
          </div>
        );
      })}
    </div>
  );
};
export default GifPickerModal;
