import { apiSlice } from 'api/apiSlice';
import { optimisticUpdater } from 'api/utils';
import { decreasePostCommentsNum, increasePostCommentsNum } from './utils';
import { addObjectToArrayIfNotExist } from 'helpers';
const secondaryUrl = 'comment';
const commentExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createComment: builder.mutation({
      query: ({ comment }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: comment
      }),
      invalidatesTags: (result) => {
        const returnedComment = result;
        if (returnedComment) {
          return [{ type: 'Comment', id: returnedComment.postId }];
        }
      },
      async onQueryStarted({ comment }, { dispatch, queryFulfilled }) {
        const { postId } = comment;
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {
            dispatch(apiSlice.util.invalidateTags([{ type: 'Post', id: 'LIST' }]));
          },
          (createdComment) => {
            dispatch(
              apiSlice.util.updateQueryData('getCommentsByPostId', { postId }, (draft) => {
                if (draft.result) draft.result.push(createdComment);
              })
            );
            increasePostCommentsNum({
              postId,
              queryName: 'getPostsByCompanyId'
            });
          }
        );
      }
    }),
    getCommentsByPostId: builder.query({
      query: ({ postId, page = 1, size = 10 }) => ({
        url: `/v1/${secondaryUrl}/post/${postId}?page=${page}&size=${size}&sort=createdAt`
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { postId } = queryArgs;
        return `${endpointName}({"postId":"${postId}"})`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a Comment, the created Comment is added to the cache, and the last Comment
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new Comment
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      }
      // providesTags: (result, error, { postId }) => {
      //   if (!result)
      //     return [
      //       { type: 'Comment', id: postId },
      //       { type: 'Comment', id: '65410c9c68a5aeff9e91336e' }
      //     ];
      //   const { result: comments } = result;
      //   if (comments) {
      //     const tags = comments.map((comment) => ({
      //       type: 'Comment',
      //       id: comment.id
      //     }));
      //     return [
      //       ...tags,
      //       { type: 'Comment', id: postId },
      //       { type: 'Comment', id: '65410c9c68a5aeff9e91336e' }
      //     ];
      //   } else {
      //     return [
      //       { type: 'Comment', id: postId },
      //       { type: 'Comment', id: '65410c9c68a5aeff9e91336e' }
      //     ];
      //   }
      // }
    }),
    deleteCommentById: builder.mutation({
      query: ({ commentId }) => ({
        url: `/v1/${secondaryUrl}/${commentId}`,
        method: 'DELETE'
      }),
      // invalidatesTags: (result, error, { commentId }) => {
      //   if (commentId) {
      //     return [{ type: 'Comment', id: commentId }];
      //   }
      // },
      async onQueryStarted({ postId, commentId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getCommentsByPostId', { postId }, (draft) => {
            const itemIndex = draft.result.findIndex((item) => item.id === commentId);
            if (itemIndex !== -1) {
              draft.result.splice(itemIndex, 1);
            }
          })
        );

        const decreasePatchResult = decreasePostCommentsNum({
          postId,
          queryName: 'getPostsByCompanyId'
        });

        optimisticUpdater([patchResult, decreasePatchResult], queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Comment', id: 'LIST' }]));
        });
      }
    }),

    updateCommentById: builder.mutation({
      query: ({ commentId, comment }) => ({
        url: `/v1/${secondaryUrl}/${commentId}`,
        method: 'PATCH',
        body: comment
      }),
      async onQueryStarted({ comment, commentId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getCommentsByPostId',
            { postId: comment.postId },
            (draft) => {
              const item = draft.result.find((item) => item.id === commentId);
              // remove profileId since it's just an id, NOT populated data
              const { ...rest } = comment;
              if (item) Object.assign(item, rest);
            }
          )
        );
        optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Comment', id: 'LIST' }]));
        });
      }
    })
  })
});

export const {
  useCreateCommentMutation,
  useGetCommentsByPostIdQuery,
  useDeleteCommentByIdMutation,
  useUpdateCommentByIdMutation
} = commentExtendedSlice;
