import React from 'react';
import styles from './NotFoundPage.module.scss';
function NotFoundPage() {
  return (
    <div className={styles['not-found-container']}>
      <div className={styles['not-found-content']}>
        <h1 className={styles['not-found-heading']}>404 - Page Not Found</h1>
        <p className={styles['not-found-text']}>
          Sorry, the page you are looking for does not exist.
        </p>
        <a href="/" className={styles['not-found-link']}>
          Go back to the Home Page
        </a>
      </div>
    </div>
  );
}

export default NotFoundPage;
