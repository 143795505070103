import React from "react";
import styles from "./Status.module.scss";
import PropTypes from 'prop-types';

function Status({color}){
    return(
       <div className={styles.circle} style={{backgroundColor:color}} />
    );
}
export default Status;
Status.propTypes = {
    color: PropTypes.string
  };