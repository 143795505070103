import React, { useState } from 'react';
import { onFieldChangeProfileModules } from 'helpers';
import DatePicker from 'react-datepicker';
import styles from './Achievements.module.scss';
import { Textarea } from 'components/form';
import { useForm } from 'react-hook-form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { debounce } from 'lodash';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {useTranslation} from "react-i18next";

function CreateAchievementModal({ open, onClose, userId, onCreateSubmit, isSaving }) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();
  const { t } = useTranslation();

  const originCreateValues = {
    from: new Date().toString(),
    to: new Date().toString()
  };

  const [fieldsChange, setFieldsChange] = useState({ ...originCreateValues });

  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  const submitFun = (data) => {
    onCreateSubmit({ ...data, ...fieldsChange });
    onClose(false);
    reset();
  };

  const onSubmit = debounce(handleSubmit(submitFun), 400);
  return (
    <>
      {open && (
        <>
          <CreateModal isSubmitting={isSaving} clickEvent={onSubmit} handleClose={onClose}>
            <h1>{t('Create New Achievement')}</h1>
            <form className={styles.achievementForm}>
              <Textarea
                maxLength={100}
                name="name"
                placeholder={t("Add Achievement Name")}
                {...register('name', { required: true })}
              />
              {errors.acherror && (
                <span className={styles.error}> {t("Achievement Name is required")}</span>
              )}

              <Textarea
                maxLength={100}
                name="institution"
                placeholder={t("Add Institution Name")}
                {...register('institution', { required: true })}
              />
              {errors.inerror && <span className={styles.error}>{t("Institution Name is required")}</span>}
              <Textarea
                name="location"
                placeholder={t("Add Country")}
                {...register('location', { required: true })}
              />
              {errors.cerror && <span className={styles.error}>{t("Country is required")}</span>}
              <MWEditorLite
                data={undefined}
                placeholderText={t("Describe your achievement")}
                setData={(value) => onFieldsChange('description', JSON.stringify(value))}
              />

              <div className={styles.card}>
                <div className="onCreateDatePicker">
                  <DatePicker
                    maxDate={new Date()}
                    selected={new Date(fieldsChange.from)}
                    onChange={(date) => onFieldsChange('from', date.toString())}
                    showYearDropdown
                  />
                  <DatePicker
                    maxDate={new Date()}
                    selected={new Date(fieldsChange.to)}
                    onChange={(date) => onFieldsChange('to', date.toString())}
                    showYearDropdown
                  />
                </div>
              </div>
            </form>
          </CreateModal>
        </>
      )}
    </>
  );
}

export default CreateAchievementModal;
