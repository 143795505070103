import { isSharedMode } from 'helpers';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import routes from 'routes';
import Layout from 'components/templates/Layout';
import { withCheck } from 'HOCs';
import { NotFound } from 'pages';
import { TourProvider } from '@reactour/tour';
import { steps } from 'constants/steps';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
function Routers({ profileData }) {
  return (
    <Router>
      <TourProvider
        rounded={10}
        disableDotsNavigation={true}
        scrollDuration={0.2}
        showDots={false}
        showPrevNextButtons={false}
        showNavigation={false}
        isHighlightingObserved={true}
        onClickMask={() => {}}
        scrollSmooth
        badgeContent={({ totalSteps, currentStep }) => (
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        )}
        highlightedAreaClassName="highlightedItem"
        maskClassName="highlightedItemMask"
        highlightedMaskClassName="highlightedItem"
        steps={steps}
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            {routes?.map((route) => {
              let Component = route.element;
              if (route.private && !(route.isSharable && isSharedMode())) {
                Component = withCheck(Component, profileData);
              }

              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  element={<Component isModalOpen={route.path === '/explore' ? true : false} />}
                />
              );
            })}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </TourProvider>
    </Router>
  );
}

export default Routers;
