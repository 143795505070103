import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './sass/styles.scss';
import styles from './Timeline.module.scss';
import SideAgenda from '../SideAgenda/SideAgenda';
import { EVENT_COLORS } from 'constants';
import {useTranslation} from "react-i18next";

function Timeline({ date, setDate, onKeyPress, onEventSelect, onSelectSlot, events }) {
  let eventCountByDay = {};
  const localizer = momentLocalizer(moment);
  const [sideAgendaProperties, setSideAgendaProperties] = useState({
    show: false,
    date: new Date(),
    events: []
  });
  const sideAgendaRef = useRef(null);
  const { t } = useTranslation();

  const messages = {
    date: t('Date'),
    time: t('Time'),
    event: t('Event'),
    allDay: t('All Day'),
    week: t('Week'),
    work_week: t('Work Week'),
    day: t('Day'),
    month: t('Month'),
    previous: t('Back'),
    next: t('Next'),
    yesterday: t('Yesterday'),
    tomorrow: t('Tomorrow'),
    today: t('Today'),
    agenda: t('Agenda')
  }

  let convertedEvents = [];
  events?.forEach((element, index) => {
    const color = EVENT_COLORS[index % EVENT_COLORS.length];
    convertedEvents.push({
      title: `${element?.host?.contact?.firstName}: ${element?.name}`,
      start: new Date(element.startDate),
      end: new Date(element.endDate),
      allDay: false,
      resource: element,
      color: color
    });
  });

  convertedEvents?.forEach((element, index) => {
    element.multiEvent = eventCountByDay[moment(element.start)] > 2;
  });

  const getDrilldownView = (_, currentView) => (currentView === 'month' ? 'month' : null);

  function eventStyleGetter(event) {
    return {
      style: {
        borderLeft: '3px solid ' + event.color
      }
    };
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideAgendaRef.current && !sideAgendaRef.current.contains(event.target)) {
        setSideAgendaProperties((prevState) => ({
          ...prevState,
          show: false
        }));
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        style={{ paddingBottom: '100px' }}
        className={sideAgendaProperties.show ? styles.agendaOpened : ''}
      >
        <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          culture="es"
          style={{ height: 1000 }}
          date={date}
          onNavigate={(date) => {
            setDate(date);
          }}
          onKeyPressEvent={onKeyPress}
          onSelectEvent={onEventSelect}
          onSelectSlot={onSelectSlot}
          onShowMore={(events, date) => {
            setSideAgendaProperties({
              show: true,
              date: date,
              events: events
            });
          }}
          selectable={true}
          events={convertedEvents}
          eventPropGetter={eventStyleGetter}
          messages={messages}
        />
      </div>
      {sideAgendaProperties.show && (
        <div ref={sideAgendaRef}>
          <SideAgenda
            modal={true}
            date={sideAgendaProperties.date}
            events={sideAgendaProperties.events}
            onEventSelect={onEventSelect}
            setShowSideAgendaProperties={setSideAgendaProperties}
          />
        </div>
      )}
    </>
  );
}

export default Timeline;
