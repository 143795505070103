import Tabs from 'components/atoms/Tabs/Tabs';
import { Link } from 'react-router-dom';
import styles from './BannerNavigation.module.scss';
import { getTheme } from 'helpers/general';
import { useSelector } from 'react-redux';
import { getCompanyThemeSelector } from 'store/login.slice';

function BannerNavigation({ data, extraClass }) {
  if (data && data.length <= 1) return null;

  const companyTheme = useSelector(getCompanyThemeSelector);
  const theme = getTheme(companyTheme, 'companyPage');

  return (
    <div
      style={{ background: theme.bannerGradient }}
      className={`${styles.container} ${styles[extraClass]} JoyridebannerNavigation`}
    >
      <Tabs {...{ data }} />
    </div>
  );
}
export default BannerNavigation;
