import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import InfoHubCard from 'components/molecules/InfoHubCard/InfoHubCard';
import ContainerSection from '../../atoms/InfoHubContainerSection';
import styles from './EmployeeCarousel.module.scss';
import { useGetProfilesByCompanyIdQuery } from 'api/individual-profile';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import { getS3Url } from 'helpers/s3Handler';
import { useTranslation } from 'react-i18next';
function EmployeeCarousel({ title, filter }) {
  const { company } = useSelector(getUserStatusSelector);
  const { t } = useTranslation();
  const { data } = useGetProfilesByCompanyIdQuery({
    companyId: company?.id,
    query: filter
  });
  if (data?.result.length === 0) return;
  return (
    <ContainerSection>
      <div className={`${styles.container} infoFrame`}>
        <h3 className={styles.title}>{t(title)}</h3>
        {typeof data !== 'undefined' && (
          <SliderContainer slides={5} breakpoint={1440} breakpointSlide={4}>
            {data?.result?.map((profile, i) => {
              return (
                <InfoHubCard
                  image={'https://cdn.wizrx.wizrapps.com/Frame_40933_lr6dhq.webp'}
                  key={i}
                  profile={getS3Url(profile?.picture)}
                  title={profile?.contact?.firstName}
                  paragraph={profile?.jobPosition || t('No Role')}
                  location={profile?.contact?.city || t('No Location')}
                  viewDetailsRedirection={`profile/${profile?.id}`}
                  social={profile?.social}
                />
              );
            })}
          </SliderContainer>
        )}
      </div>
    </ContainerSection>
  );
}
export default EmployeeCarousel;
