import React from 'react';
import Edit from 'components/organisms/Edit/Edit';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const EditPage = () => {
  document.body.style.overflow = '';

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('Edit')}</title>
        <meta name="description" content="Change is inevitable " />
      </Helmet>
      <Edit />
    </>
  );
};

export default EditPage;
