import React from 'react';
import PropTypes from 'prop-types';

export default function MWMenu({ active, ...props }) {
  const fill = active === 'universal' ? '#EB7500' : '#C0C2C6';
  return (
    <svg
      width={19}
      height={19}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="universal-icon"
    >
      <g clipPath="url(#a)">
        <path
          d="M3.319 5.74a2.422 2.422 0 1 0 0-4.843 2.422 2.422 0 0 0 0 4.844ZM9.615 5.74a2.422 2.422 0 1 0 0-4.843 2.422 2.422 0 0 0 0 4.844ZM3.319 12.037a2.422 2.422 0 1 0 0-4.844 2.422 2.422 0 0 0 0 4.844ZM9.615 12.037a2.422 2.422 0 1 0 0-4.844 2.422 2.422 0 0 0 0 4.844Z"
          fill={fill}
        />
        <path
          d="M16.032 5.74a2.422 2.422 0 1 0 0-4.843 2.422 2.422 0 0 0 0 4.844ZM16.032 12.037a2.422 2.422 0 1 0 0-4.844 2.422 2.422 0 0 0 0 4.844Z"
          fill={fill}
        />
        <path d="M3.319 18.333a2.422 2.422 0 1 0 0-4.843 2.422 2.422 0 0 0 0 4.843Z" fill={fill} />
        <path
          d="M9.615 18.454a2.422 2.422 0 1 0 0-4.843 2.422 2.422 0 0 0 0 4.843ZM16.032 18.333a2.422 2.422 0 1 0 0-4.843 2.422 2.422 0 0 0 0 4.843Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.897 .897)" d="M0 0h17.557v17.557H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
MWMenu.propTypes = {
  active: PropTypes.string
};
