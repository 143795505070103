import React, { useState } from 'react';
import styles from './NewsCovers.module.scss';
import CreateModal from '../CreateModal/CreateModal';
import {
  announcementCovers,
  checkoutCovers,
  funCovers,
  updateCovers,
  mpublisherCovers
} from 'constants/newsCovers';
import { useTranslation } from 'react-i18next';

export default function NewsCovers({ closeModal, save, selectedCover, setSelectedCover }) {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const tabs = [
    { label: 'Check it out', covers: checkoutCovers },
    { label: 'Announcements', covers: announcementCovers },
    { label: 'Fun', covers: funCovers },
    { label: 'Updates', covers: updateCovers },
    { label: 'From MPublisher', covers: mpublisherCovers }
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div>
      <CreateModal
        extraClass="coversModal"
        handleClose={closeModal}
        clickEvent={() => {
          save(selectedCover);
        }}
      >
        <div className={styles.tabs}>
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={activeTab === index ? styles.active : ''}
            >
              {t(tab.label)}
            </button>
          ))}
        </div>
        <div className={styles.tabContent}>
          <div
            className={
              tabs[activeTab].label === 'From MPublisher'
                ? `${styles.mpublisherCovers} ${styles.covers}`
                : styles.covers
            }
          >
            {tabs[activeTab].covers.map((item) => (
              <div className={styles.singleCover}>
                <img
                  src={item.cover}
                  key={item.id}
                  alt="cover"
                  onClick={() => {
                    if (item.cover === selectedCover) setSelectedCover(undefined);
                    else setSelectedCover(item.cover);
                  }}
                  className={selectedCover === item.cover ? styles.activeCover : ''}
                />
              </div>
            ))}
          </div>
        </div>
        {tabs[activeTab].label === 'From MPublisher' && (
          <p className={styles.mpublisherLink}>
            {t('want-to-see-more')}
            <a
              href="https://publisher.masterwizr.com"
              target="_blank"
              style={{ marginLeft: '5px' }}
            >
              {t('explore-mpublisher')}
            </a>
          </p>
        )}
      </CreateModal>
    </div>
  );
}
