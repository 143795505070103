import { getReviewsTags, updateReviewInListInQuery } from 'api/individual-profile/util';
import { apiSlice } from '../apiSlice';
import { optimisticUpdater } from 'api/utils';
import { addObjectToArrayIfNotExist } from 'helpers';
import { store } from '../../store';
const secondaryUrl = `review`;

export const reviewExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReviewsByProfileId: builder.query({
      query: ({ profileId, activeReviewId, page = 1, size = 200 }) => {
        const queryString = `page=${page}&size=${size}${
          activeReviewId ? `&_id=${activeReviewId}` : ''
        }`;
        return {
          url: `/v1/${secondaryUrl}/profile/${profileId}?${queryString}`
        };
      },
      providesTags: (result, error, { profileId }) => {
        const mainTags = [
          { type: 'Review', id: 'LIST' },
          { type: 'Review', id: profileId }
        ];
        if (!result) return mainTags;
        const invalidatesTags = getReviewsTags(result);
        return [...mainTags, ...invalidatesTags];
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { profileId } = queryArgs;
        return `${endpointName}({"profileId":"${profileId}"})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        newItems.result.forEach((item) => {
          // the reason we're using this function is because we want to make sure that the new item is not already in the cache
          // sometimes the cache is not updated properly and we get duplicated items
          // when you create a review, the created review is added to the cache, and the last review
          // is already in the cache, and will be fetched again in the next page, since it will be go 1 page forward
          // when creating a new review
          addObjectToArrayIfNotExist(currentCache.result, item);
        });
        currentCache.page = newItems.page;
        currentCache.links = newItems.links;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      }
    }),

    deleteReview: builder.mutation({
      query: ({ reviewId }) => ({
        url: `/v1/${secondaryUrl}/${reviewId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ profileId, reviewId }, { queryFulfilled }) {
        const deleteResult = store.dispatch(
          apiSlice.util.updateQueryData('getReviewsByProfileId', { profileId }, (draft) => {
            if (!draft) return;
            const item = draft.result.find((item) => item.id === reviewId);
            if (item) draft.result.splice(draft.result.indexOf(item), 1);
          })
        );
        await optimisticUpdater(deleteResult, queryFulfilled);
      },
      invalidatesTags: () => [{ type: 'Review', id: 'LIST' }]
    }),

    updateReview: builder.mutation({
      query: ({ reviewId, review }) => ({
        url: `/v1/${secondaryUrl}/${reviewId}`,
        method: 'PATCH',
        body: review
      }),
      async onQueryStarted({ reviewId, review }, { dispatch, queryFulfilled }) {
        const { profileId } = review;
        // Here we are removing the reviewerProfile from the review object, since
        // reviewerProfile in this case will be the id of the reviewer, NOT the reviewer's profile
        const { ...rest } = review;

        const patchResult = updateReviewInListInQuery({
          reviewId,
          review: rest,
          queryName: 'getReviewsByProfileId',
          params: { profileId }
        });
        await optimisticUpdater(patchResult, queryFulfilled, () => {
          dispatch(apiSlice.util.invalidateTags([{ type: 'Review', id: reviewId }]));
        });
      }
    }),

    saveReview: builder.mutation({
      query: ({ review }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: review
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await optimisticUpdater(
          null,
          queryFulfilled,
          () => {},
          (createdReviewData) => {
            if (!createdReviewData) return;
            store.dispatch(
              apiSlice.util.updateQueryData(
                'getReviewsByProfileId',
                {
                  profileId: createdReviewData.profileId
                },
                (draft) => {
                  draft.result.unshift(createdReviewData);
                }
              )
            );
          }
        );
      }
    })
  })
});

export const {
  useGetReviewsByProfileIdQuery,
  useDeleteReviewMutation,
  useUpdateReviewMutation,
  useSaveReviewMutation
} = reviewExtendedSlice;
