import React from 'react';
import CompanyTeams from 'components/organisms/CompanyTeams/CompanyTeams';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
const TeamsPage = () => {
  document.body.style.overflow = '';

  const companyId = useSelector(getCurrentCompanyIdSelector);
  return (
    <>
      <Helmet>
        <title>Teams Page</title>
        <meta name="description" content="Everything about a team" />
      </Helmet>
      <CompanyTeams companyId={companyId} />
    </>
  );
};

export default TeamsPage;
