import React from 'react';
import styles from './ErrorPage.module.scss';
function ErrorPageView() {
  return (
    <div className={styles['error-container']}>
      <div className={styles['error-content']}>
        <h1 className={styles['error-heading']}>Oops! Something went wrong</h1>
        <p className={styles['error-text']}>
          An error occurred while processing your request. Please try again later.
        </p>
        <a href="/" className={styles['error-link']}>
          Go back to the Home Page
        </a>
      </div>
    </div>
  );
}

export default ErrorPageView;
