import React from 'react';
import { SectionTag, Wrapper } from '../../components';
import { CompanyStory } from './sections';
import styles from './AboutUs.module.scss';
import { FileUploader } from 'components/form';
import { useUploadBlob } from 'hooks';
import { showErrorToast } from 'utils/toast';
import { deleteFromS3, getS3Url, handleAsync } from 'helpers';
import { getTheme } from 'helpers/general';
import { debounce } from 'lodash';

function AboutUs({ about, onSectionUpdate, hasEditRights, companyTheme }) {
  const [editMode, setEditMode] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const { uploadBlob, isUploading } = useUploadBlob();
  const [aboutState, setAboutState] = React.useState({
    description: about?.description || '',
    image: about?.image || ''
  });
  const appNav = document.getElementById('app-nav');
  const appNavHeight = appNav?.offsetHeight || 0;
  const theme = getTheme(companyTheme, 'companyPage');

  const onSubmit = debounce(async () => {
    setEditMode(false);
    const aboutChange = {
      about: {
        ...aboutState
      }
    };
    if (file) {
      const [status, uploadedFileUrl] = await handleAsync(uploadBlob(file));
      if (status) aboutChange.about['image'] = uploadedFileUrl;
      else {
        const errorResult = uploadedFileUrl;
        const error = typeof errorResult === 'string' ? errorResult : 'Error uploading image';
        return showErrorToast(error);
      }
      if (about?.image) handleAsync(deleteFromS3(about.image));
    }
    onSectionUpdate(aboutChange);
    setEditMode(false);
    setFile(null);
  }, 300);
  return (
    <>
      <div
        id="company-about us"
        style={{
          position: 'relative',
          top: `-${appNavHeight}px`
        }}
      />
      <Wrapper
        px={4}
        py={3}
        isEdit={editMode}
        onCloseEditClick={() => {
          setEditMode(false);
        }}
        onEditClick={() => {
          setEditMode(true);
        }}
        onSubmit={onSubmit}
        editForbidden={!hasEditRights}
      >
        <SectionTag title={theme.aboutTitle} />
        <div className={styles['about-us']}>
          <CompanyStory
            storyDescription={about?.description}
            editMode={editMode}
            onStoryChange={(val) => {
              setAboutState({
                ...aboutState,
                description: val
              });
            }}
          />

          {(editMode || isUploading) && (
            <FileUploader
              isLoading={isUploading}
              onRest={
                about?.image
                  ? () => {
                      const aboutChange = {
                        about: {
                          ...aboutState,
                          image: ''
                        }
                      };
                      onSectionUpdate(aboutChange);
                      setEditMode(false);
                    }
                  : null
              }
              text="Upload..."
              onChange={(files) => setFile(files[0])}
            />
          )}
          {!editMode && !isUploading && (
            <div
              className={styles['about-us__img']}
              style={{
                backgroundImage: `url(
      ${about?.image ? getS3Url(about?.image) : theme.assets?.storyDefaultImage}
      )`
              }}
            />
          )}
        </div>
      </Wrapper>
    </>
  );
}

export default AboutUs;
