import { Button } from 'components/ui';
import React from 'react';
import styles from './HomeCard.module.scss';
import { useNavigate } from 'react-router-dom';

export default function HomeCard({ image, title, ...rest }) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (rest?.newTab) {
      const urlParts = rest?.toLink.split('/');
      const domain = urlParts[urlParts.length - 1];
      const newTab = window.open(`http://${domain}`, '_blank');
      newTab.opener = null;
    } else {
      navigate(`/${rest?.toLink}`);
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={image} alt="card" />
        {rest?.toLink && (
          <Button bgColor="transparent" onClick={handleButtonClick} color="white">
            {rest?.btnText}
          </Button>
        )}
      </div>
      <h1>{title}</h1>
      <p>{rest?.desc}</p>
    </div>
  );
}
