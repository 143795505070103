import React, { useState, useEffect } from 'react';
import styles from './AvailabilityTable.module.scss';
import { useSelector } from 'react-redux';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { DEFAULT_SELECTED_TIMES } from 'constants';
import { TABLE_ROWS_DATA } from 'constants';
import {useTranslation} from "react-i18next";

export default function AvailabilityTable({
  handleChange,
  daysAvailability,
  originalDaysAvailability,
  isTimezoneSelected
}) {
  const globalEdit = useSelector(getGlobalEditSelector);
  const [selectedTimes, setSelectedTimes] = useState({ ...DEFAULT_SELECTED_TIMES });
  const [populateAll, setPopulateAll] = useState(!isTimezoneSelected);
  const { t } = useTranslation();

  useEffect(() => {
    if (!daysAvailability) return;
    setSelectedTimes({ ...DEFAULT_SELECTED_TIMES, ...daysAvailability });
  }, [daysAvailability]);

  const handleTimeChange = (day, value, timeValue, format) => {
    const updatedSelectedTimesForDay = {
      ...selectedTimes[day],
      // that check for overtime, since we will send only string, so format will be undefined
      [value]: timeValue?.format?.(format) || timeValue
    };
    const stateToUpdate = {
      ...selectedTimes,
      [day]: updatedSelectedTimesForDay
    };
    setSelectedTimes(stateToUpdate);
    handleChange(stateToUpdate);
  };

  const handleTimeChangeForAll = (value, timeValue, format) => {
    const updatedSelectedTimes = Object.keys(selectedTimes).reduce((acc, day) => {
      const updatedSelectedTimesForDay = {
        ...selectedTimes[day],
        // that check for overtime, since we will send only string, so format will be undefined
        [value]: timeValue?.format?.(format) || timeValue
      };
      return {
        ...acc,
        [day]: updatedSelectedTimesForDay
      };
    }, {});
    setSelectedTimes(updatedSelectedTimes);
    handleChange(updatedSelectedTimes);
  };

  return (
    <>
      <div className={styles.table}>
        <table>
          <tbody>
            <tr>
              <th></th>
              {Object.keys(selectedTimes).map((day) => (
                <th key={day}>{day}</th>
              ))}
            </tr>
            {TABLE_ROWS_DATA.map(({ value, label }, index) => (
              <tr key={value}>
                <td>{t(label)}</td>
                {Object.keys(selectedTimes).map((day) => (
                  <td key={day}>
                    {!globalEdit && <div>{originalDaysAvailability?.[day]?.[value] || '-'}</div>}
                    {globalEdit && value === 'overtime' && (
                      <select
                        className={styles['overtimeSelectPicker']}
                        onChange={(e) => {
                          handleTimeChange(day, value, e.target.value, 'h');
                        }}
                        defaultValue={selectedTimes[day][value]}
                      >
                        {Array.from({ length: 5 }, (_, i) => i).map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    )}
                    {globalEdit && value !== 'overtime' && (
                      <TimePicker
                        key={selectedTimes[day][value]}
                        showSecond={false}
                        showMinute={value !== 'overtime'}
                        className={value !== 'overtime' ? 'timePicker' : 'overtimePicker'}
                        placeholder={selectedTimes[day][value]}
                        defaultValue={
                          selectedTimes[day][value]
                            ? moment(
                                selectedTimes[day][value],
                                value !== 'overtime' ? 'h:mm A' : 'h'
                              )
                            : undefined
                        }
                        onChange={(timeValue) => {
                          if (day !== 'mon') setPopulateAll(false);
                          if (populateAll && day === 'mon')
                            handleTimeChangeForAll(
                              value,
                              timeValue,
                              value !== 'overtime' ? 'h:mm A' : 'h'
                            );
                          else
                            handleTimeChange(
                              day,
                              value,
                              timeValue,
                              value !== 'overtime' ? 'h:mm A' : 'h'
                            );
                        }}
                        format={value !== 'overtime' ? 'h:mm A' : 'h'}
                        use12Hours={value !== 'overtime'}
                        inputReadOnly
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
