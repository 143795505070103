import SingleTeam from 'components/organisms/SingleTeam/SingleTeam';
import React from 'react';
import { Helmet } from 'react-helmet';
const SingleTeamPage = () => {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>Single Team</title>
        <meta name="description" content="Details about a team" />
      </Helmet>
      <SingleTeam />
    </>
  );
};

export default SingleTeamPage;
