import React, { useEffect } from 'react';
import ServiceCard from './sections/ServiceCard/ServiceCard';
import { Wrapper, SectionTag } from '../../components';
import styles from './Services.module.scss';
import { Modal } from 'components/modals';
import { Textarea } from 'components/form';
import { Button } from 'components/ui';
import { showWarnToast } from 'utils/toast';
import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { TrashI } from 'components/ui';
import { isStringLimited } from 'helpers';
import { getTheme } from 'helpers/general';

function Services({ services, onSectionUpdate, hasEditRights, companyTheme }) {
  const [editServices, setEditServices] = React.useState(false);
  const [isAddService, setIsAddService] = React.useState(false);
  const theme = getTheme(companyTheme, 'companyPage');
  const [selectedServicesForMultiDeletion, setSelectedServicesForMultiDeletion] = React.useState(
    []
  );
  const [selectedServicesForDeletion, setSelectedServicesForDeletion] = React.useState(false);

  // this a state used to update the original services data, since the original services data
  // can't be updated directly because it's a prop
  const [originalServicesCopy, setOriginalServicesCopy] = React.useState(
    services ? [...JSON.parse(JSON.stringify(services))] : []
  );
  const [newService, setNewService] = React.useState({
    title: '',
    description: ''
  });

  const appNav = document.getElementById('app-nav');
  const appNavHeight = appNav?.offsetHeight || 0;

  useEffect(() => {
    // this useEffect is needed to update the originalServicesCopy state when the services prop changes
    // when there is no services, and then a new one is created, and then you tried updating it, app will crash
    // since the originalServicesCopy state is not updated
    if (services) {
      setOriginalServicesCopy([...JSON.parse(JSON.stringify(services))]);
    }
  }, [services]);

  const handleToggleServiceSelection = (service) => {
    if (selectedServicesForMultiDeletion.includes(service)) {
      setSelectedServicesForMultiDeletion(
        selectedServicesForMultiDeletion.filter((s) => s !== service)
      );
    } else {
      setSelectedServicesForMultiDeletion([...selectedServicesForMultiDeletion, service]);
    }
  };

  const handleDeleteSelectedServices = () => {
    const servicesCopy = services.filter(
      (service) => !selectedServicesForMultiDeletion.includes(service)
    );
    setOriginalServicesCopy([...servicesCopy]);
    onSectionUpdate({
      services: servicesCopy
    });
    setEditServices(false);
    setSelectedServicesForMultiDeletion([]);
    setSelectedServicesForDeletion(false);
  };

  return (
    <>
      <div
        className={styles.services}
        id="company-services"
        style={{
          position: 'relative',
          top: `-${appNavHeight}px`
        }}
      />
      <Wrapper
        px={4}
        py={3}
        isEdit={editServices}
        onCloseEditClick={() => {
          setEditServices(false);
          setSelectedServicesForMultiDeletion([]);
        }}
        extraClass="company"
        onEditClick={() => {
          setEditServices(true);
        }}
        onAddClick={() => {
          !isAddService && setIsAddService(true);
        }}
        onSubmit={() => {
          onSectionUpdate({
            services: [...originalServicesCopy]
          });
          setEditServices(false);
        }}
        editForbidden={!hasEditRights}
      >
        <SectionTag title="Services" />
        <Modal
          isModalOpen={isAddService}
          onClose={() => {
            setIsAddService(false);
          }}
          hasHeader
        >
          <div
            className="wrapper"
            style={{
              padding: '1rem'
            }}
          >
            <p>Add a new service</p>
            {/* <Input placeholder="Service Title" /> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // gap: '1rem',
                margin: '1rem 0'
              }}
            >
              <Textarea
                change={(e) => {
                  const val = e.target.value.substring(0, 50);
                  if (e.target.value.length > 50)
                    showWarnToast('Service title should be less than 50 characters');
                  setNewService({
                    ...newService,
                    title: val
                  });
                }}
                placeholder="Service Title"
                value={newService.title}
              />
              <Textarea
                onChange={(e) => {
                  let inputValue = e.target.value;
                  const [isWithinLimit, trimmedValue] = isStringLimited(inputValue, 200);

                  if (!isWithinLimit) {
                    showWarnToast('Service description should be less than 200 characters');
                  }

                  setNewService({
                    ...newService,
                    description: trimmedValue
                  });
                }}
                placeholder="Service Description"
                value={newService.description}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '.5rem'
              }}
              className="buttons"
            >
              <Button
                onClick={() => {
                  const servicesCopy = services ? [...services] : [];
                  const servicesData = {
                    services: [newService, ...servicesCopy]
                  };
                  onSectionUpdate(servicesData);
                  setIsAddService(false);
                  setNewService({
                    title: '',
                    description: ''
                  });
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setIsAddService(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
        <div className={styles['services']}>
          {services?.length > 0 && (
            <SliderContainer
              breakpoint={1440}
              breakpointSlide={3}
              slides={4}
              extraClass="reportsSlider"
            >
              {services?.map((service, i) => (
                <div className={styles['card-container']} key={i}>
                  <ServiceCard
                    onFieldsChange={(val, field) => {
                      const copyDate = [...JSON.parse(JSON.stringify(originalServicesCopy))];
                      copyDate[i][field] = val;
                      setOriginalServicesCopy([...copyDate]);
                    }}
                    editMode={editServices}
                    key={service.title}
                    title={service.title}
                    description={service.description}
                    onToggleSelection={() => handleToggleServiceSelection(service)}
                    isSelected={selectedServicesForMultiDeletion.includes(service)}
                    companyTheme={companyTheme}
                  />
                </div>
              ))}
            </SliderContainer>
          )}
          {editServices && selectedServicesForMultiDeletion.length > 0 && (
            <div
              onClick={() => setSelectedServicesForDeletion(true)}
              className={styles['selected-services']}
            >
              <TrashI /> <p>Delete Selected Services</p>
            </div>
          )}
          {selectedServicesForDeletion && (
            <DeleteModal
              title="Are you sure you want to delete these services ?"
              handleDelete={handleDeleteSelectedServices}
              handleClose={() => {
                setSelectedServicesForDeletion(false);
                setSelectedServicesForMultiDeletion([]);
              }}
            />
          )}
          {!services?.length && hasEditRights && (
            <>
              <h3>No Services</h3>
            </>
          )}
        </div>
      </Wrapper>
    </>
  );
}

export default Services;
