import React, { useState } from 'react';
import styles from './StatisticsSection.module.scss';
import StatisticCard from './StatisticCard/StatisticCard';
import { useStatsUtils } from 'components/organisms/Profile/hooks/templates';
import Options from 'components/atoms/Options/Options';
import CreateStatsModal from './CreateStatsModal';
import { useHandleRtkPagination } from 'hooks';
import { useGetStatsByUserIdQuery } from 'api/individual-profile/statsSlice';
import { handleAsync, isSharedMode } from 'helpers';
import { LoadMoreButton, Loader } from 'components/ui';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';

function StatisticsSectionView({
  data,
  sectionTitle,
  hasNextPage,
  isFetching,
  setPage,
  isPlaceholdersActive
}) {
  const { isCurrentUserProfile, saveStats, currentUserId, isSaving } = useStatsUtils();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  return (
    <div className={styles.statisticsContainer}>
      <h3 className={styles.name} id="statistics-name">
        {sectionTitle ?? 'Statistics'}
      </h3>
      {isCurrentUserProfile && (
        <Options
          extraClass="WhyMeOptions"
          // handleDiscard={() => setEdit(false)}
          // handleEdit={() => setEdit(true)}
          // edit={edit}
          handleSave={() => {}}
          handleAdd={() => {
            setOpenCreateModal(true);
          }}
        />
      )}
      <div className={styles.statisticsWrapper}>
        {data?.map((item) => {
          return (
            <StatisticCard
              isCurrentUserProfile={isCurrentUserProfile}
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              proficiency={item.proficiency}
              isPlaceholdersActive={isPlaceholdersActive}
            />
          );
        })}
        {isFetching && <Loader />}
        <LoadMoreButton
          hidden={!hasNextPage}
          onClick={() => setPage((pre) => pre + 1)}
          disabled={isFetching || !hasNextPage}
        />{' '}
      </div>

      <CreateStatsModal
        isSaving={isSaving}
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
        }}
        onSaveStats={async (data) => {
          const [state] = await handleAsync(
            saveStats({ stats: data, userId: currentUserId }).unwrap(),
            'Statistics saved successfully',
            'Failed to save statistics'
          );
          if (state) {
            setOpenCreateModal(false);
          }
        }}
      />
    </div>
  );
}
function StatisticsSection(args) {
  const [sharedDate] = useGetSharedProfile();
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getStatsByUserId',
    rtkQuery: useGetStatsByUserIdQuery,
    rtkQueryArgs: { userId: args?.userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });

  const { data, isLoading, isError, isFetching, error } = !isSharedMode()
    ? result
    : sharedDate ?? {};

  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }

  const statistics = isSharedMode() ? data?.stats : data?.result;
  const isPlaceholdersActive = statistics?.length === 0;
  return (
    <StatisticsSectionView
      hasNextPage={data?.page?.hasNextPage}
      setPage={setPage}
      isFetching={isFetching}
      sectionTitle={args?.sectionTitle}
      data={statistics && statistics?.length !== 0 ? statistics : placeholder}
      isPlaceholdersActive={isPlaceholdersActive}
    />
  );
}

const placeholder = [
  {
    id: 1,
    name: 'Shooting Accuracy - (Placeholder)',
    description:
      'I take pride in my shooting accuracy, consistently hitting 80% of my field goals, making 45% of my three-point attempts, and maintaining a sharp 90% free throw percentage.',
    proficiency: 80
  },
  {
    id: 2,
    name: 'Efficiency - (Placeholder)',
    description:
      "Efficiency is key to my game. I've earned a Player Efficiency Rating (PER) of 28, coupled with a commendable 65% True Shooting Percentage.",
    proficiency: 65
  },
  {
    id: 3,
    name: 'Speed - (Placeholder)',
    description:
      'Exhibiting above-average quickness, enabling me to make rapid sprints, execute swift changes in direction, and cover considerable ground in a short amount of time.',
    proficiency: 70
  }
];

export default StatisticsSection;
