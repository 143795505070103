import React, { useEffect } from 'react';

import { capitalizeFirstLetter, getContinents, isSharedMode } from 'helpers';
import { useGetTeamsByCompanyIdQuery } from 'api/team';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { useGetProfilePositionsByCompanyIdQuery } from 'api/individual-profile';

// TODO: try to remove the dependency
// NOTE:
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// If you want to change the title of the filters, you must make change to "prepareProfilesQuery" function
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

const teamDefaultFilter = {
  title: 'Team',
  filters: []
};
const continentDefaultFilter = {
  title: 'Continent',
  filters: []
};
const positionDefaultFilter = {
  title: 'Position',
  filters: []
};

function useGetMenuFilters() {
  if (isSharedMode()) return [];
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const projection = 'name id -profiles';
  const { data, isLoading, isError, isSuccess } = useGetTeamsByCompanyIdQuery(
    {
      companyId,
      page: 1,
      size: 100,
      projection
    },
    {
      skip: !companyId || isSharedMode()
    }
  );

  const { data: positions } = useGetProfilePositionsByCompanyIdQuery(
    { companyId },
    {
      skip: isSharedMode() || !companyId
    }
  );

  if (isLoading || isError) return [];
  else if (isSuccess) {
    const continents = getContinents();
    const continentsFilter = continents.map((continent) => ({
      name: capitalizeFirstLetter(continent),
      value: continent
    }));

    const teamsFilter = data?.result.map((team) => {
      return {
        name: capitalizeFirstLetter(team.name),
        value: team.id
      };
    });
    const positionsArr = [
      ...new Set(positions?.map((item) => item.jobPosition && item.jobPosition.trim()))
    ];
    const positionsFilter = positionsArr?.map((jobPosition) => {
      return {
        name: capitalizeFirstLetter(jobPosition),
        value: jobPosition
      };
    });
    return [
      { ...teamDefaultFilter, filters: teamsFilter },
      { ...continentDefaultFilter, filters: continentsFilter },
      { ...positionDefaultFilter, filters: positionsFilter }
    ];
  }
  return [];
}

export default useGetMenuFilters;
