import React from 'react';
import styles from './ResponsiveWrapper.module.scss';
function ResponsiveWrapper({ children }) {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['main-content']}>{children}</div>
    </div>
  );
}

export default ResponsiveWrapper;
