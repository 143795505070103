import { showErrorToast } from 'utils/toast';
import { useSetBioSkillsMutation } from 'api/individual-profile';
import { handleAsync } from 'helpers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import { getCurrentUserProfileId } from 'store/login.slice';

function useSkillsUtils({ skillsList = [] }) {
  const [type, setType] = useState('soft');
  const [edit, setEdit] = useState(false);
  const [allSkills, setAllSkills] = useState([...skillsList]);
  const [currentTypeSkills, setCurrentTypeSkills] = useState([
    ...skillsList.filter((skill) => skill.type === type)
  ]);
  const [updateSkills, { isLoading }] = useSetBioSkillsMutation();
  const globalEdit = useSelector(getGlobalEditSelector);
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const getSkillsByType = (type) => {
    const skillsByType = allSkills.filter((skill) => skill.type === type);
    return skillsByType;
  };

  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  useEffect(() => {
    const skillsByType = getSkillsByType(type);
    setCurrentTypeSkills([...skillsByType]);
  }, [type, allSkills]);

  const saveSkills = async () => {
    setEdit(false);
    const [status] = await handleAsync(updateSkills({ skills: allSkills }).unwrap());
    !status && showErrorToast('Something went wrong');
  };
  return {
    type,
    setType,
    edit,
    setEdit,
    allSkills,
    setAllSkills,
    currentTypeSkills,
    setCurrentTypeSkills,
    updateSkills,
    isLoading,
    globalEdit,
    getSkillsByType,
    saveSkills,
    isCurrentUserProfile
  };
}

export default useSkillsUtils;
