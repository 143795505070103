import React, { useMemo } from 'react';
import styles from './ProfileInfo.module.scss';
import Icontext from 'components/molecules/IconText/IconText';
import { ReactComponent as Tick } from 'assets/icons/tick.svg';
import Text from 'components/atoms/Text/Text';
import Icons from 'components/atoms/Icons/Icons';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Edit } from 'assets/icons/editicon.svg';
import { Link } from 'react-router-dom';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { getCurrentTimeByTimeZone, getMonthAndYearName, parseUserExp } from 'helpers/dateHandler';
import { capitalizeFirstLetter, isSharedMode } from 'helpers';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { AVAILABILITY_OPTIONS } from 'constants';
import { useTranslation } from 'react-i18next';
function ProfileInfo({ profileId }) {
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});
  const { t } = useTranslation();
  const result = useGetIndividualProfileByProfileIdQuery({ profileId }, { skip: isSharedMode() });
  const { data, isSuccess } = isSharedMode() ? sharedData : result;

  const profileTeams = useMemo(() => {
    if (!data?.teams) return [];
    const teamsNames = data.teams?.map((team) => (
      <div key={team.id}>
        <Link style={{ color: 'inherit' }} to={`/team/${team.id}`}>
          {team.name}
        </Link>
      </div>
    ));
    if (teamsNames) return teamsNames;
    return [];
  }, [data?.teams]);

  let content = null;
  if (isSuccess) {
    const dataProfile = isSharedMode()
      ? {
          ...data,
          profile: data.sharedProfile
        }
      : data;
    const contact = dataProfile?.profile.contact;
    const country = contact?.country ? contact.country : '';
    const city = contact?.city ? contact?.city : '';
    const status = dataProfile?.profile?.availability?.status;
    const countryCity =
      country && city
        ? capitalizeFirstLetter(country) + ' | ' + capitalizeFirstLetter(city)
        : country + city;
    const team = dataProfile?.profile?.team[0]?.name ?? 'None';
    const info = [
      {
        title: t('Contact'),
        subheading: contact.phone,
        paragraph: contact.email
      },
      {
        title: t('Contract'),
        subheading: dataProfile?.profile.contract
      },
      {
        title: t('Team'),
        subheading: profileTeams || 'No teams'
      }
    ];
    content = (
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={styles.profile}>
            <div style={{ alignItems: 'center' }}>
              {status === AVAILABILITY_OPTIONS.online.value && (
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'green',
                    borderRadius: '50%'
                  }}
                />
              )}
              <Icontext text={`${contact.firstName} ${contact.lastName}`} />
            </div>
            <div className={styles.desc}>
              <Text paragraph={dataProfile?.profile?.role?.name || ''} />
              <Text paragraph={dataProfile?.profile?.jobPosition || ''} />

              <Text paragraph={countryCity} />
              <Icons social={dataProfile.bio.social} />
            </div>
          </div>
          {!isSharedMode() && (
            <div className={styles.wrapper}>
              <div className={styles.moreinfo}>
                <Icontext
                  icon={<Clock />}
                  text={getCurrentTimeByTimeZone(dataProfile?.profile?.availability?.timezone)}
                />
              </div>
              <div className={`${styles.moreinfo} ${styles.experience}`}>
                <h2>{t('Experience')}</h2>
                <p
                  style={{
                    marginTop: '5px'
                  }}
                >
                  {parseUserExp(dataProfile.sumOfExp)}
                </p>
              </div>

              {info.map((d, i) => {
                return (
                  <div className={styles.moreinfo} key={i}>
                    <Text extraClass="infoData" color="rgba(255, 255, 255, 0.407)" {...d} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <p className={styles.joined}>
          <span>{t('Joined')}: </span>
          {dataProfile.profile?.startedAt
            ? getMonthAndYearName(dataProfile.profile?.startedAt)
            : getMonthAndYearName(data.profile?.createdAt)}
        </p>
      </div>
    );
  }

  return content;
}

// TODO: check if this is needed
export default React.memo(ProfileInfo);
