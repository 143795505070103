import { apiSlice } from 'api/apiSlice';
import { useSelector } from 'react-redux';

function useSelectCacheData(queryName, rtkQueryArgs) {
  const selector = apiSlice.endpoints[queryName]?.select({ ...rtkQueryArgs });
  if (!selector) return undefined;
  const cachedData = useSelector(selector);

  return cachedData;
}

export default useSelectCacheData;
