import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './en/en.json';
import noJson from './no/no.json';
import deJson from './de/de.json';
import esJson from './es/es.json';
import frJson from './fr/fr.json';
import sqJson from './sq/sq.json';
import ptJson from './pt/pt.json';
import bnJson from './bn/bn.json';

i18n.use(initReactI18next).init({
  // debug: true,
  resources: {
    en: enJson,
    no: noJson,
    de: deJson,
    es: esJson,
    fr: frJson,
    sq: sqJson,
    pt: ptJson,
    bn: bnJson
  },
  lng: 'en'
});

export default i18n;
