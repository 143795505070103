import { useGetProfileActiveTemplateQuery } from 'api/individual-profile';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from '../useGetSharedProfile';
import { TabsList } from '../../templates/utils';

/**
 * @useGetProfileActiveTemplate
 * @description custom hook to get the active template for the profile
 * @param {string} profileId - the profile id of the visited profile
 * @returns {object}
 *
 */
function useGetProfileActiveTemplate(profileId) {
  const [sharedDate] = useGetSharedProfile();
  const sharedProfileSectionList =
    sharedDate?.data?.sharedProfile?.activeTemplate?.sectionList ?? [];

  // only home tab is shared
  const sharedProfileTabList = [TabsList.home];
  const sharedProfileType = sharedDate?.data?.sharedProfile?.activeTemplate?.type ?? '';

  const { data, isLoading, error } = useGetProfileActiveTemplateQuery(
    { profileId },
    { skip: isSharedMode() }
  );
  const templateSectionList = data?.activeTemplate?.sectionList ?? sharedProfileSectionList;
  const templateTabs = data?.activeTemplate?.tabList ?? sharedProfileTabList;
  const templateType = data?.activeTemplate?.type ?? sharedProfileType;
  return {
    isLoading,
    errorMessage: error?.data?.message,
    templateSectionList,
    templateTabs,
    templateType
  };
}

export default useGetProfileActiveTemplate;
