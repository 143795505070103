import React from 'react';
import styles from './ImageSection.module.scss';

export default function ImageSection({ img, extraClass, ...rest }) {
    return (
        <div className={`${styles.container} ${styles[extraClass]}`}>
            <img src={img} alt='Info' />
            <div className={styles.content}>
                <h1>{rest?.title}</h1>
                <p>{rest?.description}</p>
            </div>
        </div>
    )
}
