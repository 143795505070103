import { showErrorToast } from 'utils/toast';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIsAdminSelector } from 'store/login.slice';

const withAdminAccess = (WrappedComponent) => {
  const ProfileAccessCheck = ({ ...props }) => {
    const navigate = useNavigate();
    const hasAdminAccess = useSelector(getIsAdminSelector);

    useEffect(() => {
      if (!hasAdminAccess) {
        showErrorToast('Only admins can access this page.');
        navigate('/');
      }
    }, [hasAdminAccess]);
    if (hasAdminAccess) return <WrappedComponent {...props} />;
    else null;
  };

  return ProfileAccessCheck;
};

export default withAdminAccess;
