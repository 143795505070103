import React from 'react';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import styles from './ContactMeSection.module.scss';
import { Textarea } from 'components/form';
import { Button } from 'components/ui';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import useContactMeUtils from 'components/organisms/Profile/hooks/templates/useContactMeUtils';
import { isSharedMode } from 'helpers';
import { useTranslation } from 'react-i18next';
import { showInfoToast, showSuccessToast } from 'utils/toast';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';
const defaultSectionName = 'Contact Me';
function ContactMeView({ profileEmail, sectionTitle, profileId }) {
  const { onContactSubmit } = useContactMeUtils();
  const currentUserProfileId = useSelector(getCurrentUserProfileId);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {}
  });

  const submitFun = async (data) => {
    let contactObject = {
      name: data.name,
      sender: data.email,
      recipient: profileEmail,
      message: data.text
    };
    showInfoToast('Sending your message');
    const [status] = await onContactSubmit(contactObject);

    if (status) {
      showSuccessToast('Message Sent Successfully');
      reset();
    }
  };
  const onSubmit = debounce(handleSubmit(submitFun), 400);
  if (currentUserProfileId !== useParams()?.id)
    return (
      <div className={styles.ContactMeWrapper}>
        <div className={styles.TitleWrapper}>
          <h3>{sectionTitle || t(defaultSectionName)}</h3>
          <h6>
            {t('member-of-mw')} <a href="#">{t('chat-directly')}</a>
          </h6>
        </div>
        <div className={styles.ContactFormWrapper}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Textarea
              maxLength={400}
              name="name"
              placeholder={t('Your name here')}
              {...register('name', { required: true })}
            />
            {errors.name && <span className={styles.error}> Your name is required</span>}
            <Textarea
              maxLength={100}
              name="email"
              placeholder={t('Your email here')}
              {...register('email', { required: true })}
            />
            {errors.email && <span className={styles.error}> Your email is required</span>}
            <Textarea
              maxLength={100}
              name="text"
              placeholder={t('add-text')}
              minRows={4}
              {...register('text', { required: true })}
            />
            {errors.text && <span className={styles.error}>{t('a-text-message-is-required')}</span>}
            <Button type="submit">{t('Send')}</Button>
          </form>
        </div>
      </div>
    );
  else return null;
}
function ContactMeSection({ ...args }) {
  const { data } = useGetIndividualProfileByProfileIdQuery(
    {
      profileId: args?.profileId
    },
    {
      skip: !args?.profileId || isSharedMode()
    }
  );

  return (
    <>
      <ContactMeView
        profileId={args.profileId}
        userId={args.userId}
        profileEmail={data?.bio?.contact?.email}
        sectionTitle={args?.sectionTitle}
      />
    </>
  );
}

export default ContactMeSection;
