import React, { useState, useEffect } from 'react';
import styles from './Publications.module.scss';
import { ProfilePic } from 'components/ui';
import PropTypes from 'prop-types';
import { EditInput } from 'components/form';
import { objectsEqual_ByKeys, onFieldChangeProfileModules } from 'helpers';
import { getMonthAndYearName } from 'helpers/dateHandler';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import Options from 'components/atoms/Options/Options';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  serialize,
  isJsonString,
  convertStringToParagraph
} from 'components/atoms/MWEditor/Helpers';

export default function Card({ name, reference, date, id, description, ...rest }) {
  const originValues = { name, reference, date, id, description };
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState({ ...originValues });
  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };
  const globalEdit = useSelector(getGlobalEditSelector);

  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);
  return (
    <div className={styles.publicationCard}>
      {rest.isCurrentUserProfile && (
        <Options
          extraClass="profileOptions"
          handleDiscard={() => setEdit(false)}
          handleEdit={() => setEdit(true)}
          handleSave={() => {
            const objectsEqual = objectsEqual_ByKeys(fieldsChange, originValues);
            if (objectsEqual) return setEdit(false);
            rest.onUpdateSubmit(fieldsChange);
            setEdit(false);
          }}
          handleDelete={() => setDeleteModal(true)}
          edit={edit}
        />
      )}
      {edit ? (
        <>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic
                extraClass={rest?.extraClass}
                image={rest?.img}
                width={rest?.width}
                height={rest?.height}
              />
              <h2 className={styles.editField}>
                <EditInput
                  maxLength={100}
                  onChange={({ target }) => onFieldsChange('name', target.value)}
                  fontWeight="bold"
                  value={fieldsChange.name}
                />
              </h2>
            </div>
            <div className={styles.content}>
              <h3 className={styles.editField}>
                <EditInput
                  maxLength={300}
                  onChange={({ target }) => onFieldsChange('reference', target.value)}
                  fontWeight="bold"
                  value={fieldsChange.reference}
                />
              </h3>
              <p className={`${styles.location} ${styles.editField}`}>
                <MWEditorLite
                  data={
                    description
                      ? isJsonString(description)
                        ? JSON.parse(description)
                        : convertStringToParagraph(description)
                      : undefined
                  }
                  placeholderText="Describe your publication"
                  setData={(value) => onFieldsChange('description', JSON.stringify(value))}
                />
              </p>
            </div>
            <div className={styles.datepicker}>
              {date && (
                <DatePicker
                  maxDate={new Date()}
                  selected={new Date(fieldsChange.date)}
                  onChange={(date) => onFieldsChange('date', date.toString())}
                  showYearDropdown
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className={styles.card}>
            <div className={styles.profile}>
              <ProfilePic
                extraClass={rest?.extraClass}
                image={rest?.img}
                width={rest?.width}
                height={rest?.height}
              />
              <h2>{name}</h2>
            </div>
            <div className={styles.content}>
              <h3>{reference}</h3>
              <div
                className={styles.location}
                dangerouslySetInnerHTML={{
                  __html: serialize(
                    description
                      ? isJsonString(description)
                        ? JSON.parse(description)
                        : convertStringToParagraph(description)
                      : undefined
                  )
                }}
              ></div>
              <p className={styles.date}>{getMonthAndYearName(date)}</p>
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          title="Are you sure you want to delete this publication ?"
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => rest.onDeleteSubmit(id)}
        />
      )}
    </div>
  );
}

Card.propTypes = {
  img: PropTypes.string,
  date: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string,
  reference: PropTypes.string,
  description: PropTypes.string,
  extraClass: PropTypes.string
};
