import { useGetProfilesByCompanyIdQuery } from 'api/individual-profile';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
// TODO: this custom hook will be extended later
const getQueryString = (name) => {
  if (!name) return '';
  return `regex[contact.firstName]=${name}&regex[contact.lastName]=${name}`;
};
function useProfilesServerSearch(size) {
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const { data, isFetching, isError } = useGetProfilesByCompanyIdQuery({
    companyId,
    size,
    page,
    query: getQueryString(name)
  });
  const setPaginationPage = (pageNum) => {
    if (pageNum <= 0) return;
    else if ((pageNum > page && data?.page?.hasNextPage) || pageNum < page) {
      setPage(pageNum);
    }
  };
  const setProfileName = debounce((name) => {
    setName(name);
  }, 300);

  const profiles = data?.result || [];
  const totalPages = data?.page?.totalPages || 1;
  if (page > totalPages) {
    setPage(totalPages);
  }
  return { setProfileName, profiles, isFetching, isError, setPaginationPage, page, totalPages };
}

export default useProfilesServerSearch;
