import React from 'react';
import SocialBoardPage from 'components/organisms/SocialBoard/SocialBoard';
import { Helmet } from 'react-helmet';
const SocialBoard = () => {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>Social Board</title>
        <meta name="description" content="All the latest news" />
      </Helmet>
      <SocialBoardPage />
    </>
  );
};

export default SocialBoard;
