import React, { useState } from 'react';
import styles from './HomeFaq.module.scss';
import { ReactComponent as Plus } from 'assets/icons/plusIcon.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import {useTranslation} from "react-i18next";

const HomeFaq = ({ faqData }) => {
  const [activeIndices, setActiveIndices] = useState([]);
  const {t} = useTranslation();

  const toggleFaq = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

  return (
    <div className={styles.faqSection}>
      <span className={styles.faq}>{t("FAQ")}</span>
      <h1 className={styles.desc}>{t("In case you missed anything")}</h1>
      {faqData.map((faq, index) => (
        <div
          key={index}
          className={`${styles.faqItem} ${activeIndices.includes(index) ? styles.active : ''}`}
          onClick={() => toggleFaq(index)}
        >
          <div className={styles.question}>
            <h1 className={styles.title}>{t(faq.question)}</h1>
            {activeIndices.includes(index) ? <Minus /> : <Plus />}
          </div>
          {activeIndices.includes(index) && <div className={styles.answer}>{t(faq.answer)}</div>}
        </div>
      ))}
    </div>
  );
};

export default HomeFaq;
