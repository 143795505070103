import React from 'react';
import styles from './SectionTag.module.scss';
function SectionTag({ title }) {
  return (
    <div
      className={styles['section-tag']}
    >
      <p
        className={styles['section-tag__title']}
      >
        {title}
      </p>
    </div>
  );
}

export default SectionTag;
