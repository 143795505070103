import React, { Suspense } from 'react';
import EmployeeCarousel from '../../molecules/EmployeeCarousel/EmployeeCarousel';

import InfoHubProfileBasedTextBlock from 'components/molecules/InfoHubProfileBasedTextBlock';
import FAQ from 'components/molecules/Faq';
import Portals from 'components/molecules/Portals/Portals';
import FeaturedVideos from 'components/molecules/FeaturedVideos/FeaturedVideos';
import Reports from 'components/molecules/Reports/Reports';
import { INITIAL_PORTALS } from 'constants';
import { INITIAL_REPORTS } from 'constants';
import { Loader } from 'components/ui';

function DefaultT({
  data,
  companyId,
  videoPlayingComponent,
  setVideoPlayingComponent,
  lastSelectedImageIndex,
  isLoading,
  profileId
}) {
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
        {/* <ClientCases /> */}
        <EmployeeCarousel filter={'sort=createdAt,asc'} title={'Latest Hires'} />
        <FeaturedVideos
          title="Highlights in videos"
          description="Videos we recommend based on your searching."
          topVideos
          data={data}
          type={'Highlights'}
          companyId={companyId}
          videoPlayingComponent={videoPlayingComponent}
          setVideoPlayingComponent={setVideoPlayingComponent}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <FeaturedVideos
          title="Product Videos"
          description="Videos we recommend based on your searching."
          data={data}
          type={'Product'}
          companyId={companyId}
          videoPlayingComponent={videoPlayingComponent}
          setVideoPlayingComponent={setVideoPlayingComponent}
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <Portals
          portals={data?.info?.portals.length ? data?.info?.portals : INITIAL_PORTALS}
          title="Corporate Portals"
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <Reports
          reports={data?.info?.reports.length ? data?.info?.reports : INITIAL_REPORTS}
          title="Reports"
          colorBgSwitcher={lastSelectedImageIndex}
        />
        <InfoHubProfileBasedTextBlock
          data={data}
          isLoading={isLoading}
          profileId={profileId}
          companyId={companyId}
        />
        <FAQ colorBgSwitcher={lastSelectedImageIndex} />
    </Suspense>
  );
}

export default DefaultT;
