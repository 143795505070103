import React, { useEffect, useState } from 'react';
import styles from './TestimonialsSection.module.scss';
import { onFieldChangeProfileModules } from 'helpers';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { CloseI, EditI } from 'components/ui';
export default function TestimonialCard({
  testimonial,
  isCurrentUserProfile,
  edit,
  setEdittedTestimonial,
  onDeleteSubmit,
  setCreate,
  originReview,
  ...rest
}) {
  const backgroundColors = [
    'linear-gradient(90deg, #33A1A1 0%, #175151 100%)',
    'linear-gradient(90deg, #367AAB 0%, #1E4562 100%)',
    'linear-gradient(90deg, rgb(96 105 160) 0%, rgb(52 64 101) 100%)'
  ];

  const originValues = {
    name: testimonial.name,
    position: testimonial.position,
    testimonialContent: testimonial.testimonial
  };
  const id = testimonial.id;
  const [deleteModal, setDeleteModal] = useState(false);
  const [fieldsChange, setFieldsChange] = useState({ ...originValues });
  const [bgColor, setBgColor] = useState('');

  useEffect(() => {
    const randomColor = backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
    setBgColor(randomColor);
  }, []);

  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };

  return (
    <div className={styles.testimonialCard}>
      {isCurrentUserProfile && edit && (
        <div className={styles.subEditting}>
          <CloseI
            onClick={() => {
              setDeleteModal(true);
            }}
          />
          <EditI
            onClick={() => {
              setEdittedTestimonial(testimonial);
              setCreate(true);
            }}
          />
        </div>
      )}
      <div className={styles.card} style={{ background: bgColor }}>
        <p className={styles.content}>{originReview?.description || rest?.description}</p>
        <p className={styles.name}>
          {originReview?.reviewerProfile?.contact?.firstName || rest?.firstName}
        </p>
        <span className={styles.position}>
          {originReview.reviewerProfile?.jobPosition || rest?.position}
        </span>
      </div>
      {deleteModal && (
        <DeleteModal
          title="Are you sure you want to delete this testimonial?"
          handleClose={() => setDeleteModal(false)}
          handleDelete={() => onDeleteSubmit(id)}
        />
      )}
    </div>
  );
}
