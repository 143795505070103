import React from 'react';
import ChatContainer from 'components/organisms/Chat/Chat';
import { Helmet } from 'react-helmet';
const Chat = () => {
  document.body.style.overflow = '';

  return (
    <>
      <Helmet>
        <title>Chat</title>
        <meta name="description" content="Chat with everyone" />
      </Helmet>
      <ChatContainer />
    </>
  );
};

export default Chat;
