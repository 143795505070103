import React, { useEffect, useState } from 'react';
import styles from './TechList.module.scss';
import { removeHeightSetWidthSvgIcon } from 'helpers';
function TechList({ list, onSelectStateChange, selectedItems, searchedText, isFetching }) {
  return (
    <div className={styles.list}>
      {!isFetching && searchedText && list.length === 0 && (
        <div className={styles.listItem}>
          <input
            checked={selectedItems.some((i) => i.name === searchedText)}
            onChange={({ target: { checked } }) =>
              onSelectStateChange({ checked, item: { name: searchedText } })
            }
            type="checkbox"
          />
          <span
            onClick={() =>
              onSelectStateChange({
                checked: !selectedItems.some((i) => i.name === searchedText),
                item: { name: searchedText }
              })
            }
          >
            <p className={styles['not-found-letter']}>
              {searchedText.substring(0, 1).toUpperCase()}
            </p>
            {searchedText}
          </span>
        </div>
      )}
      {list.map((item) => {
        return (
          <>
            <div className={styles.listItem} key={item.id}>
              <input
                checked={selectedItems.some((i) => i.name === item.name)}
                onChange={({ target: { checked } }) => onSelectStateChange({ checked, item })}
                type="checkbox"
              />
              <span
                onClick={() =>
                  onSelectStateChange({
                    checked: !selectedItems.some((i) => i.name === item.name),
                    item
                  })
                }
              >
                {' '}
                {item.icon && (
                  <span
                    dangerouslySetInnerHTML={{ __html: removeHeightSetWidthSvgIcon(item.icon) }}
                  />
                )}
                {item.name}
              </span>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default TechList;
