import React, { useState } from 'react';
import styles from './Edit.module.scss';
import BasicInfo from 'components/molecules/BasicInfo/BasicInfo';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/ui';
export default function Edit() {
  const urlProfileId = useParams()?.id;
  const { isLoading, isError, data } = useGetIndividualProfileByProfileIdQuery({
    profileId: urlProfileId
  });

  const [index, setIndex] = useState(0);
  if (isLoading) return <Loader fullpage />;
  if (isError) return <p>ERROR</p>;
  return (
    <div className={styles.edit}>
      <div className={styles.container}>
        <button
          onClick={() => setIndex(0)}
          className={index === 0 ? styles.active : ``}
          name="edit"
        ></button>
        <div className={styles.rightPart}>
          <BasicInfo profile={data.profile} bio={data.bio} />
        </div>
      </div>
    </div>
  );
}
