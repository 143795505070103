import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  userData: {},
  userStatus: {},
  isAdmin: false,
  isTouring: false
};
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser(state, action) {
      //Setting global variables for the user here after they login.
      state.isAuthenticated = true;
      state.userStatus = action.payload;
    },
    setProfile(state, action) {
      state.userStatus.profileStatus = {
        ...state.userStatus.profileStatus,
        ...action.payload
      };
    },
    setCompany(state, action) {
      state.userStatus.company = action.payload;
    },
    setUserData(state, action) {
      const { company, profile, user } = action.payload;
      state.userStatus.company = company;
      state.userStatus.profileStatus = profile;
      state.userStatus.user = user;
    },
    setTouring(state, action) {
      state.isTouring = action.payload;
    },
    setCompanyTheme(state, action) {
      state.userStatus.company.theme = action.payload;
    }
  }
});

export const {
  setUser,
  setProfile,
  setCompany,
  setUserData,
  setIsAdmin,
  setTouring,
  setCompanyTheme
} = loginSlice.actions;
export default loginSlice.reducer;
export const getUserStatusSelector = (state) => state?.user?.userStatus;
export const getCompanySelector = (state) => state?.user?.userStatus?.company;
export const getProfileStatusSelector = (state) => state?.user?.userStatus?.profileStatus;
export const getCurrentUserProfileId = (state) => state?.user?.userStatus?.profileStatus?.id;
export const getCurrentUserIdSelector = (state) => state?.user?.userStatus?.user?._id;
export const getCurrentCompanyIdSelector = (state) => state?.user?.userStatus?.company?.id;
export const getIsAdminSelector = (state) => state?.user?.userStatus?.profileStatus?.isAdmin;
export const getIsUserTouringSelector = (state) => state?.user?.isTouring;
export const getCompanyThemeSelector = (state) => state?.user?.userStatus?.company?.theme;
export const getIs_Admin_Editor_Publisher_Selector = (state) =>
  state?.user?.userStatus?.profileStatus?.isAdminOrEditorOrPublisher;
