import React from "react";
import styles from "./styles.module.scss";

const ChatQuickAccess = () => {
    return (
        <div className={`${styles.list} ${styles.horiSpacing}`}>
            <div className={styles.listItem}>Contacts</div>
            <div className={styles.listItem}>Starred Messages</div>
            <div className={styles.listItem}>Tags & Reactions</div>
            <div className={styles.listItem}>Files & Links</div>
        </div>
    )
}

export default ChatQuickAccess
