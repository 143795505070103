import React from 'react';
import styles from './TemplateCard.module.scss';
function SelectedLabel({ label }) {
  return (
    <div className={styles.selectedLabel}>
      <p
        style={{
          fontSize: '1.5rem'
        }}
      >
        {label}
      </p>
    </div>
  );
}

export default SelectedLabel;
