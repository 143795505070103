import { Textarea } from 'components/form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { Button } from 'components/ui';
import { debounce } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import styles from './CreateQAModal.module.scss';

function CreateQAModal({ isSaving, onClose, open, onQASave, isCreating }) {
  if (!open) return null;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      question: '',
      answer: ''
    }
  });

  const closeReset = () => {
    onClose();
    reset();
  };
  const submitFun = async (data) => {
    const qaObject = { ...data };
    const status = await onQASave(qaObject);
    status && closeReset();
  };
  const onSubmit = debounce(handleSubmit(submitFun), 300);

  return (
    <>
      <CreateModal
        isSubmitting={isSaving}
        clickEvent={submitFun}
        showCancel={false}
        showSave={false}
      >
        <h1>{'Create New Entry'}</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Textarea
            placeholder="Question"
            {...register('question', {
              required: {
                value: true,
                message: 'Question is required'
              },
              maxLength: {
                value: 100,
                message: 'Question must be less than 100 characters'
              },
              validate: (value) => value.trim().length > 0 || 'Question cannot be empty'
            })}
          />
          {errors.question && <span className="input-error">{errors.question.message}</span>}

          <Textarea
            placeholder="Answer"
            {...register('answer', {
              required: {
                value: true,
                message: 'Answer is required'
              },
              maxLength: {
                value: 500,
                message: 'Answer must be less than 500 characters'
              },
              validate: (value) => value.trim().length > 0 || 'Answer cannot be empty'
            })}
          />
          {errors.answer && <span className="input-error"> {errors.answer.message}</span>}
          <div className={styles.createModal}>
            <Button
              onClick={() => {
                closeReset();
              }}
              color="white"
              type="button"
              bgColor="#41414185"
            >
              Cancel
            </Button>
            <Button
              color="white"
              bgColor={isSaving ? 'gray' : '#079677'}
              type="submit"
              disable={isCreating}
            >
              {isCreating ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </form>
      </CreateModal>
    </>
  );
}

export default CreateQAModal;
