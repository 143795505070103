import React, { useState, useEffect } from 'react';
import ProfilePicker from '../ProfilePicker/ProfilePicker';
import CreateModal from '../CreateModal/CreateModal';
import styles from './ProfilePickerModal.module.scss';
import {useTranslation} from "react-i18next";
function ProfilePickerModal({
  open,
  setOpen,
  selectedProfiles,
  setSelectedProfiles,
  pickProfileObject,
  ...rest
}) {
  const [currentselectedProfiles, setCurrentSelectedProfiles] = useState([]);
  useEffect(() => {
    setCurrentSelectedProfiles(selectedProfiles);
  }, [selectedProfiles]);
  const clickEvent = () => {
    setSelectedProfiles(currentselectedProfiles);
    setOpen(false);
  };
  const { t } = useTranslation();

  const closeReset = () => {
    setCurrentSelectedProfiles(selectedProfiles);
    setOpen(false);
  };

  return (
    <>
      {open && (
        <>
          <CreateModal clickEvent={clickEvent} handleClose={closeReset} ignoreOutsideClick={true}>
            <h1>{t("Pick Profiles To Compare Events")}</h1>
            <div className={styles.card}>
              <ProfilePicker
                selectedProfiles={currentselectedProfiles}
                setProfiles={setCurrentSelectedProfiles}
                skipSelf={true}
                pickProfileObject={pickProfileObject}
              />
            </div>
          </CreateModal>
        </>
      )}
    </>
  );
}

export default ProfilePickerModal;
