import { apiSlice } from 'api/apiSlice';
const secondaryUrl = `testimonial`;

//TODO: Just a skeleton, needs implementation

export const testimonialExtendedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTestimonialsByProfileId: builder.query({
      query: ({ profileId, page = 1 }) => {
        const queryString = `page=${page}`;
        return {
          url: `/v1/${secondaryUrl}/profile/${profileId}?${queryString}`
        };
      },
      providesTags: ['Testimonial']
    }),
    saveTestimonial: builder.mutation({
      query: ({ testimonial }) => ({
        url: `/v1/${secondaryUrl}`,
        method: 'POST',
        body: testimonial
      }),
      invalidatesTags: ['Testimonial']
    }),
    updateTestimonial: builder.mutation({
      query: ({ testimonialId, testimonial }) => ({
        url: `/v1/${secondaryUrl}/${testimonialId}`,
        method: 'PATCH',
        body: testimonial
      }),
      invalidatesTags: ['Testimonial']
    }),
    deleteTestimonial: builder.mutation({
      query: ({ testimonialId }) => ({
        url: `/v1/${secondaryUrl}/${testimonialId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Testimonial']
    })
  })
});

export const {
  useGetTestimonialsByProfileIdQuery,
  useSaveTestimonialMutation,
  useUpdateTestimonialMutation,
  useDeleteTestimonialMutation
} = testimonialExtendedSlice;
