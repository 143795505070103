import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { useSkillsUtils } from 'components/organisms/Profile/hooks/templates';
import { Loader } from 'components/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import styles from './SkillsSection.module.scss';
import Options from 'components/atoms/Options/Options';
import QueryableTools from 'components/molecules/QueryableTools/QueryableTools';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import {useTranslation} from "react-i18next";
const defaultSectionTitle = 'Skills';
function SkillsSectionView({ skillsList, sectionTitle }) {
  const {
    type,
    edit,
    setEdit,
    allSkills,
    setAllSkills,
    currentTypeSkills,
    getSkillsByType,
    saveSkills,
    isCurrentUserProfile
  } = useSkillsUtils({ skillsList });
  const { t } = useTranslation();
  return (
    <div className={styles.skills}>
      {isCurrentUserProfile && (
        <Options
          extraClass="skillsOptions"
          handleDiscard={() => {
            setAllSkills(skillsList);
            setEdit(false);
          }}
          handleSave={saveSkills}
          handleEdit={() => setEdit(true)}
          edit={edit}
        />
      )}
      <h3 className={styles.sectionTitle}>{t(sectionTitle) ?? t(defaultSectionTitle)}</h3>
      <div className={styles.skillsContainer}>
        <QueryableTools
          add={edit}
          onToolsSelected={(tools) => {
            // check for a tool that has no 'type'
            tools.forEach((tool) => {
              if (!tool.type) {
                tool.type = type;
              }
            });
            const skillsWithoutCurrentType = allSkills.filter((skill) => skill.type !== type);
            setAllSkills([...skillsWithoutCurrentType, ...tools]);
          }}
          rtkQueryName="getSkillsByKeyword"
          list={edit ? [...currentTypeSkills] : [...getSkillsByType(type)]}
          title="Skills"
          searchPlaceholder="search-the-skill-you-want-to-add"
        />
      </div>
    </div>
  );
}
function SkillsSection({ sectionTitle }) {
  const [sharedDate] = useGetSharedProfile();

  const { t } = useTranslation();

  const placeHolder = [
    {
      name: t('Project Management'),
      type: 'hard'
    },
    {
      name: t('Teamwork'),
      type: 'soft'
    },
    {
      name: t('Problem Solving'),
      type: 'soft'
    },
    {
      name: t('Communication'),
      type: 'soft'
    },
    {
      name: t('Leadership'),
      type: 'soft'
    },
    {
      name: t('Time Management'),
      type: 'soft'
    },
    {
      name: t('Customer Service'),
      type: 'soft'
    },
    {
      name: t('Creativity'),
      type: 'soft'
    },
    {
      name: t('Adaptability'),
      type: 'soft'
    },
    {
      name: t('Negotiation'),
      type: 'soft'
    }
  ];


  const currentProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || currentProfileId;
  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );
  const { isLoading, isError, data, error } = !isSharedMode() ? result : sharedDate ?? {};
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const skills = data?.bio?.skills;
  return (
    <SkillsSectionView
      skillsList={skills && skills.length !== 0 ? skills : placeHolder}
      sectionTitle={sectionTitle}
    />
  );
}

export default SkillsSection;
